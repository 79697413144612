angular.module('portal')

.controller('EditQuoteModalCtrl', function ($rootScope, $scope, $log, $http, $uibModalInstance, $state, $stateParams, s4pDialogs, six4Auth, modalSettings) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.editFormModel = {};
    ctrl.dataLoading = false;

    ctrl.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    // Load Item
    ctrl.loadItem = function () {
        ctrl.dataLoading = true;
        return $http.get(portalSettings.apiUrl + '/quotes/' + modalSettings.clientId).then(function (response) {

            ctrl.dataLoading = false;
            ctrl.editFormModel = response.data.record;
            if (ctrl.editFormModel.document) {
                ctrl.oldDocument = portalSettings.filesUrl + ctrl.editFormModel.document.url;
            }
        });
    };


    // Submit Add Form
    ctrl.submitForm = function () {

        // Copy the model
        var item = angular.copy(ctrl.editFormModel);

        if (item.document) {
            item.documentId = item.document.id;
            delete item.document;
        }

        item.clientId = modalSettings.clientId;

        // Send the post request
        $http.patch(portalSettings.apiUrl + '/quotes', item, {
            form: ctrl.editForm
        }).then(function (response) {

            // Success message
            s4pDialogs.message({
                title: 'Saved!',
                message: 'Your changes have been saved',
                buttonText: "Close"
            }).result.then(function (result) {

                $uibModalInstance.close(response.data.record);

            });

            

        });

    };


    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function () {
        // Date picker options
        ctrl.datePickerOptions = {
            showWeeks: false,
            startingDay: 1,
            maxMode: 'day',
        };

        ctrl.loadItem();

    };

    init();

});