angular.module('portal')

.controller('EnquiriesCtrl', function ($rootScope, $scope, $log, $http, $state, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;


    // Get Enquiries
    ctrl.loadOutstandingEnquiries = function (keywords, company) {

        ctrl.outstandingDataLoading = true;

        var params = {
            start: ctrl.outstandingPagingModel.start,
            limit: ctrl.outstandingPagingModel.itemsPerPage,
            completed: false
        };

        if (keywords != undefined) {
            params.keywords = keywords
        }

        if (company!= undefined) {
            params.company = company
        }

        return $http.get(portalSettings.apiUrl + '/enquiries', { params: params }).then(function (response) {
            ctrl.outstandingPagingModel.totalItems = response.data.meta.totalCount;
            ctrl.outstandingPagingModel.totalPages = Math.ceil((ctrl.outstandingPagingModel.totalItems / ctrl.outstandingPagingModel.itemsPerPage));
            ctrl.outstandingTableData = response.data.records;
            ctrl.outstandingDataLoading = false;
        }, function (response) {
            ctrl.outstandingTableData = [];
            ctrl.outstandingDataLoading = false;
            s4pDialogs.message({ message: 'Sorry, there was a problem loading the enquiries.' });
        });

    };

    // Get Enquiries
    ctrl.loadCompletedEnquiries = function (keywords, company) {

        ctrl.completedDataLoading = true;

        var params = {
            start: ctrl.completedPagingModel.start,
            limit: ctrl.completedPagingModel.itemsPerPage,
            completed: true
        };

        if (keywords != undefined) {
            params.keywords = keywords
        }

        if (company != undefined) {
            params.company = company
        }

        return $http.get(portalSettings.apiUrl + '/enquiries', { params: params }).then(function (response) {
            ctrl.completedPagingModel.totalItems = response.data.meta.totalCount;
            ctrl.completedPagingModel.totalPages = Math.ceil((ctrl.completedPagingModel.totalItems / ctrl.completedPagingModel.itemsPerPage));
            ctrl.completedTableData = response.data.records;
            ctrl.completedDataLoading = false;
        }, function (response) {
            ctrl.completedTableData = [];
            ctrl.completedDataLoading = false;
            s4pDialogs.message({ message: 'Sorry, there was a problem loading the enquiries.' });
        });

    };

      ctrl.doSearch = function () {

        if (ctrl.searchLoading) {
            return;
        }

        ctrl.searchLoading = true;
        

        ctrl.loadOutstandingEnquiries(ctrl.searchForm.keywords, ctrl.searchForm.company).then(function () {
            ctrl.searchLoading = false;
        });

        ctrl.loadCompletedEnquiries(ctrl.searchForm.keywords, ctrl.searchForm.company).then(function () {
            ctrl.searchLoading = false;
            ctrl.sF.$resetForm();
        });
        
    };

    // Paging clicked
    ctrl.outstandingTablePageChanged = function () {
        ctrl.outstandingPagingModel.start = ((ctrl.outstandingPagingModel.currentPage - 1) * ctrl.outstandingPagingModel.itemsPerPage) + 1 + ctrl.outstandingPagingModel.offset;
        ctrl.loadOutstandingEnquiries();

        document.getElementsByTagName('s4p-view-main')[0].scrollTop = 0;
    };

    // Paging clicked
    ctrl.completedTablePageChanged = function () {
        ctrl.completedPagingModel.start = ((ctrl.completedPagingModel.currentPage - 1) * ctrl.completedPagingModel.itemsPerPage) + 1 + ctrl.completedPagingModel.offset;
        ctrl.loadCompletedEnquiries();

        document.getElementsByTagName('s4p-view-main')[0].scrollTop = 0;
    };


    // Row clicked
    ctrl.rowClicked = function (evt, row) {

        if (!$(evt.target).is('input[type=checkbox]')) {
            $state.go('enquiries.edit', {
                id: row.id
            });
        }

    };

    ctrl.refreshData = function () {

        ctrl.loadOutstandingEnquiries();
        ctrl.loadCompletedEnquiries();
    }

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function () {

        // Set up pagingModelMy 
        ctrl.outstandingPagingModel = {
            totalItems: 0,
            itemsPerPage: 50,
            pagesToShow: 5,
            currentPage: 1,
            offset: 0,
            start: 1
        };

        ctrl.completedPagingModel = {
            totalItems: 0,
            itemsPerPage: 50,
            pagesToShow: 5,
            currentPage: 1,
            offset: 0,
            start: 1
        };

        // Load first page
        ctrl.loadOutstandingEnquiries();
        ctrl.loadCompletedEnquiries();

    };

    init();

});