
/*******************************************************/
/*                Wheel Freedom Portal                 */
/*******************************************************/

// Create portal
var portal = angular.module('portal', ['s4p'])

// Run block
.run(function ($rootScope, $state, $stateParams, $http) {

    'use strict';

   
    return $http.get(portalSettings.apiUrl + '/enquiries', { params: { completed: false }}).then(function (response) {
        $rootScope.badges.mainMenu.outstandingEnquiries = response.data.meta.totalCount;
    });


});
    
    






