angular.module('portal')

.controller('AddEnquiryCtrl', function ($rootScope, $scope, $log, $http, $state, $stateParams, s4pDialogs, six4Auth) {

	'use strict';
    
	var master = $rootScope.master;
	var ctrl = this;

    ctrl.addForm = {};
    ctrl.addFormMeta = {};
 
    
    // Submit Add Form
	ctrl.submitAddForm = function(){
		
        // Copy the model
		var item = angular.copy(ctrl.addForm);

   
        // Send the post request
		$http.post(portalSettings.apiUrl + '/enquiries', item, {
            form: ctrl.atF
        }).then(function(response){
            
            // Success message
			s4pDialogs.message({
				title: 'Saved!',
				message: 'Your enquiry has been added',
				buttonText: "Back to Enquiries"
			}).result.then(function(result){
				
                // Redirect to view page
				$state.go('enquiries');
				
			});
			
        });
		
	};
    
	
	///////////////////////////////////////////////////////////////
    //  Initialize View
	///////////////////////////////////////////////////////////////

	var init = function () {

        // Date picker options
        ctrl.datePickerOptions = {
            showWeeks: false,
            startingDay: 1,
            maxMode: 'day',
        };
        
	};

	init();

});