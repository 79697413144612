angular.module('portal')

.controller('EditClientCtrl', function ($rootScope, $scope, $log, $http, $state, $stateParams) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;


    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function () {

    };

    init();

});