'use strict';

////////////////////////////////////////////////////////////
//                    S4P Config File                     //
////////////////////////////////////////////////////////////


// Settings are wrapped in a self invoking function so that we don't pollute the global namespace
// At the end of the function we export them for node or the browser, depending on whether the function is being called by node
(function () {

    var s4pSettings = {

        apiUrl: 'https://s4papi.64digital.co.uk/api',
        templatesUrl: 'https://six4portal.blob.core.windows.net/dist/templates',
        dataFilesUrl: 'https://six4portal.blob.core.windows.net/dist/data',
        imagesUrl: 'https://six4portal.blob.core.windows.net/dist/images',
        iconSpriteUrl: 'https://six4portal.blob.core.windows.net/dist/images',
        filesUploadUrl: 'https://s4papi.64digital.co.uk/api/files',
        scriptsUrl: 'https://six4portal.blob.core.windows.net/dist/scripts',
        stylesUrl: 'https://six4portal.blob.core.windows.net/dist/stylesheets',
        translationsUrl: 'https://six4portal.blob.core.windows.net/dist/translations'

        // Export the settings for node, or attach to window for use in Angular app
    };if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = s4pSettings;
    } else {
        window.s4pSettings = s4pSettings;
    }
})();

/*******************************************************/
/*                    Six4 Portal                      */
/*******************************************************/

// Hack for iOS7 bug - Fixed in iOS8
if (navigator.userAgent.match(/iPad;.*CPU.*OS 7_\d/i)) {
    $('html').addClass('ipadios7');
}

// Create modules for directives, filters and services
angular.module('s4p.directives', []);
angular.module('s4p.filters', []);
angular.module('s4p.services', []);

var s4pDependencies = ['six4', 'six4.directives', 'six4.filters', 'six4.services', 's4p.directives', 's4p.filters', 's4p.services', 'ngAnimate', 'ngCookies', 'ngMessages', 'ngSanitize', 'ngTouch', 'validation.match', 'ui.bootstrap', 'ui.router', 'angularFileUpload', 'oc.lazyLoad'];

if (portalSettings.i18n) {
    s4pDependencies.push('pascalprecht.translate');
}

// Add Module dependencies
if (portalSettings.emailMarketing) {
    angular.module('s4p.modules.emailMarketing', []);
    s4pDependencies.push('s4p.modules.emailMarketing');
}

// Create portal
var s4p = angular.module('s4p', s4pDependencies)

// Run block
.run(["$rootScope", "$state", "$stateParams", function ($rootScope, $state, $stateParams) {

    'use strict';

    // Put the settings objects on the root scope

    $rootScope.s4pSettings = s4pSettings;
    $rootScope.portalSettings = portalSettings;

    /* Add the current router state to the root scope */
    $rootScope.stateParams = $stateParams;

    // Every time the stage changes
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

        // Add the new state and params to $rootScope so it's available everywhere
        $rootScope.state = toState;
        $rootScope.stateParams = toParams;

        // Close the main menu
        $rootScope.$broadcast('s4pMainMenu-close');
    });

    // Pre load templates here
}]);

/*******************************************************/
/*                     Config Auth                     */
/*******************************************************/

angular.module('s4p').config(["$provide", "six4AuthProvider", function ($provide, six4AuthProvider) {

    // Add s4p API to list of APIs the auth module will automatically attach tokens for.
    six4AuthProvider.addApi({
        url: s4pSettings.apiUrl,
        defaultTokenType: 'user'
    });

    // Add local API too
    six4AuthProvider.addApi({
        url: portalSettings.apiUrl,
        defaultTokenType: 'user'
    });

    // Force login on page load if no token is stored or token is invalid
    six4AuthProvider.forceLoginOnLoad(true);

    // Force login on logout to keep the user always logged in
    six4AuthProvider.forceLoginOnLogout(true);

    // Add required methods to six4Auth module
    $provide.decorator('six4Auth', ["$delegate", "$uibModal", "$log", "$q", "s4pDialogs", function ($delegate, $uibModal, $log, $q, s4pDialogs) {

        // REQUEST LOGIN FUNCTION
        // Add requestLogin() method to auth which will open the login/register window when required
        $delegate.requestLogin = function () {

            var modalInstance = $uibModal.open({
                templateUrl: s4pSettings.templatesUrl + '/loginRegisterModal.tpl.html',
                controller: 'LoginRegisterModalCtrl as loginRegisterModal',
                windowClass: 'loginRegisterModal modal--sticky modal--has-header modal--has-footer',
                backdrop: 'static'
            });

            return modalInstance.result;
        };

        // REFRESH TOKEN FUNCTION
        // Add refreshToken() method to auth which will allow it to get a refreshed token when it has expired (and is set to persist)
        $delegate.refreshToken = function () {

            return $http.get(s4pSettings.apiUrl + '/guesttoken').then(function (response) {
                return response.headers;
            });
        };

        // STATE CHANGE AUTH FAILED FUNCTION
        // Add stateChangeAuthFailed() method to tell the user when they try to go to a route they're not allowed to
        $delegate.stateChangeAuthFailed = function () {

            var dialogInstance = s4pDialogs.message({
                message: "Sorry, you're not allowed to access that page"
            });

            return dialogInstance.result;
        };

        return $delegate;
    }]);
}]);

/*******************************************************/
/*                 Config Breakpoints                  */
/*******************************************************/

// Configure breakpoints service
angular.module('s4p').config(["six4BreakpointsProvider", function (six4BreakpointsProvider) {

    six4BreakpointsProvider.setBreakpoints([{ minimum: 0 }, { minimum: 480 }, { minimum: 640 }, { minimum: 1000 }, { minimum: 1300 }]);
}]);
/*******************************************************/
/*                  Config Codemirror                  */
/*******************************************************/

// Configure icons service
angular.module('s4p').run(["$rootScope", function ($rootScope) {

    $rootScope.defaultCodeMirrorOptions = {
        lineNumbers: true,
        theme: 's4p',
        lineWrapping: false,
        indentUnit: 4,
        indentWithTabs: true,
        smartIndent: false,
        electricChars: false,
        styleSelectedText: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        mode: 'text/html'
    };
}]);

/*******************************************************/
/*                  Config HTTP Cache                  */
/*******************************************************/

angular.module('s4p').config(['$httpProvider', function ($httpProvider) {

    //initialize get if not there
    if (!$httpProvider.defaults.headers.get) {
        $httpProvider.defaults.headers.get = {};
    }

    // Answer edited to include suggestions from comments
    // because previous version of code introduced browser-related errors

    //disable IE ajax request caching
    $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';

    // extra
    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
    $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
}]);
/*******************************************************/
/*                     Config i18n                     */
/*******************************************************/

if (portalSettings.i18n) {

    // Configure i18n service
    angular.module('s4p').config(["$translateProvider", function ($translateProvider) {

        // Get languages from portalSettings
        var languages = portalSettings.i18n.languages;

        // Create language format tokens
        var languageKeyFormats = {};

        for (var key in languages) {

            if (!languages.hasOwnProperty(key)) continue;

            languageKeyFormats[languages[key].languageKeyFormat] = key;
        }

        $translateProvider.useSanitizeValueStrategy('sanitizeParameters').useLocalStorage().useStaticFilesLoader({
            files: [{
                prefix: s4pSettings.translationsUrl + '/core-',
                suffix: '.json'
            }, {
                prefix: portalSettings.translationsUrl + '/portal-',
                suffix: '.json'
            }]
        }).registerAvailableLanguageKeys(['en', 'fr', 'de', 'es', 'it'], {
            'en_*': 'en',
            'fr_*': 'fr',
            'de_*': 'de',
            'es_*': 'es',
            'it_*': 'it'
        }).determinePreferredLanguage();
    }]).run(["$rootScope", "$translate", function ($rootScope, $translate) {

        // Add initial language to the rootscope
        $rootScope.language = $translate.proposedLanguage();
        console.log("i18n: Initial language set to: " + $translate.proposedLanguage());

        // Watch for logins and set the correct language
        $rootScope.$on("six4Auth-login-success", function ($evt, credentials) {

            if (credentials.userDetails.language) {
                $translate.use(credentials.userDetails.language);
                console.log("i18n: Setting language on login: " + credentials.userDetails.language);
            }
        });
    }]).factory('s4pTranslateStorage', ["$window", function ($window) {

        var langKey;

        return {
            put: function put(name, value) {
                langKey = value;
                $window.localStorage.setItem(name, value);
                console.log("i18n: Storage - putting: ", name, value);
            },
            get: function get(name) {
                if (!langKey) {
                    langKey = $window.localStorage.getItem(name);
                    console.log("i18n: Storage - getting: ", name, langKey);
                }
            }
        };
    }]);
}

/*******************************************************/
/*                    Config Icons                     */
/*******************************************************/

// Configure icons service
angular.module('s4p').config(["s4pIconsProvider", function (s4pIconsProvider) {

    // Add Base icons
    var baseIcons = ['alarm', 'arrow-down', 'arrow-down-circle', 'arrow-left', 'arrow-left-circle', 'arrow-right', 'arrow-right-circle', 'arrow-up', 'arrow-up-circle', 'bin', 'bookmark', 'browser', 'bulleted-list', 'calendar', 'case', 'chevron-double-left', 'chevron-double-left-circle', 'chevron-double-right', 'chevron-double-right-circle', 'chevron-down', 'chevron-down-circle', 'chevron-left', 'chevron-left-bar', 'chevron-left-bar-circle', 'chevron-left-circle', 'chevron-right', 'chevron-right-bar', 'chevron-right-bar-circle', 'chevron-right-circle', 'chevron-up', 'chevron-up-circle', 'clock', 'cog', 'contacts', 'cross', 'cross-circle', 'cross-thick', 'curly-brackets', 'dashboard', 'delivery', 'document', 'document-add', 'document-edit', 'dot-menu', 'download', 'envelope', 'exclamation-mark', 'eye', 'file', 'file-audio', 'file-code', 'file-compressed', 'file-font', 'file-image', 'file-pdf', 'file-presentation', 'file-spreadsheet', 'file-vector', 'file-video', 'floppy-disk', 'folders', 'gallery', 'grid', 'globe', 'hamburger-menu', 'image', 'key', 'lab', 'link', 'log-out', 'magnifying-glass', 'masks', 'minus', 'minus-circle', 'mobile-phone', 'notifications', 'paper-aeroplane', 'people', 'person', 'person-admin', 'phone', 'plus', 'plus-circle', 'refresh-one-arrow', 'refresh-two-arrows', 'reports', 'reports-pie-chart', 'speech-bubble', 'star', 'tag', 'tick', 'tick-circle', 'tick-thick', 'upload', 'widgets'];

    baseIcons.forEach(function (item) {

        // If we're running locally, then we're getting the icons from devs4p so don't need to proxy them
        // If we're on the server then they may be coming from azure blob storage so need to be proxied.
        var prepend;

        if (window.location.hostname === 'localhost' || s4pSettings.iconSpriteUseProxy === false) {
            prepend = '';
        } else {
            prepend = '/fileproxy.php?file=';
        }

        s4pIconsProvider.registerIcon(item, prepend + s4pSettings.iconSpriteUrl + '/iconSprite.svg#' + item);
    });
}]);

/*******************************************************/
/*                  Config s4p-lazyLoad                */
/*******************************************************/

// Configure lazyload
angular.module('s4p').config(["$ocLazyLoadProvider", "s4pLazyLoadProvider", function ($ocLazyLoadProvider, s4pLazyLoadProvider) {

    $ocLazyLoadProvider.config({
        debug: false
    });

    // Codemirror
    s4pLazyLoadProvider.registerBundle({
        name: 'codemirror',
        files: [s4pSettings.scriptsUrl + '/codemirror/codemirror.min.js', s4pSettings.stylesUrl + '/codemirror.css']
    });
}]);

/*******************************************************/
/*                    Config Pusher                    */
/*******************************************************/

angular.module('s4p').config(["$provide", "s4pPusherProvider", function ($provide, s4pPusherProvider) {

    // Register the main connection if we're using pusher in this portal
    if (portalSettings.integrations && portalSettings.integrations.integrations && portalSettings.integrations.integrations.pusher) {

        var pusherSettings = portalSettings.integrations.integrations.pusher;

        s4pPusherProvider.registerConnection({
            name: 'main',
            applicationKey: pusherSettings.appKey,
            options: {
                cluster: 'eu',
                encrypted: true,
                authEndpoint: s4pSettings.apiUrl + '/pusher/auth',
                auth: {
                    headers: {}
                }
            }

        });
    }
}]).run(["$rootScope", "six4Auth", "s4pDialogs", "s4pPusher", function ($rootScope, six4Auth, s4pDialogs, s4pPusher) {

    if (portalSettings.integrations && portalSettings.integrations.integrations && portalSettings.integrations.integrations.pusher) {

        var connection = s4pPusher.getConnection();

        // Add and remove auth token on every login and logout
        $rootScope.$on('six4Auth-loggedin-true', function (event, credentials) {
            connection.config.auth.headers['Authentication-Token'] = six4Auth.credentials().token;
        });
        $rootScope.$on('six4Auth-loggedin-false', function (event, credentials) {
            delete connection.config.auth.headers['Authentication-Token'];
        });
    }
}]);

/*******************************************************/
/*                   Config Routing                    */
/*******************************************************/

angular.module('s4p').config(["$stateProvider", "$uiViewScrollProvider", "$urlRouterProvider", "$locationProvider", "$urlMatcherFactoryProvider", function ($stateProvider, $uiViewScrollProvider, $urlRouterProvider, $locationProvider, $urlMatcherFactoryProvider) {

    'use strict';

    // use the HTML5 History API

    $locationProvider.html5Mode(false);

    // UI router scrolling
    $uiViewScrollProvider.useAnchorScroll();

    // non strict mode redirects when there's a trainling slash
    $urlMatcherFactoryProvider.strictMode(false);

    // Create all app states/routes
    $urlRouterProvider.otherwise('/dashboard');

    $stateProvider

    /* Admin */
    .state('admin', {
        url: "/admin",
        abstract: true,
        template: '<ui-view/>'

    }).state('admin.users', {
        url: '/users',
        views: { '@': {
                templateUrl: s4pSettings.templatesUrl + '/adminUsers.tpl.html',
                controller: 'AdminUsersCtrl as adminUsers'
            } },
        data: {
            pageTitle: 'Users',
            minAuthLevel: 2
        }
    }).state('admin.users.add', {
        url: '/add',
        views: { '@': {
                templateUrl: s4pSettings.templatesUrl + '/adminAddUser.tpl.html',
                controller: 'AdminAddUserCtrl as adminAddUser'
            } },
        data: {
            pageTitle: 'Add a User',
            minAuthLevel: 2
        }
    }).state('admin.users.edit', {
        url: '/{id}',
        views: { '@': {
                templateUrl: s4pSettings.templatesUrl + '/adminEditUser.tpl.html',
                controller: 'AdminEditUserCtrl as adminEditUser'
            } },
        data: {
            pageTitle: 'Edit User',
            minAuthLevel: 2
        }
    })

    // User
    .state('user', {
        url: "/user",
        abstract: true,
        template: '<ui-view/>'

    });

    // Notifications
    if (portalSettings.notifications) {

        $stateProvider

        // Admin Notifications
        .state('admin.notifications', {
            url: '/notifications',
            templateUrl: s4pSettings.templatesUrl + '/adminNotifications.tpl.html',
            controller: 'AdminNotificationsCtrl as adminNotifications',
            data: {
                pageTitle: 'Notifications',
                minAuthLevel: 2
            }
        }).state('admin.notifications.add', {
            url: '/add',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminAddNotification.tpl.html',
                    controller: 'AdminAddNotifCtrl as adminAddNotif'
                } },
            data: {
                pageTitle: 'Add a Notification',
                minAuthLevel: 1
            }
        }).state('admin.notifications.edit', {
            url: '/{id}',
            params: {
                tabId: null
            },
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminEditNotification.tpl.html',
                    controller: 'AdminEditNotifCtrl as adminEditNotif'
                } },
            data: {
                pageTitle: 'Edit Notification',
                minAuthLevel: 2
            }
        })

        // Admin Notification Tokens
        .state('admin.notificationTokens', {
            url: '/notification-tokens',
            abstract: true,
            template: '<ui-view/>'
        }).state('admin.notificationTokens.add', {
            url: '/add',
            params: {
                notificationId: null
            },
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminAddNotificationToken.tpl.html',
                    controller: 'AdminAddNotifTokenCtrl as adminAddNotifToken'
                } },
            data: {
                pageTitle: 'Add a Token',
                minAuthLevel: 1
            }
        }).state('admin.notificationTokens.edit', {
            url: '/{id}',
            params: {
                notificationId: null
            },
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminEditNotificationToken.tpl.html',
                    controller: 'AdminEditNotifTokenCtrl as adminEditNotifToken'
                } },
            data: {
                pageTitle: 'Edit a Token',
                minAuthLevel: 1
            }
        })

        // Admin Notification Groups
        .state('admin.notificationGroups', {
            url: '/notification-groups',
            templateUrl: s4pSettings.templatesUrl + '/adminNotificationGroups.tpl.html',
            controller: 'AdminNotificationGroupsCtrl as adminNotificationGroups',
            data: {
                pageTitle: 'Notification Groups',
                minAuthLevel: 2
            }
        }).state('admin.notificationGroups.add', {
            url: '/add',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminAddNotificationGroup.tpl.html',
                    controller: 'AdminAddNotifGroupCtrl as adminAddNotifGroup'
                } },
            data: {
                pageTitle: 'Add Notification Group',
                minAuthLevel: 2
            }
        }).state('admin.notificationGroups.edit', {
            url: '/{id}',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminEditNotificationGroup.tpl.html',
                    controller: 'AdminEditNotifGroupCtrl as adminEditNotifGroup'
                } },
            data: {
                pageTitle: 'Edit Notification Group',
                minAuthLevel: 2
            }
        })

        // Admin Notification Headings
        .state('admin.notificationHeadings', {
            url: '/headings',
            templateUrl: s4pSettings.templatesUrl + '/adminNotificationHeadings.tpl.html',
            controller: 'AdminNotificationHeadingsCtrl as adminNotificationHeadings',
            data: {
                pageTitle: 'Notification Headings',
                minAuthLevel: 1
            }
        }).state('admin.notificationHeadings.add', {
            url: '/add',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminAddNotificationHeading.tpl.html',
                    controller: 'AdminAddNotifHeadingCtrl as adminAddNotifHeading'
                } },
            data: {
                pageTitle: 'Add Notification Heading',
                minAuthLevel: 1
            }
        }).state('admin.notificationHeadings.edit', {
            url: '/{id}',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminEditNotificationHeading.tpl.html',
                    controller: 'AdminEditNotifHeadingCtrl as adminEditNotifHeading'
                } },
            data: {
                pageTitle: 'Edit Notification Heading',
                minAuthLevel: 1
            }
        })

        // User Notifications
        .state('notifications', {
            url: '/notifications',
            templateUrl: s4pSettings.templatesUrl + '/notifications.tpl.html',
            controller: 'NotificationsCtrl as notifications',
            data: {
                pageTitle: 'Your Notifications'
            }
        });
    }

    // Permissions
    if (portalSettings.permissions) {

        $stateProvider

        // Admin Permissions
        .state('admin.permissions', {
            url: '/permissions',
            templateUrl: s4pSettings.templatesUrl + '/adminPermissions.tpl.html',
            controller: 'AdminPermissionsCtrl as adminPermissions',
            data: {
                pageTitle: 'Permissions',
                minAuthLevel: 1
            }
        }).state('admin.permissions.add', {
            url: '/add',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminAddPermission.tpl.html',
                    controller: 'AdminAddPermissionCtrl as adminAddPermission'
                } },
            data: {
                pageTitle: 'Add a Permission',
                minAuthLevel: 1
            }
        }).state('admin.permissions.edit', {
            url: '/{id}',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminEditPermission.tpl.html',
                    controller: 'AdminEditPermissionCtrl as adminEditPermission'
                } },
            data: {
                pageTitle: 'Edit Permission',
                minAuthLevel: 1
            }
        })

        // Admin Permission Groups
        .state('admin.permissionGroups', {
            url: '/permission-groups',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminPermissionGroups.tpl.html',
                    controller: 'AdminPermissionGroupsCtrl as adminPermissionGroups'
                } },
            data: {
                pageTitle: 'Permission Groups',
                minAuthLevel: 1
            }
        }).state('admin.permissionGroups.add', {
            url: '/add',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminAddPermissionGroup.tpl.html',
                    controller: 'AdminAddPermissionGroupCtrl as adminAddPermissionGroup'
                } },
            data: {
                pageTitle: 'Add a Permission Group',
                minAuthLevel: 1
            }
        }).state('admin.permissionGroups.addTable', {
            url: '/add-table-group',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminAddTablePermissionGroup.tpl.html',
                    controller: 'AdminAddTablePermissionGroupCtrl as adminAddTablePermissionGroup'
                } },
            data: {
                pageTitle: 'Add Table Permissions Group',
                minAuthLevel: 1
            }
        }).state('admin.permissionGroups.edit', {
            url: '/{id}',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminEditPermissionGroup.tpl.html',
                    controller: 'AdminEditPermissionGroupCtrl as adminEditPermissionGroup'
                } },
            data: {
                pageTitle: 'Edit Permission Group',
                minAuthLevel: 1
            }
        })

        // Admin Roles
        .state('admin.roles', {
            url: '/roles',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminRoles.tpl.html',
                    controller: 'AdminRolesCtrl as adminRoles'
                } },
            data: {
                pageTitle: 'Roles',
                minAuthLevel: 2
            }
        }).state('admin.roles.add', {
            url: '/add',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminAddRole.tpl.html',
                    controller: 'AdminAddRoleCtrl as adminAddRole'
                } },
            data: {
                pageTitle: 'Add a Role',
                minAuthLevel: 2
            }
        }).state('admin.roles.edit', {
            url: '/{id}',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminEditRole.tpl.html',
                    controller: 'AdminEditRoleCtrl as adminEditRole'
                } },
            data: {
                pageTitle: 'Edit Role',
                minAuthLevel: 2
            }
        });
    }

    // Data
    if (portalSettings.data) {

        $stateProvider

        // Data
        .state('admin.data', {
            url: '/data',
            abstract: true,
            template: '<ui-view/>'
        }).state('admin.data.config', {
            url: '/config',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminEditDataConfig.tpl.html',
                    controller: 'AdminEditDataConfigCtrl as adminEditDataConfig'
                } },
            resolve: {
                dependencies: ["s4pLazyLoad", function (s4pLazyLoad) {
                    return s4pLazyLoad.loadBundle('codemirror');
                }]
            },
            data: {
                pageTitle: 'Edit Data Config',
                minAuthLevel: 1
            }
        }).state('data', {
            url: "/data",
            abstract: true,
            template: '<ui-view/>'

        }).state('data.table', {
            url: '/{tableName}',
            templateUrl: s4pSettings.templatesUrl + '/viewData.tpl.html',
            controller: 'ViewDataCtrl as viewData',
            dynamicPermissions: true
        }).state('data.table.add', {
            url: '/add',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/addData.tpl.html',
                    controller: 'AddDataCtrl as addData'
                } },
            dynamicPermissions: true
        }).state('data.table.edit', {
            url: '/{id}',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/editData.tpl.html',
                    controller: 'EditDataCtrl as editData'
                } },
            dynamicPermissions: true
        });
    }

    // User Settings
    if (portalSettings.users && portalSettings.users.userSettings) {

        $stateProvider.state('user.settings', {
            url: '/settings',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/userSettings.tpl.html',
                    controller: 'UserSettingsCtrl as userSettings'
                } },
            data: {
                pageTitle: 'User settings'
            }
        });
    }

    //Integrations
    if (portalSettings.integrations) {

        $stateProvider.state('admin.integrations', {
            url: '/integrations',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminIntegrations.tpl.html',
                    controller: 'AdminIntegrationsCtrl as adminIntegrations'
                } },
            data: {
                pageTitle: 'Integrations',
                minAuthLevel: 2
            }
        }).state('admin.integrations.add', {
            url: '/add/{id}',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminAddIntegration.tpl.html',
                    controller: 'AdminAddIntegrationCtrl as adminAddIntegration'
                } },
            data: {
                pageTitle: 'Add a Role',
                minAuthLevel: 2
            }
        }).state('admin.integrations.edit', {
            url: '/{id}',
            views: { '@': {
                    templateUrl: s4pSettings.templatesUrl + '/adminEditIntegration.tpl.html',
                    controller: 'AdminEditIntegrationCtrl as adminEditIntegration'
                } },
            data: {
                pageTitle: 'Edit Integration'
            }
        });
    }
}]);
/*******************************************************/
/*                  Config Templates                   */
/*******************************************************/

angular.module("uib/template/modal/backdrop.html", []).run(["$templateCache", function ($templateCache) {
    $templateCache.put("uib/template/modal/backdrop.html", "<div class=\"modal-backdrop\"\n" + "     uib-modal-animation-class=\"animate\"\n" + "     modal-in-class=\"in\"\n" + "     ng-style=\"{'z-index': 1040 + (index && 1 || 0) + index*10}\"\n" + "></div>\n" + "");
}]);

angular.module("uib/template/modal/window.html", []).run(["$templateCache", function ($templateCache) {
    $templateCache.put("uib/template/modal/window.html", "<div modal-render=\"{{$isRendered}}\" tabindex=\"-1\" role=\"dialog\" class=\"modal\"\n" + "    uib-modal-animation-class=\"animate\"\n" + "    modal-in-class=\"in\"\n" + "    ng-style=\"{'z-index': 1050 + index*10, display: 'block'}\">\n" + "    <div class=\"modal-dialog {{size ? 'modal-' + size : ''}}\"><div class=\"modal-content\" uib-modal-transclude></div></div>\n" + "</div>\n" + "");
}]);

angular.module("uib/template/pagination/pagination.html", []).run(["$templateCache", function ($templateCache) {
    $templateCache.put("uib/template/pagination/pagination.html", "<uib-pagination-inner>" + "<uib-pagination-first ng-if=\"::boundaryLinks\" ng-class=\"{disabled: noPrevious()||ngDisabled}\">" + "<s4p-button button-style=\"circle\" icon=\"chevron-left-bar-circle\" ng-click=\"selectPage(1, $event)\"></s4p-button>" + "</uib-pagination-first>\n" + "<uib-pagination-previous ng-if=\"::directionLinks\" ng-class=\"{disabled: noPrevious()||ngDisabled}\">" + "<s4p-button button-style=\"circle\" icon=\"chevron-left-circle\" ng-click=\"selectPage(page - 1, $event)\"></s4p-button>" + "</uib-pagination-previous>\n" + "<uib-pagination-numbers>" + "<uib-pagination-number ng-repeat=\"page in pages track by $index\" ng-class=\"{active: page.active,disabled: ngDisabled&&!page.active}\">" + "<s4p-button button-style=\"circle\" ng-click=\"selectPage(page.number, $event)\">{{page.text}}</s4p-button>" + "</uib-pagination-number>" + "</uib-pagination-numbers>" + "<uib-pagination-next ng-if=\"::directionLinks\" ng-class=\"{disabled: noNext()||ngDisabled}\">" + "<s4p-button button-style=\"circle\" icon=\"chevron-right-circle\" ng-click=\"selectPage(page + 1, $event)\"></s4p-button>" + "</uib-pagination-next>\n" + "<uib-pagination-last ng-if=\"::boundaryLinks\" ng-class=\"{disabled: noNext()||ngDisabled}\">" + "<s4p-button button-style=\"circle\" icon=\"chevron-right-bar-circle\" ng-click=\"selectPage(totalPages, $event)\"></s4p-button>" + "</uib-pagination-last>\n" + "</uib-pagination-inner>");
}]);

angular.module("uib/template/datepicker/datepicker.html", []).run(["$templateCache", function ($templateCache) {
    $templateCache.put("uib/template/datepicker/datepicker.html", '<div class="uib-datepicker" ng-switch="datepickerMode" role="application" ng-keydown="keydown($event)">' + '<uib-daypicker ng-switch-when="day" tabindex="0"></uib-daypicker>' + '<uib-monthpicker ng-switch-when="month" tabindex="0"></uib-monthpicker>' + '<uib-yearpicker ng-switch-when="year" tabindex="0"></uib-yearpicker>' + '</div>' + '');
}]);

angular.module("uib/template/datepicker/day.html", []).run(["$templateCache", function ($templateCache) {
    $templateCache.put("uib/template/datepicker/day.html", '<table class="uib-daypicker" ng-class="{\'is-showWeeks\':showWeeks}">' + '<thead>' +

    // Top Row
    '<tr class="uib-daypicker-title-row">' +

    // Previous
    '<th class="uib-daypicker-previous-cell">' + '<s4p-button type="button" button-style="circle" icon="chevron-left-circle" ng-click="move(-1)" tabindex="-1"></s4p-button>' + '</th>' +

    // Title
    '<th class="uib-daypicker-title-cell" colspan="{{::5 + showWeeks}}">' + '<trigger class="uib-daypicker-title" ng-click="toggleMode()" ng-disabled="datepickerMode === maxMode">{{title}}</trigger>' + '</th>' +

    // Next
    '<th class="uib-daypicker-next-cell">' + '<s4p-button type="button" button-style="circle" icon="chevron-right-circle" ng-click="move(1)" tabindex="-1"></s4p-button>' + '</th>' + '</tr>' +

    // Labels Row
    '<tr class="uib-daypicker-labels-row">' + '<th ng-if="showWeeks" class="uib-daypicker-week-number"></th>' + '<th ng-repeat="label in ::labels track by $index" class="uib-daypicker-label">{{::label.abbr}}</th>' + '</tr>' + '</thead>' + '<tbody>' +

    // Week
    '<tr class="uib-daypicker-week" ng-repeat="row in rows track by $index">' +

    // Week Number
    '<td class="uib-daypicker-week-number" ng-if="showWeeks"><em>{{weekNumbers[$index]}}</em></td>' +

    // Days
    '<td ng-repeat="dt in row" ' + 'id="{{::dt.uid}}"' + 'class="uib-daypicker-day"' +

    // The following line was changed because there is a bug in uib-datepicker - https://github.com/angular-ui/bootstrap/issues/6318
    // It originally looked like this...
    // 'ng-class="::[dt.customClass, {\'is-today\': dt.current}]"' +
    // 'uib-is-class="\'is-selected\' for selectedDt, \'is-active\' for activeDt on dt"' + 
    'ng-class="::[dt.customClass, {\'is-today\': dt.current}, {\'is-selected\': dt == selectedDt}, {\'is-active\': dt == activeDt}, {\'is-secondary\': datepicker.activeDate.getMonth() !== dt.date.getMonth()}]"' + 'ng-disabled="::dt.disabled"' + '>' + '<button type="button" ng-click="select(dt.date)" tabindex="-1" ng-disabled="::dt.disabled">' + '<span>{{::dt.label}}</span>' + '</button>' + '</td>' + '</tr>' + '</tbody>' + '</table>' + '');
}]);

angular.module("uib/template/datepicker/month.html", []).run(["$templateCache", function ($templateCache) {
    $templateCache.put("uib/template/datepicker/month.html", '<table class="uib-monthpicker" role="grid" aria-labelledby="{{::uniqueId}}-title" aria-activedescendant="{{activeDateId}}">' + '<thead>' +

    // Top Row
    '<tr class="uib-monthpicker-title-row">' +

    // Previous
    '<th class="uib-monthpicker-previous-cell">' + '<s4p-button type="button" button-style="circle" icon="chevron-left-circle" ng-click="move(-1)" tabindex="-1"></s4p-button>' + '</th>' +

    // Title
    '<th class="uib-monthpicker-title-cell">' + '<trigger id="{{::uniqueId}}-title" role="heading" class="uib-monthpicker-title" ng-click="toggleMode()" ng-disabled="datepickerMode === maxMode">{{title}}</trigger>' + '</th>' +

    // Next
    '<th class="uib-monthpicker-next-cell">' + '<s4p-button type="button" button-style="circle" icon="chevron-right-circle" ng-click="move(1)" tabindex="-1"></s4p-button>' + '</th>' + '</tr>' + '</thead>' + '<tbody>' + '<tr class="uib-monthpicker-spacer-row"><td colspan="3">&nbsp;</td></tr>' + '<tr class="uib-monthpicker-row" ng-repeat="row in rows track by $index">' +

    // Months
    '<td ng-repeat="dt in row" ' + 'id="{{::dt.uid}}"' + 'class="uib-monthpicker-month"' + 'ng-class="::[dt.customClass, {\'is-today\': dt.current}, {\'is-selected\': dt == selectedDt}, {\'is-active\': dt == activeDt}]"' + '>' + '<button type="button" ng-click="select(dt.date)" tabindex="-1" ng-disabled="::dt.disabled">' + '<span>{{::dt.label}}</span>' + '</button>' + '</td>' + '</tr>' + '</tbody>' + '</table>');
}]);

angular.module("uib/template/datepicker/year.html", []).run(["$templateCache", function ($templateCache) {
    $templateCache.put("uib/template/datepicker/year.html", '<table class="uib-yearpicker" role="grid" aria-labelledby="{{::uniqueId}}-title" aria-activedescendant="{{activeDateId}}">' + '<thead>' + '<tr class="uib-yearpicker-title-row">' +

    // Previous
    '<th class="uib-yearpicker-previous-cell">' + '<s4p-button type="button" button-style="circle" icon="chevron-left-circle" ng-click="move(-1)" tabindex="-1"></s4p-button>' + '</th>' +

    // Title
    '<th class="uib-yearpicker-title-cell" colspan="3">' + '<trigger id="{{::uniqueId}}-title" role="heading" class="uib-yearpicker-title" ng-click="toggleMode()" ng-disabled="datepickerMode === maxMode">{{title}}</trigger>' + '</th>' +

    // Next
    '<th class="uib-yearpicker-next-cell">' + '<s4p-button type="button" button-style="circle" icon="chevron-right-circle" ng-click="move(1)" tabindex="-1"></s4p-button>' + '</th>' + '</tr>' + '</thead>' + '<tbody>' + '<tr class="uib-yearpicker-spacer-row"><td colspan="5">&nbsp;</td></tr>' + '<tr class="uib-yearpicker-row" ng-repeat=\"row in rows track by $index\">' +

    // Years
    '<td ng-repeat="dt in row" ' + 'id="{{::dt.uid}}"' + 'class="uib-yearpicker-year"' + 'ng-class="::[dt.customClass, {\'is-today\': dt.current}, {\'is-selected\': dt == selectedDt}, {\'is-active\': dt == activeDt}]"' + '>' + '<button type="button" ng-click="select(dt.date)" tabindex="-1" ng-disabled="::dt.disabled">' + '<span>{{::dt.label}}</span>' + '</button>' + '</td>' + '</tr>' + '</tbody>' + '</table>');
}]);

///////////////////////////////////////////////////
//              Portal Configuration             //
///////////////////////////////////////////////////

angular.module('s4p')

// Configure cache busting for HTML templates
.config(["$provide", function ($provide) {

    if (six4Setting('templateCacheBusting')) {

        return $provide.decorator("$http", ["$delegate", function ($delegate) {

            var get = $delegate.get;

            $delegate.get = function (url, config) {

                // Check is to avoid breaking AngularUI ui-bootstrap-tpls.js: "template/accordion/accordion-group.html"
                if (!~url.indexOf('template/')) {
                    url += url.indexOf("?") === -1 ? "?" : "&";
                    url += new Date().getTime();
                }
                return get(url, config);
            };

            return $delegate;
        }]);
    }
}])

// Configure trusted resources for templates etc.
.config(["$sceDelegateProvider", function ($sceDelegateProvider) {

    $sceDelegateProvider.resourceUrlWhitelist([

    // Allow same origin resource loads.
    'self',

    // Allow loading from our assets domain.  Notice the difference between * and **.
    s4pSettings.templatesUrl + '/**']);
}])

// File Uploader default Url
.config(["s4pFileUploadConfigProvider", function (s4pFileUploadConfigProvider) {
    s4pFileUploadConfigProvider.setDefaultUploadUrl(portalSettings.filesUploadUrl);
}])

// Get some portal settings to put on $rootScope
.run(["$rootScope", function ($rootScope) {

    $rootScope.badges = {
        mainMenu: {}
    };
}]);

/*******************************************************/
/*                Six4 Portal Filters                  */
/*******************************************************/

var s4pFilters = angular.module('s4p.filters');

// Example Filter
s4pFilters.filter('example', function () {
    return function (input) {

        return {};
    };
});

/*******************************************************/
/*                S4P Translate Filter                 */
/*******************************************************/

s4pFilters.filter('s4pTranslate', ["$parse", "$injector", function ($parse, $injector) {

    'use strict';

    // If translation isn't switched on then return a filter which does nothing

    if (!portalSettings.i18n) {
        var noTranslateFilter = function noTranslateFilter(input) {
            return input;
        };
        return noTranslateFilter;
    }

    // Else, if translation is switched on
    var $translate = $injector.get('$translate');;

    var translateFilter = function translateFilter(input, translationId, interpolateParams, interpolation, forceLanguage) {

        if ($translate.use() === 'en') {
            return input;
        }

        if (!angular.isObject(interpolateParams)) {
            var ctx = this || { '__SCOPE_IS_NOT_AVAILABLE': 'More info at https://github.com/angular/angular.js/commit/8863b9d04c722b278fa93c5d66ad1e578ad6eb1f' };
            interpolateParams = $parse(interpolateParams)(ctx);
        }

        var translation = $translate.instant(translationId, interpolateParams, interpolation, forceLanguage);

        return translation === translationId ? input : translation;
    };

    if ($translate.statefulFilter()) {
        translateFilter.$stateful = true;
    }

    return translateFilter;
}]);
/*******************************************************/
/*                      S4P Data                       */
/*******************************************************/

angular.module('s4p.services').provider('s4pData', function () {

    'use strict';

    ////////////////////////////////////////
    //          Default Settings          //
    ////////////////////////////////////////

    var _config;
    var _compiledConfig;
    var _loadingConfig;
    var _configLastLoadedTime;

    ////////////////////////////////////////
    //             Initialize             //
    ////////////////////////////////////////

    init();

    ////////////////////////////////////////
    //             Public API             //
    ////////////////////////////////////////


    instantiateService.$inject = ["$rootScope", "$log", "$q", "$http", "six4Auth"];
    return {

        defaultFunction: defaultFunction,

        $get: instantiateService

    };

    // Register Connection
    function defaultFunction(settings) {}

    // Do something

    ////////////////////////////////////////
    //          Private Methods           //
    ////////////////////////////////////////


    // Private methods go here


    // Private Function Example
    /*
    function _privateFn(){}
    */

    // Public Methods
    //
    // getConfig(name)         Returns the current config.


    ////////////////////////////////////////
    //              Service               //
    ////////////////////////////////////////

    function instantiateService($rootScope, $log, $q, $http, six4Auth) {

        // Return the public API.
        return {
            loadConfig: loadConfig,
            compileConfig: compileConfig,
            getCompiledConfig: getCompiledConfig,
            getViewPageConfig: getViewPageConfig,
            getViewTableConfig: getViewTableConfig,
            getAddPageConfig: getAddPageConfig,
            getEditPageConfig: getEditPageConfig,
            getFormConfig: getFormConfig
        };

        // ---
        // PUBLIC METHODS.
        // ---

        // Loads the current config.
        function loadConfig() {

            // TODO - Remove
            //return $q(function(resolve, reject) {  
            //    resolve(_testConfig);
            //});

            var now = new Date().getTime();
            var getNewConfig;

            // If there's no config, or it's been in the cache for more than 5 minutes.
            if (_config === undefined || now - _configLastLoadedTime > 60000) {

                // If config isn't already loading
                if (!_loadingConfig) {

                    _loadingConfig = true;
                    _configLastLoadedTime = now;

                    var params = {
                        key: 'dataConfig'
                    };

                    return $http.get(s4pSettings.apiUrl + '/portalSettings', { params: params }).then(function (response) {

                        console.log('Data config: Loaded', JSON.parse(response.data.records[0].value));

                        _config = JSON.parse(response.data.records[0].value);
                        _compiledConfig = compileConfig(_config);
                        _loadingConfig = false;
                        return _config;
                    });
                }

                // Otherwise if config load is in progress
                else {

                        var deferred = $q.defer();

                        var listener = $rootScope.$watch(function () {
                            return _config;
                        }, function (newValue, oldValue) {

                            if (newValue && newValue !== oldValue) {
                                console.log('Data config: Got config which was loading', angular.copy(newValue));
                                deferred.resolve(newValue);
                                listener();
                            }
                        });

                        return deferred.promise;
                    }
            } else {

                console.log('Data config: Found in cache', _config);

                return $q(function (resolve, reject) {
                    resolve(_config);
                });
            }
        };

        // Compile Config
        function compileConfig(originalConfig) {

            console.time('Data config: Compiled config');

            var compiledConfig = originalConfig;

            // Check there are some tables to transform
            if (compiledConfig.tables === undefined) {
                $log.error('Data config: No tables found');
                return;
            }

            compiledConfig.tables.forEach(function (table) {

                // Check minimal properties exist on uncompiled config
                var requiredProperties = ['name', 'nameSingular', 'friendlyName', 'friendlyNameSingular', 'primaryKey', 'columns'];

                // Check the table has a primary key and table name
                requiredProperties.forEach(function (propertyName) {
                    if (!table.hasOwnProperty(propertyName)) {
                        $log.error('Data Config: Table found with missing property - ' + propertyName);
                    }
                });

                // Security
                table.security = table.security || {};

                if (table.security.autoTablePermissions) {
                    var capitalizeFirstLetter = function capitalizeFirstLetter(string) {
                        return string.charAt(0).toUpperCase() + string.slice(1);
                    };

                    table.security = {
                        all: {
                            permissions: ['table' + capitalizeFirstLetter(table.name) + 'Manage']
                        },
                        view: {
                            permissions: ['table' + capitalizeFirstLetter(table.name) + 'View']
                        },
                        add: {
                            permissions: ['table' + capitalizeFirstLetter(table.name) + 'Add']
                        },
                        edit: {
                            permissions: ['table' + capitalizeFirstLetter(table.name) + 'Edit']
                        },
                        delete: {
                            permissions: ['table' + capitalizeFirstLetter(table.name) + 'Delete']
                        }
                    };
                }

                table.security.all = table.security.all || {};

                table.security.view = angular.merge({}, table.security.all, table.security.view);
                table.security.add = angular.merge({}, table.security.all, table.security.add);
                table.security.edit = angular.merge({}, table.security.all, table.security.edit);
                table.security.delete = angular.merge({}, table.security.all, table.security.delete);

                // Mixins for column definitions
                table.columns = table.columns || [];

                if (table.columnsBeforeMixin) {
                    if (originalConfig.mixins && originalConfig.mixins[table.columnsBeforeMixin]) {
                        table.columns = originalConfig.mixins[table.columnsBeforeMixin].concat(table.columns);
                    } else {
                        console.error('Data config: Mixin not found - ', table.columnsBeforeMixin);
                    }
                }

                if (table.columnsAfterMixin) {
                    if (originalConfig.mixins && originalConfig.mixins[table.columnsAfterMixin]) {
                        table.columns = table.columns.concat(originalConfig.mixins[table.columnsAfterMixin]);
                    } else {
                        console.error('Data config: Mixin not found - ', table.columnsBeforeMixin);
                    }
                }

                // Columns
                table.columns.forEach(function (column) {

                    // Check the column has a name
                    if (column.name === undefined) {
                        $log.error('Data Config: Table ' + table.name + ' has a column with no name');
                    }

                    // String is the default type
                    column.type = column.type || 'string';

                    // Default displayValue to be the same as name if not supplied
                    column.displayValue = column.displayValue || column.name;

                    // Column security
                    column.security = column.security || {};
                    column.security.view = column.security.view || {};
                    column.security.add = column.security.add || {};
                    column.security.edit = column.security.edit || {};
                    column.security.delete = column.security.delete || {};
                    column.security.all = column.security.all || {};

                    // Calculate column security
                    function calculateColumnSecurity(actionName) {

                        var actionMinAuthLevel = column.security[actionName].minAuthLevel || column.security.all.minAuthLevel || table.security[actionName].minAuthLevel || table.security.all.minAuthLevel;

                        var actionPermissions = column.security[actionName].permissions || column.security.all.permissions || table.security[actionName].permissions || table.security.all.permissions;

                        var actionSecurity = {};

                        if (actionMinAuthLevel !== undefined) {
                            actionSecurity.minAuthLevel = actionMinAuthLevel;
                        }

                        if (actionPermissions !== undefined) {
                            actionSecurity.permissions = actionPermissions;
                        }

                        return actionSecurity;
                    }

                    column.security.view = calculateColumnSecurity('view');
                    column.security.add = calculateColumnSecurity('add');
                    column.security.edit = calculateColumnSecurity('edit');
                    column.security.delete = calculateColumnSecurity('delete');

                    delete column.security.all;
                });

                delete table.security.all;

                // Mixins for relationships
                table.relationships = table.relationships || [];

                if (table.relationshipsMixin) {
                    if (originalConfig.mixins && originalConfig.mixins[table.relationshipsMixin]) {
                        table.relationships = table.relationships.concat(originalConfig.mixins[table.relationshipsMixin]);
                    } else {
                        console.error('Data config: Mixin not found - ', table.relationshipsMixin);
                    }
                }

                // Relationships 
                if (table.relationships.length > 0) {

                    table.relationships.forEach(function (relationship) {

                        // var matchedTable = compiledConfig.tables.filter(function(item){
                        // return item.name === relationship.name;
                        // })[0] || {};

                        if (!relationship.name) {
                            console.error('Data config: Relationship found without a name', angular.copy(relationship));
                        }

                        // Many To One
                        if (relationship.type === 'manyToOne') {

                            if (!relationship.table) {

                                var matchedTable = compiledConfig.tables.filter(function (item) {
                                    return item.nameSingular === relationship.name;
                                })[0] || {};

                                if (matchedTable) {
                                    relationship.table = matchedTable.name;
                                } else {
                                    console.error('Data config: Relationship has no "table" property and no matching table called ' + relationship.name + ' can be found', angular.copy(relationship));
                                }
                            }

                            relationship.primaryKey = relationship.primaryKey || 'id';
                            relationship.foreignKey = relationship.foreignKey || relationship.name + 'Id';
                            relationship.displayValue = relationship.displayValue || 'name';
                        }

                        // One To Many
                        // TODO


                        // Many To Many
                        // TODO
                    });
                }
            });

            console.log('Data config: Transformed Config', compiledConfig);

            console.timeEnd('Data config: Compiled config');

            return compiledConfig;
        };

        // Returns the current config.
        function getCompiledConfig() {

            // Return a promise
            return loadConfig().then(function (config) {
                return _compiledConfig;
            });
        };

        // Returns the config for the view page for any table
        function getViewPageConfig(tableName) {

            return getCompiledConfig().then(function (config) {

                // Get the table
                var table = config.tables.filter(function (item) {
                    return item.name === tableName;
                })[0];

                var viewPageConfig = {
                    tableName: table.name,
                    tableNameSingular: table.nameSingular,
                    tableFriendlyName: table.friendlyName,
                    tableFriendlyNameSingular: table.friendlyNameSingular
                };

                viewPageConfig = angular.merge(viewPageConfig, table.viewPage);

                // Can the user view the page
                viewPageConfig.userSecurity = {
                    view: checkUserAllowed(table.security.view),
                    add: checkUserAllowed(table.security.add),
                    edit: checkUserAllowed(table.security.edit),
                    delete: checkUserAllowed(table.security.delete)
                };

                console.log('Data config: View Page Config', viewPageConfig);

                return viewPageConfig;
            });
        };

        // Returns the viewTable config for any table
        function getViewTableConfig(tableName) {

            return getCompiledConfig().then(function (config) {

                console.time('Data config: Got table config');

                // Get the table
                var table = config.tables.filter(function (item) {
                    return item.name === tableName;
                })[0];

                if (table === undefined) {
                    console.error('Data config: No table found called ' + tableName);
                    return;
                }

                // Mixins for fields definitions
                if (table.viewTable && table.viewTable.columnsMixin) {
                    if (config.mixins && config.mixins[table.viewTable.columnsMixin]) {
                        table.viewTable.columns = table.viewTable.columns || [];
                        table.viewTable.columns = table.viewTable.columns.concat(config.mixins[table.viewTable.columnsMixin]);
                    } else {
                        console.error('Data config: Mixin not found - ', table.viewTable.columnsMixin);
                    }
                }

                // Create View Table Config
                var viewTableConfig = {

                    tableName: table.name,
                    tableNameSingular: table.nameSingular,
                    tableFriendlyName: table.friendlyName,
                    tableFriendlyNameSingular: table.friendlyNameSingular,
                    tablePrimaryKey: table.primaryKey,

                    checkboxes: true,
                    deleteButton: true,
                    paging: {},
                    fixedHeight: false

                };

                // Merge viewTable definition with defaults
                viewTableConfig = angular.merge(viewTableConfig, table.viewTable);

                // Copy the columns from the table
                viewTableConfig.columns = angular.copy(table.columns);

                // Loop through all the column overrides.
                if (table.viewTable.columns && table.viewTable.columns.length > 0) {

                    table.viewTable.columns.forEach(function (column) {

                        // If the column is hidden then remove it
                        if (column.visible === false) {
                            viewTableConfig.columns = viewTableConfig.columns.filter(function (item) {
                                return item.name !== column.name;
                            });
                            return;
                        }

                        // Get matching column in table definition
                        var configColumn = viewTableConfig.columns.filter(function (item) {
                            return item.name === column.name;
                        })[0];

                        column.overriden = true;

                        // If there was a matching column then merge it
                        if (configColumn) {
                            angular.merge(configColumn, column);
                        }

                        // If there was no matching column then push it onto the array
                        else {
                                viewTableConfig.columns.push(column);
                            }
                    });
                }

                // Create defaults and fill in all the blanks for each column
                viewTableConfig.columns.forEach(function (column, index) {

                    // Type
                    column.type = column.type || 'string';

                    // Label
                    if (!column.label) {

                        if (column.subType === 'relatedTable') {

                            var relationship = table.relationships.filter(function (item) {
                                return item.name === column.relationship;
                            })[0];

                            var matchedTable = config.tables.filter(function (item) {
                                return item.name === relationship.table;
                            })[0];

                            column.label = matchedTable.nameSingular;
                        } else {

                            var columnLabel = column.fileObjectName || column.name;
                            columnLabel = columnLabel.replace(/([A-Z])/g, " $1");
                            columnLabel = columnLabel.charAt(0).toUpperCase() + columnLabel.slice(1);

                            column.label = column.label || columnLabel;
                        }
                    }

                    // If displayValue hasn't been set yet
                    if (column.displayValue === undefined) {

                        // Related Table
                        if (column.subType === 'relatedTable') {

                            var relationship = table.relationships.filter(function (item) {
                                return item.name === column.relationship;
                            })[0];

                            column.displayValue = column.relationship + '.' + relationship.labelColumn;
                        }

                        // Date
                        if (column.type === 'date') {
                            column.displayValue += ' | date:"mediumDate"';
                        }

                        // DateTime
                        if (column.type === 'dateTime') {
                            column.displayValue += ' | date:"medium"';
                        }

                        // Image 
                        if (column.subType === 'file' && column.fileType === 'image') {
                            column.displayValue = column.fileObjectName + '.url';
                        }
                    }

                    // If column width hasn't already been set...
                    if (!column.width) {

                        var defaultWidth = '150';

                        if (column.type === 'integer' && column.name.length < 12) {
                            column.width = '120';
                        }

                        if (column.type === 'decimal' && column.name.length < 12) {
                            column.width = '120';
                        }

                        if (column.type === 'date' && column.name.length < 12) {
                            column.width = '200';
                        }

                        if (column.subType === 'longString') {
                            column.width = '300';
                        }

                        if (column.subType === 'file' && column.fileType === 'image') {
                            column.width = '150';
                        }

                        column.width = column.width || defaultWidth;
                    }

                    // If column has a relationship then pull it's definition in
                    if (column.subType === 'relatedTable') {

                        column.relationship = table.relationships.filter(function (item) {
                            return item.name === column.relationship;
                        })[0];

                        if (!column.relationship) {
                            console.error('Data config: Related table column found with no relationship specified', angular.copy(column));
                        }
                    }
                });

                // Table security
                viewTableConfig.userSecurity = {
                    view: checkUserAllowed(table.security.view),
                    add: checkUserAllowed(table.security.add),
                    edit: checkUserAllowed(table.security.edit),
                    delete: checkUserAllowed(table.security.delete)
                };

                // Column Security
                viewTableConfig.columns.forEach(function (column, index) {

                    // Make sure there is an array of fields being checked
                    column.fields = column.fields || [column.name];

                    if (typeof column.fields === 'string') {
                        column.fields = [column.fields];
                    }

                    // Create security object
                    column.userSecurity = {
                        view: true,
                        add: true,
                        edit: true
                    };

                    // Check each field that needs to be checked
                    column.fields.forEach(function (fieldName) {

                        var fieldToCheck = viewTableConfig.columns.filter(function (item) {
                            return item.name === fieldName;
                        })[0];

                        if (fieldToCheck && fieldToCheck.security) {
                            column.userSecurity.view = checkUserAllowed(fieldToCheck.security.view) === false ? false : column.userSecurity.view;
                            column.userSecurity.add = checkUserAllowed(fieldToCheck.security.add) === false ? false : column.userSecurity.add;
                            column.userSecurity.edit = checkUserAllowed(fieldToCheck.security.edit) === false ? false : column.userSecurity.edit;
                        }
                    });
                });

                // Create layout if it doesn't laready exist
                if (viewTableConfig.layout === undefined) {
                    viewTableConfig.layout = { columns: viewTableConfig.columns };
                }

                viewTableConfig.layout.columns = viewTableConfig.layout.columns.map(function (column) {

                    var fullColumn = viewTableConfig.columns.filter(function (item) {
                        return item.name === column.name;
                    })[0];

                    if (fullColumn) {
                        column = angular.merge({}, fullColumn, column);
                    }

                    return column;
                });

                console.log('Data config: Table Config', viewTableConfig);
                console.timeEnd('Data config: Got table config');

                return viewTableConfig;
            });
        };

        // Returns the config for the add page for any table
        function getAddPageConfig(tableName) {

            return getCompiledConfig().then(function (config) {

                console.time('Data config: Got add page config');

                // Get the table
                var table = config.tables.filter(function (item) {
                    return item.name === tableName;
                })[0];

                var addPageConfig = {
                    tableName: table.name,
                    tableNameSingular: table.nameSingular,
                    tableFriendlyName: table.friendlyName,
                    tableFriendlyNameSingular: table.friendlyNameSingular
                };

                addPageConfig = angular.merge(addPageConfig, table.addPage);

                // Can the user view the page
                addPageConfig.userSecurity = {
                    view: checkUserAllowed(table.security.view),
                    add: checkUserAllowed(table.security.add),
                    edit: checkUserAllowed(table.security.edit),
                    delete: checkUserAllowed(table.security.delete)
                };

                console.log('Data config: Add Page Config', addPageConfig);

                console.timeEnd('Data config: Got add page config');

                return addPageConfig;
            });
        };

        // Returns the config for the edit page for any table
        function getEditPageConfig(tableName) {

            return getCompiledConfig().then(function (config) {

                console.time('Data config: Got edit page config');

                // Get the table
                var table = config.tables.filter(function (item) {
                    return item.name === tableName;
                })[0];

                var editPageConfig = {
                    tableName: table.name,
                    tableNameSingular: table.nameSingular,
                    tableFriendlyName: table.friendlyName,
                    tableFriendlyNameSingular: table.friendlyNameSingular
                };

                editPageConfig = angular.merge(editPageConfig, table.editPage);

                // Can the user view the page
                editPageConfig.userSecurity = {
                    view: checkUserAllowed(table.security.view),
                    add: checkUserAllowed(table.security.add),
                    edit: checkUserAllowed(table.security.edit),
                    delete: checkUserAllowed(table.security.delete)
                };

                console.log('Data config: Edit Page Config', editPageConfig);

                console.timeEnd('Data config: Got edit page config');

                return editPageConfig;
            });
        };

        // Returns the form config for any form
        function getFormConfig(settings) {

            return getCompiledConfig().then(function (config) {

                console.time('Data config: Got form config');

                // Take a copy of the config so we don't accidentally alter it for other components.
                config = angular.copy(config);

                var formConfig = {},
                    defaultForm = {},
                    formToMerge = {},
                    table;

                if (settings.table) {

                    // Get the table
                    var matchedTable = config.tables.filter(function (item) {
                        return item.name === settings.table;
                    })[0];

                    if (!matchedTable) {
                        console.error('Data config: Can\'t create form for table because no definition was found - ', settings.table);
                        return;
                    }

                    table = angular.copy(matchedTable);

                    defaultForm = table.forms.default || defaultForm;
                    defaultForm.fields = defaultForm.fields || [];

                    // Copy table columns to fields array
                    formConfig.fields = table.columns;

                    // Copy the relationships
                    formConfig.relationships = table.relationships;

                    // Get a reference to the form for this mode.
                    if (settings.form && settings.form !== 'default' && table.forms[settings.form] !== undefined) {
                        formToMerge = table.forms[settings.form];
                    } else {
                        formToMerge = { fields: [] };
                    }
                }

                // Mixins for fields definitions
                if (defaultForm.fieldsMixin) {
                    if (config.mixins && config.mixins[defaultForm.fieldsMixin]) {
                        defaultForm.fields = defaultForm.fields || [];
                        defaultForm.fields = defaultForm.fields.concat(config.mixins[defaultForm.fieldsMixin]);
                    } else {
                        console.error('Data config: Mixin not found - ', defaultForm.fieldsMixin);
                    }
                    delete defaultForm.fieldsMixin;
                }

                if (formToMerge.fieldsMixin) {
                    if (config.mixins && config.mixins[formToMerge.fieldsMixin]) {
                        formToMerge.fields = formToMerge.fields || [];
                        formToMerge.fields = formToMerge.fields.concat(config.mixins[formToMerge.fieldsMixin]);
                    } else {
                        console.error('Data config: Mixin not found - ', formToMerge.fieldsMixin);
                    }
                    delete formToMerge.fieldsMixin;
                }

                // Save Changes Warning
                formConfig.saveChangesWarning = formToMerge.saveChangesWarning || defaultForm.saveChangesWarning || false;

                // Endpoints
                if (defaultForm.endpoints || formToMerge.endpoints) {
                    formConfig.endpoints = angular.merge({}, defaultForm.endpoints, formToMerge.endpoints);
                };

                // Create all fields
                formConfig.fields.forEach(function (field, index) {

                    // Create default label
                    if (!field.label) {
                        field.label = field.name.replace(/([A-Z])/g, " $1");
                        field.label = field.label.charAt(0).toUpperCase() + field.label.slice(1);
                    }

                    // Create default full-width-at
                    field.fullWidthAt = '1,2';

                    // Merge defaultForm and formToMerge versions of the field into the config.
                    var defaultFormField = defaultForm.fields.filter(function (item) {
                        return item.name === field.name;
                    })[0] || {};

                    var formToMergeField = formToMerge.fields.filter(function (item) {
                        return item.name === field.name;
                    })[0] || {};

                    angular.merge(field, defaultFormField, formToMergeField);

                    // Create default control types
                    if (field.controlType === undefined) {

                        switch (field.type) {
                            case 'string':

                                switch (field.subType) {

                                    case 'enum':
                                    case 'table':
                                    case 'relatedTable':
                                        field.controlType = 'picklist';
                                        break;

                                    case 'longString':
                                        field.controlType = 'textarea';
                                        break;

                                    case 'phone':
                                        field.controlType = 'tel';
                                        break;

                                    case 'email':
                                        field.controlType = 'email';
                                        break;

                                    case 'color':
                                        field.controlType = 'color';
                                        break;

                                    default:
                                        field.controlType = 'text';
                                }

                                break;

                            case 'integer':
                            case 'decimal':

                                switch (field.subType) {

                                    case 'enum':
                                    case 'table':
                                    case 'relatedTable':
                                        field.controlType = 'picklist';
                                        break;

                                    case 'file':
                                        field.controlType = 'fileUpload';
                                        break;

                                    default:
                                        field.controlType = 'number';
                                }

                                break;

                            case 'boolean':
                                field.controlType = 'checkbox';
                                break;

                            case 'date':
                                field.controlType = 'datepicker';
                                break;

                            case 'dateTime':
                                field.controlType = 'datetimepicker';
                                break;

                            default:
                                field.controlType = 'text';
                                break;
                        }
                    }

                    // If field has a relationship then pull it's definition in
                    if (field.relationship) {

                        field.relationship = formConfig.relationships.filter(function (item) {
                            return item.name === field.relationship;
                        })[0];

                        if (!field.relationship) {
                            console.error('Data config: Relationship requested in form but not found in config', angular.copy(field));
                        }
                    }
                });

                // Remove any fields which have been hidden
                formConfig.fields = formConfig.fields.filter(function (item) {
                    return item.visible !== false;
                });

                // Security for table based forms
                if (settings.table) {

                    formConfig.userSecurity = {
                        view: checkUserAllowed(table.security.view),
                        add: checkUserAllowed(table.security.add),
                        edit: checkUserAllowed(table.security.edit),
                        delete: checkUserAllowed(table.security.delete)
                    };

                    formConfig.fields.forEach(function (field, index) {

                        // Create security object
                        field.userSecurity = {
                            view: checkUserAllowed(field.security.view),
                            add: checkUserAllowed(field.security.add),
                            edit: checkUserAllowed(field.security.edit)
                        };
                    });
                }

                // Layout
                formConfig.layout = formToMerge.layout || defaultForm.layout;

                // If no layout has been specified then create one automatically from the fields
                if (!formConfig.layout) {

                    formConfig.layout = { rows: []

                        // Copy each field into it's own row from the fields array
                    };formConfig.fields.forEach(function (item) {
                        formConfig.layout.rows.push({
                            items: [{ field: item, width: '100%' }]
                        });
                    });
                } else {

                    // Make sure each row has an items array not just a single field
                    formConfig.layout.rows.forEach(function (row, index) {

                        if (row.items === undefined) {

                            formConfig.layout.rows[index] = {
                                items: [row]
                                // Replace reference to current row with the one we just put back into the array we're iterating over.
                            };row = formConfig.layout.rows[index];
                        }

                        // Copy the whole field object into the item for easier templating.
                        row.items.forEach(function (item, index) {

                            row.items[index].field = formConfig.fields.filter(function (field) {
                                return field.name === item.field;
                            })[0];
                        });
                    });
                }

                console.log('Data config: ' + settings.form.charAt(0).toUpperCase() + settings.form.slice(1) + ' Form Config', formConfig);
                console.timeEnd('Data config: Got form config');

                return formConfig;
            });
        };

        // ---
        // PRIVATE METHODS.
        // ---

        function checkUserAllowed(securityChecks) {

            var allowed = true;

            if (securityChecks.minAuthLevel && six4Auth.credentials().authLevel > securityChecks.minAuthLevel) {
                allowed = false;
            }

            if (securityChecks.permissions && six4Auth.checkPermissions(securityChecks.permissions) === false && six4Auth.credentials().authLevel > 2) {
                allowed = false;
            }

            return allowed;
        };
    }

    ///////////////////////////////////////////////////////////////
    //           Initialize Pusher Service on Page Load          //
    ///////////////////////////////////////////////////////////////

    function init() {};
});

/////////////////////////////////////////////////////////
//                 Six4 Portal Dialog                  //
/////////////////////////////////////////////////////////

angular.module('s4p.services')

// Add templates
.run(["$templateCache", function ($templateCache) {

    $templateCache.put('/templates/messageDialog.tpl.html', '<div class="modal-inner">' + '<div class="modal-header" ng-if="title !== undefined">' + '<s4p-bar cs="{{headerCs}}">' + '<s4p-bar-item type="title" stretch="true" auto-pad="false">' + '<h3 ng-bind="title"></h3>' + '</s4p-bar-item>' + '</s4p-bar>' + '</div>' + '<div class="modal-main">' + '<div padding-all><div ng-bind-html="message" style="word-wrap:break-word;"></div></div>' + '</div>' + '<div class="modal-footer">' + '<s4p-bar cs="{{footerCs}}" pad-end="true" justify-content="right">' + '<s4p-bar-item>' + '<s4p-button cs="{{buttonCs}}" ng-click="close()">{{buttonText}}</s4p-button>' + '</s4p-bar-item>' + '</s4p-bar>' + '</div>' + '</div>');

    $templateCache.put('/templates/confirmDialog.tpl.html', '<div class="modal-inner">' + '<div class="modal-header" ng-if="title !== undefined">' + '<s4p-bar cs="{{headerCs}}">' + '<s4p-bar-item type="title" stretch="true">' + '<h3 ng-bind="title"></h3>' + '</s4p-bar-item>' + '</s4p-bar>' + '</div>' + '<div class="modal-main">' + '<div padding-all><div ng-bind-html="message"></div></div>' + '</div>' + '<div class="modal-footer">' + '<s4p-bar cs="{{footerCs}}" pad-end="true" justify-content="right">' + '<s4p-bar-item>' + '<s4p-button cs="{{button1Cs}}" ng-click="buttonClick(1)">{{button1Text}}</s4p-button>' + '</s4p-bar-item>' + '<s4p-bar-item >' + '<s4p-button cs="{{button2Cs}}" ng-click="buttonClick(2)">{{button2Text}}</s4p-button>' + '</s4p-bar-item>' + '</s4p-bar>' + '</div>' + '</div>');
}]).controller('messageDialogCtrl', ["$scope", "$sce", "$uibModalInstance", "modalSettings", "$document", function ($scope, $sce, $uibModalInstance, modalSettings, $document) {

    $scope.title = modalSettings.title;
    $scope.message = modalSettings.message !== undefined ? $sce.trustAsHtml(modalSettings.message) : '';

    $scope.headerCs = modalSettings.headerCs || 'defaultinv';
    $scope.footerCs = modalSettings.footerCs || 'default';
    $scope.buttonCs = modalSettings.buttonCs || 'default';
    $scope.buttonText = modalSettings.buttonText || 'OK';

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    $scope.close = function () {
        $uibModalInstance.close();
    };

    // Add enter key close
    function eventHandler(event) {
        event.preventDefault(); // Stops forms underneath modal being submitted if they're focussed
        if (event.which === 13) {
            $uibModalInstance.close();
        }
    }

    var EVENT_TYPES = "keydown keypress";
    $document.bind(EVENT_TYPES, eventHandler);
    $scope.$on('$destroy', function () {
        $document.unbind(EVENT_TYPES, eventHandler);
    });
}]).controller('confirmDialogCtrl', ["$scope", "$sce", "$uibModalInstance", "modalSettings", "$document", function ($scope, $sce, $uibModalInstance, modalSettings, $document) {

    $scope.title = modalSettings.title;
    $scope.message = modalSettings.message || '';
    $scope.headerCs = modalSettings.headerCs || 'defaultinv';
    $scope.footerCs = modalSettings.footerCs || 'default';
    $scope.button1Cs = modalSettings.button1Cs || 'default';
    $scope.button2Cs = modalSettings.button2Cs || 'accent';
    $scope.button1Text = modalSettings.button1Text || 'Yes';
    $scope.button2Text = modalSettings.button2Text || 'No';
    $scope.trueButton = modalSettings.trueButton || 1;

    $scope.buttonClick = function (buttonNumber) {

        if (buttonNumber === parseInt($scope.trueButton)) {
            $uibModalInstance.close();
        } else {
            $uibModalInstance.dismiss();
        }
    };

    // Add enter key close
    function eventHandler(event) {
        event.preventDefault(); // Stops forms underneath modal being submitted if they're focussed
        if (event.which === 13) {
            $uibModalInstance.close();
        }
    }

    var EVENT_TYPES = "keydown keypress";
    $document.bind(EVENT_TYPES, eventHandler);
    $scope.$on('$destroy', function () {
        $document.unbind(EVENT_TYPES, eventHandler);
    });
}]).factory('s4pDialogs', ["$uibModal", function ($uibModal) {

    return {

        // Simple message dialog
        message: function message(_modalSettings) {

            var windowClass = 'modal--sticky modal--has-footer';
            windowClass = _modalSettings.width === undefined ? windowClass += ' modal--alert' : windowClass += ' modal--' + _modalSettings.width;
            windowClass = _modalSettings.title === undefined ? windowClass : windowClass += ' modal--has-header';

            return $uibModal.open({
                templateUrl: '/templates/messageDialog.tpl.html',
                controller: 'messageDialogCtrl',
                windowClass: windowClass,
                resolve: {
                    modalSettings: function modalSettings() {
                        return _modalSettings;
                    }
                }
            });
        },

        // Confirm (true|false) dialog
        confirm: function confirm(_modalSettings2) {

            var windowClass = 'modal--sticky modal--has-footer';
            windowClass = _modalSettings2.width === undefined ? windowClass += ' modal--alert' : windowClass += ' modal--' + _modalSettings2.width;
            windowClass = _modalSettings2.title === undefined ? windowClass : windowClass += ' modal--has-header';

            return $uibModal.open({
                templateUrl: '/templates/confirmDialog.tpl.html',
                controller: 'confirmDialogCtrl',
                windowClass: windowClass,
                resolve: {
                    modalSettings: function modalSettings() {
                        return _modalSettings2;
                    }
                }
            });
        }

    };
}]);

/*******************************************************/
/*                    Icon Service                     */
/*******************************************************/

angular.module('s4p.services').provider('s4pIcons', function () {

    'use strict';

    ////////////////////////////////////////
    //              Defaults              //
    ////////////////////////////////////////

    var icons = {};

    ////////////////////////////////////////
    //             Initialize             //
    ////////////////////////////////////////

    init();

    ////////////////////////////////////////
    //             Public API             //
    ////////////////////////////////////////

    return {
        registerIcon: registerIcon,

        $get: instantiateIcons
    };

    // Register a new icon
    function registerIcon(iconName, iconUrl) {

        var newIcon = {
            url: iconUrl
        };

        icons[iconName] = newIcon;
    }

    ////////////////////////////////////////
    //          Private Methods           //
    ////////////////////////////////////////


    // Private methods go here


    ////////////////////////////////////////
    //              Service               //
    ////////////////////////////////////////

    function instantiateIcons() {

        // Return the public API.
        return {
            getIcon: getIcon
        };

        // ---
        // PUBLIC METHODS.
        // ---

        // Get an icon
        function getIcon(iconName) {
            return icons[iconName];
        }

        // ---
        // PRIVATE METHODS.
        // ---
    }

    ///////////////////////////////////////////////////////////////
    //            Initialize Auth Service on Page Load           //
    ///////////////////////////////////////////////////////////////

    function init() {

        // Do Init

    };
});

/////////////////////////////////////
// Integrations
/////////////////////////////////////

angular.module('s4p').service('s4pIntegrations', ["$rootScope", "$http", function ($rootScope, $http) {

    'use strict';

    var providers;

    var integrationsService = {

        // Get Integrations
        getProviders: function getProviders() {

            if (!providers) {

                providers = $http.get(s4pSettings.dataFilesUrl + '/integrations.json').then(function (response) {
                    return response.data.providers;
                });
            }

            return providers;
        }

    };

    return integrationsService;
}]);

/*******************************************************/
/*                  Lazy Load Service                  */
/*******************************************************/

angular.module('s4p.services').provider('s4pLazyLoad', function () {

    'use strict';

    ////////////////////////////////////////
    //              Defaults              //
    ////////////////////////////////////////

    var bundles = {};

    ////////////////////////////////////////
    //             Initialize             //
    ////////////////////////////////////////

    init();

    ////////////////////////////////////////
    //             Public API             //
    ////////////////////////////////////////


    instantiateLazyLoad.$inject = ["$ocLazyLoad"];
    return {
        registerBundle: registerBundle,
        $get: instantiateLazyLoad
    };

    // Register a new bundle
    function registerBundle(bundleSettings) {
        bundles[bundleSettings.name] = {
            settings: bundleSettings
        };
    }

    ////////////////////////////////////////
    //          Private Methods           //
    ////////////////////////////////////////


    // Private methods go here


    ////////////////////////////////////////
    //              Service               //
    ////////////////////////////////////////

    function instantiateLazyLoad($ocLazyLoad) {

        // Return the public API.
        return {
            loadBundle: loadBundle
        };

        // ---
        // PUBLIC METHODS.
        // ---

        // Load Bundle
        function loadBundle(bundleName) {
            return $ocLazyLoad.load(bundles[bundleName].settings);
        }

        // ---
        // PRIVATE METHODS.
        // ---
    }

    ///////////////////////////////////////////////////////////////
    //            Initialize Auth Service on Page Load           //
    ///////////////////////////////////////////////////////////////

    function init() {

        // Do Init

    };
});

/////////////////////////////////////
// Notifications
/////////////////////////////////////

angular.module('s4p').service('s4pNotifications', ["$rootScope", "$http", function ($rootScope, $http) {

    'use strict';

    var self = this;

    var notificationsService = {
        addNewTransformedNotification: addNewTransformedNotification,
        loadNotifications: loadNotifications,
        resetNotificationsLastSeen: resetNotificationsLastSeen,
        transformNotifications: transformNotifications

        // Transform Notifications
    };function transformNotifications(notifications) {

        var transformedNotifications = angular.copy(notifications);
        var tempDate;

        // Transform them all
        transformedNotifications.forEach(function (item) {

            // Create Time
            var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            var messageDate = new Date(item.messageDate);
            var today = new Date();
            var diffMinutes = Math.round(Math.abs(messageDate - today) / 60000);
            var timeAgo;

            if (diffMinutes < 3) {
                timeAgo = "Just now";
            } else if (diffMinutes < 60) {
                timeAgo = diffMinutes + " minutes ago";
            } else if (messageDate.toDateString() === today.toDateString()) {

                var hoursAgo = Math.floor(diffMinutes / 60);

                timeAgo = hoursAgo === 1 ? "1 hour ago" : hoursAgo + " hours ago";
            } else {
                timeAgo = messageDate.getDate() + " " + monthNames[messageDate.getMonth()] + " at " + messageDate.getHours() + ":" + (messageDate.getMinutes() < 10 ? '0' : '') + messageDate.getMinutes();
            }

            item.timeAgo = timeAgo;

            // See if we need to create a date header
            delete item.dateHeader;
            if (typeof tempDate === 'undefined' || messageDate.toDateString() !== tempDate.toDateString()) {
                item.dateHeader = messageDate.toDateString();
                tempDate = messageDate;
            }
        });

        return transformedNotifications;
    }

    // Add New Transformed Notification
    function addNewTransformedNotification(currentList, newItem) {

        if (newItem.bundleId && newItem.bundleId > 0) {
            currentList = currentList.filter(function (item) {
                return item.bundleId !== newItem.bundleId;
            });
        }

        currentList.unshift(newItem);

        return transformNotifications(currentList);
    }

    // Load Notifications
    function loadNotifications(settings) {

        var params = {};

        if (!!settings) {
            params.start = settings.pagingStart;
            params.limit = settings.pagingLimit;
        }

        return $http.get(s4pSettings.apiUrl + '/webNotificationMessages', {
            params: params
        }).then(function (response) {
            $rootScope.notificationsUnseenCount = response.data.meta.totalUnseen;
            $rootScope.notificationsLastDate = response.data.meta.notificationsLastSeen;
            return response;
        });
    };

    // Reset Last Seen
    function resetNotificationsLastSeen() {
        $rootScope.notificationsLastDate = Math.floor(new Date().getTime() / 1000);
        $rootScope.notificationsUnseenCount = 0;
        $http.post(s4pSettings.apiUrl + '/webNotificationsSeen', {});
    }

    return notificationsService;
}]);
/////////////////////////////////////
// Page title stuff
/////////////////////////////////////

angular.module('s4p').service('s4pPageTitle', ["$rootScope", function ($rootScope) {

    'use strict';

    this.set = function (newPageTitle) {
        var siteNameSuffix = portalSettings.friendlySiteName ? ' : ' + portalSettings.friendlySiteName : undefined;
        $rootScope.pageTitle = newPageTitle + (siteNameSuffix || '');
    };
}]).directive('title', ["$rootScope", "$timeout", function ($rootScope, $timeout) {

    return {

        link: function link() {

            var listener = function listener(event, toState) {

                var siteNameSuffix = portalSettings.friendlySiteName ? ' : ' + portalSettings.friendlySiteName : undefined;

                // Timout so that the page has definitely changed, otherwise it names the previous page.
                $timeout(function () {
                    $rootScope.pageTitle = toState.data && toState.data.pageTitle ? toState.data.pageTitle + (siteNameSuffix || '') : portalSettings.friendlySiteName || window.location.href;
                });
            };

            $rootScope.$on('$stateChangeSuccess', listener);
        }

    };
}]);
/*******************************************************/
/*                   Pusher Service                    */
/*******************************************************/

angular.module('s4p.services').provider('s4pPusher', function () {

    'use strict';

    ////////////////////////////////////////
    //          Default Settings          //
    ////////////////////////////////////////

    var _connections = {};

    ////////////////////////////////////////
    //             Initialize             //
    ////////////////////////////////////////

    init();

    ////////////////////////////////////////
    //             Public API             //
    ////////////////////////////////////////


    instantiatePusherService.$inject = ["$rootScope", "$log", "$q", "six4Auth"];
    return {

        registerConnection: registerConnection,

        $get: instantiatePusherService

    };

    // Register Connection
    function registerConnection(con) {

        var name = con.name ? con.name : 'main';

        if (_connections[name]) {

            if (typeof _connections[name].disconnect === 'function') {
                _connections[name].disconnect();
            }

            delete _connections[name];
        }

        var pusher = new Pusher(con.applicationKey, con.options);
        _connections[con.name] = pusher;
    }

    ////////////////////////////////////////
    //          Private Methods           //
    ////////////////////////////////////////


    // Private methods go here


    // Set the credentials
    /*
    function _privateFn(){
          
        
    }
    */

    // Public Methods
    //
    // getConnection(name)         Returns a connection (name is optional).


    ////////////////////////////////////////
    //              Service               //
    ////////////////////////////////////////

    function instantiatePusherService($rootScope, $log, $q, six4Auth) {

        // Return the public API.
        return {
            getConnection: getConnection
        };

        // ---
        // PUBLIC METHODS.
        // ---

        // Returns the first connection, or returns a connection by name.
        function getConnection(name) {

            var connectionName = name ? name : 'main';
            return _connections[connectionName];
        }

        // Registers a new connection.
        function registerConnection(con) {

            var name = con.name ? con.name : 'main';
            unregisterConnection(name);

            var pusher = new Pusher(con.applicationKey, con.options);
            _connections[name] = pusher;
        }

        // Unregister a connection.
        function unregisterConnection(name) {

            if (_connections[name]) {

                if (typeof _connections[name].disconnect === 'function') {
                    _connections[name].disconnect();
                }

                delete _connections[name];
            }
        }

        // ---
        // PRIVATE METHODS.
        // ---
    }

    ///////////////////////////////////////////////////////////////
    //           Initialize Pusher Service on Page Load          //
    ///////////////////////////////////////////////////////////////

    function init() {};
});

/////////////////////////////////////
// Page title stuff
/////////////////////////////////////

angular.module('s4p').service('s4pSaveChanges', ["$rootScope", "$injector", "$state", "s4pDialogs", function ($rootScope, $injector, $state, s4pDialogs) {

    'use strict';

    var $translate, s4pTranslateService;
    if (portalSettings.i18n) {
        $translate = $injector.get('$translate');
        s4pTranslateService = $injector.get('s4pTranslateService');
    }

    var changesMade = false,
        watches = {},
        bypassRouteChangeCheck = false,
        manualOverride = false;

    // Watch for changes in state and warn if there are unsaved changes
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

        // If auth check is happening first then wait for a re-run before kicking in.
        if ($rootScope.routeCancelledDueToAuthCheck) {

            // console.log("s4pSaveChanges: Giving up because auth is checking");

            return;
        }

        // If no changes have been made or we're bypassing the check this time then reset everything and continue with the route change.
        if (!manualOverride && !anyChanges() || bypassRouteChangeCheck) {
            bypassRouteChangeCheck = false;
            manualOverride = false;
            removeAllWatches();

            // console.log("s4pSaveChanges: Allowing route change", angular.copy(watches));

            return;
        }

        // Halt state change from starting
        event.preventDefault();

        // console.log("s4pSaveChanges: Stopping route change", angular.copy(watches));

        // Warn the user
        var dialog;
        if (portalSettings.i18n) {

            dialog = s4pDialogs.confirm({
                title: s4pTranslateService.translate('Unsaved Changes', 'unsaved_changes_ttl'),
                message: s4pTranslateService.translate('You have made changes on this page, would you like to go back and save them or continue without saving?', 'unsaved_changes_msg'),
                button1Text: s4pTranslateService.translate('Go Back', 'unsaved_changes_back_btn'),
                button2Text: s4pTranslateService.translate('Continue', 'unsaved_changes_cont_btn'),
                button1Cs: 'accent',
                button2Cs: 'default',
                trueButton: 2,
                width: 'default'
            });
        } else {

            dialog = s4pDialogs.confirm({
                title: 'Unsaved Changes',
                message: 'You have made changes on this page, would you like to go back and save them or continue without saving?',
                button1Text: 'Go Back',
                button2Text: 'Continue',
                button1Cs: 'accent',
                button2Cs: 'default',
                trueButton: 2,
                width: 'default'
            });
        }

        dialog.result.then(function () {

            // Bypass next check so we can continue to the next route           
            bypassRouteChangeCheck = true;

            // Continue
            $state.go(toState, toParams);
        });

        return false;
    });

    // PRIVATE Functions

    // Function for calculating whether any watches still exist which havetriggered changes made on them
    function calculateAnyChanges() {

        var anyTriggered = false;

        for (var watch in watches) {
            if (watches.hasOwnProperty(watch) && watches[watch].triggered === true) {
                anyTriggered = true;
            }
        }

        changesMade = anyTriggered;

        // console.log("calculateAnyChanges", angular.copy(changesMade));
    }

    // PUBLIC Functions

    // Returns true of any changes are currently being tracked.
    function anyChanges() {
        return changesMade;
    }

    // Remove all watches
    function removeAllWatches() {

        for (var watch in watches) {
            if (watches.hasOwnProperty(watch)) {
                watches[watch].watchFunction.call();
            }
        }

        watches = {};
        changesMade = false;

        // console.log("removeAllWatches", angular.copy(watches), angular.copy(anyChanges()));
    }

    // Remove a Watch
    function removeWatch(name) {

        if (!!watches[name]) {
            watches[name].watchFunction.call();
            delete watches[name];
        }

        calculateAnyChanges();

        // console.log("removeWatch", angular.copy(watches), angular.copy(anyChanges()));
    }

    // Reset all Watches
    function resetAllWatches() {

        for (var watch in watches) {
            if (watches.hasOwnProperty(watch)) {
                watches[watch].triggered = false;
            }
        }

        anyChanges = false;

        // console.log("resetAllWatches", angular.copy(watches), angular.copy(anyChanges()));
    }

    // Reset a watch
    function resetWatch(name) {

        if (!!watches[name]) {
            watches[name].triggered = false;
        }

        calculateAnyChanges();

        // console.log("resetWatch", angular.copy(watches), angular.copy(anyChanges()));
    }

    // Set Manual Override
    function setManualOverride(value) {
        manualOverride = value;
    }

    // Watch
    function watch(name, thingToWatch) {

        var watchFunction = $rootScope.$watch(function () {
            // console.log("thingtowatch", angular.copy(thingToWatch));
            return thingToWatch;
        }, function (newValue, oldValue) {

            // console.log("Changed", newValue, oldValue);

            if (!angular.equals(newValue, oldValue)) {
                watches[name].triggered = true;
                changesMade = true;
            }

            // console.log("watchFunction", angular.copy(watches), angular.copy(anyChanges()));
        }, true);

        watches[name] = {
            triggered: false,
            watchFunction: watchFunction
        };
    }

    // Create the service
    return {
        anyChanges: anyChanges,
        removeAllWatches: removeAllWatches,
        removeWatch: removeWatch,
        resetAllWatches: resetAllWatches,
        resetWatch: resetWatch,
        setManualOverride: setManualOverride,
        watch: watch
    };
}]);
angular.module('s4p').service('s4pTranslateService', ["$rootScope", "$timeout", "$filter", "$translate", function ($rootScope, $timeout, $filter, $translate) {

    'use strict';

    var self = this;

    var s4pTranslateService = {
        translate: translate
    };

    /////////////////////////////////////////////////////
    // Public Functions 
    /////////////////////////////////////////////////////


    // Translate
    function translate(input, translationId, data) {
        return !portalSettings.i18n || $translate.use() === 'en' ? input : $filter('s4pTranslate')(input, translationId, data);
    }

    return s4pTranslateService;
}]);
angular.module('s4p').directive('convertToNumber', function () {
    return {
        require: 'ngModel',
        link: function link(scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (val) {
                var returnValue = val !== '' ? parseInt(val, 10) : null;
                return returnValue;
            });
            ngModel.$formatters.push(function (val) {
                var returnValue = val != null ? '' + val : null;
                return returnValue;
            });
        }
    };
});

angular.module('s4p').directive('convertFromNumber', function () {
    return {
        require: 'ngModel',
        link: function link(scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (val) {
                return '' + val;
            });
            ngModel.$formatters.push(function (val) {
                return parseInt(val, 10);
            });
        }
    };
});

/////////////////////////////////////////////////////////
//             Datepicker Local Converter              //
/////////////////////////////////////////////////////////

angular.module('s4p.directives').directive('datepickerLocalConverter', function () {

    return {

        require: 'ngModel',

        link: function link(scope, element, attrs, ngModelController) {

            // Convert from view to model
            ngModelController.$parsers.push(function (value) {
                return moment(value).format('YYYY-MM-DD') + 'T00:00:00Z';
            });

            // Convert from model to view
            ngModelController.$formatters.push(function (datetime) {
                return datetime;
            });
        }
    };
});
////////////////////////////////////////////////
//             Datepicker Refresh             //
////////////////////////////////////////////////

// Provides a way to refresh a datepicker from a controller
// (E.g. when you've loaded some data via an ajax call)
// Just set the value of the attribute to a controller variable
// Then set the variable to true when you want to refresh.

angular.module('s4p.directives').directive('datepickerRefresh', ["$parse", function ($parse) {

    return {
        restrict: 'A',
        require: 'uibDatepicker',
        link: function link(scope, elem, attrs, dpCtrl) {

            var model = $parse(attrs.datepickerRefresh);

            scope.$watch(attrs.datepickerRefresh, function (newValue, oldValue) {

                if (!!newValue) {
                    dpCtrl.refreshView();
                }

                model.assign(scope, false);
            });
        }
    };
}]);
/////////////////////////////////////////////////////////
//                 Six4 Portal Button                  //
/////////////////////////////////////////////////////////

document.createElement('s4p-button');
document.createElement('s4p-button-content');
document.createElement('s4p-button-inner');
document.createElement('s4p-button-circle');
document.createElement('s4p-button-icon');

angular.module('s4p.directives').directive('s4pButton', function () {

    return {

        restrict: 'E',
        scope: {
            icon: '@'
        },
        transclude: true,
        replace: true,
        template: getTemplate

    };

    function isAnchor(attr) {
        return angular.isDefined(attr.href) || angular.isDefined(attr.ngHref) || angular.isDefined(attr.ngLink) || angular.isDefined(attr.uiSref);
    }

    function getTemplate(element, attr) {

        // Create a loading indicator?
        var loader = '';

        if (attr.loader && attr.loader !== 'false' || attr.formSubmittingLoader && attr.formSubmittingLoader !== 'false') {
            var loaderCs = attr.loaderCs ? 'cs=' + attr.loaderCs : '';
            loader = '<s4p-loader ' + loaderCs + '></s4p-loader>';
        }

        // Set the tag type
        var tag;

        // If a tag has been specified
        if (angular.isDefined(attr.buttonTag)) {
            tag = attr.buttonTag;
        }

        // If the element has an href/ng-href/ng-link/ui-sref attribute then make it an A tag not a BUTTON
        else if (isAnchor(attr)) {
                tag = 'a';
            } else {
                tag = 'button';
            }

        // If buttons don't have type="button", they will submit forms automatically.
        var btnType = angular.isDefined(attr.type) ? attr.type : 'button';

        // Is it an icon circle button?
        if (attr.buttonStyle && attr.buttonStyle === "circle") {
            return '<' + tag + ' s4p-button type="' + btnType + '">' + '<s4p-button-inner>' + '<s4p-button-circle>' + '<s4p-icon ng-if="icon" icon-name="{{::icon}}" ng-if="::icon"></s4p-icon>' + '<ng-transclude></ng-transclude>' + '</s4p-button-circle>' + '</s4p-button-inner>' + '</' + tag + '>';
        }

        // If the element has an href/ng-href/ng-link/ui-sref attribute then make it an A tag not a BUTTON
        else if (tag !== 'button') {

                return '<' + tag + ' s4p-button>' + '<s4p-button-inner>' + '<s4p-button-content ng-transclude></s4p-button-content>' + '<s4p-button-icon ng-if="::icon">' + '<s4p-icon icon-name="{{::icon}}"></s4p-icon>' + loader + '</s4p-button-icon>' + '</s4p-button-inner>' + '</' + tag + '>';
            }

            // Else it's just a normal button
            else {

                    return '<button s4p-button type="' + btnType + '">' + '<s4p-button-inner>' + '<s4p-button-content ng-transclude></s4p-button-content>' + '<s4p-button-icon ng-if="::icon">' + '<s4p-icon icon-name="{{::icon}}"></s4p-icon>' + loader + '</s4p-button-icon>' + '</s4p-button-inner>' + '</button>';
                }
    }
});

/////////////////////////////////////////////////////////
//                 S4P Portal Checkbox                 //
/////////////////////////////////////////////////////////

document.createElement('s4p-checkbox');

angular.module('s4p.directives').controller('S4pCheckboxCtrl', ["$scope", "$element", "$attrs", function ($scope, $element, $attrs) {

    var ctrl = this;

    // Set State
    ctrl.setState = function (newState) {
        $scope.isChecked = newState;
    };

    // Set is-focused
    ctrl.setFocused = function (value) {
        $element.toggleClass('is-focused', value);
    };
}]).directive('s4pCheckbox', ["$timeout", function ($timeout) {

    return {
        restrict: 'E',
        scope: {
            value: '@?',
            falseValue: '@?',
            onChange: '&?'
        },
        require: 'ngModel',
        transclude: true,
        controller: 'S4pCheckboxCtrl as s4pCheckbox',
        link: postLink,
        template: getTemplate
    };

    function getTemplate(element, attr) {

        var icon = angular.isDefined(attr.icon) ? attr.icon : 'tick-thick';

        return '<s4p-checkbox-inner>' + '<label>' + '<s4p-checkbox-box-wrapper>' + '<s4p-checkbox-box>' + '<s4p-checkbox-box-inner>' + '</s4p-checkbox-box-inner>' + '<input type="checkbox" ng-disabled="isDisabled">' + '<s4p-icon icon-name="' + icon + '"></s4p-icon>' + '</s4p-checkbox-box>' + '</s4p-checkbox-box-wrapper>' + '<s4p-checkbox-content ng-transclude></s4p-checkbox-content>' + '</label>' + '</s4p-checkbox-inner>';
    }

    function postLink(scope, element, attrs, ngModelCtrl) {

        scope.isMultiple = angular.isDefined(attrs.multipleValues) && attrs.multipleValues !== 'false' ? true : false;
        scope.isRequired = angular.isDefined(attrs.required) && attrs.required !== 'false' ? true : false;

        // Check to see what the value we put into the model if the box is checked should be
        if (angular.isDefined(scope.value)) {

            // If value is set to true then it should be a string not a bool. If a bool is needed then the attribute should be ommitted
            scope.value = scope.value === true ? "true" : scope.value;
        } else {
            scope.value = true;
        }

        // Check to see what the value we put into the model if the box is not checked should be
        if (angular.isDefined(scope.falseValue)) {

            // If value is set to false then it should be a string not a bool. If a bool is needed then the attribute should be ommitted
            scope.falseValue = scope.falseValue === false ? "false" : scope.falseValue;
        } else {
            scope.falseValue = false;
        }

        // listen to changes on disabled attribute
        attrs.$observe('isDisabled', function (value) {
            scope.isDisabled = value === 'true' || value === true ? true : false;
            element.toggleClass('is-disabled', scope.isDisabled);
            ngModelCtrl.$render();
        });

        // Watch for changes on scope.isChecked
        scope.$watch('isChecked', function (newValue, oldValue) {

            element.toggleClass('is-checked', scope.isChecked);

            if (newValue !== oldValue) {
                updateViewValue();
                validate();
            }
        });

        // Is the model an array?
        function modelIsArray() {
            return angular.isArray(ngModelCtrl.$modelValue);
        }

        // Is the model checked by this checkbox?
        function modelIsChecked() {

            if (modelIsArray()) {
                return ngModelCtrl.$modelValue.indexOf(scope.value) > -1;
            }

            return angular.equals(ngModelCtrl.$modelValue, scope.value);
        }

        // Is the model checked by this checkbox or a different one?
        function modelIsAnything() {

            if (ngModelCtrl.$modelValue && (ngModelCtrl.$modelValue.length > 0 || ngModelCtrl.$modelValue === true)) {
                return true;
            } else {
                return false;
            }
        }

        // Validate
        function validate() {

            $timeout(function () {
                // Timeout used because $setValidity cannot be used inside $render

                if (scope.isRequired) {
                    ngModelCtrl.$setValidity('required', modelIsAnything());
                }
            });
        }

        // Update View Value
        function updateViewValue() {

            var model = ngModelCtrl.$modelValue;

            if (scope.isMultiple) {

                // We have to create a new array, otherwise angular can't detect a model change
                var newModel = [];

                // Copy data from existing model
                if (modelIsArray()) {
                    angular.copy(model, newModel);
                }

                model = newModel;

                var index = model.indexOf(scope.value);

                if (scope.isChecked && index === -1) {
                    model.push(scope.value);
                } else if (!scope.isChecked && index > -1) {
                    model.splice(index, 1);
                }
            } else {

                if (scope.isChecked) {
                    model = scope.value;
                }

                // If the model hasn't been set yet, or it's the current true value (because we're flipping it)
                // When model is not set yet, Nagular makes it NaN which is hard to check for, hence the hack
                // https://makandracards.com/makandra/28903-javascript-how-to-check-if-an-object-is-nan
                else if (typeof model === 'number' && isNaN(model) || model === undefined || model === scope.value) {
                        model = scope.falseValue;
                    }
            }

            // update view value
            ngModelCtrl.$setViewValue(model);

            // Fire onChange callback
            if (scope.onChange) {
                scope.onChange({ value: model });
            }
        }

        // model -> UI
        function render() {

            scope.isChecked = modelIsChecked();

            validate();

            scope.$broadcast('s4pCheckbox-model-changed', scope.isChecked);
        }

        ngModelCtrl.$render = function () {
            render();
        };
    }
}]).directive('input', ["$timeout", function ($timeout) {

    return {
        restrict: 'E',
        require: ['^?s4pCheckbox'],
        link: postLink
    };

    function postLink(scope, element, attrs, ctrls) {

        var checkboxCtrl = ctrls[0];

        // If this input is not in a s4p-checkbox container or is not a checkbox then do nothing.
        if (!checkboxCtrl || attrs.type !== 'checkbox') return;

        scope.$on('s4pCheckbox-model-changed', function (evt, newValue) {
            element.prop('checked', newValue);
        });

        // When the checkbox changes state, update the main directive
        element.on('change', function (ev) {
            $timeout(function () {
                checkboxCtrl.setState(element.prop('checked'));
            });
        });

        // When the checkbox is focused, set a class
        element.on('focus', function (ev) {
            $timeout(function () {
                checkboxCtrl.setFocused(true);
            });
        });

        // When the checkbox is focused, set a class
        element.on('blur', function (ev) {
            $timeout(function () {
                checkboxCtrl.setFocused(false);
            });
        });
    }
}]);

/////////////////////////////////////////////////////////
//                  Six4 Portal Chip                   //
/////////////////////////////////////////////////////////

document.createElement('s4p-chip-list');
document.createElement('s4p-chip');
document.createElement('s4p-chip-content');
document.createElement('s4p-chip-action');

angular.module('s4p.directives').controller('S4pChipCtrl', ["$scope", "$element", "$attrs", "$rootScope", function ($scope, $element, $attrs, $rootScope) {

    'use strict';

    var ctrl = this;

    // Do Action
    ctrl.doAction = function () {

        if (!!ctrl.onActionClicked) {
            ctrl.onActionClicked();
        }
    };

    // Init
    ctrl.init = function () {};
}]).directive('s4pChip', function () {

    return {

        restrict: 'E',
        bindToController: {
            action: '@',
            actionIconName: '@',
            onActionClicked: '&?'
        },
        template: getTemplate,
        transclude: true,
        controller: 'S4pChipCtrl as s4pChip',
        link: function link(scope, element, attrs, controller) {
            controller.init();
            controller.element = element;
        }

    };

    function getTemplate(element, attr) {

        var cs = angular.isDefined(attr.cs) ? 'cs="' + attr.cs + '"' : '';

        // Is there an action button?
        if (attr.action && attr.action !== 'false') {
            return '<s4p-chip-inner class="s4p-chip--has-action">' + '<s4p-chip-content ng-transclude></s4p-chip-content>' + '<s4p-chip-action>' + '<trigger ng-click="s4pChip.doAction()">' + '<s4p-icon icon-name="{{s4pChip.actionIconName}}"></s4p-icon>' + '</trigger>' + '</s4p-chip-action>' + '</s4p-chip-inner>';
        }

        // Else there's no action
        else {

                return '<s4p-chip-inner>' + '<s4p-chip-content ng-transclude></s4p-chip-content>' + '</s4p-chip-inner>';
            }
    }
});

////////////////////////////////////////////////////
//                S4p Data Form                  //
////////////////////////////////////////////////////

document.createElement('s4p-data-form');

angular.module('s4p.directives').controller('S4pDataFormCtrl', ["$scope", "$element", "$attrs", "$http", "$state", "$rootScope", "$timeout", "$interpolate", "$q", "s4pData", "s4pSaveChanges", "six4Auth", function ($scope, $element, $attrs, $http, $state, $rootScope, $timeout, $interpolate, $q, s4pData, s4pSaveChanges, six4Auth) {

    'use strict';

    var ctrl = this;

    ctrl.config = {};

    // Get Config
    ctrl.getConfig = function () {

        ctrl.configLoading = true;

        return s4pData.getFormConfig({
            table: ctrl.tableName,
            form: ctrl.configFormName
        }).then(function (formConfig) {
            ctrl.config = formConfig;
            ctrl.configLoading = false;
        });
    };

    // Load Meta
    ctrl.loadMeta = function () {

        // Function for getting the data from an endpoint
        function getData(dataConfig) {

            var url, params;

            // Endpoint - If endpoint has been supplied then use it, otherwise construct the URL
            if (ctrl.config.endpoints && ctrl.config.endpoints.meta && ctrl.config.endpoints.meta[dataConfig.fieldName]) {

                var endpoint = ctrl.config.endpoints.meta[dataConfig.fieldName];

                var prepend = endpoint.prepend || portalSettings.apiUrl;

                if (endpoint.prependPortalSetting) {
                    prepend = portalSettings[endpoint.prependPortalSetting];
                }

                console.log("testing", endpoint);

                if (endpoint.prependS4pSetting) {
                    prepend = s4pSettings[endpoint.prependS4pSetting];
                }

                url = prepend + endpoint.url;
            } else {
                url = portalSettings.apiUrl + '/data/' + dataConfig.tableName;
            }

            // Get the data
            return $http.get(url).then(function (response) {

                ctrl.formMeta[dataConfig.fieldName] = ctrl.formMeta[dataConfig.fieldName] || {};
                ctrl.formMeta[dataConfig.fieldName].options = [];

                response.data.records.forEach(function (item) {
                    ctrl.formMeta[dataConfig.fieldName].options.push({
                        value: item[dataConfig.valueColumn],
                        text: $interpolate('{{' + dataConfig.displayValue + '}}')(item)
                    });
                });
            });
        };

        var apiCalls = [];

        // Loop through fields
        ctrl.config.fields.forEach(function (field) {

            if (field.subType === 'enum' && field.options) {
                ctrl.formMeta[field.name] = ctrl.formMeta[field.name] || {};
                ctrl.formMeta[field.name].options = field.options;
            }

            if (field.subType === 'table' && field.controlType === 'picklist') {

                apiCalls.push(getData({
                    fieldName: field.name,
                    tableName: field.table.name,
                    displayValue: field.relationship.displayValue,
                    valueColumn: field.table.valueColumn
                }));
            }

            if (field.subType === 'relatedTable' && field.controlType === 'picklist') {

                apiCalls.push(getData({
                    fieldName: field.name,
                    tableName: field.relationship.table,
                    displayValue: field.relationship.displayValue || field.relationship.primaryKey,
                    valueColumn: field.relationship.primaryKey
                }));
            }
        });

        // If any data is coming from the API then get it, otherwise return a resolved promise
        if (apiCalls.length > 0) {

            return $q.all(apiCalls).then(function (responses) {
                return responses;
            });
        } else {
            return $q.resolve();
        }
    };

    // Load Data
    ctrl.loadData = function () {

        // If there is any data to load
        if (ctrl.mode === 'edit') {

            ctrl.dataLoading = true;

            var url, params;

            // Endpoint - If endpoint has been supplied then use it, otherwise construct the URL
            if (ctrl.config.endpoints && ctrl.config.endpoints.load) {

                var endpoint = ctrl.config.endpoints.load;
                var prepend = endpoint.prependPortalSetting ? portalSettings[endpoint.prependPortalSetting] : endpoint.prepend || portalSettings.apiUrl;
                url = prepend + endpoint.url + '/' + ctrl.recordId;
            } else {
                url = portalSettings.apiUrl + '/data/' + ctrl.tableName + '/' + ctrl.recordId;
            }

            // Load the data
            return $http.get(url, {
                params: params
            }).then(function (response) {

                ctrl.dataLoading = false;
                ctrl.model = response.data.record;

                // Run Data Loaded Callback and if it returns anything then replace the original value
                if (!!ctrl.onDataLoaded) {
                    response.data = ctrl.onDataLoaded({ data: response.data }) || response.data;
                }

                // Transformations
                ctrl.config.fields.forEach(function (field) {

                    // DateTimes
                    if (field.controlType === 'datetimepicker') {

                        var loadedDateTime = moment(new Date(ctrl.model[field.name]));

                        ctrl.model[field.name] = {
                            day: loadedDateTime.format('DD'),
                            month: loadedDateTime.format('MM'),
                            year: '1997',
                            time: loadedDateTime.format('HH:mm')
                        };
                    }
                });
            });
        }

        // Otherwise return a resolved promise
        else {
                return $q.resolve();
            }
    };

    // Submit Form
    ctrl.submitForm = function () {

        // POST or PATCH to API if we're adding or editing
        if (ctrl.mode === 'add' || ctrl.mode === 'edit') {

            ctrl.dataSaving = true;

            var url;

            // Endpoint - If endpoint has been supplied then use it, otherwise construct the URL
            if (ctrl.config.endpoints && ctrl.config.endpoints.save) {

                var endpoint = ctrl.config.endpoints.save;
                var prepend = endpoint.prependPortalSetting ? portalSettings[endpoint.prependPortalSetting] : endpoint.prepend || portalSettings.apiUrl;
                url = prepend + endpoint.url;

                if (ctrl.mode === 'edit') {
                    url += '/' + ctrl.recordId;
                }
            } else {

                url = portalSettings.apiUrl + '/data/' + ctrl.tableName;

                if (ctrl.mode === 'edit') {
                    url += '/' + ctrl.recordId;
                }
            }

            // Copy Item
            var newItem = angular.copy(ctrl.model);

            // Transformations
            ctrl.config.fields.forEach(function (field) {

                // Files
                if (field.subType === 'file') {
                    if (newItem[field.name]) {
                        newItem[field.fileObjectName] = angular.copy(newItem[field.name]);
                        newItem[field.name] = newItem[field.name].id;
                    }
                }

                // DateTimes
                if (field.controlType === 'datetimepicker') {

                    var dateTimeField = newItem[field.name];
                    var hours = dateTimeField.time.substring(0, 2);
                    var minutes = dateTimeField.time.substring(3, 5);

                    var newDate = moment.utc(dateTimeField.month + '-' + dateTimeField.day + '-' + dateTimeField.year + ':' + hours + ':' + minutes, "MM-DD-YYYY:HH:mm");
                    newItem[field.name] = newDate;
                }
            });

            // POST or PATCH
            var method = ctrl.mode === 'edit' ? 'patch' : 'post';

            return $http[method](url, newItem, {
                form: ctrl[ctrl.formName]
            }).then(function (response) {

                s4pSaveChanges.resetWatch(ctrl.formName + 'Model');

                ctrl.dataSaving = false;

                // Run Data Saved Callback
                if (!!ctrl.onDataSaved) {
                    ctrl.onDataSaved({ response: response.data });
                }
            }, function (rejection) {

                // Run Data Saved Callback
                if (!!ctrl.onDataSaveError) {
                    ctrl.onDataSaveError({ rejection: rejection });
                }
            });
        }

        // If we're not adding or editing then just call the callback and pass the form data back.
        else {

                return $q.resolve().then(function () {

                    s4pSaveChanges.resetWatch(ctrl.formName + 'Model');

                    ctrl.formControl.$resetForm();

                    if (!!ctrl.onDataSaved) {
                        ctrl.onDataSaved({ response: angular.copy(ctrl.model) });
                    }
                });
            }
    };

    // Trigger Submit externally
    ctrl.triggerSubmit = function () {
        console.log("Triggering Submit", angular.copy(ctrl.formControl));
        ctrl.formControl.submit();
    };

    // Reset Form
    ctrl.resetForm = function () {
        console.log("Resetting Form", angular.copy(ctrl.formControl));
        ctrl.formControl.$resetForm();
    };

    // Allowed to view table?
    ctrl.canViewForm = function () {
        return ctrl.config.userSecurity.view && ctrl.config.userSecurity[ctrl.mode];
    };

    // Allowed to view a particular field?
    ctrl.canViewField = function (field) {
        return field.userSecurity.view;
    };

    // Allowed to view a particular row?
    ctrl.canViewRow = function (row) {

        var allowed = true;

        row.items.forEach(function (item) {
            if (item.field.userSecurity.view === false) {
                allowed = false;
            }
        });

        return allowed;
    };

    // Allowed to add or edit a particular field (depending on mode)?
    ctrl.canAddEditField = function (field) {

        if (ctrl.mode) {
            return field.userSecurity[ctrl.mode];
        } else {
            return false;
        }
    };

    // Control type uses input tag
    ctrl.isInputTag = function (field) {
        return ['text', 'number', 'tel', 'email', 'time', 'color'].indexOf(field.controlType) > -1;
    };

    // Get Old File apiUrl
    ctrl.getOldFileUrl = function (item) {
        return ctrl.model[item.field.fileObjectName] ? portalSettings.filesUrl + ctrl.model[item.field.fileObjectName].url : undefined;
    };

    // Get field display value
    ctrl.getFieldDisplayValue = function (field) {

        if (field.subType === 'relatedTable') {
            return $interpolate('{{' + field.relationship.displayValue + '}}')(ctrl.model[field.relationship.name]);
        }

        return $interpolate('{{' + field.displayValue + '}}')(ctrl.model);
    };

    // Create Date Drop Down Years
    ctrl.createDateDropDownsYears = function (field) {

        ctrl.dropDownYears = ctrl.dropDownYears || [];

        if (ctrl.dropDownYears[field.name] && ctrl.dropDownYears[field.name].length > 0) {
            return ctrl.dropDownYears[field.name];
        } else {

            var backYears = field.backYears || 100;
            var forwardYears = field.forwardYears || 5;
            var totalYears = backYears + forwardYears;
            var thisYear = new Date().getFullYear();

            var years = [];

            for (var i = 0; i < totalYears + 1; i++) {
                years.push((thisYear + forwardYears - i).toString());
            }

            return years;
        }
    };

    // Is anything currently loading?
    ctrl.loading = function () {
        return ctrl.configLoading || ctrl.metaLoading || ctrl.dataLoading;
    };

    // Is anything currently saving?
    ctrl.saving = function () {
        return ctrl.dataSaving;
    };

    // Init
    ctrl.init = function () {

        // TODO - passedConfig


        // Create a reference to the underlying form
        ctrl.formControl = ctrl[ctrl.formName];

        console.log("Found", angular.copy(ctrl.formControl));

        // Create an api so that the external world can control our table
        ctrl.api = {
            submit: ctrl.triggerSubmit,
            reset: ctrl.resetForm
        };

        // Default config before it's loaded
        ctrl.config = {
            userSecurity: {}
        };

        ctrl.mode = ctrl.mode || 'view';

        if (!ctrl.configFormName) {
            ctrl.configFormName = ctrl.mode === 'add' || ctrl.mode === 'edit' ? ctrl.configFormName = ctrl.mode : 'default';
        }

        ctrl.scrollToFirstError = ctrl.scrollToFirstError || false;
        ctrl.model = {};
        ctrl.formMeta = {};

        // Watch the loading and saving flags and propagate them to the outside world
        $scope.$watch(function () {
            return ctrl.loading();
        }, function (newValue) {
            ctrl.isLoading = newValue;
        });

        $scope.$watch(function () {
            return ctrl.saving();
        }, function (newValue) {
            ctrl.isSaving = newValue;
        });

        // Date Picker Options
        ctrl.datePickerOptions = {
            showWeeks: false,
            startingDay: 1,
            maxMode: 'day'
        };

        // Get config
        if (ctrl.tableName) {

            ctrl.getConfig().then(function () {

                return $q.all([ctrl.loadMeta(), ctrl.loadData()]).then(function () {

                    // Set save changes watch if necessary
                    if (ctrl.config.saveChangesWarning) {
                        s4pSaveChanges.watch(ctrl.formName + 'Model', ctrl.model);
                    }
                });
            });
        }
    };
}]).directive('s4pDataForm', function () {

    return {

        restrict: 'E',
        scope: {},
        bindToController: {
            formName: '@',
            tableName: '@',
            recordId: '@?',
            mode: '@?',
            configFormName: '@?',
            api: '=?',
            model: '=?',
            passedConfig: '=?config',
            isLoading: '=?',
            isSaving: '=?',
            onDataLoaded: '&?',
            onDataSubmitted: '&?',
            onDataSaved: '&?',
            onDataSaveError: '&?',
            scrollToFirstError: '&?',
            scrollTarget: '@?'
        },
        controller: 'S4pDataFormCtrl as s4pDataForm',
        templateUrl: s4pSettings.templatesUrl + '/s4p-data-form.tpl.html',
        link: function link(scope, element, attrs, controller) {

            controller.init();

            controller.element = element;
        }

    };
});

////////////////////////////////////////////////////
//                S4p Data Table                  //
////////////////////////////////////////////////////

document.createElement('s4p-data-table');

angular.module('s4p.directives').controller('S4pDataTableCtrl', ["$scope", "$element", "$attrs", "$http", "$state", "$rootScope", "$timeout", "$interpolate", "$sce", "$q", "s4pIcons", "s4pData", "six4Auth", function ($scope, $element, $attrs, $http, $state, $rootScope, $timeout, $interpolate, $sce, $q, s4pIcons, s4pData, six4Auth) {

    'use strict';

    var ctrl = this;

    ctrl.config = {};

    // Get Config
    ctrl.getConfig = function () {

        ctrl.configLoading = true;

        // Get both the table config and the whole compiled config
        return $q.all([s4pData.getCompiledConfig(), s4pData.getViewTableConfig(ctrl.tableName)]).then(function (responses) {
            ctrl.mainConfig = responses[0];
            ctrl.config = responses[1];
            ctrl.configLoading = false;
        });
    };

    // Load Data
    ctrl.loadData = function () {

        ctrl.dataLoading = true;

        var url;
        var params = {};

        // Endpoint - If endpoint has been supplied then use it, otherwise construct the URL
        if (ctrl.config.endpoints && ctrl.config.endpoints.load) {

            var prepend = portalSettings.apiUrl;
            var endpoint = ctrl.config.endpoints.load;

            if (endpoint.prepend) {
                prepend = endpoint.prepend;
            } else if (endpoint.prependPortalSetting) {
                prepend = portalSettings[endpoint.prependPortalSetting];
            }

            url = prepend + endpoint.url;
        } else {
            url = portalSettings.apiUrl + '/data/' + ctrl.tableName;
        }

        // Related Tables - If related tables definition has been supplied then use it, otherwise loop through relations of this table and add them
        if (ctrl.config.relatedTables) {
            params.relatedtables = ctrl.config.relatedTables;
        } else {

            var table = ctrl.mainConfig.tables.filter(function (item) {
                return item.name === ctrl.tableName;
            })[0];

            if (table && table.relatedTables && table.relatedTables.length > 0) {

                var relatedTables = table.relatedTables.map(function (relatedtable) {
                    return relatedtable.propertyName;
                });

                params.relatedtables = relatedTables.join('|');
            }
        }

        // Filters
        if (ctrl.filters) {
            params.filters = ctrl.filters.map(function (filter) {
                return filter.key + ':' + filter.type + ':' + filter.value;
            }).join('|');
        }

        // Sorting
        params.sortBy = ctrl.sortBy;
        params.sortDirection = ctrl.sortDirection;

        // Paging
        if (ctrl.config.paging) {
            params.start = ctrl.pagingModel.start || 1;
            params.limit = ctrl.pagingModel.itemsPerPage;
        }

        // Get the Data
        return $http.get(url, {
            params: params
        }).then(function (response) {

            if (!!ctrl.config.paging) {
                ctrl.pagingModel.totalItems = response.data.meta.totalCount;
                ctrl.pagingModel.totalPages = Math.ceil(ctrl.pagingModel.totalItems / ctrl.pagingModel.itemsPerPage);
            }

            // Run Data Loaded Callback
            if (!!ctrl.onDataLoaded) {
                ctrl.onDataLoaded({ data: response.data });
            }

            ctrl.tableData = response.data.records;
        });
    };

    // Refresh
    ctrl.refresh = function () {

        $timeout(function () {
            ctrl.getConfig().then(function () {
                ctrl.loadData();
            });
        });
    };

    // Allowed to view table?
    ctrl.canViewTable = function () {
        return ctrl.config.userSecurity.view;
    };

    // Allowed to view a particular column?
    ctrl.canViewColumn = function (column) {
        return column.userSecurity.view;
    };

    // Allowed to add records?
    ctrl.canAddRecords = function () {
        return ctrl.config.userSecurity.add;
    };

    // Allowed to edit records?
    ctrl.canEditRecords = function () {
        return ctrl.config.userSecurity.edit;
    };

    // Allowed to delete records?
    ctrl.canDeleteRecords = function () {
        if (ctrl.config.deleteMode === 'delete' || !ctrl.config.deleteMode) {
            return ctrl.config.userSecurity.delete;
        } else {
            return true;
        }
    };

    // Get Fixed Left Column Count
    ctrl.getFixedLeftColumnCount = function () {
        return ctrl.showCheckboxes() ? 1 : 0;
    };

    // Show Checkboxes?
    ctrl.showCheckboxes = function () {
        return ctrl.config.checkboxes;
    };

    // Show Delete Button?
    ctrl.showDeleteButton = function () {
        return (ctrl.config.deleteButton === undefined || ctrl.config.deleteButton) && ctrl.canDeleteRecords();
    };

    // Get Delete Button Text
    ctrl.getDeleteButtonText = function () {
        return ctrl.config.deleteButtonText || 'Remove Selected';
    };

    // Row Highlight?
    ctrl.rowHighlight = function () {
        return ctrl.canEditRecords();
    };

    // Row Clickable?
    ctrl.rowClickable = function () {
        return ctrl.onRowClicked || ctrl.canEditRecords() && ctrl.config.disableRowClick !== true;
    };

    // Get Cell Value
    ctrl.getCellValue = function (row, column) {

        // Image
        if (column.subType === 'file' && column.fileType === 'image') {
            return row[column.displayValue].url ? $sce.trustAsHtml('<div style="width:120px; height:90px; background-image:url(' + url + '); background-size:contain; background-position:center center;"></div>') : '';
        }

        // Boolean
        else if (column.type === 'boolean') {

                if (row[column.displayValue]) {
                    return $sce.trustAsHtml('<s4p-icon><svg style="transform:scale(0.65);"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' + s4pIcons.getIcon('tick-thick').url + '"></use></svg></s4p-icon>');
                } else {
                    return $sce.trustAsHtml('<s4p-icon><svg style="transform:scale(0.65);"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' + s4pIcons.getIcon('cross-thick').url + '"></use></svg></s4p-icon>');
                }
            }

            // Anything else
            else if (column.subType === 'relatedTable') {
                    return $interpolate('{{' + column.relationship.displayValue + '}}')(row[column.relationship.name]);
                } else {
                    return $interpolate('{{' + column.displayValue + '}}')(row);
                }
    };

    // Row clicked
    ctrl.rowClicked = function (evt, row) {

        if (ctrl.onRowClicked) {
            ctrl.onRowClicked({ $event: evt, row: row });
        } else if (ctrl.canEditRecords() && ctrl.config.disableRowClick !== true) {

            var route = ctrl.config.editRoute || 'data.table.edit';
            var routeParams = ctrl.config.editRouteParams || { tableName: ctrl.tableName };
            routeParams[ctrl.config.tablePrimaryKey] = row[ctrl.config.tablePrimaryKey];

            $state.go(route, routeParams);
        }
    };

    // Remove items
    ctrl.removeItems = function () {

        if (ctrl.selectedRows && ctrl.selectedRows.length > 0) {

            var url;

            // Endpoint - If endpoint has been supplied then use it, otherwise construct the URL
            if (ctrl.config.endpoints && ctrl.config.endpoints.delete) {

                var prepend = portalSettings.apiUrl;
                var endpoint = ctrl.config.endpoints.delete;

                if (endpoint.prepend) {
                    prepend = endpoint.prepend;
                } else if (endpoint.prependPortalSetting) {
                    url = portalSettings[endpoint.prependPortalSetting];
                }

                url = prepend + endpoint.url;
            } else {
                url = portalSettings.apiUrl + '/data/' + ctrl.tableName;
            }

            // Check whether we're deleting or archiving
            if (ctrl.config.deleteMode === 'archive') {

                // We're archiving
                var data = ctrl.selectedRows.map(function (item) {
                    return {
                        id: item,
                        archived: true
                    };
                });

                // Delete the records
                return $http.patch(url, data, {
                    params: {}
                }).then(function (response) {

                    // Reload the data
                    ctrl.loadData();

                    // Run Removed Callback
                    if (!!ctrl.onItemsRemoved) {
                        ctrl.onItemsRemoved({ items: ctrl.selectedRows });
                    }

                    // Reset selected rows
                    ctrl.selectedRows = [];
                });
            } else {

                // We're deleteing
                var params = {
                    ids: ctrl.selectedRows.join(',')

                    // Delete the records
                };return $http.delete(url, {
                    params: params
                }).then(function (response) {

                    // Reload the data
                    ctrl.loadData();

                    // Run Removed Callback
                    if (!!ctrl.onItemsRemoved) {
                        ctrl.onItemsRemoved({ items: ctrl.selectedRows });
                    }

                    // Reset selected rows
                    ctrl.selectedRows = [];
                });
            }
        }
    };

    // Paging clicked
    ctrl.tablePageChanged = function () {
        ctrl.pagingModel.start = (ctrl.pagingModel.currentPage - 1) * ctrl.pagingModel.itemsPerPage + 1 + ctrl.pagingModel.offset;
        ctrl.loadData();
    };

    // Init
    ctrl.init = function () {

        // Create an api so that the external world can control our table
        ctrl.api = {
            refresh: ctrl.refresh
        };

        // Default config before it's loaded
        ctrl.config = {
            userSecurity: {}

            // Get config
        };if (ctrl.tableName) {

            ctrl.getConfig().then(function () {

                // TODO - Use passedConfig if it exists

                // Set up paging if necessary
                if (ctrl.config.paging) {

                    ctrl.pagingModel = {
                        totalItems: 0,
                        itemsPerPage: ctrl.config.paging.itemsPerPage || 20,
                        pagesToShow: 5,
                        currentPage: 1,
                        offset: 0,
                        start: 1
                    };
                }

                ctrl.loadData();
            });
        }

        if (ctrl.passedConfig) {

            // TODO
            // Get passed in config and merge it with existing config
            // Set up watch to merge loaded table config with passed in config and put the result in actual config

        }
    };
}]).directive('s4pDataTable', function () {

    return {

        restrict: 'E',
        scope: {},
        bindToController: {
            tableName: '@?',
            tableFriendlyName: '@?',
            api: '=?',
            passedConfig: '=?config',
            filters: '=?',
            sortBy: '@?',
            sortDirection: '@?',
            isLoading: '=?',
            onDataLoaded: '&?',
            onRowClicked: '&?',
            onItemsRemoved: '&?',
            stretchVertically: '@?'
        },
        controller: 'S4pDataTableCtrl as s4pDataTable',
        templateUrl: s4pSettings.templatesUrl + '/s4p-data-table.tpl.html',
        link: function link(scope, element, attrs, controller) {

            controller.init();
        }

    };
});

/////////////////////////////////////////////////////////
//            Six4 Portal Date Range Picker            //
/////////////////////////////////////////////////////////


document.createElement('s4p-date-range-picker');

angular.module('s4p.directives')

// Date Range Picker
.controller('s4pDateRangePickerCtrl', ["$scope", "$element", "$attrs", "$log", "$timeout", function ($scope, $element, $attrs, $log, $timeout) {

    var ctrl = this;

    // Create Date Range Options
    ctrl.createDateRangeOptions = function () {

        ctrl.dateRangeOptions = [{
            name: 'Today',
            start: moment().utc().startOf('day').toISOString(),
            end: moment().utc().endOf('day').toISOString()
        }, {
            name: 'Yesterday',
            start: moment().utc().subtract(1, 'days').startOf('day').toISOString(),
            end: moment().utc().subtract(1, 'days').endOf('day').toISOString()
        }, {
            name: 'This Week',
            start: moment().utc().startOf('isoWeek').toISOString(),
            end: moment().utc().endOf('day').toISOString()
        }, {
            name: 'Last Week',
            start: moment().utc().subtract(1, 'week').startOf('isoWeek').toISOString(),
            end: moment().utc().subtract(1, 'week').endOf('isoWeek').toISOString()
        }, {
            name: 'Last 7 Days',
            start: moment().utc().subtract(1, 'week').startOf('day').toISOString(),
            end: moment().utc().subtract(1, 'day').endOf('day').toISOString()
        }, {
            name: 'This Month',
            start: moment().utc().startOf('month').toISOString(),
            end: moment().utc().endOf('day').toISOString()
        }, {
            name: 'Last Month',
            start: moment().utc().subtract(1, 'month').startOf('month').toISOString(),
            end: moment().utc().subtract(1, 'month').endOf('month').toISOString()
        }, {
            name: 'This Year',
            start: moment().utc().startOf('year').toISOString(),
            end: moment().utc().endOf('day').toISOString()
        }, {
            name: 'Last Year',
            start: moment().utc().subtract(1, 'year').startOf('year').toISOString(),
            end: moment().utc().subtract(1, 'year').endOf('year').toISOString()
        }, {
            name: 'All Time',
            start: moment("19000101T0000").utc().toISOString(),
            end: moment().utc().endOf('day').toISOString()
        }, {
            name: 'Custom Date Range'
        }];
    };

    // Date Range Option Changed
    ctrl.dateRangeOptionChanged = function (newValue, oldValue) {

        if (newValue === 'Custom Date Range') {
            ctrl.selectedDateRangeOption = oldValue;
            ctrl.toggleDateRangePickers(true);
            return;
        }

        ctrl.removeCustomDateRange();

        var matchedOption = ctrl.dateRangeOptions.filter(function (item) {
            return item.name === newValue;
        })[0];

        ctrl.oldDateRangeModel = angular.copy(ctrl.dateRangeModel);
        ctrl.dateRangeModel = matchedOption;

        ctrl.toggleDateRangePickers(false);

        ctrl.datesChanged();
    };

    // On Dates Changed
    ctrl.datesChanged = function (evt) {

        if (ctrl.onDatesChanged && ctrl.oldDateRangeModel) {
            $timeout(function () {
                ctrl.onDatesChanged({ dateRangeModel: ctrl.dateRangeModel });
            });
        }
    };

    // Remove Custom Date Range
    ctrl.removeCustomDateRange = function () {

        ctrl.dateRangeOptions.forEach(function (item, index, object) {
            if (item.type === 'custom') {
                object.splice(index, 1);
            }
        });
    };

    // Custom Date Range Selected
    ctrl.customDateRangeSelected = function (startDateTime, endDateTime) {

        ctrl.removeCustomDateRange();

        ctrl.dateRangeOptions.unshift({
            name: moment(startDateTime).utc().format('D MMM YYYY') + ' - ' + moment(endDateTime).utc().format('D MMM YYYY'),
            start: startDateTime,
            end: endDateTime,
            type: 'custom'
        });

        ctrl.selectedDateRangeOption = ctrl.dateRangeOptions[0].name;

        ctrl.oldDateRangeModel = angular.copy(ctrl.dateRangeModel);
        ctrl.dateRangeModel = ctrl.dateRangeOptions[0];

        ctrl.datesChanged();
    };

    // Toggle Date Range Pickers
    ctrl.toggleDateRangePickers = function (value) {
        ctrl.panelOpen = value === undefined ? !ctrl.panelOpen : value;
    };

    // Initialize
    ctrl.init = function () {

        ctrl.createDateRangeOptions();

        // Set initial Selection/Dates

        // Custom date range has been supplied
        if (ctrl.dateRangeModel && ctrl.dateRangeModel.type === 'custom') {
            ctrl.customDateRangeSelected(ctrl.dateRangeModel.start, ctrl.dateRangeModel.end);
        }

        // Range name has been supplied
        else if (ctrl.dateRangeModel && ctrl.dateRangeModel.name) {
                ctrl.selectedDateRangeOption = ctrl.dateRangeModel.name;
                ctrl.dateRangeOptionChanged(ctrl.dateRangeModel.name);
            }

            // Dates have been supplied
            else if (ctrl.dateRangeModel && ctrl.dateRangeModel.start && ctrl.dateRangeModel.end) {
                    ctrl.customDateRangeSelected(ctrl.dateRangeModel.start, ctrl.dateRangeModel.end);
                }

                // Otherwise set to All Time
                else {
                        ctrl.selectedDateRangeOption = 'All Time';
                        ctrl.dateRangeOptionChanged('All Time');
                    }
    };
}]).directive('s4pDateRangePicker', function () {

    return {
        restrict: 'EA',
        scope: {},
        bindToController: {
            minDate: '@?',
            maxDate: '@?',
            dateRangeModel: '=?',
            panelOpen: '=?',
            onDatesChanged: '&?'
        },
        controller: 's4pDateRangePickerCtrl as s4pDateRangePicker',
        link: postLink
    };

    function postLink(scope, element, attrs) {

        var s4pDateRangePicker = scope.s4pDateRangePicker;
        s4pDateRangePicker.init();
    }
})

// Select
.controller('s4pDateRangePickerSelectCtrl', ["$scope", "$element", "$attrs", "$log", function ($scope, $element, $attrs, $log) {
    var ctrl = this;
}]).directive('s4pDateRangePickerSelect', function () {

    return {
        restrict: 'EA',
        scope: {},
        require: '?^s4pDateRangePicker',
        bindToController: {
            cs: '@?'
        },
        controller: 's4pDateRangePickerSelectCtrl as s4pDateRangePickerSelect',
        link: postLink,
        template: getTemplate
    };

    function getTemplate(element, attr) {

        return '<s4p-select cs="{{s4pDateRangePickerSelect.cs}}" label-type="fade">' + '<select name="dateRange"' + 'ng-model="s4pDateRangePickerSelect.parentPicker.selectedDateRangeOption"' + 'ng-change="s4pDateRangePickerSelect.parentPicker.dateRangeOptionChanged(s4pDateRangePickerSelect.parentPicker.selectedDateRangeOption, \'{{s4pDateRangePickerSelect.parentPicker.selectedDateRangeOption}}\')"' + '>' + '<option ng-repeat="item in s4pDateRangePickerSelect.parentPicker.dateRangeOptions" value="{{item.name}}">{{item.name}}</option>' + '</select>' + '</s4p-select>';
    }

    function postLink(scope, element, attrs, controller) {

        var s4pDateRangePickerSelect = scope.s4pDateRangePickerSelect;
        s4pDateRangePickerSelect.parentPicker = controller;

        s4pDateRangePickerSelect.cs = s4pDateRangePickerSelect.cs || 'primary';
    }
})

// Panel
.directive('s4pDateRangePickerPanel', function () {

    return {
        restrict: 'EA',
        scope: {},
        require: '?^s4pDateRangePicker',
        link: postLink

    };

    function postLink(scope, element, attrs, controller) {

        var parentCtrl = controller;

        scope.$watch(function () {
            return parentCtrl.panelOpen;
        }, function (newValue, oldaValue) {
            element[0].style.display = newValue ? 'block' : 'none';
        });
    }
})

// Pickers
.controller('s4pDateRangePickerPickersCtrl', ["$scope", "$element", "$attrs", "$log", "$timeout", function ($scope, $element, $attrs, $log, $timeout) {

    var ctrl = this;

    // Cancel
    ctrl.cancel = function () {
        ctrl.parentPicker.toggleDateRangePickers(false);
    };

    // Apply
    ctrl.apply = function () {

        var startDateToApply = moment(ctrl.datePickerStartDate).startOf('day').utc().toISOString();
        var endDateToApply = moment(ctrl.datePickerEndDate).endOf('day').utc().toISOString();

        ctrl.parentPicker.customDateRangeSelected(startDateToApply, endDateToApply);
        ctrl.parentPicker.toggleDateRangePickers(false);
    };

    // Set Initial Options
    ctrl.setInitialOptions = function () {

        ctrl.startDatePickerOptions = {
            showWeeks: false,
            startingDay: 1,
            maxMode: 'day',
            customClass: ctrl.getDayClass
        };

        if (ctrl.parentPicker.maxDate === 'today') {
            ctrl.startDatePickerOptions.maxDate = new Date();
        } else if (ctrl.parentPicker.maxDate) {
            ctrl.startDatePickerOptions.maxDate = new Date(ctrl.parentPicker.maxDate);
        }

        if (ctrl.parentPicker.minDate === 'today') {
            ctrl.startDatePickerOptions.minDate = new Date();
        } else if (ctrl.parentPicker.minDate) {
            ctrl.startDatePickerOptions.minDate = new Date(ctrl.parentPicker.minDate);
        }

        ctrl.endDatePickerOptions = angular.copy(ctrl.startDatePickerOptions);
    };

    // Create Selection Watches
    ctrl.createSelectionWatches = function () {

        // Watch for Start Date Changing
        $scope.$watch(function () {
            return ctrl.datePickerStartDate;
        }, function (newValue, oldValue) {

            // If start date is after end date then clear the end date

            if (ctrl.datePickerEndDate && ctrl.datePickerStartDate >= ctrl.datePickerEndDate) {
                ctrl.datePickerRefresh = true;
                ctrl.datePickerEndDate = undefined;
            }

            // Make sure the user can't choose an end date before the start date
            ctrl.endDatePickerOptions.minDate = ctrl.datePickerStartDate;
        });

        // Watch for End Date Changing
        $scope.$watch(function () {
            return ctrl.datePickerEndDate;
        }, function (newValue, oldValue) {

            if (newValue) {
                ctrl.datePickerRefresh = true;
            }
        });
    };

    // Get Day Class for start of/in/end of range
    ctrl.getDayClass = function (data) {

        var date = data.date,
            mode = data.mode;

        if (mode === 'day' && ctrl.datePickerStartDate && ctrl.datePickerEndDate) {

            var newClass = '';

            if (date > ctrl.datePickerStartDate && date < ctrl.datePickerEndDate) {
                newClass += ' is-in-range';
            }

            if (date.getTime() === ctrl.datePickerStartDate.getTime()) {
                newClass += ' is-range-start';
            }

            if (date.getTime() === ctrl.datePickerEndDate.getTime()) {
                newClass += ' is-range-end';
            }

            return newClass;
        }
    };

    // Initialize
    ctrl.init = function () {

        // Set Initial Options
        ctrl.setInitialOptions();

        // Create On-Select Watches
        ctrl.createSelectionWatches();

        // Clean up
        $scope.$on('$destroy', function () {});
    };
}]).directive('s4pDateRangePickerPickers', function () {

    return {
        restrict: 'E',
        scope: {},
        require: '?^s4pDateRangePicker',
        bindToController: {},
        controller: 's4pDateRangePickerPickersCtrl as s4pDateRangePickerPickers',
        link: postLink,
        template: getTemplate

    };

    function getTemplate(element, attr) {

        return '<div>' + '<s4p-row spacing-bottom-half justify-content="right">' + '<s4p-row-item w="250" stretch="false" shrink="false" full-width-at="1,2">' + '<uib-datepicker ng-model="s4pDateRangePickerPickers.datePickerStartDate" datepicker-options="s4pDateRangePickerPickers.startDatePickerOptions" datepicker-refresh="s4pDateRangePickerPickers.datePickerRefresh" ng-model-options="{allowInvalid:false}"></uib-datepicker>' + '</s4p-row-item>' + '<s4p-row-item w="250" stretch="false" shrink="false" full-width-at="1,2">' + '<uib-datepicker ng-model="s4pDateRangePickerPickers.datePickerEndDate" datepicker-options="s4pDateRangePickerPickers.endDatePickerOptions" datepicker-refresh="s4pDateRangePickerPickers.datePickerRefresh" ng-model-options="{allowInvalid:false}"></uib-datepicker>' + '</s4p-row-item>' + '</s4p-row>' + '<s4p-row justify-content="right">' + '<s4p-row-item style="padding-right:5px;">' + '<s4p-button cs="accent" ng-click="s4pDateRangePickerPickers.cancel()">Cancel</s4p-button>' + '</s4p-row-item>' + '<s4p-row-item style="padding-left:5px;">' + '<s4p-button cs="primary" icon-position="right" icon="arrow-right-circle" ng-click="s4pDateRangePickerPickers.apply()">Select Dates</s4p-button>' + '</s4p-row-item>' + '</s4p-row>' + '</div>';
    }

    function postLink(scope, element, attrs, controller) {

        var s4pDateRangePickerPickers = scope.s4pDateRangePickerPickers;
        s4pDateRangePickerPickers.parentPicker = controller;

        s4pDateRangePickerPickers.init();
    }
});
/////////////////////////////////////////////////////////
//               Six4 Portal File Upload               //
/////////////////////////////////////////////////////////

document.createElement('s4p-file-upload');
document.createElement('s4p-file-upload-inner');
document.createElement('s4p-file-upload-text');
document.createElement('s4p-file-upload-button');

document.createElement('s4p-file-previews');
document.createElement('s4p-file-previews-inner');
document.createElement('s4p-file-preview');
document.createElement('s4p-file-preview-thumbnail');
document.createElement('s4p-file-preview-progress');
document.createElement('s4p-file-preview-details');
document.createElement('s4p-file-preview-label');
document.createElement('s4p-file-preview-percent');
document.createElement('s4p-file-preview-remove');
document.createElement('s4p-file-preview-noimage');
document.createElement('s4p-file-preview-noimage-inner');
document.createElement('s4p-file-preview-noimage-icon');

document.createElement('s4p-thumbnail');

angular.module('s4p.services')

// Provider
.provider('s4pFileUploadConfig', function () {

    this.setDefaultUploadUrl = function (val) {
        this.defaultUploadUrl = val;
    };

    this.$get = function () {
        return this;
    };
});

angular.module('s4p.directives')

// Templates
.run(["$templateCache", function ($templateCache) {

    $templateCache.put("/templates/s4pFileUpload.tpl.html", "<label>" + "<input class=\"uploadInput\" type=\"file\" name=\"{{::name}}\" nv-file-select uploader=\"fileUploadCtrl.nvUploader\" />" + "<s4p-file-upload-inner>" + "<s4p-file-upload-text>" + "<span ng-bind=\"filenames\"></span>" + "</s4p-file-upload-text>" + "<s4p-file-upload-button>" + "<s4p-button button-style=\"circle\" button-tag=\"span\" icon=\"upload\"></s4p-button>" + "</s4p-file-upload-button>" + "</s4p-file-upload-inner>" + "</label>");

    $templateCache.put('/templates/s4pFilePreviews.tpl.html', '<s4p-file-previews-old-thumbnail ng-if="uploader.queue.length < 1 && oldFileUrl" style="margin-bottom:30px">' + '<div ng-show="oldFileIsImage" ng-style="{width: thumbnailWidthCss, height: thumbnailHeightCss}"><img ng-src="{{oldFileUrl}}" /></div>' + '<div ng-show="oldFileIsImage === false"><a ng-href="{{oldFileUrl}}" target="_blank" href="">{{oldFileUrl}}</a></div>' + '</s4p-file-previews-old-thumbnail>' + '<s4p-file-previews-inner ng-class="{\'showOnSubmitting\': showOnSubmitting}">' + '<s4p-file-preview ng-repeat="item in uploader.queue" ng-show="checkPreviewVisibility(item)" ng-style="::{width: thumbnailWidthCss, height: thumbnailHeightCss}">' + '<s4p-file-preview-thumbnail ng-if="showThumbnails">' + '<s4p-thumbnail file="item._file" canvas-width="{{thumbnailWidth}}" canvas-height="{{thumbnailHeight}}"></s4p-thumbnail>' + '</s4p-file-preview-thumbnail>' + '<s4p-file-preview-progress ng-if="showProgress">' + '<span style="width:{{item.progress}}%"></span>' + '</s4p-file-preview-progress>' + '<s4p-file-preview-details>' + '<s4p-file-preview-label>{{::item._file.name}}</s4p-file-preview-label>' + '<s4p-file-preview-percent ng-if="showProgress">{{item.progress}}%</s4p-file-preview-percent>' + '<s4p-file-preview-remove>' + '<s4p-button button-style="circle" circle-size="small" icon="cross-circle" ng-click="removeFromQueue(item)"></s4p-button>' + '</s4p-file-preview-remove>' + '</s4p-file-preview-details>' + '</s4p-file-preview>' + '</s4p-file-previews-inner>');

    $templateCache.put("/templates/s4pFilePreviewNotImage.tpl.html", "<s4p-file-preview-noimage>" + "<s4p-file-preview-noimage-inner>" + "<s4p-file-preview-noimage-icon>" + "<s4p-icon icon-name=\"{{::iconName}}\"></s4p-icon>" + "</s4p-file-preview-noimage-icon>" + "</s4p-file-preview-noimage-inner>" + "</s4p-file-preview-noimage>");
}])

// File upload controller
.controller('s4pFileUploadCtrl', ["$scope", "$element", "$attrs", "$window", "$log", "$injector", "$interpolate", "FileUploader", "s4pFileUploadConfig", function ($scope, $element, $attrs, $window, $log, $injector, $interpolate, FileUploader, s4pFileUploadConfig) {

    var self = this;

    // Get six4Auth in case we need to add auth tokens to file uploads
    var six4Auth = $injector.has('six4Auth') ? $injector.get('six4Auth') : undefined;

    // Get attributes or defaults
    if (!$scope.name) {
        $log.warn('s4p-form-upload not created: Needs a name');
        return;
    }

    $scope.uploadUrl = !!$attrs.uploadUrl ? $interpolate($attrs.uploadUrl)($scope) : s4pFileUploadConfig.defaultUploadUrl;

    if (!$scope.uploadUrl) {
        $log.warn('s4p-form-upload not created: Needs upload URL');
        return;
    }

    $scope.allowedExtensions = $attrs.allowedExtensions;
    $scope.autoUpload = $attrs.autoUpload === "false" ? false : true;
    $scope.multipleFiles = $attrs.multipleFiles === "true" ? true : false;

    if ($scope.displayName) {
        $scope.filenames = $scope.displayName;
    } else {
        $scope.filenames = $scope.multipleFiles ? 'Choose files...' : 'Choose a file...';
    }

    // Make the input control accept multiple files?
    if ($scope.multipleFiles) {
        var inputEl = $element[0].querySelector('INPUT');
        var att = document.createAttribute("multiple");
        inputEl.setAttributeNode(att);
    }

    // Create uploader
    self.nvUploader = new FileUploader({
        alias: $scope.name,
        url: $scope.uploadUrl,
        autoUpload: $scope.autoUpload
    });

    // Add any additional data
    if (!!$scope.dbConnectionStringName) {
        self.nvUploader.formData.push({ dbConnectionStringName: $scope.dbConnectionStringName });
    }
    if (!!$scope.storageConnectionStringName) {
        self.nvUploader.formData.push({ storageConnectionStringName: $scope.storageConnectionStringName });
    }

    // Do this in controller so it's ready before the template is bound.
    $scope.uploader = self.nvUploader;

    // Add file extension filters if necessary
    if ($scope.allowedExtensions) {

        self.nvUploader.filters.push({
            name: 'extensions',
            fn: function fn(item, options) {
                var extensions = '|' + $scope.allowedExtensions + '|';
                var type = '|' + item.name.slice(item.name.lastIndexOf('.') + 1) + '|';
                return extensions.indexOf(type) !== -1;
            }
        });
    }

    // When a file is added to the uploader
    self.nvUploader.onAfterAddingFile = function (fileItem) {

        // Add the field name and display names
        fileItem.displayName = fileItem.file.name;

        // Add auth token if necessary
        if (six4Auth) {
            fileItem.headers = {
                'Authentication-Token': six4Auth.credentials().token
            };
        }

        // Overwrite previous if multiple=false
        if (!$scope.multipleFiles && self.nvUploader.queue.length > 1) {
            self.nvUploader.queue.splice(0, self.nvUploader.queue.splice.length - 1);
        }

        // Update the state
        self.updateState();
    };

    // When a file is uploaded, add the new URL to the model
    self.nvUploader.onSuccessItem = function (item, response, status, headers) {

        // If there was an error or no URL was returned then do nothing
        if (status !== 200 || !response.record) {
            return;
        }

        // Add the returned URL to the queue item
        item.fileObject = response.record;

        // Update the state
        self.updateState();
    };

    // When adding a file to the uploader fails
    self.nvUploader.onWhenAddingFileFailed = function (item, filter, options) {

        if (filter.name === 'extensions') {
            var allowedFileTypes = '.' + $scope.allowedExtensions.replace('|', ', .');
            alert("Oops! you can't add a file with that type. The following file types are allowed: " + allowedFileTypes);
        }
    };

    // Update the state of the control (e.g. classes, filenames etc.)
    self.updateState = function () {

        // Make sure control is cleared in case user tries to upload the same file again.
        $element.find('input[type="file"]').prop('value', null);

        var queueLength = self.nvUploader.queue.length;

        // Set the label
        if (queueLength === 0) {

            if ($scope.displayName) {
                $scope.filenames = $scope.displayName;
            } else {
                $scope.filenames = $scope.multipleFiles ? 'Choose files...' : 'Choose a file...';
            }
        } else if (queueLength === 1) {
            $scope.filenames = self.nvUploader.queue[0]._file.name;
        } else if (queueLength > 1) {
            $scope.filenames = queueLength + ' files selected';
        }

        // Set the has-value class
        $element[0].classList.toggle('has-value', queueLength > 0);

        // Set the isComplete state of the uploader


        var isComplete = true;
        if (queueLength > 0) {

            self.nvUploader.queue.forEach(function (item) {
                if (!item.isUploaded) {
                    isComplete = false;
                }
            });

            self.nvUploader.isComplete = isComplete;
        }

        // Update the model
        updateModel();
    };

    // Whenever the queue length changes
    $scope.$watch(function () {
        return self.nvUploader.queue.length;
    }, function (newValue, oldValue) {
        self.updateState();
    });

    // Update the ng-model value when queue changes
    function updateModel() {

        var values = [];

        // Get the returned URLs from the queue
        self.nvUploader.queue.forEach(function (item) {
            if (item.fileObject) {
                values.push(item.fileObject);
            }
        });

        // Set the model value
        if (values.length === 0) {
            self.ngModelController.$setViewValue(undefined);
        } else if (values.length > 1) {
            self.ngModelController.$setViewValue(values);
        } else if (values.length === 1) {
            self.ngModelController.$setViewValue(values[0]);
        }
    }

    // Initialize
    self.initialize = function () {

        // Add the uploader to the form's uploaders collection
        self.formController.$registerUploader(self.nvUploader);

        // Update queue when ng-model value changes
        self.ngModelController.$render = function () {

            if (self.ngModelController.$viewValue === undefined || self.ngModelController.$viewValue === null || self.ngModelController.$viewValue === '') {
                self.nvUploader.clearQueue();
            }
        };
    };
}])

// File upload directive
.directive('s4pFileUpload', ["$templateCache", function ($templateCache) {

    return {
        restrict: 'E',
        scope: {
            name: '@',
            displayName: '@',
            uploader: '=?',
            dbConnectionStringName: '@',
            storageConnectionStringName: '@'
        },
        require: ['^form', '?ngModel'],
        controller: 's4pFileUploadCtrl as fileUploadCtrl',
        template: getTemplate,
        link: postLink

    };

    function getTemplate(element, attr) {

        return $templateCache.get('/templates/s4pFileUpload.tpl.html');
    }

    function postLink(scope, element, attr, ctrls) {

        scope.fileUploadCtrl.formController = ctrls[0];
        scope.fileUploadCtrl.ngModelController = ctrls[1];

        // Initialize controller
        scope.fileUploadCtrl.initialize();
    }
}])

// Previews controller
.controller('s4pFilePreviewsCtrl', ["$scope", "$element", "$attrs", "$window", "$timeout", function ($scope, $element, $attrs, $window, $timeout) {

    var self = this;

    $scope.showProgress = $attrs.showProgress === "false" ? false : true;
    $scope.showThumbnails = $attrs.showThumbnails === "false" ? false : true;
    $scope.showRemoveButton = $attrs.showRemoveButton === "false" ? false : true;
    $scope.showOnSubmitting = $attrs.showOnSubmitting === "true" ? true : false;
    $scope.removeOnComplete = $attrs.removeOnComplete === "true" ? true : false;
    $scope.thumbnailWidth = $attrs.thumbnailWidth;

    $scope.thumbnailWidthCss = angular.isDefined($attrs.thumbnailWidth) ? $attrs.thumbnailWidth + 'px' : 'auto';

    if (!angular.isDefined($attrs.thumbnailWidth)) {
        $scope.thumbnailWidth = 200;
        $scope.thumbnailWidthCss = '200px';
    }

    // Check whether each item is currently visible
    $scope.checkPreviewVisibility = function (item) {
        if ($scope.removeOnComplete && item.isUploaded) {
            return false;
        } else {
            return true;
        }
    };

    // Initialize
    self.initialize = function () {

        // Do we need to find the uploader on the form?
        if ($attrs.formUploadControl) {

            // Timeout because the uploader might not have been registered with the form yet
            // E.g. if the preview is before the uplaoder control in the source order.
            $timeout(function () {
                self.formController.$uploaders.forEach(function (item) {
                    if (item.alias === $attrs.formUploadControl) {
                        $scope.uploader = item;
                    }
                });
            });
        }

        // Remove an item from the queue
        $scope.removeFromQueue = function (item) {
            $scope.uploader.removeFromQueue(item);
        };
    };
}])

// Previews directive
.directive('s4pFilePreviews', ["$templateCache", function ($templateCache) {

    return {
        restrict: 'E',
        scope: {
            uploader: '=?',
            oldFileUrl: '@?'
        },
        require: '?^form',
        controller: 's4pFilePreviewsCtrl as filePreviewsCtrl',
        templateUrl: '/templates/s4pFilePreviews.tpl.html',
        link: postLink

    };

    function postLink(scope, element, attr, formController) {

        scope.filePreviewsCtrl.formController = formController;

        // Set the oldFileIsImage flag every time the oldFileUrl changes
        scope.$watch('oldFileUrl', function (newValue, oldValue) {

            if (newValue) {
                var extension = newValue.substr(newValue.lastIndexOf('.') + 1).toLowerCase();
                scope.oldFileIsImage = 'jpg,png,jpeg,bmp,gif'.indexOf(extension) !== -1 ? true : false;
            }
        });

        // Initialize controller
        scope.filePreviewsCtrl.initialize();
    }
}])

// Thumbnail directive
.directive('s4pThumbnail', ["$window", "$compile", "$templateCache", "s4pIcons", function ($window, $compile, $templateCache, s4pIcons) {

    var helper = {
        support: !!($window.FileReader && $window.CanvasRenderingContext2D),
        isFile: function isFile(item) {
            return angular.isObject(item) && item instanceof $window.File;
        },
        isImage: function isImage(file) {
            var type = ('|' + file.name.slice(file.name.lastIndexOf('.') + 1) + '|').toLowerCase();
            return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
        }
    };

    return {
        restrict: 'E',
        scope: {
            file: '='
        },
        link: function link(scope, element, attributes) {

            // Check all features are supported
            if (!helper.support) return;

            // Get the sizing
            var params = {
                width: attributes.canvasWidth * 2 || undefined
            };

            if (!params.width) {
                params.width = 400;
            }

            // Watch the file to see when it changes
            scope.$watch('file', function () {
                drawThumb();
            });

            // Draw the thumbnail
            function drawThumb() {

                // Check that we're dealing with a file
                if (!helper.isFile(scope.file)) return;

                // If the file is NOT an image
                if (!helper.isImage(scope.file)) {

                    var iconReplacements = {
                        pdf: 'document',
                        doc: 'document',
                        docx: 'document',
                        rtf: 'document',
                        txt: 'document',
                        page: 'document',
                        xml: 'code',
                        html: 'code',
                        css: 'code',
                        js: 'code',
                        php: 'code',
                        ttf: 'font',
                        woff: 'font',
                        fon: 'font',
                        ppt: 'presentation',
                        pptx: 'presentation',
                        key: 'presentation',
                        xls: 'spreadsheet',
                        xlsx: 'spreadsheet',
                        csv: 'spreadsheet',
                        tif: 'image',
                        psd: 'image',
                        ai: 'vector',
                        eps: 'vector',
                        svg: 'vector',
                        mp4: 'video',
                        wmv: 'video',
                        avi: 'video',
                        flv: 'video',
                        mpg: 'video',
                        mov: 'video',
                        wav: 'audio',
                        mp3: 'audio',
                        wma: 'audio',
                        zip: 'compressed',
                        pkg: 'compressed'
                    };

                    // Get file extension
                    var extension = scope.file.name.substr(scope.file.name.lastIndexOf('.') + 1);

                    // Is there an icon for the exact file type?
                    if (s4pIcons.getIcon('file-' + extension) !== undefined) {
                        scope.iconName = 'file-' + extension;
                    } else if (iconReplacements.hasOwnProperty(extension) && s4pIcons.getIcon('file-' + iconReplacements[extension]) !== undefined) {
                        scope.iconName = 'file-' + iconReplacements[extension];
                    } else {
                        scope.iconName = 'file';
                    }

                    // Compile the template and put it in the DOM
                    var template = $templateCache.get("/templates/s4pFilePreviewNotImage.tpl.html");
                    var linkFn = $compile(template);
                    var content = linkFn(scope);
                    element.empty().append(content);
                }

                // Otherwise, the file is an image and we need to generate a thumbnail
                else {

                        var onLoadImage = function onLoadImage() {
                            var width = params.width || this.width / this.height * params.height;
                            var height = this.height / this.width * params.width;
                            canvas.setAttribute("width", width);
                            canvas.setAttribute("height", height);
                            canvas.getContext('2d').drawImage(this, 0, 0, width, height);

                            element[0].appendChild(canvas);
                        };

                        var onLoadFile = function onLoadFile(event) {
                            var img = new Image();
                            img.onload = onLoadImage;
                            img.src = event.target.result;
                        };

                        var canvas = document.createElement('canvas');
                        var reader = new FileReader();

                        reader.onload = onLoadFile;
                        reader.readAsDataURL(scope.file);
                    }
            }
        }

    };
}]);

/////////////////////////////////////////////////////////
//                 Six4 Portal Header                  //
/////////////////////////////////////////////////////////

document.createElement('s4p-header');
document.createElement('s4p-header-main-menu-button');
document.createElement('s4p-header-main-menu-logo');
document.createElement('s4p-header-tools');
document.createElement('s4p-header-tool');
document.createElement('s4p-header-tool-button');
document.createElement('s4p-header-tool-button-circle');
document.createElement('s4p-header-menu');
document.createElement('s4p-header-menu-section');
document.createElement('s4p-header-account');
document.createElement('s4p-header-account-button');

angular.module('s4p.directives').controller('s4pHeaderCtrl', ["$scope", "$element", "$attrs", "$rootScope", "$http", "$document", "$uibModal", "six4Auth", "$state", "s4pNotifications", function ($scope, $element, $attrs, $rootScope, $http, $document, $uibModal, six4Auth, $state, s4pNotifications) {

    var self = this;

    $scope.menusOpen = {
        favourites: false,
        notifications: false,
        users: false,
        search: false,
        account: false

        // Make Profile Pic URL
    };$scope.makeProfilePicUrl = function () {
        if ($rootScope.userDetails.profilePhoto) {
            return portalSettings.filesUrl + $rootScope.userDetails.profilePhoto.url.replace(/ /g, '%20') + '?width=100';
        } else {
            return false;
        }
    };

    // Toggle Main Slide In  Menu
    $scope.toggleMainMenu = function () {
        $rootScope.$broadcast('s4pMainMenu-toggle');
    };

    // Toggle Menu
    $scope.toggleMenu = function (menuName) {

        $scope.menusOpen[menuName] = !$scope.menusOpen[menuName];

        // Scroll totifications tray to the top
        $element.find('s4p-notifications').animate({ scrollTop: 0 }, 250);
    };

    $scope.$on('s4pHedader-toggleMenu', function (event, data) {
        $scope.toggleMenu(data.menuName);
    });

    // Notification button clicked
    $scope.notificationButtonClicked = function () {

        $scope.toggleMenu('notifications');

        // Reset Notifications Last Seen Date
        s4pNotifications.resetNotificationsLastSeen();
    };

    // Open Edit Details Modal
    $scope.openEditDetailsModal = function () {
        $uibModal.open({
            templateUrl: s4pSettings.templatesUrl + '/editProfileModal.tpl.html',
            controller: 'EditProfileModalCtrl as editProfileModal',
            windowClass: 'modal--sticky modal--has-header modal--has-footer'
        });
        $scope.menusOpen.account = false;
    };

    // Open Change Password Modal
    $scope.openChangePasswordModal = function () {
        $uibModal.open({
            templateUrl: s4pSettings.templatesUrl + '/changePasswordModal.tpl.html',
            controller: 'ChangePasswordModalCtrl as changePasswordModal',
            windowClass: 'modal--sticky modal--has-header modal--has-footer'
        });
        $scope.menusOpen.account = false;
    };

    // Open User Settings Page
    $scope.openUserSettingsPage = function () {
        $scope.menusOpen.account = false;
        $state.go('user.settings');
    };

    // Log Out
    $scope.logOut = function () {
        six4Auth.logOut();
        $state.go('dashboard');
        $scope.menusOpen.account = false;
    };

    // Handle clicking anywhere on the page except the menu itself to close it.
    var documentClickHandler = function documentClickHandler(event) {

        $scope.$apply(function () {

            var hasMenuEl = $(event.target).closest('[s4p-header-has-menu]');
            var dontClose = '';

            if (hasMenuEl.length > 0) {
                dontClose = hasMenuEl.attr('s4p-header-has-menu');
            }

            $scope.menusOpen.favourites = dontClose !== 'favourites' ? false : $scope.menusOpen.favourites;
            $scope.menusOpen.notifications = dontClose !== 'notifications' ? false : $scope.menusOpen.notifications;
            $scope.menusOpen.users = dontClose !== 'users' ? false : $scope.menusOpen.users;
            $scope.menusOpen.search = dontClose !== 'search' ? false : $scope.menusOpen.search;
            $scope.menusOpen.account = dontClose !== 'account' ? false : $scope.menusOpen.account;
        });
    };

    $document.on("click touchstart", documentClickHandler);
    $scope.$on("$destroy", function () {
        $document.off("click touchstart", documentClickHandler);
    });
}]).directive('s4pHeader', function () {

    return {
        restrict: 'E',
        scope: {},
        controller: 's4pHeaderCtrl',
        templateUrl: s4pSettings.templatesUrl + '/s4p-header.tpl.html'
    };
});
/////////////////////////////////////////////////////////
//                  Six4 Portal Icon                   //
/////////////////////////////////////////////////////////

document.createElement('s4p-icon');

angular.module('s4p.directives').directive('s4pIcon', ["s4pIcons", function (s4pIcons) {

    return {

        restrict: 'E',
        scope: {},
        template: '<svg><use xlink:href="{{::icon.url}}"></use></svg>',

        compile: function compile(element, attributes) {

            return {

                post: function post(scope, element, attributes) {
                    scope.icon = s4pIcons.getIcon(attributes.iconName);
                }

            };
        }

    };
}]);

/////////////////////////////////////////////////////////
//               Six4 Portal Main Menu                 //
/////////////////////////////////////////////////////////

document.createElement('s4p-main-menu');

angular.module('s4p.directives').controller('s4pMainMenuCtrl', ["$scope", "$element", "$attrs", "$state", "$timeout", "$rootScope", "$injector", "six4Auth", function ($scope, $element, $attrs, $state, $timeout, $rootScope, $injector, six4Auth) {

    'use strict';

    // Inject translate if i18n is switched on

    var $translate;
    if (portalSettings.i18n) {
        $translate = $injector.get('$translate');
    }

    var controller = this;

    // Set up default menu template
    // Inser the item "portalItems" as a placeholder for each portal's items
    var mainMenuTemplate = {
        items: ["portalItems", {
            id: 'admin',
            name: 'Admin',
            nameTranslateToken: 'mm_adm',
            icon: 'person-admin',
            minAuthLevel: 2,
            items: [{
                id: 'adminUsers',
                name: 'Users',
                nameTranslateToken: 'mm_adm_usrs',
                state: 'admin.users',
                minAuthLevel: 2
            }]
        }]
    };

    var adminItems = mainMenuTemplate.items[1].items;

    // Add notifications if it's switched on.
    if (portalSettings.notifications) {

        adminItems.push({
            type: 'divider'
        });

        adminItems.push({
            id: 'adminNotifications',
            name: 'Notifications',
            nameTranslateToken: 'mm_adm_notif',
            state: 'admin.notifications',
            minAuthLevel: 2
        });
    }

    // Add permissions and roles if the permissions system is being used.
    if (portalSettings.permissions) {

        adminItems.push({
            type: 'divider'
        });

        adminItems.push({
            id: 'adminPermissionGroups',
            name: 'Permission Groups',
            nameTranslateToken: 'mm_adm_perm_grps',
            state: 'admin.permissionGroups',
            minAuthLevel: 1
        });

        adminItems.push({
            id: 'adminPermissions',
            name: 'Permissions',
            nameTranslateToken: 'mm_adm_perms',
            state: 'admin.permissions',
            minAuthLevel: 1
        });

        adminItems.push({
            id: 'adminRoles',
            name: 'Roles & Permissions',
            nameTranslateToken: 'mm_adm_roles_and_perms',
            state: 'admin.roles',
            minAuthLevel: 2
        });
    }

    // Add 'Advanced section if there's anything to go in it
    var advancedSection = [];

    if (portalSettings.integrations) {

        advancedSection.push({
            id: 'adminIntegrations',
            name: 'Integrations',
            nameTranslateToken: 'mm_adm_integ',
            state: 'admin.integrations',
            minAuthLevel: 2
        });
    }

    if (portalSettings.data) {

        advancedSection.push({
            id: 'adminEditDataConfig',
            name: 'Edit Data Config',
            nameTranslateToken: 'mm_adm_edit_data_cfg',
            state: 'admin.data.config',
            minAuthLevel: 1
        });
    }

    if (advancedSection.length > 0) {

        adminItems.push({
            type: 'divider'
        });

        adminItems.push({
            id: 'adminAdvanced',
            name: 'Advanced',
            nameTranslateToken: 'mm_adm_adv',
            items: advancedSection
        });
    }

    // Merge the two menu objects (doesn't merge any arrays)
    var mainMenu = angular.merge({}, mainMenuTemplate, portalSettings.mainMenu);

    // Splice the portal menu items into the defaults where the "placeholder is"
    mainMenuTemplate.items.splice.apply(mainMenuTemplate.items, [mainMenuTemplate.items.indexOf('portalItems'), 1].concat(portalSettings.mainMenu.items));
    mainMenu.items = angular.copy(mainMenuTemplate.items);

    // Set up defaults
    $scope.menuData = mainMenu;
    $scope.isOpen = false;
    $scope.topLevelActiveItem;
    $scope.subMenus = [];

    // Toggle state when toggle event is detected
    $scope.$on('s4pMainMenu-toggle', function (event) {

        // Are we opening or closing?
        if ($scope.isOpen) {
            $scope.closeMenu();
        } else {
            $scope.openMenu();
        }
    });

    // Close the menu from anywhere
    $scope.$on('s4pMainMenu-close', function (event) {
        $scope.closeMenu();
    });

    // Open the menu from anywhere
    $scope.$on('s4pMainMenu-open', function (event) {
        $scope.openMenu();
    });

    // Close the menu;
    $scope.closeMenu = function () {

        // Close all sub menus
        $scope.subMenus = [];

        // Close the main menu
        $scope.isOpen = false;

        // Remove the avtive item flag
        $scope.topLevelActiveItem = undefined;
    };

    // Open the menu;
    $scope.openMenu = function () {
        $scope.isOpen = true;
    };

    // Check credentials required for each item
    $scope.checkCredentials = function (item) {

        var meetsRequirement = true;

        // Check Permissions
        if (item.permissions) {
            meetsRequirement = six4Auth.checkPermissions(item.permissions) ? true : false;
        }

        // Check minAuthLevel
        if (item.minAuthLevel) {
            meetsRequirement = six4Auth.credentials().authLevel <= item.minAuthLevel ? true : false;
        }

        return meetsRequirement && !(item.hideFromAdmin && six4Auth.credentials().authLevel < 3);
    };

    // Get Item Name
    $scope.getName = function (item) {
        if (portalSettings.i18n && item.nameTranslateToken) {
            var translatedText = $translate.instant(item.nameTranslateToken);
            return translatedText === item.nameTranslateToken ? item.name : translatedText;
        } else {
            return item.name;
        }
    };

    // Get Badge Value
    $scope.getBadgeValue = function (item) {
        return $rootScope.badges.mainMenu[item.badge] === 0 ? '' : $rootScope.badges.mainMenu[item.badge];
    };

    // Item clicked
    $scope.itemClicked = function (item) {

        // Are we sending to a route or opening a submenu?
        if (item.state) {

            $scope.closeMenu();
            $state.go(item.state, item.stateParams || {});
        } else if (item.items && item.items.length > 0) {

            // Open the main menu
            $scope.openMenu();

            // Make sure the submenu isn't already open
            if (item.name !== $scope.topLevelActiveItem) {

                // Close all other items
                $scope.subMenus = [];

                // Add the new menu template
                var newSubMenu = angular.copy(item);
                newSubMenu.level = 2;
                $scope.subMenus.push(newSubMenu);
                $scope.topLevelActiveItem = item.name;
            }
            // If the submenu was already open then remove sub menus except for the first level
            else {
                    $scope.subMenus = [$scope.subMenus[0]];
                    $scope.subMenus[$scope.subMenus.length - 1].covered = false;
                }
        } else {

            // Open the main menu
            $scope.openMenu();
        }
    };

    // Sub menu closed
    $scope.subMenuClose = function (subMenu) {

        // Remove the top item
        $scope.subMenus.pop();

        // If this was the only submenu open and none are left then remove the top level active item flag
        if ($scope.subMenus.length === 0) {
            $scope.topLevelActiveItem = undefined;
        }
        // If it wasn't the only submenu then we need to mark the top most one as no longer being covered
        else {
                $scope.subMenus[$scope.subMenus.length - 1].covered = false;
            }
    };

    // Sub menu item clicked
    $scope.subMenuItemClicked = function (subMenuItem, subMenu) {

        // Are we sending to a route or opening a submenu?
        if (subMenuItem.state) {

            $state.go(subMenuItem.state, subMenuItem.stateParams || {});
            $scope.closeMenu();
        } else if (subMenuItem.items && subMenuItem.items.length > 0) {

            // Move any other sub menus out of the way
            $scope.subMenus.forEach(function (item) {
                item.covered = true;
            });

            // Add the new menu template
            var newSubMenu = angular.copy(subMenuItem);
            newSubMenu.level = subMenu.level + 1;
            $scope.subMenus.push(newSubMenu);
        }
    };
}]).directive('s4pMainMenu', ["$document", function ($document) {

    return {

        restrict: 'E',
        scope: {},
        controller: 's4pMainMenuCtrl',
        templateUrl: s4pSettings.templatesUrl + '/s4p-main-menu.tpl.html',

        link: function link(scope, element, attrs, controller) {

            // Handle clicking anywhere on the page except the menu itself to close it.
            var documentClickHandler = function documentClickHandler(event) {
                var eventOutsideTarget = element[0] !== event.target && element.find(event.target).length === 0;
                var eventNotOnToggle = $(event.target).closest('s4p-header-main-menu-button').length === 0;

                if (eventOutsideTarget && eventNotOnToggle) {
                    scope.$apply(function () {
                        scope.closeMenu();
                    });
                }
            };

            $document.on("click", documentClickHandler);
            scope.$on("$destroy", function () {
                $document.off("click", documentClickHandler);
            });
        }

    };
}]);

/////////////////////////////////////////////////////////
//           Six4 Portal Notification Tray             //
/////////////////////////////////////////////////////////

document.createElement('s4p-notification-tray');

angular.module('s4p.directives').controller('S4pNotificationTrayCtrl', ["$scope", "$element", "$attrs", "$http", "$state", "$rootScope", "$timeout", "six4Auth", "s4pNotifications", "s4pPusher", function ($scope, $element, $attrs, $http, $state, $rootScope, $timeout, six4Auth, s4pNotifications, s4pPusher) {

    'use strict';

    var ctrl = this;

    // Load Notifications
    $scope.loadNotifications = function () {

        $scope.notificationsLoading = true;

        return s4pNotifications.loadNotifications({
            pagingStart: 1,
            pagingLimit: 20
        }).then(function (response) {

            $scope.transformedNotifications = s4pNotifications.transformNotifications(response.data.records);
            $scope.notificationsLoading = false;
        });
    };

    // Notification is clicked
    $scope.notificationClicked = function (notif) {

        // Update true value in the tray
        notif.read = true;

        // Tell the API which notification has been read
        var newItem;

        if (notif.id > 0) {
            newItem = { webNotificationMessageId: notif.id };
        } else if (notif.bundleId > 0) {
            newItem = { webNotificationBundleId: notif.bundleId };
        }

        if (newItem !== {}) {
            $http.post(s4pSettings.apiUrl + '/webNotificationRead', newItem);
        }

        // Reset unseen counter
        s4pNotifications.resetNotificationsLastSeen();

        // Go to route
        var params = notif.routeParams ? JSON.parse(notif.routeParams) : {};

        $state.go(notif.route, params);

        $scope.$emit('s4pHedader-toggleMenu', {
            menuName: 'notifications'
        });
    };

    // View all button clicked
    $scope.viewAllClicked = function () {
        $scope.$emit('s4pHedader-toggleMenu', {
            menuName: 'notifications'
        });
        $state.go('notifications');
    };

    // Init
    var init = function init() {

        // Declare these here so they can be used in both the logged in and logged out functions
        var connection = s4pPusher.getConnection();
        var notificationsChannel;
        var eventFunction;

        // When the user logs in, load the initial notifications into the tray then subscribe to pusher channel
        six4Auth.whenLoggedIn(function (credentials) {

            // Load the initial tray notifications
            $scope.loadNotifications().then(function () {

                notificationsChannel = connection.subscribe('private-notifications-' + six4Auth.credentials().userId.replace(/-/g, '.'));

                // Bind to pusher events on the notifications channel
                eventFunction = function eventFunction(data) {

                    $rootScope.$apply(function () {
                        $rootScope.$broadcast('s4p-notification-received', JSON.parse(data));
                    });
                };

                notificationsChannel.bind('notification', eventFunction);
            });
        }, function () {
            notificationsChannel.unbind('notification', eventFunction);
            notificationsChannel.unsubscribe();
        }, {
            onImmediately: true
        });

        // Watch for new events
        $scope.$on('s4p-notification-received', function (event, data) {

            var currentUnseenCount = $rootScope.notificationsUnseenCount || 0;

            $rootScope.notificationsUnseenCount++;

            // If we're replacing one that is unseen then don't increment the counter
            if (data.bundleId && data.bundleId > 0) {

                $scope.transformedNotifications.forEach(function (item) {

                    if (item.bundleId === data.bundleId && new Date(item.messageDate) > new Date($rootScope.notificationsLastDate * 1000)) {
                        $rootScope.notificationsUnseenCount--;
                    }
                });
            }

            $scope.transformedNotifications = s4pNotifications.addNewTransformedNotification($scope.transformedNotifications, data);
        });
    };

    init();
}]).directive('s4pNotificationTray', ["$document", function ($document) {

    return {

        restrict: 'E',
        controller: 'S4pNotificationTrayCtrl as s4pNotificationTray',
        templateUrl: s4pSettings.templatesUrl + '/s4p-notification-tray.tpl.html',

        link: function link(scope, element, attrs, controller) {}

    };
}]).directive('s4pNotificationMessage', ["$compile", function ($compile) {

    return {

        restrict: 'E',
        scope: {},

        link: function link(scope, element, attrs, controller) {

            scope.template = attrs.messageBody;

            var template = angular.element("<span>" + scope.template + "</span>");
            var linkFn = $compile(template);
            var content = linkFn(scope);
            element.append(content);
        }

    };
}]);

/////////////////////////////////////////////////////////
//                 Six4 Portal Paging                  //
/////////////////////////////////////////////////////////

document.createElement('s4p-paging');

angular.module('s4p.directives').directive('s4pPaging', ["$injector", function ($injector) {

    var paging = angular.copy($injector.get('uibPaginationDirective'))[0];

    paging.restrict = 'E';
    paging.replace = false;

    return paging;
}]);
/////////////////////////////////////////////////////////
//                 Six4 Portal Select                 //
/////////////////////////////////////////////////////////

document.createElement('s4p-select');
document.createElement('s4p-select-arrow');
document.createElement('s4p-select-bar');

angular.module('s4p.directives').directive('s4pSelect', s4pSelectDirective).directive('select', selectDirective);

function s4pSelectDirective(s4pIcons) {

    s4pSelectCtrl.$inject = ["$scope", "$element", "$attrs", "$animate", "$parse"];
    return {
        restrict: 'E',
        link: postLink,
        controller: s4pSelectCtrl
    };

    function postLink(scope, element) {

        // Add the arrow icon
        var iconUrl = s4pIcons.getIcon('chevron-down').url;
        var arrowEl = angular.element('<s4p-select-arrow><s4p-icon><svg><use xlink:href="' + iconUrl + '"></use></svg></s4p-icon></s4p-select-arrow>');
        element.prepend(arrowEl);
    }

    function s4pSelectCtrl($scope, $element, $attrs, $animate, $parse) {

        var self = this;

        self.isErrorGetter = $attrs.isError && $parse($attrs.isError);

        self.delegateClick = function () {
            self.select.focus();
        };

        self.element = $element;

        self.setFocused = function (isFocused) {
            $element.toggleClass('is-focused', !!isFocused);
        };

        self.setDisabled = function (isDisabled) {
            $element.toggleClass('is-disabled', !!isDisabled);
        };

        self.setHasValue = function (hasValue) {
            $element.toggleClass('has-value', !!hasValue);
        };

        self.setInvalid = function (isInvalid) {
            if (isInvalid) {
                $animate.addClass($element, 'is-invalid');
            } else {
                $animate.removeClass($element, 'is-invalid');
            }
        };

        self.setRequired = function (isRequired) {
            $element.toggleClass('is-required', !!isRequired);
        };
    }
}
s4pSelectDirective.$inject = ["s4pIcons"];

function selectDirective($log, six4Utils) {

    return {
        restrict: 'E',
        require: ['^?s4pSelect', '?ngModel'],
        link: postLink
    };

    function postLink(scope, element, attr, ctrls) {

        var containerCtrl = ctrls[0];
        var hasNgModel = !!ctrls[1];
        var ngModelCtrl = ctrls[1]; // || $mdUtil.fakeNgModel();
        var isDisabled = angular.isDefined(attr.disabled);
        var isRequired = angular.isDefined(attr.required);

        // If this input in not in a s4p-select container then do nothing, it's just a normal select
        if (!containerCtrl) return;

        // Check that this is the only select inside the s4p-select
        if (containerCtrl.select) {
            $log.warn("s4p-select can only have one select element.");
            return;
        }

        // Register the select with the parent container
        containerCtrl.select = element;

        // Add a class to the select element
        element.addClass('s4p-select-select');

        // Add the bar div
        var barEl = angular.element('<s4p-select-bar>');
        element.after(barEl);

        // Set container to required if necessary
        if (isRequired) {
            containerCtrl.setRequired(true);
        }

        // Add a UID if the element doesn't already have one.
        if (!element.attr('id')) {
            element.attr('id', 'select--' + six4Utils.getUid());
        }

        // If the input doesn't have an ngModel, it may have a static value. For that case,
        // we have to do one initial check to determine if the container should be in the
        // "has a value" state.
        if (!hasNgModel) {
            selectCheckValue();
        }

        // Function for checking if the control is currently errored
        var isErrorGetter = containerCtrl.isErrorGetter || function () {
            return ngModelCtrl.$invalid && (ngModelCtrl.$touched || isParentFormSubmitted());
        };

        // Function for checking if the form has been submitted
        var isParentFormSubmitted = function isParentFormSubmitted() {

            var parent = element.closest('form');
            var form = parent ? angular.element(parent).controller('form') : null;

            return form ? form.$submitted : false;
        };

        // Watch the isErrorGetter function to check when an error occurs and set the container appropriately
        scope.$watch(isErrorGetter, containerCtrl.setInvalid);

        // Check the value each time it changes, when ng-model changes in either direction
        ngModelCtrl.$parsers.push(ngModelPipelineCheckValue);
        ngModelCtrl.$formatters.push(ngModelPipelineCheckValue);

        // Check the value each time the user types
        element.on('change', selectCheckValue);

        // Tell the container when the select is focused and blurred
        element.on('focus', function (ev) {
            containerCtrl.setFocused(true);
        }).on('blur', function (ev) {
            containerCtrl.setFocused(false);
            selectCheckValue();
        });

        // Set classes when the select is disabled
        attr.$observe('disabled', function (disabled) {
            isDisabled = angular.isString(disabled) || disabled === true;
            containerCtrl.setDisabled(isDisabled);
        });

        // This bit was already commented out in ngMaterial code
        //ngModelCtrl.$setTouched();
        //if( ngModelCtrl.$invalid ) containerCtrl.setInvalid();


        // When the control is destroyed, reset some stuff on the parent container
        scope.$on('$destroy', function () {
            containerCtrl.setFocused(false);
            containerCtrl.setHasValue(false);
            containerCtrl.input = null;
        });

        // Set the container hasValue if the select is not empty when ngModel changes
        function ngModelPipelineCheckValue(arg) {
            containerCtrl.setHasValue(!ngModelCtrl.$isEmpty(arg));
            return arg;
        }

        function selectCheckValue() {

            // A select's value counts if its length > 0,
            // or if the input's validity state says it has bad input (eg string in a number input)
            containerCtrl.setHasValue(element.val().length > 0 || (element[0].validity || {}).badInput);
        }
    }
}
selectDirective.$inject = ["$log", "six4Utils"];

/////////////////////////////////////////////////////////
//                  Six4 Portal Table                  //
/////////////////////////////////////////////////////////

document.createElement('s4p-table');
document.createElement('s4p-table-head');
document.createElement('s4p-table-body');

angular.module('s4p.directives')

// Table Controller
.controller('s4pTableCtrl', ["$scope", "$element", "$attrs", "$timeout", "$window", function ($scope, $element, $attrs, $timeout, $window) {

    var ctrl = this;

    ////////////////////////
    //     Checkboxes     //
    ////////////////////////

    ctrl.rowCheckboxes = [];
    ctrl.selectedRows = [];

    // Register Row Checkbox
    ctrl.registerRowCheckbox = function (itemScope) {

        ctrl.rowCheckboxes.push(itemScope);

        itemScope.$on('$destroy', function (event) {
            ctrl.removeRowCheckbox(itemScope);
        });
    };

    // Register Check All Checkbox
    ctrl.registerCheckAll = function (itemScope) {
        ctrl.checkAllCheckbox = itemScope;
    };

    // Remove Row Checkbox
    ctrl.removeRowCheckbox = function (itemScope) {
        var index = ctrl.rowCheckboxes.indexOf(itemScope);
        if (index !== -1) {
            ctrl.rowCheckboxes.splice(index, 1);
        }
    };

    // Check or un-check all rows
    ctrl.checkAll = function (value) {

        ctrl.selectedRows = [];

        angular.forEach(ctrl.rowCheckboxes, function (item) {

            item.setCheckbox(value);

            if (value) {
                ctrl.selectedRows.push(item.rowId);
            }
        });
    };

    // Toggle a row's checkbox
    ctrl.toggleRow = function (id) {

        angular.forEach(ctrl.rowCheckboxes, function (item) {
            if (item.rowId === id) {
                item.toggleCheckbox();
            }
        });

        ctrl.rowToggled();
    };

    // Row toggled
    ctrl.rowToggled = function () {

        ctrl.selectedRows = [];

        angular.forEach(ctrl.rowCheckboxes, function (item) {
            if (item.checked) {
                ctrl.selectedRows.push(item.rowId);
            }
        });

        if (ctrl.checkAllCheckbox) {
            ctrl.checkAllCheckbox.setCheckbox(false);
        }
    };

    ////////////////////////
    //        Rows        //
    ////////////////////////

    // Row was clicked
    ctrl.rowClicked = function (evt, id, row) {

        if (!!ctrl.checkOnRowClicked && $(evt.target).closest('[cell-type="rowCheckbox"]').length === 0 && !$(evt.target).is('input[type=checkbox]')) {
            ctrl.toggleRow(id);
        } else if (!!ctrl.onRowClicked && $(evt.target).closest('[cell-type="rowCheckbox"]').length === 0 && !$(evt.target).is('input[type=checkbox]')) {
            ctrl.onRowClicked({ id: id, row: row, $event: evt });
        }
    };

    /////////////////////////
    //   Other Functions   //
    /////////////////////////

    // Reset pending data flags
    ctrl.resetPendingData = function () {
        ctrl.pendingData = {
            fixedLeft: ctrl.fixedLeftColumns > 0,
            main: true,
            fixedRight: ctrl.fixedRightColumns > 0
        };
    };

    // Row hover highlighting
    ctrl.attachRowHoverEvents = function () {
        ctrl;

        $element.find("tbody tr:not([s4p-table-no-results])").on('mouseenter', function () {
            if (ctrl.rowHighlight) {
                var index = $(this).index() + 1;
                $element.find('tbody tr:nth-child(' + index + ')').addClass('rowHighlighted');
            }
        }).on('mouseleave', function () {
            var index = $(this).index() + 1;
            $element.find('tbody tr:nth-child(' + index + ')').removeClass('rowHighlighted');
        });
    };

    // Recalculate header column widths and frozen column cell heights
    ctrl.layoutTables = function () {

        var el = $element[0];
        var headerColumnWidths = [];
        var columnWidths = [];
        var rowHeights = [];

        var headerTable = el.querySelector('s4p-table-header s4p-table-scrollable table, s4p-table-header > table');
        var headerCells = el.querySelectorAll('s4p-table-header s4p-table-scrollable thead TR:first-child TH:not([width]), s4p-table-header > table thead TR:first-child TH:not([width])');
        var firstRow = el.querySelector('s4p-table-body s4p-table-scrollable tbody TR:not([s4p-table-no-results]):not([width]), s4p-table-body > table tbody TR:not([s4p-table-no-results]):not([width])');
        var firstRowCells = !!firstRow ? firstRow.querySelectorAll('TD') : [];

        // Give up if there is no data
        if (firstRowCells.length === 0) {
            return;
        }

        el.classList.add('is-doing-layout');

        // Equalize the widths of headers and body cells
        if (headerTable) {

            headerTable.style.minWidth = 'auto';

            for (var i = 0; i < headerCells.length; ++i) {

                var newWidth = firstRowCells[i].clientWidth;

                headerCells[i].style.width = newWidth + 'px';
                headerCells[i].style.minWidth = newWidth + 'px';
                headerCells[i].style.maxWidth = newWidth + 'px';
            }
        }

        // Only do this if there are any columns fixed on the left or right hand sides
        if (ctrl.fixedLeftColumns > 0 || ctrl.fixedRightColumns > 0) {

            // Equalize the heights of all the rows
            var fixedLeftRows = el.querySelectorAll('s4p-table-body s4p-table-fixed-left TR');
            var mainRows = el.querySelectorAll('s4p-table-body s4p-table-scrollable TR:not([s4p-table-no-results])');
            var fixedRightRows = el.querySelectorAll('s4p-table-body s4p-table-fixed-right TR');

            for (var j = 0; j < mainRows.length; ++j) {

                // Calculate max height from the left/middle/right rows
                var fixedLeftHeight = fixedLeftRows.length > 0 ? fixedLeftRows[j].getBoundingClientRect().height : 0;
                var mainHeight = mainRows[j].getBoundingClientRect().height;
                var fixedRightHeight = fixedRightRows.length > 0 ? fixedRightRows[j].getBoundingClientRect().height : 0;
                var maxHeight = Math.max(fixedLeftHeight, mainHeight, fixedRightHeight);

                // Set all heights to the max
                if (fixedLeftRows.length > 0) {
                    fixedLeftRows[j].style.height = maxHeight + 'px';
                    fixedLeftRows[j].style.minHeight = maxHeight + 'px';
                }

                mainRows[j].style.height = maxHeight + 'px';
                mainRows[j].style.minHeight = maxHeight + 'px';

                if (fixedRightRows.length > 0) {
                    fixedRightRows[j].style.height = maxHeight + 'px';
                    fixedRightRows[j].style.minHeight = maxHeight + 'px';
                }
            }
        }

        // Scroll main panel to the top left
        var mainScrollableArea = el.querySelector('s4p-table-body s4p-table-scrollable');
        if (mainScrollableArea) {
            mainScrollableArea.scrollTop = 0;
        }

        $timeout(function () {
            el.classList.remove('is-doing-layout');
        });
    };

    // Called when sub table has loaded all it's data
    ctrl.tableLoaded = function (tableName) {

        ctrl.pendingData[tableName] = false;

        // Are we ready to do the layout?
        if (!ctrl.pendingData.fixedLeft && !ctrl.pendingData.main && !ctrl.pendingData.fixedRight) {

            ctrl.layoutTables();
            ctrl.attachRowHoverEvents();
            ctrl.resetPendingData();

            ctrl.isLoading = false;
        }
    };

    // Initialize
    ctrl.init = function () {

        ctrl.layoutTables();
        ctrl.attachRowHoverEvents();
        ctrl.resetPendingData();

        // Keep fixed section scroll in sync
        $element.find("s4p-table-scrollable").on('scroll', function () {
            $element.find("s4p-table-header s4p-table-scrollable table").css({ transform: "translateX(" + (0 - this.scrollLeft) + "px)" });
            $element.find("s4p-table-body s4p-table-fixed-left table, s4p-table-body s4p-table-fixed-right table").css({ transform: "translateY(" + (0 - this.scrollTop) + "px)" });
        });

        // Watch isLoading to set loading class
        $scope.$watch('tableCtrl.isLoading', function (newValue, oldValue) {
            $element.toggleClass('is-loading', newValue || false);
        });

        // Watch table data to show loading indicator and empty template.
        $scope.$watch('tableCtrl.tableData', function (newValue, oldValue) {

            if (newValue) {
                if (newValue.length === 0) {
                    ctrl.resetPendingData();
                    ctrl.isLoading = false;
                    $element.addClass('s4p-table-no-results');
                } else {
                    $element[0].classList.add('is-doing-layout');
                    $element.removeClass('s4p-table-no-results');
                }
            }
        }, true);

        // Do layout every time window size changes
        angular.element($window).on("resize", function () {
            ctrl.layoutTables();
        });

        // Clean up
        $scope.$on('$destroy', function () {
            angular.element($window).on("resize");
            $element.find("s4p-table-scrollable").off('scroll');
            $element.find("tbody tr").off('mouseenter');
            $element.find("tbody tr").off('mouseleave');
        });
    };
}]).directive('s4pTable', ["$window", function ($window) {

    return {
        restrict: 'EA',
        scope: {},
        bindToController: {
            isLoading: '=?',
            tableData: '=?',
            selectedRows: '=?',
            rowHighlight: '@?',
            fixedHeader: '@?',
            fixedLeftColumns: '@?',
            fixedRightColumns: '@?',
            checkOnRowClicked: '@?',
            onRowClicked: '&?'
        },
        controller: 's4pTableCtrl as tableCtrl',
        link: postLink
    };

    function postLink(scope, element, attrs, controller) {

        var tableCtrl = scope.tableCtrl;

        tableCtrl.init();
    }
}]).directive('s4pTableRow', function () {

    return {
        restrict: 'A',
        require: '^s4pTable',
        link: function link(scope, element, attrs, tableCtrl) {

            scope.rowData = angular.isDefined(attrs.rowData) ? scope.$eval(attrs.rowData) : scope.row;
            scope.rowId = angular.isDefined(attrs.rowId) ? scope.$eval(attrs.rowId) : scope.rowData.id;

            // Watch for checkbox clicks
            var listener = function listener(ev) {
                scope.$apply(function () {
                    tableCtrl.rowClicked(ev, scope.rowId, scope.rowData);
                });
            };

            element.on('click', listener);
        }
    };
}).directive('cellType', function () {

    return {
        restrict: 'A',
        require: '^s4pTable',
        link: function link(scope, element, attrs, tableCtrl) {

            if (attrs.cellType === 'rowCheckbox') {

                // Watch for checkbox clicks
                var listener = function listener(evt) {

                    if (!$(evt.target).is('input[type=checkbox]')) {

                        var checkbox = element.find('INPUT');
                        checkbox.trigger('click');

                        evt.stopPropagation();
                    }
                };

                element.on('click', listener);
            }
        }
    };
}).directive('tableCheckAll', function () {

    return {
        restrict: 'A',
        require: '^s4pTable',
        link: function link(scope, element, attrs, tableCtrl) {

            // Register with parent table
            tableCtrl.registerCheckAll(scope);

            // Set Checkbox State
            scope.setCheckbox = function (newState) {
                element[0].checked = newState;
            };

            // Watch for checkbox clicks
            var listener = function listener(ev) {
                tableCtrl.checkAll(element[0].checked);
            };

            element.on('click', listener);
        }
    };
}).directive('tableRowCheckbox', function () {

    return {
        restrict: 'A',
        require: '^s4pTable',
        link: function link(scope, element, attrs, tableCtrl) {

            scope.rowId = scope.$eval(attrs.tableRowCheckbox);
            scope.checked = false;

            // Register with parent table
            tableCtrl.registerRowCheckbox(scope);

            // Set Checkbox State
            scope.setCheckbox = function (newState) {
                element[0].checked = newState;
                scope.checked = newState;
            };

            // Toggle Checkbox State
            scope.toggleCheckbox = function () {
                element[0].checked = !element[0].checked;
                scope.checked = element[0].checked;
            };

            // Watch for checkbox clicks
            var listener = function listener(evt) {

                scope.$apply(function () {
                    scope.checked = element[0].checked;
                    tableCtrl.rowToggled();
                    evt.stopPropagation();
                });
            };

            element.on('click', listener);
        }
    };
}).directive('tableLoaded', ["$timeout", function ($timeout) {

    return {
        restrict: 'EA',
        require: '^s4pTable',
        link: function link(scope, element, attrs, tableCtrl) {

            var tableName = attrs.tableLoaded;

            if (scope.$last) {
                $timeout(function () {
                    tableCtrl.tableLoaded(tableName);
                });
            }
        }
    };
}]);

/////////////////////////////////////////////////////////
//                  Six4 Portal Tabs                   //
/////////////////////////////////////////////////////////

document.createElement('s4p-tab-container');
document.createElement('s4p-tabs');
document.createElement('s4p-tab');
document.createElement('s4p-tab-inner');
document.createElement('s4p-tab-icon');
document.createElement('s4p-tab-title');
document.createElement('s4p-tab-bar');

angular.module('s4p.directives')

// Tab Container
.controller('s4pTabContainerCtrl', ["$scope", "$element", "$attrs", function ($scope, $element, $attrs) {

    var self = this;
    self.panels = [];

    // Add Panel
    self.addPanel = function (itemScope) {
        self.panels.push(itemScope);
        itemScope.$on('$destroy', function (event) {
            self.removePanel(itemScope);
        });
    };

    // Remove Panel
    self.removePanel = function (itemScope) {
        var index = self.panels.indexOf(itemScope);
        if (index !== -1) {
            self.panels.splice(index, 1);
        }
    };

    // Switch Panel
    self.switchPanel = function (panelId) {
        $scope.activePanel = panelId;
    };

    // Active panel changed
    $scope.$watch('activePanel', function (newValue, oldValue) {

        // Loop through all panels and switch them on or off
        self.panels.forEach(function (item) {
            var active = item.id === newValue ? true : false;
            item.setActive(active);
        });
    });
}]).directive('s4pTabContainer', function () {

    return {
        restrict: 'EA',
        scope: {
            activeTab: '=?'
        },
        controller: 's4pTabContainerCtrl as tabContainerCtrl',
        link: postLink
    };

    function postLink(scope, element) {}
})

// Tabs
.controller('s4pTabsCtrl', ["$scope", "$element", "$attrs", "$timeout", function ($scope, $element, $attrs, $timeout) {

    var self = this;
    self.initialize = initialize;

    self.tabs = [];

    // Add Tab
    self.addTab = function (itemScope) {
        self.tabs.push(itemScope);
        itemScope.$on('$destroy', function (event) {
            self.removeTab(itemScope);
        });
    };

    // Remove Tab
    self.removeTab = function (itemScope) {
        var index = self.tabs.indexOf(itemScope);
        if (index !== -1) {
            self.tabs.splice(index, 1);
        }
    };

    // Switch Tab
    self.switchTab = function (tabId) {
        $scope.activeTab = tabId;
    };

    // Active tab changed
    $scope.$watch('activeTab', function (newValue, oldValue) {

        // Loop through all tabs and switch them on or off
        self.tabs.forEach(function (item) {
            var active = item.id === newValue ? true : false;
            item.setActive(active);
        });

        // Tell the container to switch the panel
        if ($scope.containerCtrl) {
            $scope.containerCtrl.switchPanel(newValue);
        }

        // Run the change function if one was passed in.
        if ($scope.onActiveTabChanged) {
            $scope.onActiveTabChanged({ tabName: newValue });
        }
    });

    // Initialize
    function initialize() {}
}]).directive('s4pTabs', function () {

    return {
        restrict: 'E',
        scope: {
            activeTab: '=?',
            onActiveTabChanged: '&?'
        },
        require: ['^?s4pTabContainer'],
        controller: 's4pTabsCtrl as tabsCtrl',
        link: postLink
    };

    function postLink(scope, element, attr, ctrls) {

        var containerCtrl = ctrls[0];

        // Add container controller to scope if it exists.
        if (containerCtrl) {
            scope.containerCtrl = containerCtrl;
        }

        scope.tabsCtrl.initialize();
    }
})

// Tab
.controller('s4pTabCtrl', ["$scope", "$element", "$attrs", "$timeout", function ($scope, $element, $attrs, $timeout) {

    var self = this;

    self.initialize = initialize;

    // Initialize
    function initialize() {

        // Register with parent if it exists
        if ($scope.tabs) {
            $scope.tabs.addTab($scope);
        }

        // Defaults
        $scope.defaultTab = angular.isDefined($attrs.defaultTab) ? !!$attrs.defaultTab : false;

        // Is this the default tab?
        if ($scope.defaultTab && $scope.tabs) {
            $timeout(function () {
                $scope.tabs.switchTab($scope.id);
            });
        }

        // Set active function
        $scope.setActive = function (state) {
            $scope.active = state;
            $element.toggleClass('is-active', state);
        };

        // Tab is clicked
        $scope.tabClicked = function () {
            if ($scope.tabs && !$scope.disabled) {
                $scope.tabs.switchTab($scope.id);
            }
        };

        // Get Badge Value
        $scope.getBadgeValue = function (item) {
            return $scope.badgeModel === 0 ? false : $scope.badgeModel;
        };
    }
}]).directive('s4pTab', function () {

    return {
        restrict: 'E',
        scope: {
            id: '@tabId',
            icon: '@',
            title: '@',
            badgeModel: '='
        },
        require: ['^?s4pTabs'],
        controller: 's4pTabCtrl as tabCtrl',
        link: postLink,
        template: getTemplate
    };

    function getTemplate(element, attr) {

        var badgeExists = angular.isDefined(attr.badge) && attr.badge !== 'false' && angular.isDefined(attr.badgeModel);
        var badgeCs = angular.isDefined(attr.badgeCs) ? 'cs="' + attr.badgeCs + '"' : '';

        var badgeEl = '';

        if (badgeExists) {
            badgeEl = '<s4p-badge ng-if="getBadgeValue(item)" ng-bind="getBadgeValue(item)" ' + badgeCs + '></s4p-badge>';
        }

        return '<s4p-tab-inner ng-click="tabClicked()">' + badgeEl + '<s4p-tab-icon ng-if="::icon">' + '<s4p-icon icon-name="{{::icon}}"></s4p-icon>' + '</s4p-tab-icon>' + '<s4p-tab-title ng-if="title">' + '<div ng-bind="title"></div>' + '</s4p-tab-title>' + '</s4p-tab-inner>' + '<s4p-tab-bar></s4p-tab-bar>';
    }

    function postLink(scope, element, attr, ctrls) {

        scope.disabled = angular.isDefined(attr.disabled) && attr.disabled !== 'false' ? true : false;

        var tabsCtrl = ctrls[0];

        // Add container controller to scope if it exists.
        if (tabsCtrl) {
            scope.tabs = tabsCtrl;
        }

        scope.tabCtrl.initialize();
    }
})

// Tab Panel Controller
.controller('s4pTabPanelCtrl', ["$scope", "$element", "$attrs", "$animate", "$timeout", function ($scope, $element, $attrs, $animate, $timeout) {

    var self = this;

    self.initialize = initialize;

    // Initialize
    function initialize() {

        // Register with parent if it exists
        if ($scope.tabPanelCtrl.containerCtrl) {
            $scope.tabPanelCtrl.containerCtrl.addPanel($scope);
        }

        // Defaults
        $scope.reload = angular.isDefined($attrs.reload) ? !!$attrs.reload : false;
        $scope.hasLoader = angular.isDefined($attrs.loader) ? !!$attrs.loader : true;
        $scope.panelLoaded = angular.isDefined($attrs.template) ? false : true;

        $scope.active = false;
        $scope.loadContent = false;
        $scope.isLoading = false;

        $scope.preparingToUnloadContent = false;

        // Is it the default tab? If not, hide it.
        $element.addClass('ng-hide');

        // Active state is changed
        $scope.setActive = function (state) {

            $element.toggleClass('is-active', state);
            $scope.active = state;

            // If the panel is being switched on
            if (state) {

                // Do we need to reload?
                if ($scope.reload) {
                    $scope.panelLoaded = false;
                    $scope.loadContent = false;
                }

                // Set a loading indicator if we're loading
                if ($scope.panelLoaded !== true) {
                    $scope.isLoading = true;
                }

                // Load the content if it needs to be and tell the panel it's no longer preparing to unload if it was in that state
                $scope.loadContent = true;
                $scope.preparingToUnloadContent = false;

                // Show the panel
                $animate.removeClass($element, 'ng-hide', {
                    tempClasses: 'ng-hide-animate'
                });
            }

            // Else the panel is being switched off
            else {

                    // Remove the loading indicator
                    $scope.isLoading = false;

                    // If reloading is true then unload the content until the panel is reactivated
                    // Delay removal of content until fade animation has happened (doubling it to be sure)
                    if ($scope.reload) {
                        $scope.preparingToUnloadContent = true;
                        $scope.panelLoaded = false;
                        $timeout(function () {
                            $scope.loadContent = $scope.preparingToUnloadContent ? false : $scope.loadContent;
                        }, 250);
                    }

                    // Hide the element
                    $animate.addClass($element, 'ng-hide', {
                        tempClasses: 'ng-hide-animate'
                    });
                }
        };

        // When the content has loaded
        $scope.onPanelLoaded = function () {
            $scope.panelLoaded = true;
            $scope.isLoading = false;
        };
    }
}])

// Tab Panel Directive
.directive('s4pTabPanel', ["$interpolate", "$compile", function ($interpolate, $compile) {

    var loader;

    return {
        restrict: 'E',
        scope: {
            id: '@?tabId',
            template: '@?',
            controller: '@?'
        },
        priotity: 1000,
        require: ['^?s4pTabContainer'],
        controller: 's4pTabPanelCtrl as tabPanelCtrl',
        link: postLink,
        transclude: true,
        template: getTemplate
    };

    function getTemplate(element, attr) {

        loader = attr.loader && attr.loader !== 'false' ? '<s4p-loader-cover ng-if="isLoading"><s4p-loader></s4p-loader></s4p-loader-cover>' : '';

        // Panel loads template and controller
        if (attr.template && attr.controller) {
            // If we want to dynamically include a controller we have to manually compile
            // See the link function
            return '';
        }

        // Or does it have a template with no controller?
        else if (attr.template) {
                return loader + '<s4p-tab-panel-inner ng-if="loadContent" ng-include="template" onload="onPanelLoaded()" ng-transclude></s4p-tab-panel-inner>';
            }

            // Otherwise just transclude the original content
            else {
                    return loader + '<s4p-tab-panel-inner ng-transclude>' + '</s4p-tab-panel-inner>';
                }
    }

    function postLink(scope, element, attr, ctrls) {

        // Compile the contents manually if we need to dynamically include a controller
        if (attr.template && attr.controller) {
            var html = loader + '<s4p-tab-panel-inner ng-if="loadContent" ng-include="template" onload="onPanelLoaded()" ng-controller="' + attr.controller + '"></s4p-tab-panel-inner>';
            var el = $compile(html)(scope);
            element.append(el);
        }

        var containerCtrl = ctrls[0];

        // Add container controller to scope if it exists.
        if (containerCtrl) {
            scope.tabPanelCtrl.containerCtrl = containerCtrl;
        }

        scope.tabPanelCtrl.initialize();
    }
}]);

////////////////////////////////////////////////////
//                 S4p Tag Picker                 //
////////////////////////////////////////////////////

document.createElement('s4p-tag-picker');

angular.module('s4p.directives').controller('S4pTagPickerCtrl', ["$scope", "$element", "$attrs", "$http", "$state", "$timeout", "$q", "six4Auth", function ($scope, $element, $attrs, $http, $state, $timeout, $q, six4Auth) {

    'use strict';

    var ctrl = this;

    // Load Available Tags Tags
    ctrl.doLoadAvailableTags = function () {

        console.log("Loading Available Tags");

        ctrl.availableTagsLoading = true;

        var params;

        if (ctrl.relationType) {
            params = {
                filters: 'tagType.code:eq:' + (ctrl.tagType || ctrl.relationType)
            };
        }

        console.log('Loading Available Tags', params, ctrl.tagType);

        // Get the tags which can be used with this type of relation
        return $http.get(s4pSettings.apiUrl + '/tags', { params: params }).then(function (response) {

            ctrl.availableTags = response.data.records || [];

            ctrl.computeAddableTags();
            ctrl.availableTagsLoading = false;
        });
    };

    // Load tags
    ctrl.doLoadTags = function () {

        ctrl.tagsLoading = true;

        console.log("Loading Tags");

        var params = {
            filters: 'relationType:eq:' + ctrl.relationType + '|relationId:eq:' + ctrl.relationId,
            format: 'includeTag'

            // Get the tags which can be used with users
        };return $http.get(s4pSettings.apiUrl + '/tagRelations', { params: params }).then(function (response) {

            ctrl.model = response.data.records;

            ctrl.computeAddableTags();
            ctrl.tagsLoading = false;
        });
    };

    // Compute Addable tags
    ctrl.computeAddableTags = function () {

        ctrl.addableTags = ctrl.availableTags.filter(function (availableTag) {

            return ctrl.model.filter(function (tag) {
                return availableTag.id === tag.tag.id;
            }).length === 0;
        });
    };

    // Submit Form
    ctrl.submitForm = function () {

        ctrl.addTagSaving = true;

        var newItem = {
            tagId: ctrl.formModel.tagToAdd.id,
            relationType: ctrl.relationType,
            relationId: ctrl.relationId,
            tag: angular.copy(ctrl.formModel.tagToAdd)
        };

        ctrl.model.push(newItem);
        ctrl.computeAddableTags();

        ctrl.formModel = {};

        // If saving is switched on then save it
        if (ctrl.saveTags === 'true') {

            console.log("Saving Tag");

            var params = {
                returnObject: true
            };

            return $http.post(s4pSettings.apiUrl + '/tagRelations', newItem, { params: params }).then(function (response) {

                // Get new ID and save it back to the in memory collection
                ctrl.model.forEach(function (item) {

                    console.log("Looping", item);

                    if (item.tag.id === newItem.tagId) {

                        console.log("item", item, response.data.record);

                        item.id = response.data.record.id;
                    }
                });

                if (!!ctrl.onTagAdded) {
                    ctrl.onTagAdded();
                }
                ctrl.addTagSaving = false;
            });
        } else {
            if (!!ctrl.onTagAdded) {
                ctrl.onTagAdded();
            }
            ctrl.addTagSaving = false;
        }
    };

    // Remove Tag
    ctrl.removeTag = function (tag) {

        ctrl.removeTagSaving = true;

        ctrl.model = ctrl.model.filter(function (item) {
            return tag.tag.id !== item.tag.id;
        });

        ctrl.computeAddableTags();

        // If saving is switched on then save it
        if (ctrl.saveTags === 'true') {

            return $http.delete(s4pSettings.apiUrl + '/tagRelations/' + tag.id).then(function (response) {

                if (!!ctrl.onTagRemoved) {
                    ctrl.onTagRemoved();
                }

                ctrl.removeTagSaving = false;
            });
        } else {

            if (!!ctrl.onTagRemoved) {
                ctrl.onTagRemoved();
            }

            ctrl.removeTagSaving = false;
        }
    };

    // Init
    ctrl.init = function () {

        // Create an api so that the external world can control our table
        ctrl.api = {
            ready: ctrl.ready
        };

        console.log("Logging ctrl", angular.copy(ctrl));

        // Models
        ctrl.formModel = {};
        ctrl.formMeta = {};

        // Load available tags if specified
        ctrl.availableTags = ctrl.availableTags || [];

        if (ctrl.loadAvailableTags === 'true') {
            ctrl.doLoadAvailableTags();
        }

        // Load tags if specified
        if (ctrl.loadTags === 'true') {
            ctrl.model = [];
            ctrl.doLoadTags();
        } else if (ctrl.model && ctrl.model.length > 0) {
            // Do nothing, the tags have been supplied
            console.log("Tags supplied", angular.copy(ctrl.model));
        } else {
            ctrl.model = [];
        }

        // Set isLoading
        $scope.$watch(function () {
            return ctrl.availableTagsLoading;
        }, function (newValue) {
            ctrl.isLoading = newValue;
        });

        // Set isSaving
        $scope.$watch(function () {
            return ctrl.addTagSaving || ctrl.removeTagSaving;
        }, function (newValue) {
            ctrl.isSaving = newValue;
        });
    };
}]).directive('s4pTagPicker', function () {

    return {

        restrict: 'E',
        scope: {},
        bindToController: {
            api: '=?',
            model: '=?',
            availableTags: '=?',
            relationType: '@?',
            relationId: '@?',
            tagType: '@?',
            loadAvailableTags: '@?',
            loadTags: '@?',
            saveTags: '@?',
            isLoading: '=?',
            isSaving: '=?',
            onTagAdded: '&?',
            onTagRemoved: '&?',
            chipCs: '@?'
        },
        controller: 'S4pTagPickerCtrl as s4pTagPicker',
        templateUrl: s4pSettings.templatesUrl + '/s4pTagPicker.tpl.html',
        link: function link(scope, element, attrs, controller) {
            controller.init();
            controller.element = element;
        }

    };
});

/////////////////////////////////////////////////////////
//                Six4 Portal Textbox                  //
/////////////////////////////////////////////////////////

document.createElement('s4p-textbox');
document.createElement('s4p-textbox-bar');

angular.module('s4p.directives').directive('s4pTextbox', s4pTextboxDirective).directive('input', inputTextareaDirective).directive('textarea', inputTextareaDirective);

function s4pTextboxDirective() {

    s4pTextboxCtrl.$inject = ["$scope", "$element", "$attrs", "$animate", "$parse"];
    return {
        restrict: 'E',
        controller: s4pTextboxCtrl
    };

    function s4pTextboxCtrl($scope, $element, $attrs, $animate, $parse) {

        var self = this;

        self.isErrorGetter = $attrs.isError && $parse($attrs.isError);

        self.delegateClick = function () {
            self.input.focus();
        };

        self.element = $element;

        self.setFocused = function (isFocused) {
            $element.toggleClass('is-focused', !!isFocused);
        };

        self.setHasValue = function (hasValue) {
            $element.toggleClass('has-value', !!hasValue);
        };

        self.setInvalid = function (isInvalid) {
            if (isInvalid) {
                $animate.addClass($element, 'is-invalid');
            } else {
                $animate.removeClass($element, 'is-invalid');
            }
        };

        self.setRequired = function (isRequired) {
            $element.toggleClass('is-required', !!isRequired);
        };
    }
}

function inputTextareaDirective($rootScope, $window, $log, $timeout, six4Utils) {

    return {
        restrict: 'E',
        require: ['^?s4pTextbox', '?ngModel'],
        link: postLink
    };

    function postLink(scope, element, attr, ctrls) {

        var containerCtrl = ctrls[0];
        var hasNgModel = !!ctrls[1];
        var ngModelCtrl = ctrls[1]; // || $mdUtil.fakeNgModel();
        var isReadonly = angular.isDefined(attr.readonly);
        var isRequired = angular.isDefined(attr.required);
        var isTypeahead = angular.isDefined(attr.uibTypeahead);
        var tagName = element[0].tagName.toLowerCase();

        // If this input in not in a s4p-textbox container then do nothing, it's just a normal input
        if (!containerCtrl) return;

        // Check that this is the only input inside the s4p-textbox
        if (containerCtrl.input) {
            $log.warn("s4p-textbox can only have one input.");
            return;
        }

        // Register the input with the parent container
        containerCtrl.input = element;

        // Add a class to the input
        element.addClass('s4p-textbox-input');

        // Add the bar div
        var barEl = angular.element('<s4p-textbox-bar>');
        element.after(barEl);

        // Set autocomplete to off if it's a typeahead
        if (isTypeahead) {
            element.attr('autocomplete', 'off');
        }

        // Set container to required if necessary
        if (isRequired) {
            containerCtrl.setRequired(true);
        }

        // Add a UID if the element doesn't already have one.
        if (!element.attr('id')) {
            element.attr('id', 'input--' + six4Utils.getUid());
        }

        // If it's a textArea then set it up
        if (tagName === 'textarea') {
            setupTextarea();
        }

        // If the input doesn't have an ngModel, it may have a static value. For that case,
        // we have to do one initial check to determine if the container should be in the
        // "has a value" state.
        if (!hasNgModel) {
            inputCheckValue();
        }

        // Function for checking if the control is currently errored
        var isErrorGetter = containerCtrl.isErrorGetter || function () {
            return ngModelCtrl.$invalid && (ngModelCtrl.$touched || isParentFormSubmitted());
        };

        // Function for checking if the form has been submitted
        var isParentFormSubmitted = function isParentFormSubmitted() {

            var parent = element.closest('form');
            var form = parent ? angular.element(parent).controller('form') : null;

            return form ? form.$submitted : false;
        };

        // Watch the isErrorGetter function to check when an error occurs and set the container appropriately
        scope.$watch(isErrorGetter, function (newValue, oldValue) {
            containerCtrl.setInvalid(newValue);
        });

        // Check the value each time it changes, when ng-model changes in either direction
        ngModelCtrl.$parsers.push(ngModelPipelineCheckValue);
        ngModelCtrl.$formatters.push(ngModelPipelineCheckValue);

        // Check the value each time the user types
        element.on('input', inputCheckValue);

        // Tell the container when the input is focused and blurred
        if (!isReadonly) {
            element.on('focus', function (ev) {
                $timeout(function () {
                    containerCtrl.setFocused(true);
                });
            }).on('blur', function (ev) {
                $timeout(function () {
                    containerCtrl.setFocused(false);
                    inputCheckValue();
                });
            });
        }

        // This bit was already commented out in ngMaterial code
        //ngModelCtrl.$setTouched();
        //if( ngModelCtrl.$invalid ) containerCtrl.setInvalid();

        scope.$on('$destroy', function () {
            containerCtrl.setFocused(false);
            containerCtrl.setHasValue(false);
            containerCtrl.input = null;
        });

        // Set the container hasValue if the input is not empty when ngModel changes
        function ngModelPipelineCheckValue(arg) {
            containerCtrl.setHasValue(!ngModelCtrl.$isEmpty(arg));
            return arg;
        }

        function inputCheckValue() {

            // An input's value counts if its length > 0,
            // or if the input's validity state says it has bad input (eg string in a number input)
            containerCtrl.setHasValue(element.val().length > 0 || (element[0].validity || {}).badInput);
        }

        // Set up Textarea
        function setupTextarea() {

            var isAutogrowing = !attr.hasOwnProperty('disable-autogrow');
            if (!isAutogrowing) return;

            // Can't check if height was or not explicity set,
            // so rows attribute will take precedence if present
            var minRows = attr.hasOwnProperty('rows') ? parseInt(attr.rows) : NaN;
            var maxRows = attr.hasOwnProperty('maxRows') ? parseInt(attr.maxRows) : NaN;
            var scopeResizeListener = scope.$on('s4p-resize-textarea', growTextarea);
            var lineHeight = null;
            var node = element[0];

            // This timeout is necessary, because the browser needs a little bit
            // of time to calculate the `clientHeight` and `scrollHeight`.
            $timeout(function () {
                growTextarea();
            }, 10, false);

            // We could leverage ngModel's $parsers here, however it
            // isn't reliable, because Angular trims the input by default,
            // which means that growTextarea won't fire when newlines and
            // spaces are added.
            element.on('input', growTextarea);

            $rootScope.$watch(function () {
                return element.val().length;
            }, function (newValue, oldValue) {
                growTextarea();
            });

            // We should still use the $formatters, because they fire when
            // the value was changed from outside the textarea.
            if (hasNgModel) {
                ngModelCtrl.$formatters.push(formattersListener);
            }

            if (!minRows) {
                element.attr('rows', 1);
            }

            angular.element($window).on('resize', growTextarea);
            scope.$on('$destroy', disableAutogrow);

            // Grow Text Area
            function growTextarea() {

                // temporarily disables element's flex so its height 'runs free'
                element.attr('rows', 1).css('height', 'auto');

                var height = getHeight();

                if (!lineHeight) {
                    // offsetHeight includes padding which can throw off our value
                    var origPaddingTop = element[0].style.paddingTop || '';
                    var origPaddingBottom = element[0].style.paddingBottom || '';

                    lineHeight = element.css('padding-top', 0).css('padding-bottom', 0).prop('offsetHeight');

                    element[0].style.paddingTop = origPaddingTop;
                    element[0].style.paddingBottom = origPaddingBottom;
                }

                if (minRows && lineHeight) {
                    height = Math.max(height, lineHeight * minRows);
                }

                if (maxRows && lineHeight) {

                    var maxHeight = lineHeight * maxRows;

                    if (maxHeight < height) {
                        element.attr('disable-autogrow', '');
                        height = maxHeight;
                    } else {
                        element.removeAttr('disable-autogrow');
                    }
                }

                if (lineHeight) {
                    element.attr('rows', Math.round(height / lineHeight));
                }

                element.css('height', height + 'px');
            }

            function getHeight() {

                // I changed this from the original angular-material code because it didn't seem
                // to be taking account of the bottom border. So I calculated the border height
                // then added it to the returnHeight - Jon

                var offsetHeight = node.offsetHeight;
                var line = node.scrollHeight - offsetHeight;
                var borderHeight = element.outerHeight() - element.innerHeight();
                var returnHeight = offsetHeight + Math.max(line, 0) + borderHeight;
                return returnHeight;
            }

            function formattersListener(value) {
                growTextarea();
                return value;
            }

            // This is used for removing all the events and things we've attached when the scope gets destroyed
            function disableAutogrow() {

                if (!isAutogrowing) return;

                isAutogrowing = false;
                angular.element($window).off('resize', growTextarea);

                element.attr('disable-autogrow', '').off('input', growTextarea);

                if (hasNgModel) {

                    var listenerIndex = ngModelCtrl.$formatters.indexOf(formattersListener);

                    if (listenerIndex > -1) {
                        ngModelCtrl.$formatters.splice(listenerIndex, 1);
                    }
                }
            }

            // Attach a watcher to detect when the textarea gets shown.
            if (attr.hasOwnProperty('detect-hidden')) {

                var handleHiddenChange = function () {

                    var wasHidden = false;

                    return function () {

                        var isHidden = node.offsetHeight === 0;

                        if (isHidden === false && wasHidden === true) {
                            growTextarea();
                        }

                        wasHidden = isHidden;
                    };
                }();

                // Check every digest cycle whether the visibility of the textarea has changed.
                // Queue up to run after the digest cycle is complete.
                var timeoutPromise;

                scope.$watch(function () {

                    $timeout.cancel(timeoutPromise);

                    timeoutPromise = $timeout(function () {
                        handleHiddenChange();
                        return true;
                    }, delayInMs);
                });
            }
        }
    }
}
inputTextareaDirective.$inject = ["$rootScope", "$window", "$log", "$timeout", "six4Utils"];

/////////////////////////////////////////////////////////
//                Six4 Portal Toolbar                  //
/////////////////////////////////////////////////////////

document.createElement('s4p-toolbar');
document.createElement('s4p-toolbar-content');
document.createElement('s4p-toolbar-section');
document.createElement('s4p-toolbar-item-group');
document.createElement('s4p-toolbar-item');
document.createElement('s4p-toolbar-overflow-button');
document.createElement('s4p-toolbar-overflow');

angular.module('s4p.directives').controller('s4pToolbarCtrl', ["$scope", "$element", "$attrs", "$timeout", "six4Breakpoints", function ($scope, $element, $attrs, $timeout, six4Breakpoints) {

    'use strict';

    var self = this;
    self.sections = [];

    // Set up defaults
    $scope.overflowOpen = false;

    // Add Section
    self.addSection = function (itemScope) {

        self.sections.push(itemScope);

        itemScope.$on('$destroy', function (event) {
            self.removeSection(itemScope);
        });
    };

    // Remove Section
    self.removeSection = function (itemScope) {

        var index = self.sections.indexOf(itemScope);

        if (index !== -1) {
            self.sections.splice(index, 1);
        }
    };

    // Update visibility of sections 
    self.updateSectionsVisibility = function () {

        var itemsInOverflow = false;

        self.sections.forEach(function (section) {
            itemsInOverflow = section.calculateVisibility() === 'overflow' ? true : itemsInOverflow;
        });

        if (itemsInOverflow === false) {
            $element.toggleClass('is-overflow-empty', true);

            $timeout(function () {
                $scope.overflowOpen = false;
            });
        } else {
            $element.toggleClass('is-overflow-empty', false);
        }
    };

    // Handle the breakpoint changing
    var offBreakpointChanged = $scope.$on('breakpointChanged', function (event, breakpoint) {
        self.updateSectionsVisibility();
    });
    $scope.$on('$destroy', function () {
        offBreakpointChanged();
    });

    // Toggle the overflow menu
    $scope.toggleOverflow = function () {
        $scope.overflowOpen = !$scope.overflowOpen;
    };
}]).directive('s4pToolbar', ["$compile", "$document", function ($compile, $document) {

    return {

        restrict: 'E',
        scope: {},
        controller: 's4pToolbarCtrl',
        transclude: true,
        template: getTemplate,

        link: function link(scope, element, attrs, controller, transclude) {

            // Copy the contents of the toolbar into both slots in the template
            transclude(scope.$parent, function (clone) {
                element.find('[transclude-main]').replaceWith(clone);
            });

            transclude(scope.$parent, function (clone) {

                clone.find('FORM').each(function () {
                    $(this).attr('name', $(this).attr('name') + '2');
                });

                element.find('[transclude-overflow]').replaceWith(clone);
            });

            // Handle clicking anywhere on the page except the overflow to close it.
            var overflowEl = element.find('s4p-toolbar-overflow-button');

            var documentClickHandler = function documentClickHandler(event) {

                var eventOutsideTarget = overflowEl[0] !== event.target && overflowEl.find($(event.target)).length === 0;

                if (eventOutsideTarget) {
                    scope.$apply(function () {
                        scope.overflowOpen = false;
                    });
                }
            };

            $document.on('click touchstart', documentClickHandler);

            scope.$on("$destroy", function () {
                $document.off("click touchstart", documentClickHandler);
            });

            scope.$on('close-all-toolbar-overflows', function () {
                scope.overflowOpen = false;
            });

            // Update the visibility of all the sections
            controller.updateSectionsVisibility();
        }

    };

    function getTemplate(element, attr) {

        // Colour scheme
        var cs = attr.cs ? 'cs="' + attr.cs + '"' : '';

        return '<s4p-toolbar-main><div transclude-main></div></s4p-toolbar-main>' + '<s4p-toolbar-overflow-button ng-class="{&quot;is-open&quot;:overflowOpen}">' + '<s4p-button ' + cs + ' button-style="circle" icon="dot-menu" ng-click="toggleOverflow()"></s4p-button>' + '<s4p-toolbar-overflow ng-show="overflowOpen" class="ng-hide" ng-cloak><div transclude-overflow></div></s4p-toolbar-overflow>' + '</s4p-toolbar-overflow-button>';
    }
}]).directive('s4pToolbarSection', ["six4Breakpoints", function (six4Breakpoints) {

    return {

        restrict: 'E',
        scope: {},
        require: '^s4pToolbar',

        link: function link(scope, element, attrs, controller) {

            // Register with parent toolbar
            controller.addSection(scope);

            scope.inMain = false;
            scope.inOverflow = true;

            var showAtAttribute = element.attr('show-at');

            if (showAtAttribute) {
                scope.showAtValues = element.attr('show-at').split(',');
            }

            // Set the visibility of this section to Main or Overflow
            scope.setVisibility = function (visibleIn) {

                if (visibleIn === 'main') {
                    scope.inMain = true;
                    element.toggleClass('is-in-main', true);
                    scope.inOverflow = false;
                    element.toggleClass('is-in-overflow', false);
                } else if (visibleIn === 'overflow') {
                    scope.inMain = false;
                    element.toggleClass('is-in-main', false);
                    scope.inOverflow = true;
                    element.toggleClass('is-in-overflow', true);
                }
            };

            // Calculate where this section is visible
            scope.calculateVisibility = function () {

                var breakpoint = six4Breakpoints.current();

                var breakpointNumber = breakpoint.number;

                // If there are no show-at values set for this section then it's always in the overflow
                if (scope.showAtValues === undefined || scope.showAtValues.length === 0) {
                    scope.setVisibility('overflow');
                    return 'overflow';
                }

                var visibleIn = 'overflow';

                // Loop through the show-at values
                scope.showAtValues.forEach(function (item) {

                    // If the rule has a > and the current breakpoint is higher or equal then the
                    // section is visible in the main toolbar
                    if (item.indexOf('>') > -1) {
                        var number = item.replace('>', '');
                        if (breakpointNumber >= parseInt(number)) {
                            visibleIn = 'main';
                        }
                    }

                    // If the current breakpoint matches the showat rule then the
                    // section is visible in the main toolbar 
                    else if (breakpointNumber === parseInt(item)) {
                            visibleIn = 'main';
                        }
                });

                scope.setVisibility(visibleIn);
                return visibleIn;
            };
        }

    };
}]);

// Modification of https://github.com/kseamon/fast-bind/blob/master/src/directives/bind-on-notify/bind-on-notify.js

/*
 * Binds the to the expression, dirty-checking and updating only when notified on the specified event-name,
 * Usage: <span s4p-translate-bind="myExpression"
 *              s4p-translate-bind-event="event-name"</span>
 */

angular.module('s4p.directives').directive('s4pTranslateBind', ["$rootScope", "$parse", function ($rootScope, $parse) {

    return {

        compile: function compile(element, attributes) {

            var expr = $parse(attributes.s4pTranslateBind);

            return function link(scope, element) {

                var lastValue;

                // Bind Function
                function bind() {

                    var value = expr(scope);

                    if (value !== lastValue) {
                        element.text(value);
                    }

                    lastValue = value;
                }

                // Bind Main Event
                var cleanup = $rootScope.$on('$translateChangeSuccess', function () {
                    bind();
                });

                scope.$on('$destroy', function ($rootscope) {
                    cleanup();
                });

                // Allow another event name to be passed to re-trigger the binding
                if (attributes.s4pTranslateBindEvent) {

                    var cleanup2 = $rootScope.$on(attributes.s4pTranslateBindEvent, function () {
                        bind();
                    });

                    scope.$on('$destroy', function ($rootscope) {
                        cleanup2();
                    });
                }

                bind();
            };
        }

    };
}])

/*
 * Binds the attributes to the object values, dirty-checking and updating only when notified on the specified event-name,
 * Usage: <span s4p-translate-bind-attr="{attr1: myExpression, attr2: myOtherExpression}"
 *              s4p-translate-bind-attr-event="event-name"</span>
 */

.directive('s4pTranslateBindAttr', ["$rootScope", "$parse", function ($rootScope, $parse) {

    return {

        compile: function compile(element, attributes) {

            var expr = $parse(attributes.s4pTranslateBindAttr);

            return function link(scope, element, attrs) {

                var lastValues = {};

                // Bind Function
                function bind() {

                    var values = expr(scope);

                    angular.forEach(values, function (value, key) {

                        if (value !== lastValues[key]) {
                            attrs.$set(key, value);
                        }

                        lastValues[key] = value;
                    });
                }

                // Bind Main Event
                var cleanup = $rootScope.$on('$translateChangeSuccess', function () {
                    bind();
                });

                scope.$on('$destroy', function ($rootscope) {
                    cleanup();
                });

                // Allow another event name to be passed to re-trigger the binding
                if (attributes.s4pTranslateBindAttrEvent) {

                    var cleanup2 = $rootScope.$on(attributes.s4pTranslateBindAttrEvent, function () {
                        bind();
                    });

                    scope.$on('$destroy', function ($rootscope) {
                        cleanup2();
                    });
                }

                bind();
            };
        }

    };
}]);
angular.module('s4p').controller('AdminEditDataConfigCtrl', ["$rootScope", "$scope", "$log", "$http", "$timeout", "$state", "$q", "s4pData", "s4pDialogs", "s4pPageTitle", function ($rootScope, $scope, $log, $http, $timeout, $state, $q, s4pData, s4pDialogs, s4pPageTitle) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.editFormModel = {};

    // Load Config
    ctrl.loadConfig = function () {

        ctrl.dataLoading = true;

        var params = {
            key: 'dataConfig'
        };

        return $http.get(s4pSettings.apiUrl + '/portalSettings', { params: params }).then(function (response) {

            if (response.data.records && response.data.records.length > 0) {
                ctrl.editFormModel.config = response.data.records[0].value;
            }

            ctrl.dataLoading = false;
        });
    };

    // Form Submitted
    ctrl.submitEditForm = function ($event) {

        ctrl.dataSaving = true;

        var uncompiledItem = angular.copy(ctrl.editFormModel.config);

        try {
            var compiledItem = JSON.stringify(s4pData.compileConfig(JSON.parse(ctrl.editFormModel.config)));
        } catch (err) {

            s4pDialogs.message({
                title: 'Error in Config',
                message: 'Your JSON does not validate. Try validating it using jsonlint.com before trying again.'
            });

            ctrl.dataSaving = false;

            return;
        }

        var newItems = [{ key: 'dataConfig', value: uncompiledItem, groupName: 'data' }, { key: 'dataConfigCompiled', value: compiledItem, groupName: 'data' }];

        // Save settings
        return $http.post(s4pSettings.apiUrl + '/portalSettings/multiupdate', newItems).then(function (response) {

            s4pDialogs.message({
                title: 'Saved Changes',
                message: 'Your changes have been saved'
            });

            ctrl.dataSaving = false;
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        // Load config
        ctrl.loadConfig();

        // Init the template editor
        ctrl.codeMirrorOptions = $rootScope.defaultCodeMirrorOptions;
        ctrl.codeMirrorOptions.mode = 'application/json';
        ctrl.codeMirrorOptions.smartIndent = true;

        console.log("ctrl.codeMirrorOptions", ctrl.codeMirrorOptions);
    };

    init();
}]);

angular.module('s4p').controller('AdminAddIntegrationCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "$q", "s4pDialogs", "s4pPageTitle", "s4pIntegrations", function ($rootScope, $scope, $log, $http, $state, $timeout, $q, s4pDialogs, s4pPageTitle, s4pIntegrations) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    ctrl.formModel = {
        settings: {}
    };

    // Load Integration
    ctrl.loadProviders = function () {

        ctrl.providersLoading = true;

        return s4pIntegrations.getProviders().then(function (response) {

            ctrl.providersLoading = false;
            ctrl.providers = response;

            ctrl.provider = ctrl.providers.filter(function (item) {
                return item.name === $state.params.id;
            })[0];

            s4pPageTitle.set('Edit ' + ctrl.provider.friendlyName + ' Settings');
        });
    };

    // Form is submitted
    ctrl.submitForm = function ($event) {

        // Add the integration object to the database
        $http.post(s4pSettings.apiUrl + '/integrations', {
            name: ctrl.formModel.name,
            providerName: ctrl.provider.name,
            providerFriendlyName: ctrl.provider.friendlyName
        }).then(function (response) {

            var integrationId = response.data.record.id;

            // Add all the settings one by one
            var counter = 0;

            for (var setting in ctrl.formModel.settings) {

                if (ctrl.formModel.settings.hasOwnProperty(setting)) {

                    counter++;

                    // Create new item
                    var newItem = {
                        integrationId: integrationId,
                        key: setting,
                        value: ctrl.formModel.settings[setting]
                    };

                    $http.post(s4pSettings.apiUrl + '/integrationSettings/', newItem).then(function (response) {

                        counter--;

                        if (counter === 0) {
                            ctrl.doneSaving();
                        }
                    });
                }
            }
        });
    };

    // Finished saving settings
    ctrl.doneSaving = function () {

        s4pDialogs.message({
            title: 'All done',
            message: 'Your settings have been saved',
            buttonText: 'Back to Integrations'
        }).result.then(function () {
            $state.go('admin.integrations');
        });
    };

    // Is anything loading?
    ctrl.pageLoading = function () {
        return ctrl.providersLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.loadProviders();
    };

    init();
}]);
angular.module('s4p').controller('AdminEditIntegrationCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "$q", "s4pDialogs", "s4pPageTitle", "s4pIntegrations", function ($rootScope, $scope, $log, $http, $state, $timeout, $q, s4pDialogs, s4pPageTitle, s4pIntegrations) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    ctrl.formModel = {};

    // Load Providers
    ctrl.loadProviders = function () {

        ctrl.providersLoading = true;

        return s4pIntegrations.getProviders().then(function (response) {

            ctrl.providersLoading = false;
            ctrl.providers = response;
        });
    };

    // Load Integration
    ctrl.loadIntegration = function () {

        ctrl.integrationLoading = true;

        // Get all matching integration settings
        return $http.get(s4pSettings.apiUrl + '/integrations/' + $state.params.id).then(function (response) {

            ctrl.integration = response.data.record;
            ctrl.integrationLoading = false;
        });
    };

    // Load Integration Settings
    ctrl.loadIntegrationSettings = function () {

        ctrl.integrationSettingsLoading = true;

        // Get all matching integration settings
        return $http.get(s4pSettings.apiUrl + '/integrationSettings/', {
            params: {
                integrationId: $state.params.id
            }
        }).then(function (response) {
            ctrl.integrationSettings = response.data.records;
            ctrl.integrationSettingsLoading = false;
        });
    };

    // Create View model
    ctrl.createViewModel = function () {

        ctrl.formModel = {
            settings: {}
        };

        // Get the provider which this integration belongs to
        ctrl.provider = ctrl.providers.filter(function (item) {
            return item.name === ctrl.integration.providerName;
        })[0];

        // Add the name field to the model
        ctrl.formModel.name = ctrl.integration.name;

        // Add each setting to the form model
        ctrl.integrationSettings.forEach(function (item) {
            ctrl.formModel.settings[item.key] = item.value;
        });

        s4pPageTitle.set('Edit ' + ctrl.provider.friendlyName + ' Settings');
    };

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var counter = 0;

        // Patch the main integrations object
        counter++;
        $http.patch(s4pSettings.apiUrl + '/integrations/' + $state.params.id, {
            name: ctrl.formModel.name
        }).then(function (response) {

            counter--;

            if (counter === 0) {
                ctrl.doneSaving();
            }
        });

        // Loop through form model and post/patch each one
        for (var setting in ctrl.formModel.settings) {

            if (ctrl.formModel.settings.hasOwnProperty(setting)) {

                counter++;

                // Get matching settings from original loaded settings?
                var matchingSettings = ctrl.integrationSettings.filter(function (item) {
                    return item.key === setting;
                });

                // Did it exist when settings were loaded?
                if (matchingSettings.length > 0) {

                    var newItem = {
                        value: ctrl.formModel.settings[setting]
                    };

                    $http.patch(s4pSettings.apiUrl + '/integrationSettings/' + matchingSettings[0].id, newItem).then(function (response) {

                        counter--;

                        if (counter === 0) {
                            ctrl.doneSaving();
                        }
                    });
                }

                // Otherwise create it
                else {

                        var newItem = {
                            integrationId: $state.params.id,
                            key: setting,
                            value: ctrl.formModel.settings[setting]
                        };

                        $http.post(s4pSettings.apiUrl + '/integrationSettings/', newItem).then(function (response) {

                            counter--;

                            if (counter === 0) {
                                ctrl.doneSaving();
                            }
                        });
                    }
            }
        }
    };

    // Finished saving settings
    ctrl.doneSaving = function () {

        s4pDialogs.message({
            title: 'All done',
            message: 'Your settings have been saved',
            buttonText: 'Back to Integrations'
        }).result.then(function () {
            $state.go('admin.integrations');
        });
    };

    // Is anything loading?
    ctrl.pageLoading = function () {
        return ctrl.providersLoading || ctrl.integrationsLoading || ctrl.integrationSettingsLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        // Load the providers and the settings for this integration then bind them all together to make the view model
        $q.all([ctrl.loadProviders(), ctrl.loadIntegration(), ctrl.loadIntegrationSettings()]).then(function () {

            ctrl.createViewModel();
        });
    };

    init();
}]);
angular.module('s4p').controller('AdminIntegrationsCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", "s4pIntegrations", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs, s4pIntegrations) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    // Load Data
    ctrl.loadData = function () {

        ctrl.dataLoading = true;

        $http.get(s4pSettings.apiUrl + '/Integrations').then(function (response) {
            ctrl.tableData = response.data.records;
            ctrl.dataLoading = false;
        });
    };

    // Remove Items
    ctrl.removeItems = function () {

        // Check that they definitely want to delete the group
        s4pDialogs.confirm({
            title: 'Are you sure?',
            message: 'If you delete an integration which is used by other features (e.g. delete a SendGrid integration which is being used by the notification system) then you could break things. Make sure you check things like the portal settings table and any other table which has an integrationId column to make sure nothing is being orphaned..',
            button1Text: 'Cancel',
            button2Text: 'Delete Integrations',
            button1Cs: 'accent',
            button2Cs: 'default',
            trueButton: 2
        }).result.then(function () {

            ctrl.dataLoading = true;
            var counter = 0;

            ctrl.selectedRows.forEach(function (id) {

                counter++;

                $http.delete(s4pSettings.apiUrl + '/integrations/' + id).then(function () {

                    counter--;

                    if (counter === 0) {
                        ctrl.loadData();
                    }
                });
            });
        });
    };

    // Load Providers
    ctrl.loadProviders = function () {

        ctrl.providersLoading = true;

        return s4pIntegrations.getProviders().then(function (response) {

            ctrl.providersLoading = false;
            ctrl.providers = response;

            // Get unique groups
            ctrl.providersGroups = response.map(function (item) {
                return item.groupName;
            }).filter(function (elem, pos, arr) {
                return arr.indexOf(elem) == pos;
            });
        });
    };

    // User clicked in table
    ctrl.tableRowClicked = function (evt, item) {
        if (!$(evt.target).is('input[type=checkbox]')) {
            $state.go('admin.integrations.edit', {
                id: item.id
            });
        }
    };

    // Is anything loading?
    ctrl.pageLoading = function () {
        return ctrl.dataLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.loadData();

        ctrl.loadProviders();
    };

    init();
}]);
angular.module('s4p').controller('AdminAddNotifCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    ctrl.formModel = {};

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var newItem = angular.copy(ctrl.formModel);

        $http.post(s4pSettings.apiUrl + '/notifications', newItem, {
            form: ctrl.addNotificationForm
        }).then(function (response) {

            s4pDialogs.message({
                title: 'Notification Added',
                message: 'New notification has been added. You can now fill in all the details.',
                buttonText: 'Continue'
            }).result.finally(function () {
                $state.go('admin.notifications.edit', {
                    id: response.data.record.id
                });
            });
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {};

    init();
}]);
angular.module('s4p').controller('AdminNotificationsCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.formModel = {};

    // Load Notifications
    ctrl.loadData = function () {

        ctrl.dataLoading = true;

        $http.get(s4pSettings.apiUrl + '/notifications').then(function (response) {
            ctrl.tableData = response.data.records;
            ctrl.dataLoading = false;
        });
    };

    // Load Integrations
    ctrl.loadIntegrations = function () {

        ctrl.integrationsLoading = true;

        $http.get(s4pSettings.apiUrl + '/integrations').then(function (response) {

            var allowedEmailIntegrations = ['mailGun', 'sendGrid'];

            ctrl.emailIntegrations = response.data.records.filter(function (item) {
                return allowedEmailIntegrations.indexOf(item.providerName) > -1;
            });

            var allowedSmsIntegrations = ['twilio'];

            ctrl.smsIntegrations = response.data.records.filter(function (item) {
                return allowedSmsIntegrations.indexOf(item.providerName) > -1;
            });

            ctrl.integrationsLoading = false;
        });
    };

    // Load Portal Settings
    ctrl.loadPortalSettings = function () {

        ctrl.portalSettingsLoading = true;

        $http.get(s4pSettings.apiUrl + '/portalSettings', {
            groupName: 'notifications'
        }).then(function (response) {

            // Create formModel from loaded settings
            response.data.records.forEach(function (item) {
                ctrl.formModel[item.key] = item.value;
            });

            ctrl.portalSettingsLoading = false;
        });
    };

    // Remove Items
    ctrl.removeItems = function () {

        if (ctrl.selectedRows && ctrl.selectedRows.length > 0) {

            // Check that they definitely want to delete the notification
            s4pDialogs.confirm({
                title: 'Are you sure?',
                message: 'If you delete this notification, it will be revoved from all groups and all users will lose access to it.',
                button1Text: 'Cancel',
                button2Text: 'Delete Notification',
                button1Cs: 'accent',
                button2Cs: 'default',
                trueButton: 2
            }).result.then(function () {

                ctrl.dataLoading = true;
                var counter = 0;

                ctrl.selectedRows.forEach(function (id) {

                    counter++;

                    $http.delete(s4pSettings.apiUrl + '/notifications/' + id).then(function () {

                        counter--;

                        if (counter === 0) {
                            ctrl.loadData();
                        }
                    });
                });
            });
        }
    };

    // Submit Settings Form
    ctrl.submitForm = function () {

        ctrl.portalSettingsSaving = true;

        var settingsToSave = [];

        for (var setting in ctrl.formModel) {
            if (ctrl.formModel.hasOwnProperty(setting)) {

                settingsToSave.push({
                    key: setting,
                    value: ctrl.formModel[setting],
                    groupName: "notifications"
                });
            }
        }

        $http.post(s4pSettings.apiUrl + '/portalSettings/multiUpdate', settingsToSave, {
            form: ctrl.settingsForm
        }).then(function (response) {

            // Confirm
            s4pDialogs.message({
                title: 'Saved',
                message: 'Your changes have been saved.',
                buttonText: 'Continue'
            });

            ctrl.portalSettingsSaving = false;
        });
    };

    // User clicked in table
    ctrl.tableRowClicked = function (evt, item) {
        if (!$(evt.target).is('input[type=checkbox]')) {
            $state.go('admin.notifications.edit', {
                id: item.id
            });
        }
    };

    // Is the page loading?
    ctrl.pageLoading = function () {
        return ctrl.dataLoading || ctrl.portalSettingsLoading || ctrl.integrationsLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        // Load Data
        ctrl.loadData();
        ctrl.loadPortalSettings();
        ctrl.loadIntegrations();
    };

    init();
}]);
angular.module('s4p').controller('AdminAddPermissionCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    ctrl.formModel = {};
    ctrl.formMeta = {};

    // Load Permission Groups
    ctrl.loadPermissionGroups = function () {

        return $http.get(s4pSettings.apiUrl + '/permissionGroups').then(function (response) {
            ctrl.formMeta.groups = response.data.records;
            ctrl.metaLoading = false;
        });
    };

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var newItem = angular.copy(ctrl.formModel);

        $http.post(s4pSettings.apiUrl + '/permissions', newItem, {
            form: ctrl.addPermissionForm
        }).then(function (response) {

            $state.go('admin.permissions');
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.metaLoading = true;

        ctrl.loadPermissionGroups();
    };

    init();
}]);
angular.module('s4p').controller('AdminAddTablePermissionGroupCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    ctrl.formModel = {};

    // Reset Form Model
    ctrl.resteFormModel = function () {

        ctrl.formModel = {
            viewAction: true,
            addAction: true,
            editAction: true,
            deleteAction: true
        };
    };

    // Form is submitted
    ctrl.submitGroupForm = function ($event) {

        var newItem = angular.copy(ctrl.formModel);

        newItem.defaultPermissions = [];

        if (newItem.viewAction) {
            newItem.defaultPermissions.push('view');
        }

        if (newItem.addAction) {
            newItem.defaultPermissions.push('add');
        }

        if (newItem.editAction) {
            newItem.defaultPermissions.push('edit');
        }

        if (newItem.deleteAction) {
            newItem.defaultPermissions.push('delete');
        }

        delete newItem.viewAction;
        delete newItem.addAction;
        delete newItem.editAction;
        delete newItem.deleteAction;

        $http.post(s4pSettings.apiUrl + '/tablePermissionGroups', newItem, {
            form: ctrl.groupForm
        }).then(function (response) {
            $state.go('admin.permissionGroups');
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.resteFormModel();
    };

    init();
}]);
angular.module('s4p').controller('AdminEditPermissionCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "$q", "s4pDialogs", "s4pPageTitle", function ($rootScope, $scope, $log, $http, $state, $timeout, $q, s4pDialogs, s4pPageTitle) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    ctrl.editPermissionFormModel = {};
    ctrl.editPermissionFormMeta = {};

    // Load Item
    ctrl.loadItem = function () {

        return $http.get(s4pSettings.apiUrl + '/permissions/' + $state.params.id).then(function (response) {
            ctrl.editPermissionFormModel = response.data.record;
            s4pPageTitle.set(response.data.record.friendlyName);
        });
    };

    // Load Permission Groups
    ctrl.loadPermissionGroups = function () {

        return $http.get(s4pSettings.apiUrl + '/permissionGroups').then(function (response) {
            ctrl.editPermissionFormMeta.groups = response.data.records;
        });
    };

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var newItem = angular.copy(ctrl.editPermissionFormModel);

        // Send the patch request
        $http.patch(s4pSettings.apiUrl + '/permissions/' + $state.params.id, newItem, {
            form: ctrl.editPermissionForm
        }).then(function (response) {
            $state.go('admin.permissions');
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.metaLoading = true;

        // Load item and meta date in parallel, then switch the loading indicator off.
        $q.all([ctrl.loadPermissionGroups(), ctrl.loadItem()]).then(function () {
            ctrl.metaLoading = false;
        });
    };

    init();
}]);
angular.module('s4p').controller('AdminPermissionsCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    // Load Permissions
    ctrl.loadPermissions = function () {

        ctrl.dataLoading = true;

        var params = {
            start: ctrl.pagingModel.start,
            limit: ctrl.pagingModel.itemsPerPage
        };

        $http.get(s4pSettings.apiUrl + '/permissions', { params: params }).then(function (response) {
            ctrl.pagingModel.totalItems = response.data.meta.totalCount;
            ctrl.pagingModel.totalPages = Math.ceil(ctrl.pagingModel.totalItems / ctrl.pagingModel.itemsPerPage);
            ctrl.tableData = response.data.records;
        });
    };

    // Paging clicked
    ctrl.tablePageChanged = function () {
        ctrl.pagingModel.start = (ctrl.pagingModel.currentPage - 1) * ctrl.pagingModel.itemsPerPage + 1 + ctrl.pagingModel.offset;
        ctrl.loadPermissions();
    };

    // Remove Items
    ctrl.removeItems = function () {

        if (ctrl.selectedRows && ctrl.selectedRows.length > 0) {

            // Check that they definitely want to delete the permissions
            s4pDialogs.confirm({
                title: 'Are you sure?',
                message: 'If you delete these permissions they will be removed from all roles and you must ensure there are no traces left in the code.',
                button1Text: 'Cancel',
                button2Text: 'Yes, delete them',
                button1Cs: 'accent',
                button2Cs: 'default',
                trueButton: 2
            }).result.then(function () {

                ctrl.dataLoading = true;
                var counter = 0;

                ctrl.selectedRows.forEach(function (id) {

                    counter++;

                    $http.delete(s4pSettings.apiUrl + '/permissions/' + id).then(function () {

                        counter--;

                        if (counter === 0) {
                            ctrl.loadPermissions();
                        }
                    });
                });
            });
        }
    };

    // Permission clicked in table
    ctrl.permissionRowClicked = function (evt, item) {
        if (!$(evt.target).is('input[type=checkbox]')) {
            $state.go('admin.permissions.edit', {
                id: item.id
            });
        }
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        console.log("Running Permissions Controller");

        // Set up pagingModel
        ctrl.pagingModel = {
            totalItems: 0,
            itemsPerPage: 50,
            pagesToShow: 5,
            currentPage: 1,
            offset: 0,
            start: 1
        };

        // Load first page
        ctrl.loadPermissions();
    };

    init();
}]);
angular.module('s4p').controller('AdminAddUserCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;

    var adminAddUser = this;
    adminAddUser.addUserForm = {};

    // Form is submitted
    adminAddUser.submitForm = function ($event) {

        var userToAdd = angular.copy(adminAddUser.addUserForm);

        if (userToAdd.profilePhoto) {
            userToAdd.profilePhotoId = userToAdd.profilePhoto.id;
        }

        delete userToAdd.profilePhoto;

        $http.post(s4pSettings.apiUrl + '/users', userToAdd, {
            form: adminAddUser.aauF
        }).then(function (response) {

            // Check whether we need to go to the edit page because there are more details to add
            if (portalSettings.users && portalSettings.users.extendedUserDetails) {

                s4pDialogs.message({
                    title: 'User Added',
                    message: 'The user has been created, you can now add their extended profile information details.'
                }).result.finally(function () {
                    $state.go('admin.users.edit', {
                        id: response.data.record.id
                    });
                });
            } else {

                $state.go('admin.users');
            }
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        adminAddUser.addUserForm.authLevel = "3";
    };

    init();
}]);
angular.module('s4p').service('adminEditUserService', function () {
    this.ctrl = {};
}).controller('AdminEditUserCtrl', ["$rootScope", "$scope", "$log", "$http", "$timeout", "$state", "s4pDialogs", "s4pPageTitle", "adminEditUserService", function ($rootScope, $scope, $log, $http, $timeout, $state, s4pDialogs, s4pPageTitle, adminEditUserService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;
    adminEditUserService.ctrl = ctrl;

    ctrl.user = {};
    ctrl.editUserForm = {};

    // User form is submitted
    ctrl.submitEditUserForm = function ($event) {

        var editedUser = angular.copy(ctrl.editUserForm);

        // Don't patch the authLevel if they were a super user when loaded in.
        if (ctrl.oldAuthLevel === 1) {
            delete editedUser.authLevel;
        }

        // Transform profile photo fields
        if (editedUser.profilePhoto) {
            editedUser.profilePhotoId = editedUser.profilePhoto.id;
        }
        delete editedUser.profilePhoto;

        // Patch the user
        $http.patch(s4pSettings.apiUrl + '/users/' + $state.params.id, editedUser, {
            form: ctrl.aeuF
        }).then(function (response) {

            s4pDialogs.message({
                title: 'Saved Changes',
                message: 'Your changes have been saved'
            });
        });
    };

    // Password form is submitted
    ctrl.submitChangePasswordForm = function ($event) {

        var newPassword = angular.copy(ctrl.changePasswordForm);

        $http.patch(s4pSettings.apiUrl + '/users/' + $state.params.id, newPassword, {
            form: ctrl.acpF
        }).then(function (response) {

            s4pDialogs.message({
                title: 'Saved Changes',
                message: 'The user\'s password has been changed'
            });
        });
    };

    // Load User
    ctrl.loadUser = function () {

        $http.get(s4pSettings.apiUrl + '/users/' + $state.params.id).then(function (response) {

            ctrl.oldAuthLevel = response.data.record.authLevel;
            ctrl.user = response.data.record;
            ctrl.editUserForm = ctrl.user;
            ctrl.recordLoaded = true;

            $scope.$broadcast('tabParentLoaded');
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.loadUser();
    };

    init();
}]);

angular.module('s4p').controller('AdminEditUserRolesTabCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$q", "$timeout", "s4pDialogs", "adminEditUserService", function ($rootScope, $scope, $log, $http, $state, $q, $timeout, s4pDialogs, adminEditUserService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.rolesFormModel = {};
    ctrl.addRoleFormMeta = {};

    // Load Roles
    ctrl.loadRoles = function () {

        ctrl.metaLoading = true;

        // Get the roles for this user
        return $http.get(s4pSettings.apiUrl + '/roles').then(function (response) {

            ctrl.addRoleFormMeta.roles = response.data.records;
            ctrl.metaLoading = false;
        });
    };

    // Load User Roles
    ctrl.loadUserRoles = function () {

        ctrl.dataLoading = true;

        // Get the roles for this user
        return $http.get(s4pSettings.apiUrl + '/userRoles', {
            params: {
                userId: adminEditUserService.ctrl.user.id
            }
        }).then(function (response) {

            ctrl.tableData = response.data.records;
            ctrl.dataLoading = false;
        });
    };

    // Remove User Role
    ctrl.removeUserRole = function (evt, userRole) {

        ctrl.dataLoading = true;

        // Get the roles for this user
        return $http.delete(s4pSettings.apiUrl + '/userRoles/' + userRole.id).then(function (response) {

            ctrl.loadUserRoles();
        }).finally(function () {
            ctrl.dataLoading = false;
        });
    };

    // Add Form Submit
    ctrl.submitForm = function () {

        if (!ctrl.addRoleFormModel.role) {
            ctrl.addRoleForm.$resetForm();
            return;
        }

        var newItem = {
            userId: adminEditUserService.ctrl.user.id,
            roleId: ctrl.addRoleFormModel.role.id
        };

        // Get any matching userRole which already exist
        $http.get(s4pSettings.apiUrl + '/userRoles', {
            params: newItem
        }).then(function (response) {

            // Check that user isn't already in role
            if (response.data.records.length > 0) {

                s4pDialogs.message({
                    title: 'User in Role',
                    message: adminEditUserService.ctrl.user.firstName + ' already has this role assigned to them. No changes were made.',
                    buttonText: 'Continue'
                });
            } else {

                $http.post(s4pSettings.apiUrl + '/userRoles', newItem, {
                    form: ctrl.addRoleForm,
                    resetFormOnSuccess: true
                }).then(function (response) {
                    ctrl.loadUserRoles();
                });
            }
        }).finally(function () {
            ctrl.addRoleFormModel = {};
            ctrl.addRoleForm.$resetForm();
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.dataLoading = true;
        ctrl.metaLoading = true;

        // Load roles once the user has been loaded into the parent controller
        var userLoadWatcher = $scope.$watch(function () {
            return adminEditUserService.ctrl.user;
        }, function (newValue, oldValue) {

            if (newValue !== undefined && Object.keys(newValue).length !== 0 && newValue.constructor === Object) {

                ctrl.loadUserRoles();
                ctrl.loadRoles();

                // Remove the watch
                userLoadWatcher();
            }
        });
    };

    init();
}]);
angular.module('s4p').controller('AdminEditUserTagsTabCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$q", "$timeout", "s4pDialogs", "adminEditUserService", function ($rootScope, $scope, $log, $http, $state, $q, $timeout, s4pDialogs, adminEditUserService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.parent = adminEditUserService.ctrl;

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {};

    var parentLoaded = function parentLoaded() {};

    init();

    // Wait for parent to load before Init
    ctrl.parentLoading = true;

    if (ctrl.parent.recordLoaded) {
        parentLoaded();
    } else {
        $scope.$on('tabParentLoaded', function () {
            parentLoaded();
        });
    }
}]);
angular.module('s4p').controller('AdminUsersCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", function ($rootScope, $scope, $log, $http, $state) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    // Load Users
    ctrl.loadData = function () {

        ctrl.dataLoading = true;

        // Params
        var params = {
            start: ctrl.pagingModel.start,
            limit: ctrl.pagingModel.itemsPerPage

            // Filters
        };var filters = [];

        if (ctrl.sortFormModel.roleId && ctrl.sortFormModel.roleId > 0) {
            filters.push({
                key: 'roleId',
                type: 'eq',
                value: ctrl.sortFormModel.roleId
            });
        }

        if (ctrl.sortFormModel.tagId && ctrl.sortFormModel.tagId > 0) {
            filters.push({
                key: 'tagId',
                type: 'eq',
                value: ctrl.sortFormModel.tagId
            });
        }

        if (filters.length > 0) {
            params.filters = filters.map(function (item) {
                return item.key + ':' + item.type + ':' + item.value;
            }).join('|');
        }

        // Get the users
        $http.get(s4pSettings.apiUrl + '/users', { params: params }).then(function (response) {
            ctrl.pagingModel.totalItems = response.data.meta.totalCount;
            ctrl.pagingModel.totalPages = Math.ceil(ctrl.pagingModel.totalItems / ctrl.pagingModel.itemsPerPage);
            ctrl.tableData = response.data.records;
            ctrl.dataLoading = false;
        });
    };

    // Load Roles
    ctrl.loadRoles = function () {

        ctrl.rolesLoading = true;

        $http.get(s4pSettings.apiUrl + '/roles').then(function (response) {
            ctrl.meta.roles = response.data.records;
            ctrl.rolesLoading = false;
        });
    };

    // Load Tags
    ctrl.loadTags = function () {

        ctrl.tagsLoading = true;

        var params = {
            filters: 'tagType.code:eq:user'
        };

        $http.get(s4pSettings.apiUrl + '/tags', { params: params }).then(function (response) {
            ctrl.meta.tags = response.data.records;
            ctrl.tagsLoading = false;
        });
    };

    // Do Search
    ctrl.doSearch = function () {
        ctrl.pagingModel.start = 1;
        ctrl.pagingModel.currentPage = 1;
        ctrl.loadData();
    };

    // Create Auth Level Label
    ctrl.createAuthLevelLabel = function (level) {

        switch (level) {
            case 1:
                return '64 Digital';
            case 2:
                return 'Administrator';
            default:
                return 'User';
        }
    };

    // Paging clicked
    ctrl.tablePageChanged = function () {
        ctrl.pagingModel.start = (ctrl.pagingModel.currentPage - 1) * ctrl.pagingModel.itemsPerPage + 1 + ctrl.pagingModel.offset;
        ctrl.loadData();
    };

    // Remove Items
    ctrl.removeItems = function () {

        if (ctrl.selectedRows && ctrl.selectedRows.length > 0) {

            ctrl.dataLoading = true;
            var counter = 0;

            ctrl.selectedRows.forEach(function (id) {

                counter++;

                $http.delete(s4pSettings.apiUrl + '/users/' + id).then(function () {

                    counter--;

                    if (counter === 0) {
                        ctrl.loadData();
                    }
                });
            });
        }
    };

    // User clicked in table
    ctrl.userRowClicked = function (evt, item) {
        if (!$(evt.target).is('input[type=checkbox]')) {
            $state.go('admin.users.edit', {
                id: item.id
            });
        }
    };

    // Page Loading
    ctrl.pageLoading = function () {
        return ctrl.dataLoading || ctrl.rolesLoading || ctrl.tagsLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        // Set up pagingModel
        ctrl.pagingModel = {
            totalItems: 0,
            itemsPerPage: 50,
            pagesToShow: 5,
            currentPage: 1,
            offset: 0,
            start: 1
        };

        // Meta
        ctrl.meta = {};

        // Sort Form Model
        ctrl.sortFormModel = {};

        // Load Roles
        ctrl.loadRoles();

        // Load Tags
        ctrl.loadTags();

        // Load first page
        ctrl.loadData();
    };

    init();
}]);
angular.module('s4p').controller('AdminAddNotifGroupCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    ctrl.formModel = {};

    // Load Roles
    ctrl.loadRoles = function () {

        ctrl.rolesLoading = true;

        return $http.get(s4pSettings.apiUrl + '/roles').then(function (response) {
            ctrl.roles = response.data.records;
            ctrl.rolesLoading = false;
        });
    };

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var newItem = angular.copy(ctrl.formModel);

        $http.post(s4pSettings.apiUrl + '/notificationGroups', newItem, {
            form: ctrl.addForm
        }).then(function (response) {

            s4pDialogs.message({
                title: 'New Group Added',
                message: 'The new group has been created. You can now add notifications and users to this group.',
                buttonText: 'Continue'
            }).result.finally(function () {
                $state.go('admin.notificationGroups.edit', {
                    id: response.data.record.id
                });
            });
        });
    };

    // Is anything loading?
    ctrl.pageLoading = function () {
        return ctrl.rolesLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.loadRoles();
    };

    init();
}]);
angular.module('s4p').service('adminEditNotificationGroupService', function () {
    this.ctrl = {};
}).controller('AdminEditNotifGroupCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "$q", "s4pDialogs", "s4pPageTitle", "adminEditNotificationGroupService", function ($rootScope, $scope, $log, $http, $state, $timeout, $q, s4pDialogs, s4pPageTitle, adminEditNotificationGroupService) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    adminEditNotificationGroupService.ctrl = ctrl;

    ctrl.formModel = {};

    // Load Item
    ctrl.loadData = function () {

        ctrl.dataLoading = true;

        return $http.get(s4pSettings.apiUrl + '/notificationGroups/' + $state.params.id).then(function (response) {
            ctrl.formModel = response.data.record;
            s4pPageTitle.set(response.data.record.name);
            ctrl.dataLoading = false;
        });
    };

    // Load Roles
    ctrl.loadRoles = function () {

        ctrl.rolesLoading = true;

        return $http.get(s4pSettings.apiUrl + '/roles').then(function (response) {
            ctrl.roles = response.data.records;
            ctrl.rolesLoading = false;
        });
    };

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var newItem = angular.copy(ctrl.formModel);

        // Send the patch request
        $http.patch(s4pSettings.apiUrl + '/notificationGroups/' + $state.params.id, newItem, {
            form: ctrl.editForm
        }).then(function (response) {

            s4pDialogs.message({
                title: 'Saved',
                message: 'Your changes have been saved',
                buttonText: 'Continue'
            });
        });
    };

    // Is anything loading?
    ctrl.pageLoading = function () {
        return ctrl.dataLoading || ctrl.rolesLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.loadData();
        ctrl.loadRoles();

        // Set the tab if the state says so, otherwise choose the first tab
        if ($state.params.tabId) {
            ctrl.activeTab = $state.params.tabId;
        } else {
            ctrl.activeTab = 'details';
        }
    };

    init();
}]);
angular.module('s4p').controller('AdminEditNotifGroupNotifsTabCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", "adminEditNotificationGroupService", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs, adminEditNotificationGroupService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.parent = adminEditNotificationGroupService.ctrl;

    ctrl.formModel = {};

    // Load notifications
    ctrl.loadNotifications = function () {

        ctrl.loadingNotifications = true;

        return $http.get(s4pSettings.apiUrl + '/notifications').then(function (response) {
            ctrl.notifications = response.data.records;
            ctrl.loadingNotifications = false;
        });
    };

    // Load Data
    ctrl.loadData = function () {

        ctrl.dataLoading = true;

        var params = {
            notificationGroupId: ctrl.parent.formModel.id
        };

        return $http.get(s4pSettings.apiUrl + '/notificationGroupNotifications', { params: params }).then(function (response) {
            ctrl.tableData = response.data.records;
        });
    };

    // Remove Items
    ctrl.removeItems = function () {

        if (ctrl.selectedRows && ctrl.selectedRows.length > 0) {

            ctrl.dataLoading = true;
            var counter = 0;

            ctrl.selectedRows.forEach(function (id) {

                counter++;

                $http.delete(s4pSettings.apiUrl + '/notificationGroupNotifications/' + id).then(function () {

                    counter--;

                    if (counter === 0) {
                        ctrl.loadData();
                    }
                });
            });
        }
    };

    // Row clicked in table
    ctrl.rowClicked = function (evt, item) {
        if (!$(evt.target).is('input[type=checkbox]')) {
            $state.go('admin.notifications.edit', {
                id: item.notification.id
            });
        }
    };

    // Add form submitted
    ctrl.submitForm = function (viewValue) {

        // Get any matching notificationGroupNotification which already exist
        $http.get(s4pSettings.apiUrl + '/notificationGroupNotifications', {
            params: {
                notificationGroupId: ctrl.parent.formModel.id,
                notificationId: ctrl.formModel.notificationId
            }
        }).then(function (response) {

            // Check that user isn't already in the group
            if (response.data.records.length > 0) {

                s4pDialogs.message({
                    title: 'Notification in Group',
                    message: 'This notification has already been added to this group. No changes were made.',
                    buttonText: 'Continue'
                });
            } else {

                var newItem = {
                    notificationGroupId: ctrl.parent.formModel.id,
                    notificationId: ctrl.formModel.notificationId
                };

                $http.post(s4pSettings.apiUrl + '/notificationGroupNotifications', newItem, {
                    form: ctrl.addForm,
                    resetFormOnSuccess: true
                }).then(function (response) {
                    ctrl.loadData();
                });
            }
        }).finally(function () {
            ctrl.formModel = {};
            ctrl.addForm.$resetForm();
        });
    };

    ctrl.tabLoading = function () {
        return ctrl.dataLoading || ctrl.loadingNotifications || ctrl.parentLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.parentLoading = true;

        // Load everything once the role has been loaded in the parent controller
        var parentLoadWatcher = $scope.$watch(function () {
            return ctrl.parent.formModel;
        }, function (newValue, oldValue) {

            if (newValue !== undefined && Object.keys(newValue).length !== 0 && newValue.constructor === Object) {

                ctrl.parentLoading = false;

                // Load Data
                ctrl.loadData();

                // Load Notifications for dropdown
                ctrl.loadNotifications();

                // Remove the watch
                parentLoadWatcher();
            }
        });
    };

    init();
}]);
angular.module('s4p').controller('AdminEditNotifGroupUsersTabCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", "adminEditNotificationGroupService", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs, adminEditNotificationGroupService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.parent = adminEditNotificationGroupService.ctrl;

    ctrl.formModel = {};

    // Load Data
    ctrl.loadData = function () {

        ctrl.dataLoading = true;

        var params = {
            start: ctrl.pagingModel.start,
            limit: ctrl.pagingModel.itemsPerPage,
            notificationGroupId: ctrl.parent.formModel.id
        };

        $http.get(s4pSettings.apiUrl + '/notificationGroupUsers', { params: params }).then(function (response) {
            ctrl.pagingModel.totalItems = response.data.meta.totalCount;
            ctrl.pagingModel.totalPages = Math.ceil(ctrl.pagingModel.totalItems / ctrl.pagingModel.itemsPerPage);
            ctrl.tableData = response.data.records;
        });
    };

    // Paging clicked
    ctrl.tablePageChanged = function () {
        ctrl.pagingModel.start = (ctrl.pagingModel.currentPage - 1) * ctrl.pagingModel.itemsPerPage + 1 + ctrl.pagingModel.offset;
        ctrl.loadData();
    };

    // Remove Items
    ctrl.removeItems = function () {

        if (ctrl.selectedRows && ctrl.selectedRows.length > 0) {

            ctrl.dataLoading = true;
            var counter = 0;

            ctrl.selectedRows.forEach(function (id) {

                counter++;

                $http.delete(s4pSettings.apiUrl + '/notificationGroupUsers/' + id).then(function () {

                    counter--;

                    if (counter === 0) {
                        ctrl.loadData();
                    }
                });
            });
        }
    };

    // Row clicked in table
    ctrl.rowClicked = function (evt, item) {
        if (!$(evt.target).is('input[type=checkbox]')) {
            $state.go('admin.users.edit', {
                id: item.user.id
            });
        }
    };

    // Get users for add a user typeahead
    ctrl.userSearch = function (viewValue) {

        ctrl.userSearchLoading = true;

        return $http.get(s4pSettings.apiUrl + '/users', {
            params: {
                keywords: viewValue
            }
        }).then(function (response) {
            ctrl.userSearchLoading = false;
            return response.data.records;
        });
    };

    // Add user form submitted
    ctrl.submitForm = function (viewValue) {

        if (!ctrl.formModel.user) {
            ctrl.addForm.$resetForm();
            return;
        }

        // Get any matching notificationGroupUser which already exist
        $http.get(s4pSettings.apiUrl + '/notificationGroupUsers', {
            params: {
                notificationGroupId: ctrl.parent.formModel.id,
                userId: ctrl.formModel.user.id
            }
        }).then(function (response) {

            // Check that user isn't already in the group
            if (response.data.records.length > 0) {

                s4pDialogs.message({
                    title: 'User in Group',
                    message: ctrl.formModel.user.firstName + ' has already been assigned to this group. No changes were made.',
                    buttonText: 'Continue'
                });
            } else {

                var newItem = {
                    notificationGroupId: ctrl.parent.formModel.id,
                    userId: ctrl.formModel.user.id
                };

                $http.post(s4pSettings.apiUrl + '/notificationGroupUsers', newItem, {
                    form: ctrl.addForm,
                    resetFormOnSuccess: true
                }).then(function (response) {

                    ctrl.loadData();
                });
            }
        }).finally(function () {
            ctrl.formModel = {};
            ctrl.addForm.$resetForm();
        });
    };

    ctrl.tabLoading = function () {
        return ctrl.dataLoading || ctrl.parentLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.parentLoading = true;

        // Set up pagingModel
        ctrl.pagingModel = {
            totalItems: 0,
            itemsPerPage: 50,
            pagesToShow: 5,
            currentPage: 1,
            offset: 0,
            start: 1
        };

        // Load everything once the role has been loaded in the parent controller
        var parentLoadWatcher = $scope.$watch(function () {
            return ctrl.parent.formModel;
        }, function (newValue, oldValue) {

            if (newValue !== undefined && Object.keys(newValue).length !== 0 && newValue.constructor === Object) {

                ctrl.parentLoading = false;

                // Load first page
                ctrl.loadData();

                // Remove the watch
                parentLoadWatcher();
            }
        });
    };

    init();
}]);
angular.module('s4p').controller('AdminNotificationGroupsCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    // Load Data
    ctrl.loadData = function () {

        ctrl.dataLoading = true;

        $http.get(s4pSettings.apiUrl + '/notificationGroups').then(function (response) {
            ctrl.tableData = response.data.records;
            ctrl.dataLoading = false;
        });
    };

    // Remove Items
    ctrl.removeItems = function () {

        // Check that they definitely want to delete the group
        s4pDialogs.confirm({
            title: 'Are you sure?',
            message: 'This will delete the groups you ticked. Any users in these groups will no longer have access to the notifications in these groups unless they are contained in a different group.',
            button1Text: 'Cancel',
            button2Text: 'Delete Group',
            button1Cs: 'accent',
            button2Cs: 'default',
            trueButton: 2
        }).result.then(function () {

            ctrl.dataLoading = true;
            var counter = 0;

            ctrl.selectedRows.forEach(function (id) {

                counter++;

                $http.delete(s4pSettings.apiUrl + '/notificationGroups/' + id).then(function () {

                    counter--;

                    if (counter === 0) {
                        ctrl.loadData();
                    }
                });
            });
        });
    };

    // User clicked in table
    ctrl.tableRowClicked = function (evt, item) {
        if (!$(evt.target).is('input[type=checkbox]')) {
            $state.go('admin.notificationGroups.edit', {
                id: item.id
            });
        }
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        // Load first page
        ctrl.loadData();
    };

    init();
}]);
angular.module('s4p').controller('AdminAddNotifHeadingCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    ctrl.formModel = {};

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var newItem = angular.copy(ctrl.formModel);

        $http.post(s4pSettings.apiUrl + '/notificationHeadings', newItem, {
            form: ctrl.addForm
        }).then(function (response) {

            $state.go('admin.notificationHeadings');
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {};

    init();
}]);
angular.module('s4p').controller('AdminEditNotifHeadingCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "$q", "s4pDialogs", "s4pPageTitle", function ($rootScope, $scope, $log, $http, $state, $timeout, $q, s4pDialogs, s4pPageTitle) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    ctrl.formModel = {};

    // Load Item
    ctrl.loadItem = function () {

        ctrl.dataLoading = true;

        return $http.get(s4pSettings.apiUrl + '/notificationHeadings/' + $state.params.id).then(function (response) {
            ctrl.formModel = response.data.record;
            s4pPageTitle.set(response.data.record.name);
            ctrl.dataLoading = false;
        });
    };

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var newItem = angular.copy(ctrl.formModel);

        // Send the patch request
        $http.patch(s4pSettings.apiUrl + '/notificationHeadings/' + $state.params.id, newItem, {
            form: ctrl.editForm
        }).then(function (response) {

            $state.go('admin.notificationHeadings');
        });
    };

    // Is the page loading?
    ctrl.pageLoading = function () {
        return ctrl.dataLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.loadItem();
    };

    init();
}]);
angular.module('s4p').controller('AdminNotificationHeadingsCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    // Load Data
    ctrl.loadData = function () {

        ctrl.dataLoading = true;

        $http.get(s4pSettings.apiUrl + '/notificationHeadings').then(function (response) {
            ctrl.tableData = response.data.records;
            ctrl.dataLoading = false;
        });
    };

    // Remove Items
    ctrl.removeItems = function () {

        // Check that they definitely want to delete the heading
        s4pDialogs.confirm({
            title: 'Are you sure?',
            message: 'If you delete this heading, make sure you re-assign all notifications to another heading, otherwise they will be invisible to admins.',
            button1Text: 'Cancel',
            button2Text: 'Delete Heading',
            button1Cs: 'accent',
            button2Cs: 'default',
            trueButton: 2
        }).result.then(function () {

            ctrl.dataLoading = true;
            var counter = 0;

            ctrl.selectedRows.forEach(function (id) {

                counter++;

                $http.delete(s4pSettings.apiUrl + '/notificationHeadings/' + id).then(function () {

                    counter--;

                    if (counter === 0) {
                        ctrl.loadData();
                    }
                });
            });
        });
    };

    // User clicked in table
    ctrl.tableRowClicked = function (evt, item) {
        if (!$(evt.target).is('input[type=checkbox]')) {
            $state.go('admin.notificationHeadings.edit', {
                id: item.id
            });
        }
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        // Load first page
        ctrl.loadData();
    };

    init();
}]);
angular.module('s4p').service('adminEditNotificationService', function () {
    this.ctrl = {};
}).controller('AdminEditNotifCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "$q", "s4pDialogs", "s4pPageTitle", "adminEditNotificationService", function ($rootScope, $scope, $log, $http, $state, $timeout, $q, s4pDialogs, s4pPageTitle, adminEditNotificationService) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    adminEditNotificationService.ctrl = ctrl;

    ctrl.editNotifFormModel = {};

    // Function for adding/subtracting time difference from values which contain hour of the day (0-23)
    ctrl.currentTimezoneOffsetHours = 0 - new Date().getTimezoneOffset() / 60;
    ctrl.modifyHoursWithTimezone = function (setting, add) {

        if (parseInt(setting) > -1 && parseInt(setting) < 24) {

            setting = !!add ? setting + ctrl.currentTimezoneOffsetHours : setting - ctrl.currentTimezoneOffsetHours;
            setting = setting > 23 ? setting - 24 : setting;
            setting = setting < 0 ? setting + 24 : setting;

            return setting;
        } else {
            return null;
        }
    };

    // Load Item
    ctrl.loadItem = function () {

        ctrl.dataLoading = true;

        return $http.get(s4pSettings.apiUrl + '/notifications/' + $state.params.id).then(function (response) {

            ctrl.editNotifFormModel = response.data.record;

            // Default line breaks in templates to true
            if (ctrl.editNotifFormModel.emailTemplateReplaceLineBreaks === null) {
                ctrl.editNotifFormModel.emailTemplateReplaceLineBreaks = true;
            }

            if (ctrl.editNotifFormModel.emailBundleTemplateReplaceLineBreaks === null) {
                ctrl.editNotifFormModel.emailBundleTemplateReplaceLineBreaks = true;
            }

            // Select default on emailBundleMinutes
            ctrl.editNotifFormModel.emailBundleMinutes = ctrl.editNotifFormModel.emailBundleMinutes || "60";
            ctrl.editNotifFormModel.smsBundleMinutes = ctrl.editNotifFormModel.smsBundleMinutes || "60";

            // Transform fields with hours to account for time zone.
            ctrl.editNotifFormModel.emailBundleTime = ctrl.modifyHoursWithTimezone(ctrl.editNotifFormModel.emailBundleTime, true);
            ctrl.editNotifFormModel.smsBundleTime = ctrl.modifyHoursWithTimezone(ctrl.editNotifFormModel.smsBundleTime, true);
            ctrl.editNotifFormModel.smsStartTime = ctrl.modifyHoursWithTimezone(ctrl.editNotifFormModel.smsStartTime, true);
            ctrl.editNotifFormModel.smsEndTime = ctrl.modifyHoursWithTimezone(ctrl.editNotifFormModel.smsEndTime, true);

            s4pPageTitle.set(response.data.record.name);
            ctrl.dataLoading = false;
        });
    };

    // Load Tokens
    ctrl.loadTokens = function () {

        ctrl.tokensLoading = true;

        return $http.get(s4pSettings.apiUrl + '/notificationTokens', {
            params: {
                notificationId: $state.params.id
            }
        }).then(function (response) {

            ctrl.tokens = response.data.records || [];

            var defaultTokens = [{
                "key": "item-count",
                "singleOnly": false,
                "bundleOnly": true
            }, {
                "key": "items",
                "singleOnly": false,
                "bundleOnly": true
            }, {
                "key": "link",
                "singleOnly": false,
                "bundleOnly": false
            }, {
                "key": "recipient-first-name",
                "singleOnly": false,
                "bundleOnly": false
            }, {
                "key": "recipient-last-name",
                "singleOnly": false,
                "bundleOnly": false
            }, {
                "key": "recipient-email",
                "singleOnly": false,
                "bundleOnly": false
            }, {
                "key": "recipient-id",
                "singleOnly": false,
                "bundleOnly": false
            }];

            ctrl.tokens = ctrl.tokens.concat(defaultTokens);

            ctrl.tokensLoading = false;
        });
    };

    // Load Notification Headings
    ctrl.loadNotifHeadings = function () {

        ctrl.headingsLoading = true;

        return $http.get(s4pSettings.apiUrl + '/notificationHeadings').then(function (response) {
            ctrl.notificationHeadings = response.data.records;
            ctrl.headingsLoading = false;
        });
    };

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var newItem = {
            name: ctrl.editNotifFormModel.name,
            notificationHeadingId: ctrl.editNotifFormModel.notificationHeadingId,
            description: ctrl.editNotifFormModel.description

            // Send the patch request
        };$http.patch(s4pSettings.apiUrl + '/notifications/' + $state.params.id, newItem, {
            form: ctrl.editNotifForm
        }).then(function (response) {

            s4pDialogs.message({
                title: 'Saved',
                message: 'Your changes have been saved',
                buttonText: 'Continue'
            });
        });
    };

    // Is anything loading?
    ctrl.pageLoading = function () {
        return ctrl.dataLoading || ctrl.headingsLoading || ctrl.tokensLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.loadItem();
        ctrl.loadTokens();
        ctrl.loadNotifHeadings();

        // Set the tab if the state says so, otherwise choose the first tab
        if ($state.params.tabId) {
            ctrl.activeTab = $state.params.tabId;
        } else {
            ctrl.activeTab = 'details';
        }
    };

    init();
}]);
angular.module('s4p').controller('AdminEditNotificationEmailTabCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$q", "$timeout", "s4pDialogs", "adminEditNotificationService", function ($rootScope, $scope, $log, $http, $state, $q, $timeout, s4pDialogs, adminEditNotificationService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.parent = adminEditNotificationService.ctrl;

    // Get Email Master Templates
    ctrl.loadEmailMasters = function () {

        ctrl.emailMastersLoading = true;

        return $http.get(s4pSettings.apiUrl + '/emailMasters').then(function (response) {
            ctrl.emailMasters = response.data.records;
            ctrl.emailMastersLoading = false;
        });
    };

    // Email Form Submitted
    ctrl.submitForm = function () {

        var parentModel = ctrl.parent.editNotifFormModel;

        var newItem = {
            emailEnabled: parentModel.emailEnabled,
            emailAdminEnabled: parentModel.emailAdminEnabled,
            emailDefaultOn: parentModel.emailDefaultOn,
            emailSubjectTemplate: parentModel.emailSubjectTemplate,
            emailTemplate: parentModel.emailTemplate,
            emailTemplateReplaceLineBreaks: parentModel.emailTemplateReplaceLineBreaks,
            emailTemplateMasterId: parentModel.emailTemplateMasterId,
            emailUrl: parentModel.emailUrl,
            emailBundle: parentModel.emailBundle,
            emailBundleDefaultOn: parentModel.emailBundleDefaultOn,
            emailBundleSubjectTemplate: parentModel.emailBundleSubjectTemplate,
            emailBundleTemplate: parentModel.emailBundleTemplate,
            emailBundleTemplateReplaceLineBreaks: parentModel.emailBundleTemplateReplaceLineBreaks,
            emailBundleTemplateMasterId: parentModel.emailBundleTemplateMasterId,
            emailBundleUrl: parentModel.emailBundleUrl,
            emailBundleMinutes: parentModel.emailBundleMinutes,
            emailBundleTime: parentModel.emailBundleTime

            // Transform fields with hours to account for time zone.
        };newItem.emailBundleTime = ctrl.parent.modifyHoursWithTimezone(newItem.emailBundleTime);

        // Send the patch request
        $http.patch(s4pSettings.apiUrl + '/notifications/' + $state.params.id, newItem, {
            form: ctrl.editNotifEmailForm
        }).then(function (response) {

            s4pDialogs.message({
                title: 'Saved',
                message: 'Your changes have been saved',
                buttonText: 'Continue'
            });
        });
    };

    // Are emails enabled?
    ctrl.enabled = function () {
        return ctrl.parent.editNotifFormModel.emailEnabled && ctrl.parent.editNotifFormModel.emailAdminEnabled;
    };

    // Is the tab loading?
    ctrl.tabLoading = function () {
        return ctrl.notificationLoading || ctrl.emailMastersLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.notificationLoading = true;

        ctrl.loadEmailMasters();

        // Load everything once the object has been loaded in the parent controller
        var parentLoadWatcher = $scope.$watch(function () {
            return ctrl.parent.editNotifFormModel;
        }, function (newValue, oldValue) {

            if (newValue !== undefined && Object.keys(newValue).length !== 0 && newValue.constructor === Object) {

                ctrl.notificationLoading = false;

                // Remove the watch
                parentLoadWatcher();
            }
        });
    };

    init();
}]);
angular.module('s4p').controller('AdminEditNotificationSmsTabCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$q", "$timeout", "s4pDialogs", "adminEditNotificationService", function ($rootScope, $scope, $log, $http, $state, $q, $timeout, s4pDialogs, adminEditNotificationService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.parent = adminEditNotificationService.ctrl;

    // SMS Form Submitted
    ctrl.submitForm = function () {

        var parentModel = ctrl.parent.editNotifFormModel;

        var newItem = {
            smsEnabled: parentModel.smsEnabled,
            smsAdminEnabled: parentModel.smsAdminEnabled,
            smsDefaultOn: parentModel.smsDefaultOn,
            smsTemplate: parentModel.smsTemplate,
            smsUrl: parentModel.smsUrl,
            smsBundle: parentModel.emailBundle,
            smsBundleDefaultOn: parentModel.smsBundleDefaultOn,
            smsBundleTemplate: parentModel.smsBundleTemplate,
            smsBundleUrl: parentModel.smsBundleUrl,
            smsBundleMinutes: parentModel.smsBundleMinutes,
            smsBundleTime: parseInt(parentModel.smsBundleTime),
            smsStartTime: parseInt(parentModel.smsStartTime),
            smsEndTime: parseInt(parentModel.smsEndTime),
            smsWeekdaysOnly: parentModel.smsWeekdaysOnly

            // Transform fields with hours to account for time zone.
        };newItem.smsBundleTime = ctrl.parent.modifyHoursWithTimezone(newItem.smsBundleTime);
        newItem.smsStartTime = ctrl.parent.modifyHoursWithTimezone(newItem.smsStartTime);
        newItem.smsEndTime = ctrl.parent.modifyHoursWithTimezone(newItem.smsEndTime);

        // Send the patch request
        $http.patch(s4pSettings.apiUrl + '/notifications/' + $state.params.id, newItem, {
            form: ctrl.editNotifSmsForm
        }).then(function (response) {

            s4pDialogs.message({
                title: 'Saved',
                message: 'Your changes have been saved',
                buttonText: 'Continue'
            });
        });
    };

    // Are SMSs enabled?
    ctrl.enabled = function () {
        return ctrl.parent.editNotifFormModel.smsEnabled && ctrl.parent.editNotifFormModel.smsAdminEnabled;
    };

    // Is the tab loading?
    ctrl.tabLoading = function () {
        return ctrl.parent.pageLoading();
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.notificationLoading = true;

        // Load everything once the object has been loaded in the parent controller
        var parentLoadWatcher = $scope.$watch(function () {
            return ctrl.parent.editNotifFormModel;
        }, function (newValue, oldValue) {

            if (newValue !== undefined && Object.keys(newValue).length !== 0 && newValue.constructor === Object) {

                ctrl.notificationLoading = false;

                // Remove the watch
                parentLoadWatcher();
            }
        });
    };

    init();
}]);
angular.module('s4p').controller('AdminEditNotificationTokensTabCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", "adminEditNotificationService", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs, adminEditNotificationService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.parent = adminEditNotificationService.ctrl;

    // Remove Items
    ctrl.removeItems = function () {

        if (ctrl.selectedRows && ctrl.selectedRows.length > 0) {

            ctrl.dataLoading = true;
            var counter = 0;

            ctrl.selectedRows.forEach(function (id) {

                counter++;

                $http.delete(s4pSettings.apiUrl + '/notificationTokens/' + id).then(function () {

                    counter--;

                    if (counter === 0) {
                        ctrl.parent.loadTokens();
                    }
                });
            });
        }
    };

    // Row clicked in table
    ctrl.rowClicked = function (evt, item) {
        if (!$(evt.target).is('input[type=checkbox]')) {

            $state.go('admin.notificationTokens.edit', {
                id: item.id,
                notificationId: $state.params.id
            });
        }
    };

    // Redirect to Add Token Page
    ctrl.addToken = function () {
        $state.go('admin.notificationTokens.add', {
            notificationId: $state.params.id
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.notificationLoading = true;

        // Load everything once the object has been loaded in the parent controller
        var parentLoadWatcher = $scope.$watch(function () {
            return ctrl.parent.editNotifFormModel;
        }, function (newValue, oldValue) {

            if (newValue !== undefined && Object.keys(newValue).length !== 0 && newValue.constructor === Object) {

                ctrl.notificationLoading = false;

                // Remove the watch
                parentLoadWatcher();
            }
        });
    };

    init();
}]);
angular.module('s4p').controller('AdminEditNotificationWebTabCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$q", "$timeout", "s4pDialogs", "adminEditNotificationService", function ($rootScope, $scope, $log, $http, $state, $q, $timeout, s4pDialogs, adminEditNotificationService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.parent = adminEditNotificationService.ctrl;

    // Web Form Submitted
    ctrl.submitForm = function () {

        var parentModel = ctrl.parent.editNotifFormModel;

        console.log("parentModel", parentModel);

        var newItem = {
            webEnabled: parentModel.webEnabled,
            webAdminEnabled: parentModel.webAdminEnabled,
            webDefaultOn: parentModel.webDefaultOn,
            webTemplate: parentModel.webTemplate,
            webLongTemplate: parentModel.webLongTemplate,
            webLongTemplateReplaceLineBreaks: parentModel.webLongTemplateReplaceLineBreaks,
            webIconName: parentModel.webIconName,
            webRoute: parentModel.webRoute,
            webRouteParams: parentModel.webRouteParams,
            webBundle: parentModel.webBundle,
            webBundleDefaultOn: parentModel.webBundleDefaultOn,
            webBundleTemplate: parentModel.webBundleTemplate,
            webBundleLongTemplate: parentModel.webBundleLongTemplate,
            webBundleLongTemplateReplaceLineBreaks: parentModel.webBundleLongTemplateReplaceLineBreaks,
            webBundleRoute: parentModel.webBundleRoute,
            webBundleRouteParams: parentModel.webBundleRouteParams

            // Send the patch request
        };$http.patch(s4pSettings.apiUrl + '/notifications/' + $state.params.id, newItem, {
            form: ctrl.editNotifWebForm
        }).then(function (response) {

            s4pDialogs.message({
                title: 'Saved',
                message: 'Your changes have been saved',
                buttonText: 'Continue'
            });
        });
    };

    // Are web notifications enabled?
    ctrl.enabled = function () {
        return ctrl.parent.editNotifFormModel.webEnabled && ctrl.parent.editNotifFormModel.webAdminEnabled;
    };

    // Is the tab loading?
    ctrl.tabLoading = function () {
        return ctrl.notificationLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.notificationLoading = true;

        // Load everything once the object has been loaded in the parent controller
        var parentLoadWatcher = $scope.$watch(function () {
            return ctrl.parent.editNotifFormModel;
        }, function (newValue, oldValue) {

            if (newValue !== undefined && Object.keys(newValue).length !== 0 && newValue.constructor === Object) {

                ctrl.notificationLoading = false;

                // Remove the watch
                parentLoadWatcher();
            }
        });
    };

    init();
}]);
angular.module('s4p').controller('AdminAddNotifTokenCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    ctrl.formModel = {};

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var newItem = angular.copy(ctrl.formModel);
        newItem.notificationId = $state.params.notificationId;

        $http.post(s4pSettings.apiUrl + '/notificationTokens', newItem, {
            form: ctrl.addForm
        }).then(function (response) {

            $state.go('admin.notifications.edit', {
                id: $state.params.notificationId,
                tabId: 'tokens'
            });
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {};

    init();
}]);
angular.module('s4p').controller('AdminEditNotifTokenCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", "s4pPageTitle", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs, s4pPageTitle) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    ctrl.formModel = {};

    // Load Item
    ctrl.loadItem = function () {

        ctrl.dataLoading = true;

        return $http.get(s4pSettings.apiUrl + '/notificationTokens/' + $state.params.id).then(function (response) {
            ctrl.formModel = response.data.record;
            ctrl.dataLoading = false;
            s4pPageTitle.set(response.data.record.key);
        });
    };

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var newItem = angular.copy(ctrl.formModel);

        // Send the patch request
        $http.patch(s4pSettings.apiUrl + '/notificationTokens/' + $state.params.id, newItem, {
            form: ctrl.editForm
        }).then(function (response) {

            $state.go('admin.notifications.edit', {
                id: $state.params.notificationId,
                tabId: 'tokens'
            });
        });
    };

    ctrl.pageLoading = function () {
        return ctrl.dataLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.loadItem();
    };

    init();
}]);
angular.module('s4p').controller('AdminAddPermissionGroupCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    ctrl.formModel = {};

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var newItem = angular.copy(ctrl.formModel);

        $http.post(s4pSettings.apiUrl + '/permissionGroups', newItem, {
            form: ctrl.groupForm
        }).then(function (response) {

            s4pDialogs.message({
                title: 'Group Added',
                message: 'You can now add permissions to this group.',
                buttonText: 'Continue'
            }).result.finally(function () {
                $state.go('admin.permissionGroups.edit', { id: response.data.record.id });
            });
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {};

    init();
}]);
angular.module('s4p').service('adminEditPermissionGroupService', function () {
    this.ctrl = {};
}).controller('AdminEditPermissionGroupCtrl', ["$rootScope", "$scope", "$log", "$http", "$timeout", "$state", "s4pDialogs", "s4pPageTitle", "adminEditPermissionGroupService", function ($rootScope, $scope, $log, $http, $timeout, $state, s4pDialogs, s4pPageTitle, adminEditPermissionGroupService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;
    adminEditPermissionGroupService.ctrl = ctrl;

    ctrl.group = {};
    ctrl.detailsFormModel = {};

    // Details form is submitted
    ctrl.submitDetailsForm = function ($event) {

        var newItem = angular.copy(ctrl.detailsFormModel);

        $http.patch(s4pSettings.apiUrl + '/permissionGroups/' + $state.params.id, newItem, {
            form: ctrl.groupForm
        }).then(function (response) {

            s4pDialogs.message({
                title: 'Saved Changes',
                message: 'Your changes have been saved',
                buttonText: 'Continue'
            });
        });
    };

    // Load Group
    ctrl.loadGroup = function () {

        $http.get(s4pSettings.apiUrl + '/permissionGroups/' + $state.params.id).then(function (response) {

            ctrl.group = response.data.record;
            ctrl.detailsFormModel = response.data.record;
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.loadGroup();
    };

    init();
}]);

angular.module('s4p').controller('AdminEditPermissionGroupPermissionsTabCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", "adminEditPermissionGroupService", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs, adminEditPermissionGroupService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.permissionFormModel = {};

    // Load Permissions
    ctrl.loadPermissions = function () {

        ctrl.dataLoading = true;

        var params = {
            groupId: adminEditPermissionGroupService.ctrl.group.id
        };

        $http.get(s4pSettings.apiUrl + '/permissions', { params: params }).then(function (response) {
            ctrl.tableData = response.data.records;
        });
    };

    // Remove Items
    ctrl.removeItems = function () {

        if (ctrl.selectedRows && ctrl.selectedRows.length > 0) {

            // Check that they definitely want to remove the permissions from this group
            s4pDialogs.confirm({
                title: 'Are you sure?',
                message: 'Do you want to remove these permissions from this group? If you do then make sure you reassign them to a different group',
                button1Text: 'Cancel',
                button2Text: 'Yes, remove them',
                button1Cs: 'accent',
                button2Cs: 'default',
                trueButton: 2
            }).result.then(function () {

                ctrl.dataLoading = true;
                var counter = 0;

                ctrl.selectedRows.forEach(function (id) {

                    counter++;

                    $http.patch(s4pSettings.apiUrl + '/permissions/' + id, {
                        permissionGroupId: 0
                    }).then(function () {

                        counter--;

                        if (counter === 0) {
                            ctrl.loadPermissions();
                        }
                    });
                });
            });
        }
    };

    // Permission clicked in table
    ctrl.permissionRowClicked = function (evt, item) {
        if (!$(evt.target).is('input[type=checkbox]')) {
            $state.go('admin.permissions.edit', {
                id: item.id
            });
        }
    };

    // Form is submitted
    ctrl.submitPermissionForm = function ($event) {

        var newItem = angular.copy(ctrl.permissionFormModel);
        newItem.permissionGroupId = adminEditPermissionGroupService.ctrl.group.id;

        $http.post(s4pSettings.apiUrl + '/permissions', newItem, {
            form: ctrl.permissionForm,
            resetFormOnSuccess: true
        }).then(function (response) {

            s4pDialogs.message({
                title: 'Permission Added',
                message: 'New permission has been added',
                buttonText: 'Continue'
            }).result.finally(function () {
                ctrl.permissionFormModel = {};
                ctrl.loadPermissions();
            });
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.dataLoading = true;
        ctrl.groupLoading = true;

        // Load permissions once the group has been loaded in the parent controller
        var groupLoadWatcher = $scope.$watch(function () {
            return adminEditPermissionGroupService.ctrl.group;
        }, function (newValue, oldValue) {

            if (newValue !== undefined && Object.keys(newValue).length !== 0 && newValue.constructor === Object) {

                ctrl.groupLoading = false;
                ctrl.loadPermissions();

                // Remove the watch
                groupLoadWatcher();
            }
        });
    };

    init();
}]);
angular.module('s4p').controller('AdminPermissionGroupsCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    // Load Permission Groups
    ctrl.loadData = function () {

        ctrl.dataLoading = true;

        var params = {};

        $http.get(s4pSettings.apiUrl + '/permissionGroups', { params: params }).then(function (response) {
            ctrl.tableData = response.data.records;
            ctrl.dataLoading = false;
        });
    };

    // Remove Items
    ctrl.removeItems = function () {

        if (ctrl.selectedRows && ctrl.selectedRows.length > 0) {

            // Check that they definitely want to delete the group
            s4pDialogs.confirm({
                title: 'Are you sure?',
                message: 'This will delete the groups you ticked. Please make sure you reassign any permissions in these groups to a new group.',
                button1Text: 'Cancel',
                button2Text: 'Delete Group',
                button1Cs: 'accent',
                button2Cs: 'default',
                trueButton: 2
            }).result.then(function () {

                ctrl.dataLoading = true;
                var counter = 0;

                ctrl.selectedRows.forEach(function (id) {

                    counter++;

                    $http.delete(s4pSettings.apiUrl + '/permissionGroups/' + id).then(function () {

                        counter--;

                        if (counter === 0) {
                            ctrl.loadData();
                        }
                    });
                });
            });
        }
    };

    // Permission clicked in table
    ctrl.permissionGroupRowClicked = function (evt, item) {
        if (!$(evt.target).is('input[type=checkbox]')) {
            $state.go('admin.permissionGroups.edit', {
                id: item.id
            });
        }
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        // Load first page
        ctrl.loadData();
    };

    init();
}]);
angular.module('s4p').controller('AdminAddRoleCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    ctrl.formModel = {};

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var newItem = angular.copy(ctrl.formModel);

        $http.post(s4pSettings.apiUrl + '/roles', newItem, {
            form: ctrl.groupForm
        }).then(function (response) {

            s4pDialogs.message({
                title: 'Role Added',
                message: 'You can now add permissions to this role and assign it to users.',
                buttonText: 'Continue'
            }).result.finally(function () {
                $state.go('admin.roles.edit', { id: response.data.record.id });
            });
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {};

    init();
}]);
angular.module('s4p').service('adminEditRoleService', function () {
    this.ctrl = {};
}).controller('AdminEditRoleCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "$q", "s4pDialogs", "s4pPageTitle", "adminEditRoleService", function ($rootScope, $scope, $log, $http, $state, $timeout, $q, s4pDialogs, s4pPageTitle, adminEditRoleService) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    adminEditRoleService.ctrl = ctrl;

    ctrl.formModel = {};
    ctrl.formMeta = {};

    // Load Role
    ctrl.loadRole = function () {

        return $http.get(s4pSettings.apiUrl + '/roles/' + $state.params.id).then(function (response) {
            ctrl.formModel = response.data.record;
            ctrl.role = response.data.record;
            s4pPageTitle.set(response.data.record.name);
        });
    };

    // Form is submitted
    ctrl.submitForm = function ($event) {

        var newItem = angular.copy(ctrl.formModel);

        // Send the patch request
        $http.patch(s4pSettings.apiUrl + '/roles/' + $state.params.id, newItem, {
            form: ctrl.detailsForm
        }).then(function (response) {

            s4pDialogs.message({
                title: 'Saved',
                message: 'Your changes have been saved',
                buttonText: 'Continue'
            });
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.metaLoading = true;

        // Load all details
        $q.all([ctrl.loadRole()]).then(function () {
            ctrl.metaLoading = false;
        });
    };

    init();
}]);
angular.module('s4p').controller('AdminEditRolePermissionsTabCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$q", "$timeout", "s4pDialogs", "adminEditRoleService", function ($rootScope, $scope, $log, $http, $state, $q, $timeout, s4pDialogs, adminEditRoleService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.permissionFormModel = {};

    // Load Permission Groups
    ctrl.loadPermissionGroups = function () {

        // Get the groups and permissions
        return $http.get(s4pSettings.apiUrl + '/permissionGroups', {
            params: {
                withPermissions: true
            }
        }).then(function (response) {

            ctrl.permissionGroups = response.data.records;

            // Format the groups and permissions for the view
            ctrl.permissionGroupsViewModel = [];

            ctrl.permissionGroups.forEach(function (group) {

                var newGroup = angular.copy(group);

                function findTablePermission(actionName) {

                    if (newGroup.permissions) {

                        var tablePermission = newGroup.permissions.filter(function (item) {
                            return item.action === actionName;
                        });

                        return tablePermission[0];
                    } else {
                        return false;
                    }
                }

                newGroup.viewPermission = findTablePermission('view');
                newGroup.addPermission = findTablePermission('add');
                newGroup.editPermission = findTablePermission('edit');
                newGroup.deletePermission = findTablePermission('delete');

                newGroup.customPermissions = [];

                newGroup.permissions.forEach(function (item) {
                    if (!item.action || item.action === 'custom') {
                        newGroup.customPermissions.push(item);
                    }
                });

                delete newGroup.permissions;

                ctrl.permissionGroupsViewModel.push(newGroup);
            });
        });
    };

    // Load Role Permissions
    ctrl.loadRolePermissions = function () {

        return $http.get(s4pSettings.apiUrl + '/rolePermissions', {
            params: {
                roleId: adminEditRoleService.ctrl.role.id
            }
        }).then(function (response) {

            ctrl.rolePermissions = response.data.records;

            // Fill the permissionSet object for the view
            ctrl.rolePermissions.forEach(function (item) {
                ctrl.permissionsSet[item.permission.name] = true;
            });
        });
    };

    // Checkbox Changed
    ctrl.checkboxChanged = function (permission, value) {

        var action = ctrl.permissionsSet[permission.name] && ctrl.permissionsSet[permission.name] === true ? 'post' : 'delete';

        var changeObject = {
            permissionId: permission.id,
            action: action

            // Remove any previous pending changes for this permission
        };ctrl.pendingChanges = ctrl.pendingChanges.filter(function (item) {
            return item.permissionId !== changeObject.permissionId;
        });

        // Add new change
        ctrl.pendingChanges.push(changeObject);
    };

    // Save Changes
    ctrl.saveChanges = function () {

        var updatesObject = {
            roleId: adminEditRoleService.ctrl.role.id,
            updates: angular.copy(ctrl.pendingChanges)
        };

        if (ctrl.pendingChanges && ctrl.pendingChanges.length > 0) {

            return $http.post(s4pSettings.apiUrl + '/rolePermissions/multiUpdate', updatesObject).then(function (response) {

                // Reset Pending Changes and form
                ctrl.pendingChanges = [];
                ctrl.rolePermissionsForm.$resetForm();

                // Confirm Dialog
                s4pDialogs.confirm({
                    title: 'All done',
                    message: 'Your changes have been saved.',
                    button1Text: 'Back to Roles',
                    button2Text: 'Continue Editing',
                    button1Cs: 'accent',
                    button2Cs: 'default',
                    trueButton: 2
                }).result.then(function () {
                    // Do nothing if continuing
                }, function () {
                    $state.go('admin.roles');
                });
            });
        } else {

            // No changes to save
            s4pDialogs.message({
                title: 'No changes',
                message: 'You haven\'t made any changes, so none were saved.',
                buttonText: 'Continue'
            });

            ctrl.rolePermissionsForm.$resetForm();

            return $.when();
        }
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.dataLoading = true;

        // Load permissions once the role has been loaded in the parent controller
        var roleLoadWatcher = $scope.$watch(function () {
            return adminEditRoleService.ctrl.role;
        }, function (newValue, oldValue) {

            if (newValue !== undefined && Object.keys(newValue).length !== 0 && newValue.constructor === Object) {

                // Load Permission groups/permissions and rolePermissions
                $q.all([ctrl.loadPermissionGroups(), ctrl.loadRolePermissions()]).then(function () {
                    ctrl.dataLoading = false;
                });

                // Set up empty change object
                ctrl.pendingChanges = [];

                // Set up empty object to store which permissions are set
                ctrl.permissionsSet = {};

                // Remove the watch
                roleLoadWatcher();
            }
        });
    };

    init();
}]);
angular.module('s4p').controller('AdminEditRoleUsersTabCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", "adminEditRoleService", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs, adminEditRoleService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.formModel = {};

    // Load Users
    ctrl.loadUsers = function () {

        ctrl.dataLoading = true;

        var params = {

            start: ctrl.pagingModel.start,
            limit: ctrl.pagingModel.itemsPerPage,
            roleId: adminEditRoleService.ctrl.role.id
        };

        $http.get(s4pSettings.apiUrl + '/userRoles', { params: params }).then(function (response) {
            ctrl.pagingModel.totalItems = response.data.meta.totalCount;
            ctrl.pagingModel.totalPages = Math.ceil(ctrl.pagingModel.totalItems / ctrl.pagingModel.itemsPerPage);
            ctrl.tableData = response.data.records;
        });
    };

    // Paging clicked
    ctrl.tablePageChanged = function () {
        ctrl.pagingModel.start = (ctrl.pagingModel.currentPage - 1) * ctrl.pagingModel.itemsPerPage + 1 + ctrl.pagingModel.offset;
        ctrl.loadUsers();
    };

    // Remove Items
    ctrl.removeItems = function () {

        if (ctrl.selectedRows && ctrl.selectedRows.length > 0) {

            ctrl.dataLoading = true;
            var counter = 0;

            ctrl.selectedRows.forEach(function (id) {

                counter++;

                $http.delete(s4pSettings.apiUrl + '/userRoles/' + id).then(function () {

                    counter--;

                    if (counter === 0) {
                        ctrl.loadUsers();
                    }
                });
            });
        }
    };

    // Row clicked in table
    ctrl.rowClicked = function (evt, item) {
        if (!$(evt.target).is('input[type=checkbox]')) {
            $state.go('admin.users.edit', {
                id: item.user.id
            });
        }
    };

    // Get users for add a user typeahead
    ctrl.userSearch = function (viewValue) {

        ctrl.userSearchLoading = true;

        return $http.get(s4pSettings.apiUrl + '/users', {
            params: {
                keywords: viewValue
            }
        }).then(function (response) {
            ctrl.userSearchLoading = false;
            return response.data.records;
        });
    };

    // Add user form submitted
    ctrl.submitForm = function (viewValue) {

        if (!ctrl.formModel.user) {
            ctrl.addUserForm.$resetForm();
            return;
        }

        // Get any matching userRole which already exist
        $http.get(s4pSettings.apiUrl + '/userRoles', {
            params: {
                roleId: adminEditRoleService.ctrl.role.id,
                userId: ctrl.formModel.user.id
            }
        }).then(function (response) {

            // Check that user isn't already in role
            if (response.data.records.length > 0) {

                s4pDialogs.message({
                    title: 'User in Role',
                    message: ctrl.formModel.user.firstName + ' has already been assigned to this role. No changes were made.',
                    buttonText: 'Continue'
                });
            } else {

                var newItem = {
                    roleId: adminEditRoleService.ctrl.role.id,
                    userId: ctrl.formModel.user.id
                };

                $http.post(s4pSettings.apiUrl + '/userRoles', newItem, {
                    form: ctrl.addUserForm,
                    resetFormOnSuccess: true
                }).then(function (response) {

                    ctrl.loadUsers();

                    s4pDialogs.message({
                        title: 'User Assigned',
                        message: 'The user has been assigned to this role.',
                        buttonText: 'Continue'
                    });
                });
            }
        }).finally(function () {
            ctrl.formModel = {};
            ctrl.addUserForm.$resetForm();
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.roleLoading = true;

        // Set up pagingModel
        ctrl.pagingModel = {
            totalItems: 0,
            itemsPerPage: 50,
            pagesToShow: 5,
            currentPage: 1,
            offset: 0,
            start: 1
        };

        // Load everything once the role has been loaded in the parent controller
        var roleLoadWatcher = $scope.$watch(function () {
            return adminEditRoleService.ctrl.role;
        }, function (newValue, oldValue) {

            if (newValue !== undefined && Object.keys(newValue).length !== 0 && newValue.constructor === Object) {

                ctrl.dataLoading = true;
                ctrl.roleLoading = false;

                // Load first page
                ctrl.loadUsers();

                // Remove the watch
                roleLoadWatcher();
            }
        });
    };

    init();
}]);
angular.module('s4p').controller('AdminRolesCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "s4pDialogs", function ($rootScope, $scope, $log, $http, $state, $timeout, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    // Load Records
    ctrl.loadRecords = function () {

        ctrl.dataLoading = true;

        var params = {};

        $http.get(s4pSettings.apiUrl + '/roles', { params: params }).then(function (response) {
            ctrl.tableData = response.data.records;
        });
    };

    // Remove Items
    ctrl.removeItems = function () {

        if (ctrl.selectedRows && ctrl.selectedRows.length > 0) {

            // Check that they definitely want to delete the itemsPerPage
            s4pDialogs.confirm({
                title: 'Are you sure?',
                message: 'If you remove this role, all users will be unassigned from it.',
                button1Text: 'Cancel',
                button2Text: 'Delete Role',
                button1Cs: 'accent',
                button2Cs: 'default',
                trueButton: 2
            }).result.then(function () {

                ctrl.dataLoading = true;
                var counter = 0;

                ctrl.selectedRows.forEach(function (id) {

                    counter++;

                    $http.delete(s4pSettings.apiUrl + '/roles/' + id).then(function () {

                        counter--;

                        if (counter === 0) {
                            ctrl.loadRecords();
                        }
                    });
                });
            });
        }
    };

    // Table Row Clicked
    ctrl.tableRowClicked = function (evt, item) {
        if (!$(evt.target).is('input[type=checkbox]')) {
            $state.go('admin.roles.edit', {
                id: item.id
            });
        }
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        // Load first page
        ctrl.loadRecords();
    };

    init();
}]);
angular.module('s4p').controller('LoginRegisterModalCtrl', ["$rootScope", "$scope", "$log", "$http", "$location", "$state", "$stateParams", "$uibModalInstance", "six4Auth", function ($rootScope, $scope, $log, $http, $location, $state, $stateParams, $uibModalInstance, six4Auth) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;

    // Dismiss Modal
    ctrl.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    // Submit Login Function
    ctrl.submitLoginForm = function ($event) {

        var loginDetails = angular.copy(ctrl.loginFormModel);

        loginDetails.site = portalSettings.site;

        $http.post(s4pSettings.apiUrl + '/login', loginDetails, {
            attachToken: false,
            form: ctrl.loginForm
        }).then(function (response) {

            if (response.data.success === true) {

                // We only need to close the modal here because the six4Auth request interceptor
                // will already have grabbed the token
                $uibModalInstance.close();
            } else {
                $log.error("Sorry, there was a problem logging in but no error code was returned");
            }
        });
    };

    // Cancel Reset Password
    ctrl.resetPasswordCancel = function () {
        ctrl.panel = "loginForm";
    };

    // Check Verification code in URL
    ctrl.checkCodeFromUrl = function () {

        console.log("Checking URL codes");

        ctrl.panel = 'resetPasswordLoading';

        // Check the code
        var url = s4pSettings.apiUrl + '/users/resetPasswordCheckCode';

        $http.get(url, {
            attachToken: false,
            params: {
                site: portalSettings.site,
                email: ctrl.urlParamEmail,
                verificationCode: ctrl.urlParamVerificationCode
            }
        }).then(function (response) {
            ctrl.openResetPasswordPanel();
        }, function (rejection) {
            ctrl.openInvalidResetCodePanel();
        });
    };

    // Open Reset Code Request Panel
    ctrl.openResetCodeRequestPanel = function () {
        ctrl.requestCodeFormModel = {
            email: ctrl.resetPasswordEmail || ctrl.urlParamEmail
        };
        ctrl.panel = "requestCode";
    };

    // Submit Request Verification Code Form
    ctrl.submitRequestCodeForm = function () {

        var url = s4pSettings.apiUrl + '/users/resetPasswordRequest';

        $http.get(url, {
            attachToken: false,
            form: ctrl.requestCodeForm,
            params: {
                site: portalSettings.site,
                email: ctrl.requestCodeFormModel.email
            }
        }).then(function (response) {
            ctrl.resetPasswordEmail = angular.copy(ctrl.requestCodeFormModel.email);
            ctrl.enterCodeFormModel = {};
            ctrl.openEnterCodePanel();
        });
    };

    // Open Enter Verification Code Panel
    ctrl.openEnterCodePanel = function () {
        ctrl.panel = "enterCode";
    };

    // Submit Enter Verification Code Form
    ctrl.submitEnterCodeForm = function ($event) {

        var url = s4pSettings.apiUrl + '/users/resetPasswordCheckCode';

        $http.get(url, {
            attachToken: false,
            form: ctrl.enterCodeForm,
            params: {
                site: portalSettings.site,
                email: ctrl.resetPasswordEmail,
                verificationCode: ctrl.enterCodeFormModel.verificationCode
            }
        }).then(function (response) {
            ctrl.resetPasswordVerificationCode = angular.copy(ctrl.enterCodeFormModel.verificationCode);
            ctrl.resetPasswordFormModel = {};

            // TODO - Swap these round
            ctrl.openResetPasswordPanel();
        }, function (rejection) {
            ctrl.openInvalidResetCodePanel();
        });
    };

    // Open Invalid Reset Code Panel
    ctrl.openInvalidResetCodePanel = function () {
        ctrl.panel = "invalidResetCode";
    };

    // Open Reset Password Form Panel
    ctrl.openResetPasswordPanel = function () {
        ctrl.panel = "resetPassword";
    };

    // Submit Reset Password Form
    ctrl.submitResetPasswordForm = function ($event) {

        console.log("Submitting reset form");

        var url = s4pSettings.apiUrl + '/users/resetPassword';

        // Use verification code typed in, or from the URL, whichever exists
        var email = ctrl.resetPasswordEmail || ctrl.urlParamEmail;
        var verificationCode = ctrl.resetPasswordVerificationCode || ctrl.urlParamVerificationCode;

        var data = angular.copy(ctrl.resetPasswordFormModel);

        $http.post(url, data, {
            attachToken: false,
            form: ctrl.resetPasswordForm,
            params: {
                site: portalSettings.site,
                email: email,
                verificationCode: verificationCode
            }
        }).then(function (response) {
            ctrl.openResetPasswordSuccessPanel();
        });
    };

    // Open Reset Password Success Panel
    ctrl.openResetPasswordSuccessPanel = function () {
        ctrl.panel = "resetPasswordSuccess";
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.panel = "loginForm";

        ctrl.urlParamResetPassword = $location.$$search.resetpassword || $location.$$search.resetPassword;
        ctrl.urlParamEmail = $location.$$search.resetpasswordemail || $location.$$search.email;
        ctrl.urlParamVerificationCode = $location.$$search.verificationcode || $location.$$search.verificationCode;

        // Check to see if we need to open the reset password panel
        if (portalSettings.users && portalSettings.users.resetPassword && ctrl.urlParamResetPassword === 'true' && ctrl.urlParamEmail !== undefined && ctrl.urlParamVerificationCode !== undefined) {
            ctrl.checkCodeFromUrl();
        }
    };

    init();
}]);

angular.module('s4p').controller('MasterCtrl', ["$rootScope", "$scope", "$log", function ($rootScope, $scope, $log) {

    'use strict';

    var master = $rootScope.master = this;

    ///////////////////////////////////////////////////////////////
    //  Initialize Master
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        // console.log("Master Contoller Running");

    };

    init();
}]);
angular.module('s4p').controller('AddDataCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$interpolate", "$q", "s4pDialogs", "s4pData", function ($rootScope, $scope, $log, $http, $state, $interpolate, $q, s4pDialogs, s4pData) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.tableName = $state.params.tableName;

    // Load Page Config
    ctrl.loadPageConfig = function () {

        // Get page config
        return s4pData.getAddPageConfig(ctrl.tableName).then(function (config) {
            ctrl.pageConfig = config;
        });
    };

    // Data Saved
    ctrl.dataSaved = function (newItem) {

        // Redirect Function
        function doRedirect() {

            var url, params;

            if (ctrl.pageConfig.redirectAfterSaveRoute) {
                url = ctrl.pageConfig.redirectAfterSaveRoute;
                params = ctrl.pageConfig.redirectAfterSaveRouteParams || {};
            } else {
                url = 'data.table.edit';
                params = { id: 'item.id' };
            }

            var data = { item: newItem };

            for (var prop in params) {
                if (params.hasOwnProperty(prop)) {
                    params[prop] = $interpolate('{{' + params[prop] + '}}')(data);
                }
            }

            $state.go(url, params);
        };

        // Show confirmation message?
        if (ctrl.pageConfig.showSavedDialog === undefined || ctrl.pageConfig.showSavedDialog === true) {

            s4pDialogs.message({
                title: 'Saved',
                message: ctrl.pageConfig.savedDialogMessage || 'Record has been created. Click continue to edit.',
                buttonText: 'Continue'
            }).result.finally(function () {

                // Do Redirect?
                if (ctrl.pageConfig.redirectAfterSave === undefined || ctrl.pageConfig.redirectAfterSave === true) {
                    doRedirect();
                }
            });
        } else {

            // Do Redirect?
            if (ctrl.pageConfig.redirectAfterSave === undefined || ctrl.pageConfig.redirectAfterSave === true) {
                doRedirect();
            }
        }
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.loadPageConfig();
    };

    init();
}]);
angular.module('s4p').controller('EditDataCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$interpolate", "$q", "$timeout", "s4pDialogs", "s4pData", "s4pPageTitle", "s4pSaveChanges", function ($rootScope, $scope, $log, $http, $state, $interpolate, $q, $timeout, s4pDialogs, s4pData, s4pPageTitle, s4pSaveChanges) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.tableName = $state.params.tableName;

    // Load Page Config
    ctrl.loadPageConfig = function () {

        // Get page config
        return s4pData.getEditPageConfig(ctrl.tableName).then(function (config) {
            ctrl.pageConfig = config;
        });
    };

    // Back
    ctrl.back = function (newItem) {

        // TODO - Go Back

    };

    // Get Title
    ctrl.getTitle = function () {
        if (ctrl.formLoading) {
            return 'Loading...';
        } else {
            return ctrl.formModel.name || 'Edit ' + ctrl.pageConfig.tableFriendlyNameSingular;
        }
    };

    // Go to list page
    ctrl.goToListPage = function () {

        var url, params;

        if (ctrl.pageConfig.listButtonRoute) {
            url = ctrl.pageConfig.listButtonRoute;
            params = ctrl.pageConfig.listButtonRouteParams || {};
        } else {
            url = 'data.table';
            params = { tableName: 'tableName' };
        }

        var route = ctrl.pageConfig.listButtonRoute || 'data.table';
        var data = { tableName: ctrl.tableName };
        var routeParams = ctrl.pageConfig.listButtonRouteParams || { tableName: ctrl.tableName };

        $state.go(route, routeParams);
    };

    // Data Saved
    ctrl.dataSaved = function (newItem) {

        // Redirect Function
        function doRedirect() {

            var url, params;

            if (ctrl.pageConfig.redirectAfterSaveRoute) {
                url = ctrl.pageConfig.redirectAfterSaveRoute;
                params = ctrl.pageConfig.redirectAfterSaveRouteParams || {};
            } else {
                url = 'data.table';
                params = { tableName: 'tableName' };
            }

            var data = { tableName: ctrl.tableName, item: newItem };

            for (var prop in params) {
                if (params.hasOwnProperty(prop)) {
                    params[prop] = $interpolate('{{' + params[prop] + '}}')(data);
                }
            }

            $state.go(url, params);
        };

        // Show confirmation message?
        if (ctrl.pageConfig.showSavedDialog === undefined || ctrl.pageConfig.showSavedDialog === true) {

            s4pDialogs.message({
                title: 'Saved',
                message: ctrl.pageConfig.savedDialogMessage || 'Your changes have been saved.',
                buttonText: 'Continue'
            }).result.finally(function () {

                // Do Redirect?
                if (ctrl.pageConfig.redirectAfterSave) {
                    doRedirect();
                }
            });
        } else {

            // Do Redirect?
            if (ctrl.pageConfig.redirectAfterSave) {
                doRedirect();
            }
        }
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.loadPageConfig();

        ctrl.recordId = $state.params.id;
        ctrl.formModel = {};
        ctrl.pageConfig = {};
    };

    init();
}]);

angular.module('s4p').controller('ViewDataCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$q", "s4pData", function ($rootScope, $scope, $log, $http, $state, $q, s4pData) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.tableName = $state.params.tableName;

    // Load Page Config
    ctrl.loadPageConfig = function () {

        // Get page config
        return s4pData.getViewPageConfig(ctrl.tableName).then(function (config) {
            ctrl.pageConfig = config;
        });
    };

    // Go to add page
    ctrl.goToAddPage = function () {

        var url = ctrl.pageConfig.addButtonRoute || 'data.table';
        var params = ctrl.pageConfig.addButtonRouteParams || { tableName: 'tableName' };

        $state.go(url, params);
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.loadPageConfig();
    };

    init();
}]);
angular.module('s4p').controller('MediaLibraryModalCtrl', ["$rootScope", "$scope", "$log", "$http", "$q", "$timeout", "$uibModalInstance", "s4pDialogs", "six4Utils", "modalSettings", function ($rootScope, $scope, $log, $http, $q, $timeout, $uibModalInstance, s4pDialogs, six4Utils, modalSettings) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    // Dismiss Modal
    ctrl.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    ////////////////////////////////////////////////
    //              Files & Folders               //
    ////////////////////////////////////////////////

    // Load Files and Folders
    ctrl.loadFilesFolders = function () {

        ctrl.filesFoldersLoading = true;

        var params = {
            mediaLibraryFolderId: ctrl.folderId || 0,
            start: ctrl.pagingModel.start,
            limit: ctrl.pagingModel.itemsPerPage,
            orderBy: ctrl.orderBy || 'default',
            orderDirection: ctrl.orderDirection || 'asc'
        };

        if (ctrl.keywords) {
            params.keywords = ctrl.keywords;
        }

        return $http.get(s4pSettings.apiUrl + '/mediaLibrary/folderAndFiles', {
            params: params
        }).then(function (response) {

            // Files & Folders
            ctrl.filesFolders = response.data.records.map(function (item) {
                item.guid = six4Utils.getUid();
                return item;
            });

            ctrl.breadcrumb = response.data.meta.breadCrumbItems || [];
            ctrl.breadcrumb.splice(0, 0, {
                id: 0,
                name: 'Home'
            });

            // Paging
            ctrl.pagingModel.totalItems = response.data.meta.totalCount;
            ctrl.pagingModel.totalPages = Math.ceil(ctrl.pagingModel.totalItems / ctrl.pagingModel.itemsPerPage);
            ctrl.scrollToTop();

            ctrl.filesFoldersLoading = false;
        });
    };

    // Change Folder
    ctrl.changeFolder = function (id) {
        ctrl.folderId = id;
        ctrl.resetPaging();
        ctrl.loadFilesFolders();
    };

    // Sort Order Changed
    ctrl.filesFoldersOrderChanged = function () {

        switch (ctrl.filesFoldersOrder) {

            case 'default':
                ctrl.orderBy = 'default';
                ctrl.orderDirection = 'asc';
                break;

            case 'az':
                ctrl.orderBy = 'alpha';
                ctrl.orderDirection = 'asc';
                break;

            case 'za':
                ctrl.orderBy = 'alpha';
                ctrl.orderDirection = 'desc';
                break;

            case 'recentlyModified':
                ctrl.orderBy = 'modifiedDate';
                ctrl.orderDirection = 'desc';
                break;

            case 'filesizedesc':
                ctrl.orderBy = 'fileSize';
                ctrl.orderDirection = 'desc';
                break;

            case 'filesizeasc':
                ctrl.orderBy = 'fileSize';
                ctrl.orderDirection = 'asc';
                break;

            case 'widthdesc':
                ctrl.orderBy = 'width';
                ctrl.orderDirection = 'desc';
                break;

            case 'widthasc':
                ctrl.orderBy = 'width';
                ctrl.orderDirection = 'asc';
                break;

        }

        $rootScope.$broadcast('close-all-toolbar-overflows');
        ctrl.resetPaging();
        ctrl.loadFilesFolders();
    };

    // Do Search
    ctrl.doSearch = function () {
        $rootScope.$broadcast('close-all-toolbar-overflows');
        ctrl.keywords = ctrl.toolbarSearchFormModel.keywords;
        ctrl.resetPaging();
        ctrl.loadFilesFolders();
    };

    // Get File URL
    ctrl.getFileUrl = function (fileFolder) {

        fileFolder = ctrl.getFileFolderFromGuid(fileFolder.guid);

        s4pDialogs.message({
            title: 'File URL',
            message: portalSettings.filesUrl + fileFolder.file.url,
            buttonText: 'Close'
        });
    };

    // Paging clicked
    ctrl.pageChanged = function () {
        ctrl.pagingModel.start = (ctrl.pagingModel.currentPage - 1) * ctrl.pagingModel.itemsPerPage + 1 + ctrl.pagingModel.offset;
        ctrl.loadFilesFolders();
    };

    // Reset Paging
    ctrl.resetPaging = function () {
        ctrl.pagingModel = {
            totalItems: 0,
            itemsPerPage: 10,
            pagesToShow: 5,
            currentPage: 1,
            offset: 0,
            start: 1
        };
    };

    // Scroll to top
    ctrl.scrollToTop = function () {
        document.querySelectorAll('[view-medialibrary-main]').forEach(function (item) {
            item.scrollTop = 0;
        });
    };

    // File or folder clicked
    ctrl.fileFolderClicked = function (fileFolder) {

        fileFolder = ctrl.getFileFolderFromGuid(fileFolder.guid);

        if (fileFolder.type === 'folder') {
            ctrl.changeFolder(fileFolder.id);
        } else if (fileFolder.type === 'file') {

            // Toggle the current card selection
            fileFolder.selected = !fileFolder.selected;

            // De-select all other cards if we're in single mode
            if (!modalSettings.multipleSelections) {
                ctrl.filesFolders.forEach(function (item) {
                    if (!(item.guid === fileFolder.guid)) {
                        item.selected = false;
                    }
                });
            }

            // Get new selected file count
            ctrl.selectedFilesCount = ctrl.getSelectedFileCount();
        }
    };

    // Get Selected Files Count
    ctrl.getSelectedFileCount = function () {
        return ctrl.filesFolders.filter(function (item) {
            return item.selected;
        }).length;
    };

    // Close all card menus
    ctrl.closeCardMenus = function (excludeItem) {

        ctrl.filesFolders.forEach(function (item) {

            // If this isn't the item we're excluding
            if (!(excludeItem && item.guid === excludeItem.guid)) {
                item.animateMenu = false;
                $timeout(function () {
                    item.menuOpen = false;
                }, 200);
            }
        });
    };

    // Toggle Card Menu
    ctrl.toggleCardMenu = function (fileFolder, value) {

        fileFolder = ctrl.getFileFolderFromGuid(fileFolder.guid);

        // Close all other menus
        ctrl.closeCardMenus(fileFolder);

        var newState = value !== undefined ? value : !fileFolder.menuOpen;

        // If we're opening the item then set it to open and wait until it's shown before animating it in.
        if (newState) {
            fileFolder.menuOpen = true;
            $timeout(function () {
                fileFolder.animateMenu = true;
            }, 10);
        }

        // If we're switching it off then animate it out and wait for it to finish before removing it.
        else {
                fileFolder.animateMenu = false;
                $timeout(function () {
                    fileFolder.menuOpen = false;
                }, 200);
            }
    };

    // Get File or Folder from ID
    ctrl.getFileFolderFromGuid = function (fileFolderGuid) {
        return ctrl.filesFolders.filter(function (item) {
            return item.guid === fileFolderGuid;
        })[0];
    };

    // Submit File Selecitons
    ctrl.submitFileSelection = function () {

        var selectedFiles = ctrl.filesFolders.filter(function (item) {
            return item.selected;
        });

        if (modalSettings.multipleSelections) {
            $uibModalInstance.close(selectedFiles);
        } else {
            $uibModalInstance.close(selectedFiles[0]);
        }
    };

    ////////////////////////////////////////////////
    //                 Add Folder                 //
    ////////////////////////////////////////////////


    // Open Add Folder Panel
    ctrl.openFolderAdd = function () {
        ctrl.mode = 'addFolder';
        ctrl.addFolderFormModel = {};
    };

    // Add Folder Form Submitted
    ctrl.submitAddFolderForm = function () {

        var newItem = {
            name: ctrl.addFolderFormModel.name,
            parentMediaLibraryFolderId: ctrl.folderId
        };

        return $http.post(s4pSettings.apiUrl + '/mediaLibraryFolders', newItem, {
            form: ctrl.addFolderForm
        }).then(function (response) {
            ctrl.folderId = response.data.record.id;
            ctrl.mode = 'folder';
            ctrl.loadFilesFolders();
        });
    };

    ////////////////////////////////////////////////
    //                Edit Folder                 //
    ////////////////////////////////////////////////

    // Open Edit Folder Panel
    ctrl.openFolderEdit = function (fileFolder) {

        ctrl.mode = 'editFolder';
        ctrl.editFolderFormModel = {};
        ctrl.folderEditLoading = true;

        var folderId = ctrl.getFileFolderFromGuid(fileFolder.guid).id;

        return $http.get(s4pSettings.apiUrl + '/mediaLibraryFolders/' + folderId).then(function (response) {
            ctrl.editFolderFormModel = {
                id: response.data.record.id,
                name: response.data.record.name
            };
            ctrl.folderEditLoading = false;
        });
    };

    // Edit Folder Form Submitted
    ctrl.submitEditFolderForm = function () {

        var newItem = angular.copy(ctrl.editFolderFormModel);

        // Send the patch request
        return $http.patch(s4pSettings.apiUrl + '/mediaLibraryFolders/' + newItem.id, newItem, {
            form: ctrl.editFolderForm
        }).then(function (response) {
            ctrl.mode = 'folder';
            ctrl.loadFilesFolders();
        });
    };

    ////////////////////////////////////////////////
    //               Archive Folder               //
    ////////////////////////////////////////////////

    // Archive Folder
    ctrl.archiveFolder = function (fileFolder) {

        var newItem = {
            archived: true

            // Send the patch request
        };return $http.patch(s4pSettings.apiUrl + '/mediaLibraryFolders/' + fileFolder.id, newItem).then(function (response) {
            ctrl.mode = 'folder';
            ctrl.loadFilesFolders();
        });
    };

    // Un-Archive Folder
    ctrl.unArchiveFolder = function (fileFolder) {

        var newItem = {
            archived: false

            // Send the patch request
        };return $http.patch(s4pSettings.apiUrl + '/mediaLibraryFolders/' + fileFolder.id, newItem).then(function (response) {
            ctrl.mode = 'folder';
            ctrl.loadFilesFolders();
        });
    };

    ////////////////////////////////////////////////
    //                 Add Files                  //
    ////////////////////////////////////////////////

    // Open Add Files Panel
    ctrl.openFilesAdd = function () {

        ctrl.mode = 'addFiles';

        ctrl.addFilesFormModel = {
            newFiles: []
        };
    };

    // Add Files Form Submitted
    ctrl.submitAddFilesForm = function () {

        var params = {
            mediaLibraryFolderId: ctrl.folderId
        };

        var item = {
            newFiles: angular.copy(ctrl.addFilesFormModel.newFiles)
        };

        console.log("item", angular.copy(item));

        // Make sure newItems is an array even if it's a single file'
        if (!Array.isArray(item.newFiles)) {
            item.newFiles = [item.newFiles];
        }

        return $http.post(s4pSettings.apiUrl + '/mediaLibrary/createFiles', item, {
            params: params
        }).then(function (response) {
            ctrl.mode = 'folder';
            ctrl.loadFilesFolders();
        });
    };

    ////////////////////////////////////////////////
    //                 Edit Files                 //
    ////////////////////////////////////////////////


    // Open Edit File Panel
    ctrl.openFileEdit = function (fileFolder) {
        ctrl.editFile = ctrl.getFileFolderFromGuid(fileFolder.guid);
        ctrl.editFileFormModel = ctrl.editFile;
        ctrl.mode = 'editFile';
    };

    // Edit File Form Submitted
    ctrl.submitEditFileForm = function () {

        var newItem = angular.copy(ctrl.editFileFormModel);
        newItem.fileId = newItem.newFile.id;

        delete newItem.guid;
        delete newItem.menuOpen;
        delete newItem.animateMenu;
        delete newItem.file;
        delete newItem.newFile;
        delete newItem.type;

        return $http.patch(s4pSettings.apiUrl + '/mediaLibraryFiles' + ctrl.newItem.id, newItem, {
            form: ctrl.editFileForm
        }).then(function (response) {
            ctrl.mode = 'folder';
            ctrl.loadFilesFolders();
        });
    };

    ////////////////////////////////////////////////
    //                Archive Files               //
    ////////////////////////////////////////////////

    ctrl.archiveFiles = function (fileFolder) {

        var filesToDelete;

        // Create string to supply in parameters
        if (fileFolder === 'selected') {

            filesToDelete = ctrl.filesFolders.filter(function (item) {
                return item.selected;
            }).map(function (item) {
                return item.id;
            }).toString();
        } else if (fileFolder.id) {
            filesToDelete = fileFolder.id;
        }

        // Send the patch request
        return $http.patch(s4pSettings.apiUrl + '/mediaLibrary/archiveFiles', {}, {
            params: {
                ids: filesToDelete
            }
        }).then(function (response) {
            ctrl.mode = 'folder';
            ctrl.resetPaging();
            ctrl.loadFilesFolders();
        });
    };

    // Un-Archive File
    ctrl.unArchiveFile = function (fileFile) {

        var newItem = {
            archived: false

            // Send the patch request
        };return $http.patch(s4pSettings.apiUrl + '/mediaLibraryFiles/' + fileFolder.id, newItem).then(function (response) {
            ctrl.mode = 'folder';
            ctrl.loadFilesFolders();
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        // Put in folder view model
        ctrl.mode = 'folder';

        // Reset search form
        ctrl.toolbarSearchFormModel = {};

        // Modal Settings
        ctrl.folderId = modalSettings.folderId || 0;

        // Set up pagingModel
        ctrl.resetPaging();

        // Load files and folders
        ctrl.loadFilesFolders();
    };

    init();
}]);

angular.module('s4p').controller('ChangePasswordModalCtrl', ["$rootScope", "$scope", "$log", "$http", "$uibModalInstance", "six4Auth", "s4pDialogs", function ($rootScope, $scope, $log, $http, $uibModalInstance, six4Auth, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;
    var changePasswordModal = this;
    changePasswordModal.changePasswordForm = {};

    // Dismiss Modal
    changePasswordModal.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    // Form is submitted
    changePasswordModal.submitForm = function ($event) {

        $http.post(s4pSettings.apiUrl + '/users/changepassword', changePasswordModal.changePasswordForm, {
            form: changePasswordModal.cpmF
        }).then(function (response) {

            s4pDialogs.message({
                title: 'All done',
                message: 'Your password has been changed.'
            });

            $uibModalInstance.close();
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {};

    init();
}]);
angular.module('s4p').controller('EditProfileModalCtrl', ["$rootScope", "$scope", "$log", "$http", "$uibModalInstance", "$injector", "six4Auth", "s4pDialogs", function ($rootScope, $scope, $log, $http, $uibModalInstance, $injector, six4Auth, s4pDialogs) {

    'use strict';

    // Inject translate if i18n is switched on

    var $translate, s4pTranslateService;
    if (portalSettings.i18n) {
        $translate = $injector.get('$translate');
        s4pTranslateService = $injector.get('s4pTranslateService');
    }

    var master = $rootScope.master;
    var ctrl = this;
    ctrl.editProfileForm = {};

    // Dismiss Modal
    ctrl.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    // Show Editable Field?
    ctrl.showEditableField = function (fieldName) {

        // Default is ON
        // If the setting exists and it's set to anything other than 'edit' then don't show it.
        if (portalSettings.users && portalSettings.users.editProfileForm && portalSettings.users.editProfileForm[fieldName] && portalSettings.users.editProfileForm[fieldName].mode !== 'edit') {
            return false;
        }

        return true;
    };

    // Show Viewable Field?
    ctrl.showViewableField = function (fieldName) {

        // Default is OFF
        // If the setting exists and it's set to 'view' then show it.
        if (portalSettings.users && portalSettings.users.editProfileForm && portalSettings.users.editProfileForm[fieldName] && portalSettings.users.editProfileForm[fieldName].mode === 'view') {
            return true;
        }

        return false;
    };

    // Edit form is submitted
    ctrl.submitForm = function ($event) {

        var newProfile = angular.copy(ctrl.editProfileForm);

        if (newProfile.profilePhoto) {
            newProfile.profilePhotoId = newProfile.profilePhoto.id;
        }

        delete newProfile.profilePhoto;

        $http.patch(s4pSettings.apiUrl + '/users/' + six4Auth.credentials().userId, newProfile).then(function (response) {

            if (portalSettings.i18n) {

                s4pDialogs.message({
                    title: s4pTranslateService.translate('All done', 'usr_profmod_svdmod_ttl'),
                    message: s4pTranslateService.translate('Your new details have been saved', 'usr_profmod_svdmod_msg'),
                    buttonText: s4pTranslateService.translate('OK', 'usr_profmod_svdmod_continue')
                });

                $translate.use(newProfile.language);
            } else {
                s4pDialogs.message({
                    title: 'All done',
                    message: 'Your new details have been saved',
                    buttonText: 'OK'
                });
            }

            $uibModalInstance.close();
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        var credentials = six4Auth.credentials();
        ctrl.editProfileForm = angular.copy(credentials.userDetails);

        // Add old profile pic to form
        if (credentials.userDetails.profilePhoto) {
            ctrl.oldProfilePic = portalSettings.filesUrl + credentials.userDetails.profilePhoto.url;
        }

        // i18n
        if (portalSettings.i18n) {

            ctrl.editProfileForm.language = ctrl.editProfileForm.language || 'en';

            ctrl.languageOptions = [];
            var languages = portalSettings.i18n.languages;

            for (var key in languages) {
                if (!languages.hasOwnProperty(key)) continue;
                ctrl.languageOptions.push({
                    code: key,
                    name: languages[key].name
                });
            }
        }
    };

    init();
}]);
angular.module('s4p').controller('NotificationsCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "s4pNotifications", function ($rootScope, $scope, $log, $http, $state, s4pNotifications) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;

    // Load Notifications
    ctrl.loadNotifications = function () {

        ctrl.notificationsLoading = true;

        var params = {
            pagingStart: ctrl.pagingModel.start,
            pagingLimit: ctrl.pagingModel.itemsPerPage
        };

        s4pNotifications.loadNotifications(params).then(function (response) {

            // Transform new results
            ctrl.transformedNotifications = s4pNotifications.transformNotifications(response.data.records);

            // Paging
            ctrl.pagingModel.totalItems = response.data.meta.totalCount;
            ctrl.pagingModel.totalPages = Math.ceil(ctrl.pagingModel.totalItems / ctrl.pagingModel.itemsPerPage);

            // If we're on page one then mark everything as read
            if (ctrl.pagingModel.start === 1) {
                s4pNotifications.resetNotificationsLastSeen();
            }

            ctrl.notificationsLoading = false;
        });
    };

    // Notification is clicked
    ctrl.notificationClicked = function (notif) {

        // Update hte local notification to say it's been read.'
        notif.read = true;

        // Tell the API which notification has been read
        var newItem;

        if (notif.id > 0) {
            newItem = { webNotificationMessageId: notif.id };
        } else if (notif.bundleId > 0) {
            newItem = { webNotificationBundleId: notif.bundleId };
        }

        if (newItem !== {}) {
            $http.post(s4pSettings.apiUrl + '/webNotificationRead', newItem);
        }
    };

    // View Button Clicked
    ctrl.viewNotifiction = function (notif) {

        // Go to route
        var params = notif.routeParams ? JSON.parse(notif.routeParams) : {};
        $state.go(notif.route, params);
    };

    // Scroll Main View To Top
    ctrl.scrollMainViewToTop = function () {
        $('s4p-view-main').animate({ scrollTop: 0 }, 250);
    };

    // Paging clicked
    ctrl.tablePageChanged = function () {
        ctrl.pagingModel.start = (ctrl.pagingModel.currentPage - 1) * ctrl.pagingModel.itemsPerPage + 1 + ctrl.pagingModel.offset;
        ctrl.loadNotifications();
        ctrl.scrollMainViewToTop();
    };

    // Is the page currently loading?
    ctrl.pageLoading = function () {
        return ctrl.notificationsLoading;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        // Set up pagingModel
        ctrl.pagingModel = {
            totalItems: 0,
            itemsPerPage: 20,
            pagesToShow: 5,
            currentPage: 1,
            offset: 0,
            start: 1
        };

        // Load first page
        ctrl.loadNotifications();
    };

    init();
}]);
angular.module('s4p').service('userSettingsService', function () {
    this.ctrl = {};
}).controller('UserSettingsCtrl', ["$rootScope", "$scope", "$log", "$http", "$state", "$timeout", "$q", "s4pDialogs", "s4pPageTitle", "userSettingsService", function ($rootScope, $scope, $log, $http, $state, $timeout, $q, s4pDialogs, s4pPageTitle, userSettingsService) {

    'use strict';

    var master = $rootScope.master;

    var ctrl = this;
    userSettingsService.ctrl = ctrl;

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        // Set the tab if the state says so, otherwise choose the first tab
        if ($state.params.tabId) {
            ctrl.activeTab = $state.params.tabId;
        } else {
            ctrl.activeTab = 'general';
        }

        // Set something in the controller so that the children can watch for it
        ctrl.settings = {};
    };

    init();
}]);
angular.module('s4p').controller('UserSettingsNotificationsModalCtrl', ["$rootScope", "$scope", "$log", "$http", "$uibModalInstance", "modalSettings", "six4Auth", "s4pDialogs", function ($rootScope, $scope, $log, $http, $uibModalInstance, modalSettings, six4Auth, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.settingsFormModel = {};
    ctrl.notif = angular.copy(modalSettings.notif);
    ctrl.sectionName = angular.copy(modalSettings.sectionName);

    ctrl.changes = [];

    // Dismiss Modal
    ctrl.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    // Function for adding/subtracting time difference from values which contain hour of the day (0-23)
    ctrl.currentTimezoneOffsetHours = 0 - new Date().getTimezoneOffset() / 60;
    ctrl.modifyHoursWithTimezone = function (setting, add) {

        if (parseInt(setting) > -1 && parseInt(setting) < 24) {

            setting = !!add ? setting + ctrl.currentTimezoneOffsetHours : setting - ctrl.currentTimezoneOffsetHours;
            setting = setting > 23 ? setting - 24 : setting;
            setting = setting < 0 ? setting + 24 : setting;

            return setting;
        } else {
            return null;
        }
    };

    // Value has changed
    ctrl.valueChanged = function (field, value) {

        // Create the change
        var newItem = {
            userId: six4Auth.credentials().userId,
            notificationId: ctrl.notif.id
        };

        newItem[field] = value;

        ctrl.changes.push(newItem);

        console.log("Changes", ctrl.changes);
    };

    // Form is submitted
    ctrl.submitForm = function ($event) {

        // Loop through changes and transform all hours with timezone.
        // Looks a bit hacky because of this
        // http://stackoverflow.com/a/31298343/6025645
        var fieldsToConvert = ['emailBundleTime', 'smsBundleTime', 'smsStartTime', 'smsEndTime'];

        ctrl.changes.forEach(function (change, index) {

            var changeIndex = index;

            fieldsToConvert.forEach(function (fieldName, index) {
                if (change.hasOwnProperty(fieldName)) {
                    ctrl.changes[changeIndex][fieldName] = ctrl.modifyHoursWithTimezone(ctrl.changes[changeIndex][fieldName], false);
                }
            });
        });

        console.log("Submitting", ctrl.changes);

        // Send the update request
        $http.post(s4pSettings.apiUrl + '/userNotificationPrefs/multiUpdate', ctrl.changes, {
            form: ctrl.settingsForm
        }).then(function (response) {

            $uibModalInstance.close(ctrl.notif);
        });
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {};

    init();
}]);
angular.module('s4p').controller('UserSettingsNotifsTabCtrl', ["$rootScope", "$scope", "$log", "$http", "$q", "$state", "$timeout", "six4Auth", "$uibModal", "s4pDialogs", "userSettingsService", function ($rootScope, $scope, $log, $http, $q, $state, $timeout, six4Auth, $uibModal, s4pDialogs, userSettingsService) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.parent = userSettingsService.ctrl;

    // Function for adding/subtracting time difference from values which contain hour of the day (0-23)
    ctrl.currentTimezoneOffsetHours = 0 - new Date().getTimezoneOffset() / 60;
    ctrl.modifyHoursWithTimezone = function (setting, add) {

        if (parseInt(setting) > -1 && parseInt(setting) < 24) {

            setting = !!add ? setting + ctrl.currentTimezoneOffsetHours : setting - ctrl.currentTimezoneOffsetHours;
            setting = setting > 23 ? setting - 24 : setting;
            setting = setting < 0 ? setting + 24 : setting;

            return setting;
        } else {
            return null;
        }
    };

    // Load notifications
    ctrl.loadNotifications = function () {

        ctrl.loadingNotifications = true;

        return $http.get(s4pSettings.apiUrl + '/notifications', {
            params: {
                userIdHasAccess: $rootScope.credentials.userId
            }
        }).then(function (response) {

            ctrl.notifications = response.data.records;

            // Transform hours to accommodate for timezone
            ctrl.notifications.forEach(function (item) {
                item.emailBundleTime = ctrl.modifyHoursWithTimezone(item.emailBundleTime, true);
                item.smsBundleTime = ctrl.modifyHoursWithTimezone(item.smsBundleTime, true);
                item.smsStartTime = ctrl.modifyHoursWithTimezone(item.smsStartTime, true);
                item.smsEndTime = ctrl.modifyHoursWithTimezone(item.smsEndTime, true);
            });

            ctrl.loadingNotifications = false;
        });
    };

    // Load user prefs
    ctrl.loadUserPrefs = function () {

        ctrl.loadingUserPrefs = true;

        return $http.get(s4pSettings.apiUrl + '/userNotificationPrefs', {
            params: {
                userId: $rootScope.credentials.userId
            }
        }).then(function (response) {

            ctrl.userPrefs = response.data.records;

            // Transform hours to accommodate for timezone
            ctrl.userPrefs.forEach(function (item) {
                item.emailBundleTime = ctrl.modifyHoursWithTimezone(item.emailBundleTime, true);
                item.smsBundleTime = ctrl.modifyHoursWithTimezone(item.smsBundleTime, true);
                item.smsStartTime = ctrl.modifyHoursWithTimezone(item.smsStartTime, true);
                item.smsEndTime = ctrl.modifyHoursWithTimezone(item.smsEndTime, true);
            });

            ctrl.loadingUserPrefs = false;
        });
    };

    // Create user notifications
    ctrl.createUserNotifications = function () {

        // Get unique headings from array of notifications.
        var uniqueHeadings = ctrl.notifications.map(function (item) {
            return item.notificationHeading.name;
        }).filter(function (elem, pos, arr) {
            return arr.indexOf(elem) == pos;
        });

        ctrl.userNotifications = [];

        // Loop through each heading and create each group
        uniqueHeadings.forEach(function (heading) {

            var groupItems = [];

            // Get all notifications under this heading
            var groupNotifications = ctrl.notifications.filter(function (item) {
                return item.notificationHeading.name === heading;
            });

            // Transform each notification by over-riding it with any user preferences
            groupNotifications.forEach(function (notification) {

                var userNotification = {
                    id: notification.id,
                    name: notification.name,
                    notificationHeadingId: notification.notificationHeadingId,
                    notificationHeading: notification.notificationHeading,
                    webEnabled: notification.webEnabled,
                    webAdminEnabled: notification.webAdminEnabled,
                    webBundle: notification.webBundle,
                    emailEnabled: notification.emailEnabled,
                    emailAdminEnabled: notification.emailAdminEnabled,
                    emailBundle: notification.emailBundle,
                    smsEnabled: notification.smsEnabled,
                    smsAdminEnabled: notification.smsAdminEnabled,
                    smsBundle: notification.smsBundle
                };

                // Check to see if there are any user preference over-rides for this notification.
                var overrides = ctrl.userPrefs.filter(function (pref) {
                    return pref.notificationId === notification.id;
                });

                var override = {};

                // Have we found any overrides?
                if (overrides.length > 0) {
                    override = overrides[0];
                }

                function overrideExisits(setting) {
                    return override[setting] !== undefined && override[setting] !== null;
                }

                function mergeSetting(setting) {
                    return overrideExisits(setting) ? override[setting] : notification[setting];
                }

                // Web On
                userNotification.webOn = overrideExisits('webOn') ? override.webOn : notification.webDefaultOn;
                userNotification.webBundleOn = overrideExisits('webBundleOn') ? override.webBundleOn : notification.webBundle && notification.webBundleDefaultOn;

                // Email
                userNotification.emailOn = overrideExisits('emailOn') ? override.emailOn : notification.emailDefaultOn;
                userNotification.emailBundleOn = overrideExisits('emailBundleOn') ? override.emailBundleOn : notification.emailBundle && notification.emailBundleDefaultOn;
                userNotification.emailBundleMinutes = mergeSetting('emailBundleMinutes');
                userNotification.emailBundleTime = mergeSetting('emailBundleTime');

                // SMS On
                userNotification.smsOn = overrideExisits('smsOn') ? override.smsOn : notification.smsDefaultOn;
                userNotification.smsStartTime = mergeSetting('smsStartTime');
                userNotification.smsEndTime = mergeSetting('smsEndTime');
                userNotification.smsBundleOn = overrideExisits('smsBundleOn') ? override.smsBundleOn : notification.smsBundle && notification.smsBundleDefaultOn;
                userNotification.smsBundleMinutes = mergeSetting('smsBundleMinutes');
                userNotification.smsBundleTime = mergeSetting('smsBundleTime');
                userNotification.smsWeekdaysOnly = mergeSetting('smsWeekdaysOnly');

                groupItems.push(userNotification);
            });

            var group = {
                heading: heading,
                items: groupItems
            };

            ctrl.userNotifications.push(group);
        });
    };

    // Checkbox turned on or off
    ctrl.checkboxChanged = function (notif, field, value) {

        // Create the change
        var newItem = {
            userId: six4Auth.credentials().userId,
            notificationId: notif.id
        };

        newItem[field] = value;

        // Send the update request
        $http.post(s4pSettings.apiUrl + '/userNotificationPrefs/multiUpdate', [newItem]).then(function (response) {
            // Do nothing if successful
        });
    };

    // Open Advanced Modal
    ctrl.openAdvancedModal = function (notif, sectionName) {

        var notificationId = notif.id;

        $uibModal.open({
            templateUrl: s4pSettings.templatesUrl + '/userSettingsNotificationsModal.tpl.html',
            controller: 'UserSettingsNotificationsModalCtrl as userSettingsNotificationsModal',
            windowClass: 'modal--sticky modal--has-header modal--has-footer',
            resolve: {
                modalSettings: function modalSettings() {
                    return {
                        notif: notif,
                        sectionName: sectionName
                    };
                }
            }
        }).result.then(function (response) {

            // Loop through all userNotifications and replace the correct one with the modal response
            // Looks a bit hacky because of this
            // http://stackoverflow.com/a/31298343/6025645
            ctrl.userNotifications.forEach(function (group, index) {

                var groupIndex = index;

                group.items.forEach(function (notification, index) {
                    if (notification.id === notificationId) {
                        ctrl.userNotifications[groupIndex].items[index] = angular.copy(response);
                    }
                });
            });
        });
    };

    // Format friendly minute text
    ctrl.getFriendlyMinutes = function (minutes) {

        switch (minutes) {

            case 60:
                return 'hourly';
            case 180:
                return 'every few hours';
            default:
                return 'every ' + minutes + ' minutes';
        }
    };

    // Format friendly hour text
    ctrl.getFriendlyHour = function (hour) {

        if (hour === 0) {
            return 'Midnight';
        } else if (hour === 12) {
            return 'Midday';
        } else if (hour < 12) {
            return hour + 'am';
        } else if (hour > 12) {
            return hour - 12 + 'pm';
        }

        return '';
    };

    // Is the tab in a loading state?
    ctrl.tabLoading = function () {
        return ctrl.loadingNotifications || ctrl.parentLoading;
    };

    // Is anything enabled
    ctrl.isAnythingEnabled = function (notif) {
        return notif.webEnabled && notif.webAdminEnabled || notif.emailEnabled && notif.emailAdminEnabled || notif.smsEnabled && notif.smsAdminEnabled;
    };

    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function init() {

        ctrl.parentLoading = true;

        ctrl.userNotifications = [];

        // Load everything once the role has been loaded in the parent controller
        var parentLoadWatcher = $scope.$watch(function () {
            return ctrl.parent.settings;
        }, function (newValue, oldValue) {

            if (newValue !== undefined && newValue.constructor === Object) {

                ctrl.parentLoading = false;

                // Remove the watch
                parentLoadWatcher();

                // Wait for data to load, then create the view model
                $q.all([ctrl.loadNotifications(), ctrl.loadUserPrefs()]).then(function () {

                    ctrl.createUserNotifications();
                });
            }
        });
    };

    init();
}]);