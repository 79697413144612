angular.module('portal')

.controller('AddClientCtrl', function ($rootScope, $scope, $log, $http, $state, $stateParams, s4pDialogs, six4Auth) {

	'use strict';
    
	var master = $rootScope.master;
	var ctrl = this;

    ctrl.addForm = {};
    ctrl.addFormMeta = {};


    // Load Colors
    ctrl.loadColors = function(){
        return $http.get(portalSettings.apiUrl + '/colors').then(function(response){
            ctrl.addFormMeta.colors = response.data.records;
        });
    };

    
    // Submit Add Form
	ctrl.submitAddForm = function(){
		
        // Copy the model
		var item = angular.copy(ctrl.addForm);

        // Transform it if necessary
        if(item.photo){
            item.photoId = item.photo.id;
            delete item.photo;
        }
        
        if(item.color){
            item.colorId = item.color.id;
            delete item.color;
        }


        // Send the post request
		$http.post(portalSettings.apiUrl + '/clients', item, {
            form: ctrl.atF
        }).then(function(response){
            
            // Success message
			s4pDialogs.message({
				title: 'Saved!',
				message: 'Your client has been added',
				buttonText: "Back to Clients"
			}).result.then(function(result){
				
                // Redirect to view page
				$state.go('clients');
				
			});
			
        });
		
	};
    
	
	///////////////////////////////////////////////////////////////
    //  Initialize View
	///////////////////////////////////////////////////////////////

	var init = function () {

        // Date picker options
        ctrl.datePickerOptions = {
            showWeeks: false,
            startingDay: 1,
            maxMode: 'day',
        };

        // Load color options
        ctrl.loadColors();


	};

	init();

});