angular.module('portal')

.controller('ClientsCtrl', function ($rootScope, $scope, $log, $http, $state) {

	'use strict';
    
	var master = $rootScope.master;

	var ctrl = this;

	ctrl.searchForm = {};

    
    // Get Clients
    ctrl.loadClients = function(){
        
        ctrl.dataLoading = true;
        
        var params = {
            start: ctrl.pagingModel.start,
            limit: ctrl.pagingModel.itemsPerPage
        };
                
        return $http.get(portalSettings.apiUrl + '/clients', {params: params}).then(function (response) {
            ctrl.pagingModel.totalItems = response.data.meta.totalCount;
            ctrl.pagingModel.totalPages = Math.ceil((ctrl.pagingModel.totalItems / ctrl.pagingModel.itemsPerPage));
            ctrl.tableData = response.data.records;
        });
        
    };
    
    
    // Do Search
    ctrl.doSearch = function () {

        if (ctrl.searchLoading) {
            return;
        }

        ctrl.searchLoading = true;
        
        var params = {
            start: ctrl.pagingModel.start,
            limit: ctrl.pagingModel.itemsPerPage,
            keywords: ctrl.searchForm.keywords,
            company: ctrl.searchForm.company
        };

        return $http.get(portalSettings.apiUrl + '/clients', { params: params }).then(function (response) {
            ctrl.pagingModel.totalItems = response.data.meta.totalCount;
            ctrl.pagingModel.totalPages = Math.ceil((ctrl.pagingModel.totalItems / ctrl.pagingModel.itemsPerPage));
            ctrl.tableData = response.data.records;            
            ctrl.searchLoading = false;
            ctrl.sF.$resetForm();
        });
        
    };

    // Remove items from api using single delete requests
    ctrl.removeItems = function(){
        
        if(ctrl.selectedRows && ctrl.selectedRows.length > 0){

            ctrl.dataLoading = true;
            var counter = 0;

            ctrl.selectedRows.forEach(function(id){

                counter++;

                $http.delete(portalSettings.apiUrl + '/clients/' + id).then(function(){
                    
                    counter--;
                    if(counter===0){
                        ctrl.loadClients();
                    }

                });

            });

        }

    };


    // Paging clicked
    ctrl.tablePageChanged = function(){
        ctrl.pagingModel.start = ((ctrl.pagingModel.currentPage -1) * ctrl.pagingModel.itemsPerPage) + 1 + ctrl.pagingModel.offset;
        ctrl.loadclients();
    };
    
    
    // Row clicked
    ctrl.rowClicked = function(evt, row){

        if(!$(evt.target).is('input[type=checkbox]')) {
            $state.go('clients.edit', {
                id: row.id
            });
        } 
        
    };
	
    
    
	///////////////////////////////////////////////////////////////
    //  Initialize View
	///////////////////////////////////////////////////////////////

	var init = function () {

        // Set up pagingModel
        ctrl.pagingModel = {
            totalItems: 0,
            itemsPerPage: 50,
            pagesToShow: 5,
            currentPage: 1,
            offset: 0,
            start: 1
        };
        
        // Load first page
        ctrl.loadClients();

	};

	init();

});