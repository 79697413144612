angular.module('portal')

.controller('EditEnquiryCtrl', function ($rootScope, $scope, $log, $http, $state, $stateParams, s4pDialogs, six4Auth, s4pPageTitle) {

	'use strict';
    
	var master = $rootScope.master;
	var ctrl = this;

    ctrl.editForm = {};
    ctrl.editFormMeta = {};
    ctrl.dataLoading = false;
    
    // Load Item
    ctrl.loadItem = function () {
        ctrl.dataLoading = true;
        return $http.get(portalSettings.apiUrl + '/Enquiries/' + $stateParams.id).then(function(response){

            ctrl.dataLoading = false;
            ctrl.editForm = response.data.record;
            s4pPageTitle.set(response.data.record.name);            

        });
    };
    
    
    // Submit Edit Form
	ctrl.submitEditForm = function(){
		
        // Copy the model
		var item = angular.copy(ctrl.editForm);

        // Transform it if necessary
        if(item.photo){
            item.photoId = item.photo.id;
            delete item.photo;
        }
        
        if(item.color){
            item.colorId = item.color.id;
            delete item.color;
        }

        // Send the patch request
		$http.patch(portalSettings.apiUrl + '/Enquiries/' + $stateParams.id, item, {
            form: ctrl.epcF
        }).then(function(response){
            
            // Success message
			s4pDialogs.message({
				title: 'Saved!',
				message: 'Your changes have been saved',
				buttonText: "Back to Enquiries"
			}).result.then(function(result){
				
                // Redirect to view page
				$state.go('enquiries');
				
			});
			
        });
		
	};
    
	
	///////////////////////////////////////////////////////////////
    //  Initialize View
	///////////////////////////////////////////////////////////////

	var init = function () {

        // Date picker options
        ctrl.datePickerOptions = {
            showWeeks: false,
            startingDay: 1,
            maxMode: 'day',
        };

        // Load the item into the form
        ctrl.loadItem();

	};

	init();

});