///////////////////////////////////////////////////
//              Portal Configuration             //
///////////////////////////////////////////////////


angular.module('portal');








