/*******************************************************/
/*                   Config Routing                    */
/*******************************************************/

// Configure Routing for custom views
angular.module('portal')

.config(function($stateProvider, $injector){

    $stateProvider
    
        /* Dashboard */
        .state('dashboard', {
            url: "/dashboard",
            templateUrl: "/templates/dashboard.tpl.html",
            controller: "DashboardCtrl as dashboard"
        })
             
    // Clients
        .state('clients', {
            url: '/clients',
            templateUrl: "/templates/clients.tpl.html",
            controller: "ClientsCtrl as clients",
            data: {
                pageTitle: 'Clients'
            }
        })
        .state('clients.add', {
            url: "/add",
            views: {
                '@': {
                    templateUrl: "/templates/addClient.tpl.html",
                    controller: "AddClientCtrl as addClient"
                }
            },
            data: {
                pageTitle: 'Add a Client'
            }
        })
        .state('clients.edit', {
            url: "/{id}",
            views: {
                '@': {
                    templateUrl: "/templates/editClient.tpl.html",
                    controller: "EditClientCtrl as editClient"
                }
            },
            data: {
                pageTitle: 'Edit a Client'
            }
        })

    // Clients
        .state('enquiries', {
            url: '/enquiries',
            templateUrl: "/templates/enquiries.tpl.html",
            controller: "EnquiriesCtrl as enquiries",
            data: {
                pageTitle: 'Enquiries'
            }
        })
        .state('enquiries.add', {
            url: "/add",
            views: {
                '@': {
                    templateUrl: "/templates/addEnquiry.tpl.html",
                    controller: "AddEnquiryCtrl as addEnquiry"
                }
            },
            data: {
                pageTitle: 'Add an Enquiry'
            }
        })
        .state('enquiries.edit', {
            url: "/{id}",
            views: {
                '@': {
                    templateUrl: "/templates/editEnquiry.tpl.html",
                    controller: "EditEnquiryCtrl as editEnquiry"
                }
            },
            data: {
                pageTitle: 'Edit an Enquiry'
            }
        })


});