/*******************************************************/
/*                    Config Icons                     */
/*******************************************************/


// Configure icons service
angular.module('portal')

.config(function(s4pIconsProvider){
    
    // Wheel freedom icons
    s4pIconsProvider.registerIcon('iconName', '/images/iconSprite.svg#iconName');

});