
// Framework wide settings
var six4Settings = six4Settings || {};

// Site wide settings
var six4Setting = function(name, value){
    
    // Are we getting?
    if(value === undefined){
        
        var getValue;
        
        try {
            getValue = six4Settings[name];
            return getValue;
        }
        catch(err){
            return undefined;
        }

    }
    
    // Are we setting?
    else{
        six4Settings[name] = value;
    }
    
}


// Hack the 300ms delay on clicks in mobile browsers
if(FastClick !== undefined){
    window.addEventListener('load', function() {
        FastClick.attach(document.body);
    }, false);
}


// svg4everybody makes svgs work for ie https://github.com/jonathantneal/svg4everybody
if(svg4everybody !== undefined){
    svg4everybody();
}


// Add no-touch class so that :hover does nothing on mobile. 
// This is an unpredictable hack because Modernizr removed it :(
if ("ontouchstart" in document.documentElement) {
    $('body').removeClass('no-touch');
}

// Fix stupid problem in IE/Edge where SVG elements don't have a blur funciton
if (typeof SVGElement.prototype.blur == 'undefined') {
    SVGElement.prototype.blur = function(){};
}


// Polyfill for requestAnimationFrame()
(function() {
    var lastTime = 0;
    var vendors = ['webkit', 'moz'];
    for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
        window.cancelAnimationFrame =
          window[vendors[x]+'CancelAnimationFrame'] || window[vendors[x]+'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame)
        window.requestAnimationFrame = function(callback, element) {
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = window.setTimeout(function() { callback(currTime + timeToCall); },
              timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };

    if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function(id) {
            clearTimeout(id);
        };
}());


/*
 * jquery.closestchild 0.1.1
 *
 * Author: Andrey Mikhaylov aka lolmaus
 * Email: lolmaus@gmail.com
 *
 */
 
 ;(function($){
  $.fn.closestChild = function(selector) {
    var $children, $results;
    
    $children = this.children();
    
    if ($children.length === 0)
      return $();
  
    $results = $children.filter(selector);
    
    if ($results.length > 0)
      return $results;
    else
      return $children.closestChild(selector);
  };
})(window.jQuery);




// six4 Module
angular.module('six4', []);

// Create modules for directives, filters and services
angular.module('six4.directives', []);
angular.module('six4.filters', []);
angular.module('six4.services', []);



/*******************************************************/
/*                       Config                        */
/*******************************************************/

// Configure an HTTP interceptor to deal with errors
angular.module('six4').config(["$httpProvider", function($httpProvider){

    'use strict';
    
    $httpProvider.interceptors.push(["$log", "$rootScope", "$q", "$injector", function($log, $rootScope, $q, $injector) {
        
        function handleHttpErrors(response){

            var errors = [];
            
            // Find embedded errors and loop through them
            if(response.data && response.data.success === false && response.data.errors.length > 0){
                
                response.data.errors.forEach(function(error){
                    errors.push({
                        statusCode: response.status,
                        field: error.field,
                        message: error.message
                    });
                });
                
            }
            
            // Deal with HTTP errors
            else if(response.status && response.status > 299){
                
                var message = response.statusText;
                message = response.data.message ? message + " - " + response.data.message : message;
                
                errors.push({
                    statusCode: response.status,
                    message: message
                });
                
            }
            
            // Log all the errors to the console.
            errors.forEach(function(error){
                $log.error("HTTP Error", error, response);
            });
            
            // Set the server errors on the form if it has been passed in
            if(response.config.form){
                response.config.form.$setServerErrors(errors);
            }
            
        }
    
        return {
            response: function(response) {
                
                // If there is a form, stop it submitting
                if(response.config.form){
                    response.config.form.$setSubmitting(false);
                }
                
                // If there was no error and resetFormOnSuccess is true, then reset the form
                if(response.config.form && response.config.resetFormOnSuccess){
                    response.config.form.$resetForm();
                }

                // Else return as normal
                return response;
                
            },
            responseError: function(rejection) {
                
                console.log("responseError intercepted", rejection);

                // If there is a form, stop it submitting
                if(rejection.config.form){
                    rejection.config.form.$setSubmitting(false);
                }
                
                // Log the error and return as normal
                handleHttpErrors(rejection);
                return $q.reject(rejection);
                
            }
        };
        
    }]);

}]);

/*******************************************************/
/*                    Six4 Filters                     */
/*******************************************************/


var six4Filters = angular.module('six4.filters');


// Trust as HTML
six4Filters.filter('trust', ['$sce', function ($sce) {
    return function (value, type) {
        return $sce.trustAs(type || 'html', value);
    }
}]);




// Text
six4Filters.filter('capitalize', function() {
	return function(text) {

		return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();

	};
});

six4Filters.filter('capitalizeEveryWord', function() {  
  return function(input){

	if(!input){
		return '';
	}

    if(input.indexOf(' ') !== -1){
      var inputPieces,
          i;

      input = input.toLowerCase();
      inputPieces = input.split(' ');

      for(i = 0; i < inputPieces.length; i++){
        inputPieces[i] = capitalizeString(inputPieces[i]);
      }

      return inputPieces.toString().replace(/,/g, ' ');
    }
    else {
      input = input.toLowerCase();
      return capitalizeString(input);
    }

    function capitalizeString(inputString){
      return inputString.substring(0,1).toUpperCase() + inputString.substring(1);
    }
  };
});


six4Filters.filter('fullName', function() {
	return function(item) {

		if(item === undefined || item === null){return;}

		return [item.firstName, item.lastName].filter(function(item){
			return item;
		}).join(" ");

	};
});

six4Filters.filter('ellipsis', function() {
	return function(text, textLength) {

		return text.length > textLength ? text.substring(0,textLength) + "..." : text;

	};
});



// Numbers
six4Filters.filter('twoDecimalPlaces', function() {
	return function(number) {

		return parseFloat(Math.round(number * 100) / 100).toFixed(2);

	};
});


// Images
six4Filters.filter('validImageFile', function() {
	return function(imageURL) {

		imageURL = imageURL || "";

		return !(imageURL === "" || imageURL.indexOf("BodyPart") === 0);

	};
});




// Errors
six4Filters.filter('serverErrors', ["$sce", function($sce) {
	return function(newErrors) {

		// If it's an array of errors
		if(newErrors && newErrors.constructor === Array && newErrors.length > 0){
			var errorString = '';
			newErrors.forEach(function(errorItem){
				errorString += errorItem.message + '<br/>';
			});
			errorString = errorString.slice(0, -5);
			return $sce.trustAsHtml(errorString);
		}

		// Else if it's a .net server error
		else if(newErrors && newErrors.data && newErrors.data.ExceptionMessage){
			var returnError = $sce.trustAsHtml(newErrors.data.ExceptionMessage);
			return returnError;
		}

		// Else if it's anything else
		else if(newErrors){
			return $sce.trustAsHtml(newErrors);
		}

		// Or, if nothing was supplied then return an empty string
		else{
			return '';
		}

	};
}]);


/*******************************************************/
/*                    Auth Service                     */
/*******************************************************/


angular.module('six4.services')


// TODO - need to update this list

// Credentials Object has the following fields
//
// * authLevel                  integer
// * sessionId                  string
// * token                      string
// * tokenExpiry                date
// * userDetails                object
// * userId                     integer


.provider('six4Auth', function(){

    'use strict';


    ////////////////////////////////////////
    //          Default Settings          //
    ////////////////////////////////////////
    
    var _user = {
        authLevel: 3,
        userDetails: {}
    }

    var _apiLogoutUrl;
    var _apis = [];
    var _forceLoginOnLoad = false;
    var _forceLoginOnLogout = false;
    var _gettingToken = false;
    var _logoutRedirectUrl;
    var _loginUrl;
    
    


    ////////////////////////////////////////
    //             Initialize             //
    ////////////////////////////////////////

    init();


    ////////////////////////////////////////
    //             Public API             //
    ////////////////////////////////////////

    instantiateAuth.$inject = ["$q", "$injector", "$rootScope", "$log", "$httpBuffer"];
    return({

        addApi: addApi,
        apiLogoutUrl: apiLogoutUrl,
        forceLoginOnLoad: forceLoginOnLoad,
        forceLoginOnLogout: forceLoginOnLogout,
        logoutRedirectUrl: logoutRedirectUrl,
        loginUrl: loginUrl,

        $get: instantiateAuth

    });

    // Add a new API so that we can automatically attach tokens to it
    function addApi(newApi){
        _apis.push(newApi);
    }

    // Set api logout URL (api endpoint to call when logout happens)
    function apiLogoutUrl(newValue){
        _apiLogoutUrl = newValue;
    }
    
    // Force login on page load if no details are stored
    function forceLoginOnLoad(newValue){
        _forceLoginOnLoad = newValue;
    }
    
    // Force login on logout
    function forceLoginOnLogout(newValue){
        _forceLoginOnLogout = newValue;
    }
    

    // Set logout redirect URL (URL to redirect to after logout method is called)
    function logoutRedirectUrl(newValue){
        _logoutRedirectUrl = newValue;
    }
    
    // Set login URL (URL to redirect user to when login is required)
    function loginUrl(newValue){
        _loginUrl = newValue;
    }
    
    


    ////////////////////////////////////////
    //          Private Methods           //
    ////////////////////////////////////////


    // Private methods go here


    // Set the credentials
    function _setCredentials(newCredentials){

        // Token stuff
        if(typeof newCredentials === 'string'){

            _user.token = newCredentials;
            
            // Extract the payload from the JWT (the middle section between the two .s), decode it and convert it to an object
            var payload = newCredentials.slice(newCredentials.indexOf('.') + 1, newCredentials.indexOf('.', newCredentials.indexOf('.') + 1));
            payload = window.atob(payload);
            payload = JSON.parse(payload);

            // Copy data from payload
            _user.authLevel = payload.authLevel !== undefined ? payload.authLevel : _user.authLevel;
            _user.sessionId = payload.sessionId !== undefined ? payload.sessionId : _user.sessionId;
            _user.userId = payload.userId !== undefined ? payload.userId : _user.userId;
            _user.userDetails = payload.userDetails !== undefined ? payload.userDetails : _user.userDetails;
            _user.persist = payload.persist !== undefined ? payload.persist : _user.persist;
            _user.permissions = payload.permissions !== undefined ? payload.permissions : _user.permissions;
            
            // If token contains timestamp in seconds, convert it to milliseconds
            _user.tokenExpiry = payload.exp < 10000000000 ? payload.exp * 1000 : payload.exp;

        }
        else if(typeof newCredentials === 'object'){
            _user = newCredentials;
        }
        else{
            return false;
        }
        
        // Put the object into storage
        localStorage.setItem('authUser', JSON.stringify(_user));

        return _user;
        
    }


    // Public Methods
    //
    // apis()                                   Returns the array of apis we're authenticating for.
    // authenticationStatus()                   Returns the current authentication status - none/guest/user/expired
    // callGetNewGuestToken()                   Wraps the getNewToken function which each site can add to six4Auth - Also fires events etc.
    // callRequestLogin()                       Wraps the getNewToken function which each site can add to six4Auth - Also fires events etc.
    // callStateChangeAuthFailed()              Wraps the stateChangeAuthFailed function which each site can add to six4Auth - Also fires events etc.
    // credentials()                            Gets or sets the current credentials object
    // forceLoginOnLoad()                       Gets the current status of this variable to see if login is being forced when the page loads
    // gettingToken()                           Gets or sets a variable which tracks whether there is currently an attempt pending to get a new token
    // isLoggedIn()                             Returns true if the current user is logged in (not guest) and email verification is passed or not required
    // loginUrl()                               Gets the current login URL if one was set during config.
    // logOut()                                 Logs the user out and sends them to another page or route if necessary.
    // userType()                               Gets the type of user regardless of token validity


    ////////////////////////////////////////
    //              Service               //
    ////////////////////////////////////////

    function instantiateAuth($q, $injector, $rootScope, $log, $httpBuffer) {

        // Return the public API.
        return({
            apis: apis,
            authenticationStatus: authenticationStatus,
            callGetNewGuestToken: callGetNewGuestToken,
            callRequestLogin: callRequestLogin,
            callStateChangeAuthFailed: callStateChangeAuthFailed,
            checkPermissions: checkPermissions,
            credentials: credentials,
            forceLoginOnLoad: forceLoginOnLoad,
            gettingToken: gettingToken,
            isLoggedIn: isLoggedIn,
            loginUrl: loginUrl,
            logOut: logOut,
            userType: userType,
            whenLoggedIn: whenLoggedIn
        });


        // ---
        // PUBLIC METHODS.
        // ---
        
        // Get APIs (details of APIs we need to attach tokens for)
        function apis() {
            return _apis;
        }
        
        
        // Authentication Status
        // Returns token staus ('none', 'expired', 'guest', 'user')
        function authenticationStatus(){
            
            // Does the user have no token?
            if(!_user.token){
                return 'none';
            }
            
            // Else is token expired
            else if(!!_user.token && (new Date).getTime() > _user.tokenExpiry){
                return 'expired';
            }
            
            // Else we have a valid token
            else if(!!_user.token && (new Date).getTime() <= _user.tokenExpiry){
                
                if(_user.userId !== undefined){
                    return 'user'
                }
                else if(_user.sessionId !== undefined){
                    return 'guest'
                }
                
            }

            
        }

       
        // Call Get New Guest Token Function
        function callGetNewGuestToken(args){

            // Get six4Auth using injector rather than using local function call because it may not be added until the site is instantiated
            var six4Auth = $injector.get('six4Auth');

            if (six4Auth.getNewGuestToken === undefined || six4Auth.gettingToken()){
                return $q.reject();
            }

            // Flag that we're going to request a token so that nothing else does
            six4Auth.gettingToken(true);

            // Broadcast event
            $rootScope.$broadcast("six4Auth-requesting-new-guest-token");

            // Call the site's getNewGuestToken function
            // Using apply and arguments to pass through any arguments supplied to callGetNewGuestToken
            return six4Auth.getNewGuestToken.apply(this, arguments).then(function(response){
                $rootScope.$broadcast("six4Auth-new-guest-token-success", response);
                return response;
            }, function(){
                $log.error("Auth - Failed to log in");
                $rootScope.$broadcast("six4Auth-requesting-new-guest-token-failed");
            }).finally(function(){
                six4Auth.gettingToken(false);
            });
            
        }


        // Call Request Login Function
        function callRequestLogin(args){

            // Clear the current details before we get a new one            <------ If this causes any problems then it might need to be removed.
            // Clear the user object
            credentials({
                authLevel: 3,
                userDetails: {}
            });

            // Get six4Auth using injector rather than using local function call because it may not be added until the site is instantiated
            var six4Auth = $injector.get('six4Auth');

            if (six4Auth.requestLogin === undefined || six4Auth.gettingToken()){
                return $q.reject();
            }

            // Flag that we're going to request a login so that nothing else does
            six4Auth.gettingToken(true);

            // Broadcast event
            $rootScope.$broadcast("six4Auth-requesting-login");

            // Call the site's login function
            // Using apply and arguments to pass through any arguments supplied to callRequestLogin
            return six4Auth.requestLogin.apply(this, arguments).then(function(response){
                $httpBuffer.retryAll();
                $rootScope.$broadcast("six4Auth-login-success", credentials());
                return response;
            }, function(){
                $httpBuffer.rejectAll();
                $rootScope.$broadcast("six4Auth-requesting-login-failed");
                $log.error("Auth - Failed to log in");
            }).finally(function(){
                six4Auth.gettingToken(false);
            });


        }


        // Call State Change Auth Failed Function
        function callStateChangeAuthFailed(args){

            // Broadcast event
            $rootScope.$broadcast("six4Auth-state-change-auth-failed");

            // Get six4Auth using injector rather than using local function call because it may not be added until the site is instantiated
            var six4Auth = $injector.get('six4Auth');
            
            // Check the site actually has a function for this
            if (six4Auth.stateChangeAuthFailed === undefined){
                return $q.reject();
            }

            // Call the site's StateChangeAuthFailed function
            // Using apply and arguments to pass through any arguments supplied to callRequestLogin
            return six4Auth.stateChangeAuthFailed.apply(this, arguments).then(function(response){
                return response;
            });

        }


        // Check Permissions
        function checkPermissions(checks) {

            // Bypass permission checking if the user is an admin or superuser
            if(_user.authLevel < 3){
                return true;
            }
           
            // If nothing is being checked, or the user has no permissions set then they fail
            if(!(checks && _user.permissions && _user.permissions.length > 0)){
                return false;
            }

            // Function (if it's an array and the last item is a function)
            if(Array.isArray(checks) && checks.length > 0 && typeof checks[checks.length - 1] === 'function') {
                return $injector.invoke(checks);
            }

            // Array
            else if(Array.isArray(checks) && checks.length > 0){

                // Check to see that at least one of the permissions is present.
                var checksPassed = checks.filter(function(item){
                    return _user.permissions.indexOf(item) > -1;
                });

                return (checksPassed && checksPassed.length > 0);

            }

            // Single
            else if(typeof checks === 'string'){
                return _user.permissions.indexOf(checks) > -1;
            }

            return false;

        }


        // Get/Set Credentials
        function credentials(newCredentials) {
            if(newCredentials !== undefined){

                _setCredentials(newCredentials);
                $rootScope.credentials = _user;
                $rootScope.userDetails = _user.userDetails;

                // console.log("$rootScope.credentials set", $rootScope.credentials);
            }
            else{
                return _user;
            }
        }
        

        // Force login on page load if no details are found
         function forceLoginOnLoad(){
            return _forceLoginOnLoad;
        }
        
        
        // Get/Set Getting Token variable (flag which say whether an attempt is pending to get a new token)
        function gettingToken(value) {
            if(value !== undefined){

                _gettingToken = value;

               // console.log("Auth: New gettingToken Value", value);
                
            }
            else{
                return _gettingToken;
            }
            
        }


        // Is the user logged in?
        function isLoggedIn() {
            return authenticationStatus() === 'user' || (authenticationStatus() === 'expired' && userType() === 'user' && credentials().persist === true)
        }
        
        
        // Get login url
        function loginUrl() {
            return _loginUrl;
        }
        

        // Log Out
        function logOut() {

            // Clear the user object
            credentials({
                authLevel: 3,
                userDetails: {}
            });
            
            return $q.when(function(){

                // Do we need to tell the server we're logging out?
                if(_apiLogoutUrl){
                    
                    $http = $http || $injector.get('$http');
                    
                    // Call logout on the server
                    return $http.post(_apiLogoutUrl).then(function (response) {

                    }, function (response) {
                        $log.info('HTTP error when logging out');
                    });
                    
                }
                else{
                    return $q.when(true);
                }
                
                
            }).then(function(){
                
                // Redirect to URL if set in config
                if(_logoutRedirectUrl !== undefined){
                    window.location = _logoutRedirectUrl;
                }
                
                
                if(_forceLoginOnLogout){
                    
                    var six4Auth = $injector.get('six4Auth');

                    return six4Auth.callRequestLogin().then(function(){
                        // Do nothing
                    }, function(){                            
                        // TODO - Force the popup to open again
                    });
                    
                }
                
                
            });
            

        }

        // User Type
        // Returns user type ('none', 'guest', 'user')
        function userType() {

            // Does the user have no token?
            if (!_user.token) {
                return 'none';
            }

            else {

                if (_user.userId !== undefined) {
                    return 'user'
                }
                else if (_user.sessionId !== undefined) {
                    return 'guest'
                }

            }


        }


        // Wait For Login status to change to true
        function whenLoggedIn(onCallback, offCallback, settings) {

            settings = settings || {};

            angular.merge(settings, {
                onImmediately: true,
                offImmediately: false,
                persistAcrossRoutes: true,
            })


            // Logging in
            if(isLoggedIn() && onCallback && settings.onImmediately){
                onCallback(credentials());
            }

            var onLoginEvent = $rootScope.$on('six4Auth-loggedin-true', function(){
                onCallback(credentials());
            });

            // Logging out
            if(!isLoggedIn() && offCallback && settings.offImmediately){
                offCallback();
            }

            var onLogoutEvent = $rootScope.$on('six4Auth-loggedin-false', function(){
                offCallback();
            });

            // Changing Routes
            if(!settings.persistAcrossRoutes){

                settings.controllerScope.$on('$destroy', function() {
                    onLoginEvent();
                    onLogoutEvent();
                    offCallback();
                });

            }

        }


        // ---
        // PRIVATE METHODS.
        // ---

    }



    ///////////////////////////////////////////////////////////////
    //            Initialize Auth Service on Page Load           //
    ///////////////////////////////////////////////////////////////

    function init(){
        

    };


})


// Configure the http interceptor for checking tokens
.config(["$httpProvider", function($httpProvider){

    'use strict';

    // HTTP Interceptors
    $httpProvider.interceptors.push(["$log", "$rootScope", "$q", "$httpBuffer", "$injector", function($log, $rootScope, $q, $httpBuffer, $injector) {
        
        
        return {
            
            // REQUEST - Intercept all HTTP requests going to the server
            request: function(config) {
  
                // console.log("Auth: HTTP request being made", config);
                
  
                // Get the authService using injector to avoid circular dependency
                var authService = $injector.get('six4Auth');
    
                // Check whether a token is required and which type
                var tokenRequired = config.attachToken;
                
                // If not specified, see if we're posting to one of our specified APIs
                if(tokenRequired === undefined){
                    authService.apis().forEach(function(api){                        
                        if(config.url.indexOf(api.url) > -1){
                            tokenRequired = api.defaultTokenType ? api.defaultTokenType : 'Unknown Type';
                        }
                    });
                }
                                
                // UNKNOWN TOKEN TYPE REQUIRED
                // If a token is required but we don't know which type then give an error and reject
                if(tokenRequired === 'Unknown Type'){
                    $log.error("HTTP Call rejected because no default token type specified" + config);
                    return $q.reject(config); 
                }
                

                // TOKEN REQUIRED
                else if(tokenRequired === 'guest' || tokenRequired === 'user'){

                    // console.log("Auth: Required token is", tokenRequired);
                    
                    var currentAuthenticationStatus = authService.authenticationStatus();
                    var currentUserType = authService.userType();
                                        
                    // Is the current token type valid for the request
                    var tokenIsValid =
                        tokenRequired === currentAuthenticationStatus ||
                        (tokenRequired === 'guest' && currentAuthenticationStatus === 'user') ||
                        (currentAuthenticationStatus === 'expired' && authService.credentials().persist === true && currentUserType === 'user');
                    
                    
                    // console.log("Auth: tokenIsValid", tokenIsValid);

                    // If the token is not valid
                    if(!tokenIsValid){                                        

                        // console.log("Auth: Invalid token found");        

                        // console.log("Auth: gettingToken()", authService.gettingToken());
                        
                        // Do we need to redirect to the user login
                        if(tokenRequired === 'user' && authService.loginUrl() !== undefined){
                            window.location = authService.loginUrl();
                        }

                        // console.log("Auth: Not redirecting because it's an SPA");
                        
                        // If we're not already getting a token we need to get one
                        if(authService.gettingToken() !== true){

                            // console.log("Auth: Not already getting token");
                            
                            // GUEST TOKEN REQUIRED
                            // TODO - Retry queue and reset gettingToken variable after we get the new token
                            if(tokenRequired === 'guest' && authService.getNewGuestToken !== undefined){
  
                                // Get a new guest token and retry all queued requests
                                authService.callGetNewGuestToken().then(function(newToken){
                                    $httpBuffer.retryAll();
                                }, function(){
                                    $httpBuffer.rejectAll();
                                });
                                
                            }
                                                        
                            // NEW USER TOKEN REQUIRED
                            else if(tokenRequired === 'user'){
                            
                                // console.log("Auth: Asking for new user token");  

                                // Request a login from the user then retry or reject all requests
                                authService.callRequestLogin().then(function(){

                                    // console.log("Auth: Login done, calling Buffer Retry All");  

                                    // Do Nothing
                                }, function(){
                                    // Do Nothing
                                });
                                
                            }
                            
                            
                        }
                        
                        // Queue the request and return a new promise. When the promise is resolved, add the token.

                        // console.log("Auth: Adding to $httpBuffer", config);

                        return $httpBuffer.store(config, 'return').then(function(config){
                            config.headers["Authentication-Token"] = authService.credentials().token;
                            return config;
                        });
                        
                    }

                    // Attach the current token
                    config.headers["Authentication-Token"] = authService.credentials().token;

                }
                
                // If no other action is required then continue with request
                return config;
                
            },
            
            
             // RESPONSE - Intercept responses from the server 
             response: function(response){
                 
                
                
                // Stash the Authentication-Token if it's in the headers
                if(response.headers('Authentication-Token')){
                     
                    // Get the authService using injector to avoid circular dependency - See notes above
                    var authService = $injector.get('six4Auth');
                     
                    authService.credentials(response.headers('Authentication-Token'));
                     
                }

                return response;
                
            },
             
             // RESPONSEERROR - Intercept all errors from the server
             responseError: function(rejection) {

                // Get the authService using injector to avoid circular dependency - See notes above
                var authService = $injector.get('six4Auth');

                // Check to see if the server says we're not authenticated
                if (rejection.status === 401) {
                    authService.callRequestLogin();
                }

                // Check to see if the server says we're logged in but trying to do something we're not allowed to do.
                if (rejection.status === 403) {
                    // TODO - Do we want to do anything here?
                }

                // Pass the rejection down the chain
                return $q.reject(rejection); 
            }
            
        };
    }]);

}])


// HTTP Buffer service
.factory('$httpBuffer', ["$q", "$injector", function($q, $injector) {

    // Requests buffer
    var buffer = [];

    // HTTP service, initialized later due to circular dependency
    var $http;

    // Retry an http request (useful if it was a request that was intercepted)
    function retryHttpRequest(config, deferred) {

        //Get the http service now
        $http = $http || $injector.get('$http');

        //Retry the request
        $http(config).then(function(response) {
            deferred.resolve(response);
        }, function(reason) {
            deferred.reject(reason);
        });

    }
  
    // Return an http request (useful if it was a request that was intercepted)
    function returnHttpRequest(config, deferred){
        deferred.resolve(config);
    }
  
  
    return {

        // Store a new request in the buffer
        store: function(config, mode) {
            var deferred = $q.defer();
            buffer.push({
                config: config,
                deferred: deferred,
                mode:mode
            });

            // console.log("Auth: Added To Buffer", buffer);

            return deferred.promise;
        },

        // Clear the buffer (without rejecting requests)
        clear: function() {
            buffer = [];
        },

        // Reject all the buffered requests
        rejectAll: function(reason) {

            // Loop all buffered requests and reject them
            for (var i = 0; i < buffer.length; i++) {
                buffer[i].deferred.reject(reason);
            }

            // Clear the buffer
            this.clear();

        },

        // Retry all buffered requests
        retryAll: function(configUpdater) {

            //Loop all buffered requests
            for (var i = 0; i < buffer.length; i++) {

                //Config updater provided? Use it
                if (configUpdater && angular.isFunction(configUpdater)) {
                    buffer[i].config = configUpdater(buffer[i].config);
                }

                // Return or retry the request
                if(buffer[i].mode === 'return'){
                    returnHttpRequest(buffer[i].config, buffer[i].deferred);
                }
                else{
                    retryHttpRequest(buffer[i].config, buffer[i].deferred);
                }
                
            }

            // Clear the buffer
            this.clear();
            
        }
    
    };
  
}])


// Run function
.run(["$rootScope", "$log", "$timeout", "$q", "$state", "$stateParams", "six4Auth", function ($rootScope, $log, $timeout, $q, $state, $stateParams, six4Auth) {

    'use strict';

    // Check whether there's a token to load
    var storedUser = JSON.parse(localStorage.getItem('authUser'));

    if(!!storedUser){

        six4Auth.credentials(storedUser);

        if(six4Auth.isLoggedIn()){
            $timeout(function(){
                $rootScope.$broadcast("six4Auth-loggedin-true", six4Auth.credentials());
            });
        }

    }
    
    // Copy stuff to rootscope so it can be used in templates
    $rootScope.credentials = six4Auth.credentials();
    $rootScope.userDetails = six4Auth.credentials().userDetails;

    $rootScope.auth = {
        authenticationStatus: six4Auth.authenticationStatus,
        checkPermissions: six4Auth.checkPermissions,
        userType: six4Auth.userType
    };

    // Broadcast an event when the user's token expires and isn't set to persist
    $rootScope.$watch(function(){
        return six4Auth.isLoggedIn();
    }, function(newValue, oldValue){
        if(!newValue && oldValue){
            $rootScope.$broadcast("six4Auth-loggedin-false", six4Auth.credentials());
        }
        else if(newValue && !oldValue){
            $rootScope.$broadcast("six4Auth-loggedin-true", six4Auth.credentials());
        }
    });
 

    // If no details are found and login needs to be forced
    if (six4Auth.forceLoginOnLoad() && !six4Auth.isLoggedIn()) {

        // Timeout to make sure that nothing else (mainly the router) has already requested a login
        $timeout(function(){

            six4Auth.callRequestLogin().then(function(){
                // Do Nothing
            }, function(){
                // TODO - Force another login request if the previous one failed.
            });

        });
        
    }
    


    // Create route based auth checking
    var six4BypassStateChangeAuthCheck;

    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {

        toState.data = toState.data || {};

        // If we're bypassing the check this time then reset the flag for next time and give up.
        if (six4BypassStateChangeAuthCheck){    
            $rootScope.routeCancelledDueToAuthCheck = false;
            return;
        } 

        // Do we need to do any checks?
        var checksExist = toState.data.minAuthLevel || toState.data.permissions;


        // if checks are required. Otherwise we don't need to continue.
        if (!checksExist) {
            return;
        }

        // Halt state change from even starting
        event.preventDefault();

        // Tell the rest of the app we've cancelled the root change
        $rootScope.routeCancelledDueToAuthCheck = true;

           
        // Is the user logged in?
        var tokenAction = 'login';
        var currentAuthStatus = six4Auth.authenticationStatus();
        
        if(currentAuthStatus === 'user' || (currentAuthStatus === 'expired' && six4Auth.userType() === 'user' && six4Auth.credentials().persist === true)){
            tokenAction = '';
        } 
        
        // Make sure we have a token, then start checking requirements match
        var getTokenFn = function(){

            if (tokenAction === 'login') {
                return six4Auth.callRequestLogin();
            }
            else{
                return $q.resolve();
            }

        }

        getTokenFn().then(function(){

            var meetsRequirement = true;

            // Check Permissions
            if(toState.data.permissions){
                meetsRequirement = six4Auth.checkPermissions(toState.data.permissions) ? meetsRequirement : false;
            }

            // Check minAuthLevel
            if(toState.data.minAuthLevel){
                meetsRequirement = six4Auth.credentials().authLevel <= toState.data.minAuthLevel ? true : false;
            }

            // Continue if user passed checks
            if (meetsRequirement || six4Auth.credentials().authLevel === 1) {          

                // Bypass next call           
                six4BypassStateChangeAuthCheck = true;   

                // Continue with the initial state change               
                $state.go(toState, toParams);    

            }
            else{

                // Call the failed method if we didn't pass the checks.
                six4Auth.callStateChangeAuthFailed(toState, toParams);
            }

        }, function(){

            // The user was not logged in and we couldn't request a login.
            six4Auth.callStateChangeAuthFailed(toState, toParams);

        });

        return false;

    });

    $rootScope.$on('$stateChangeSuccess', function() {
        six4BypassStateChangeAuthCheck = false;
    });

    

}]);






// TODO - Deal with not authenticated response in interceptor properly.



/*******************************************************/
/*                 Breakpoints Service                 */
/*******************************************************/


angular.module('six4.services')



.provider('six4Breakpoints', function(){

    'use strict';

    ////////////////////////////////////////
    //              Defaults              //
    ////////////////////////////////////////

    var breakpoints = [];
    
    var currentBreakpoint = {};
    var previousBreakpoint = {};
    var windowWidth = 0;


    ////////////////////////////////////////
    //             Initialize             //
    ////////////////////////////////////////

    init();


    ////////////////////////////////////////
    //             Public API             //
    ////////////////////////////////////////

    instantiate.$inject = ["$rootScope", "$window"];
    return({

        setBreakpoints: setBreakpoints,

        $get: instantiate

    });


    // Set the breakpoints array
    function setBreakpoints(newValue){
        breakpoints = newValue;
    }


    ////////////////////////////////////////
    //          Private Methods           //
    ////////////////////////////////////////


    // Private methods go here


    // Example
    //function _privateFunction(parameter1){}



    // Public Methods
    //



    ////////////////////////////////////////
    //              Service               //
    ////////////////////////////////////////

    function instantiate($rootScope, $window) {

        _initService();

        // Return the public API.
        return({
            current: current,
            previous: previous
        });


        // ---
        // PUBLIC METHODS.
        // ---
        



        // Get Current Breakpoint
        function current() {

            return currentBreakpoint;

        }
        
        // Get Previous Breakpoint
        function previous() {

            

        }
        
        

        // ---
        // PRIVATE METHODS.
        // ---
        
        function _setCurrentBreakpoint(){
            
            // Loop through breakpoints and find the correct one
            breakpoints.forEach(function(item, index){
                
                var breakpointMinimum = item.minimum;
                var newBreakpoint = currentBreakpoint;
                
                if (breakpointMinimum < windowWidth){
                    
                    // Set the new breakpoint
                    currentBreakpoint = {
                      number: index + 1,
                      minimum: breakpointMinimum
                    };

                }
                
            });
            
            return currentBreakpoint
            
        }
        

        function _windowWidthChanged(){
            
            // Set the window width
            windowWidth = $window.innerWidth;
            
            // Remember the old breakpoint
            var oldBreakpoint = angular.copy(currentBreakpoint);
            
            // Set the current breakpoint
            _setCurrentBreakpoint();
            
            // If the breakpoint number has changed then set the previous one and broadcast an event
            if(currentBreakpoint.number !== oldBreakpoint.number){

                var eventObject = {
                    currentBreakpoint: currentBreakpoint,
                    oldBreakpoint: oldBreakpoint
                }
                
                // Set the previous breakpoint
                previousBreakpoint = oldBreakpoint;
                
                // Broadcast Event
                $rootScope.$broadcast('breakpointChanged', eventObject);

            }
            
        }
        
        
        
        // ---
        // Init Service.
        // ---
        
        function _initService(){
            
            angular.element($window).bind('resize', _windowWidthChanged);
            
            _windowWidthChanged();
            
        }
        

    }



    ///////////////////////////////////////////////////////////////
    //              Initialize Provider on Page Load             //
    ///////////////////////////////////////////////////////////////

    function init(){

        

    };


});





/*******************************************************/
/*                      Utilities                      */
/*******************************************************/

var nextUniqueId = 0;

angular.module('six4.services').factory('six4Utils', ['$log', '$timeout', '$rootScope', function ($log, $timeout, $rootScope) {

    'use strict';

    var utils = {};


    // Cancel default actions of clicks
    utils.cancelClick = function($event){
        $event = $event || {};
        if ($event.stopPropagation) {$event.stopPropagation();}
        if ($event.preventDefault) {$event.preventDefault();}
        $event.cancelBubble = true;
        $event.returnValue = false;
    };


    // Get URL parameters and return as an object
    utils.getUrlParams = function(){
        
        var queryString = window.location.search.toString().toLowerCase();
        var hash;
        var myJson = {};
        var hashes = queryString.slice(queryString.indexOf('?') + 1).split('&');

        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            myJson[hash[0]] = decodeURIComponent(hash[1]);
        }

        return myJson;

    };


    // Convert javascript object into query string
    utils.makeUrlParams = function(objectToConvert){
        return jQuery.param(objectToConvert);
    };
    
    
    // Genareate Unique ID
    utils.getUid = function(){
        return 'uid-' + nextUniqueId++;
    };
    
    
    // Debounce
    utils.debounce = function(func, wait, scope, invokeApply) {
      
        var timer;

        return function debounced() {
            
            var context = scope;
            var args = Array.prototype.slice.call(arguments);

            $timeout.cancel(timer);
            
            timer = $timeout(function() {
                timer = undefined;
                func.apply(context, args);
            }, wait || 10, invokeApply);
            
        };
      
    };


    return utils;
    
}]);
/////////////////////////////////////////////////////////
//                     Six4 Form                       //
/////////////////////////////////////////////////////////

document.createElement('six4-form');
document.createElement('six4-form-server-errors');


angular.module('six4.directives')


.constant('six4FormDefaults', {
    scrollToFirstError: false,
    scrollTarget: 'HTML',
    scrollSpeed: 300,
    scrollMargin: 100,
    scrollTargetTraverseDirection: 'up'
})

.controller('six4FormCtrl', ["$scope", "$element", "$attrs", function($scope, $element, $attrs){


}])

.directive('six4Form', ["$log", "$animate", "six4FormDefaults", "$timeout", function ($log, $animate, six4FormDefaults, $timeout) {

    return {

        restrict: 'A',
        scope: {
            scrollToFirstError: '@',
            scrollTarget: '@',
            scrollSpeed: '@',
            scrollMargin: '@',
            scrollTargetTraverseDirection: '@',
            submit: '&',
            api: '=?'
        },
        require: 'form',
        controller: 'six4FormCtrl as six4FormCtrl',

        compile: function(element, attrs){


            // Auto set novalidate
            if (!attrs.novalidate) {
                element.attr('novalidate', '');
                attrs.novalidate = true;
            }


            return {

                pre: function(scope, element, attrs, formCtrl){
                    
                    // Make sure form has a formErrors Array
                    formCtrl.$serverErrors = [];
                    
                    // Create register function for uploaders
                    formCtrl.$uploaders = [];
                    formCtrl.$registerUploader = function(uploader){
                        formCtrl.$uploaders.push(uploader);
                    };
                    
                    scope.$on('$destroy', function(){
                        formCtrl.$serverErrors = undefined;
                        formCtrl.$uploaders = undefined;
                    });
                    
                },

                post: function (scope, element, attrs, formCtrl){
                    
                    
                    // Settings default
                    scope.scrollToFirstError = angular.isDefined(scope.scrollToFirstError) ? scope.$eval(scope.scrollToFirstError) : six4FormDefaults.scrollToFirstError;
                    scope.scrollTarget = angular.isDefined(scope.scrollTarget) ? scope.scrollTarget : six4FormDefaults.scrollTarget;
                    scope.scrollSpeed = angular.isDefined(scope.scrollSpeed) ? scope.scrollSpeed : six4FormDefaults.scrollSpeed;
                    scope.scrollMargin = angular.isDefined(scope.scrollMargin) ? scope.scrollMargin : six4FormDefaults.scrollMargin;
                    scope.scrollTargetTraverseDirection = angular.isDefined(scope.scrollTargetTraverseDirection) ? scope.scrollTargetTraverseDirection : six4FormDefaults.scrollTargetTraverseDirection;


                    // Set form.$submitting to false and create function to change it.
                    formCtrl.$submitting = false;

                    formCtrl.$setSubmitting = function(value){

                        if(typeof value === 'boolean'){

                            // Set $submitting
                            formCtrl.$submitting = value;


                            // Set $submitting on parent
                            var parentForm = formCtrl.$$parentForm;

                            if(parentForm.$setSubmitting){
                                parentForm.$setSubmitting(value);
                            }

                            // Set classes
                            if(value !== false){
                                $animate.addClass(element, 'ng-submitting');
                            }
                            else{
                                $animate.removeClass(element, 'ng-submitting');
                            }

                        }

                    }
                    
                    // Allow the form to be reset
                    formCtrl.$resetForm = function(){
                        formCtrl.$setSubmitting(false);
                        formCtrl.$setPristine();
                        formCtrl.$setUntouched();
                        formCtrl.$submitted = false;
                        formCtrl.$serverErrors = [];
                    }
                    
                    // Function for setting the server errors (called by http response interceptor normally when server sends back errors)
                    formCtrl.$setServerErrors = function(errors){
                        
                        if(errors){
                            formCtrl.$serverErrors = errors;
                        }
                        else{
                            formCtrl.$serverErrors = [];
                        }
                        
                    }

                    
                    // Multiple file uploaders can attempt a retry so we need to count them and only let one of them through.
                    var retrySubmitCounter = 0;

                    // Submit function
                    function doSubmit(forceSubmit, event){

                        // Set submitting just incase we're calling this function externally and the HTML form hasn't been submitted
                        formCtrl.$setSubmitted();
                        
                        // If form is already submitting it can't be submitted again
                        if(formCtrl.$submitting && !forceSubmit){
                            return;
                        };


                        // Form is NOT valid
                        if (formCtrl && !formCtrl.$valid) {

                            if(event){
                                event.preventDefault();
                                event.stopPropagation();
                                event.stopImmediatePropagation();
                            }

                            // Set fields to $dirty and remove $pristine so that error messages are shown
                            angular.forEach( formCtrl , function ( formElement , fieldName ) {

                                if ( fieldName[0] === '$' ){
                                    return;
                                } 

                                formElement.$setDirty();

                            }, this);

                            formCtrl.$setDirty();


                            // Scroll to first error and focus it
                            if(scope.scrollToFirstError){
                                
                                // Find the element being scrolled
                                var scrollTargetEl;

                                if(scope.scrollTargetTraverseDirection === 'up'){
                                    scrollTargetEl = element.closest(scope.scrollTarget).first();
                                }
                                else{
                                    scrollTargetEl = element.find(scope.scrollTarget).first();
                                }

                                // Find the first invalid control
                                var firstInvalid = element.find('.ng-invalid:first');
                                
                                // Get Y position of first invalid control
                                var newScrollPosition;

                                if(scope.scrollTarget === 'HTML'){
                                    newScrollPosition = firstInvalid.offset().top - scope.scrollMargin;
                                }

                                else if (scrollTargetEl.length > 0){
                                    newScrollPosition = firstInvalid.offset().top + scrollTargetEl.scrollTop() - scrollTargetEl.offset().top - scope.scrollMargin;
                                }


                                // Check new scroll position is not negative
                                newScrollPosition = newScrollPosition < 0 ? 0 : newScrollPosition;

                                
                                // Do the scroll with GSAP if it's available
                                if(typeof TweenMax !== 'undefined'){

                                    TweenMax.to(scrollTargetEl, scope.scrollSpeed/1000, {
                                        scrollTo: {y: newScrollPosition}, 
                                        ease: Power4.easeOut, 
                                        onComplete: function(){

                                            // Set the first invalid input to be focussed once the scroll is finished
                                            firstInvalid.focus();

                                        }
                                    });
                                
                                }

                                // Otherwise do it with jQuery
                                else{

                                    scrollTargetEl.animate({ scrollTop: newScrollPosition}, parseInt(scope.scrollSpeed), function(){
                                        
                                        // Set the first invalid input to be focussed once the scroll is finished
                                        firstInvalid.focus();

                                    });

                                }
                            
                            }

                        }

                        // Form IS valid
                        else{

                            //Set the $submitting variable and 'ng-submitting' class
                            formCtrl.$setSubmitting(true);
                            
                            // check if any uploaders are still uploading.
                            if(formCtrl.$uploaders && formCtrl.$uploaders.length > 0){
                                
                                var unfinishedUploaders = 0;
                                
                                formCtrl.$uploaders.forEach(function(item){

                                    if(item.queue.length > 0 && !item.isComplete){
                                        
                                       // We found an unfinished uploader so count it and start uploading
                                        unfinishedUploaders++;
                                        item.uploadAll();
                                        
                                        // Now start watching the uploader progress and wait till it finishes
                                        var listener = scope.$watch(function(){
                                            return item.isComplete;
                                        },
                                        function(newValue, oldValue){
  
                                            // If it's finished then clear the watch and try the submit again
                                            // (Other uploaders might still be going)
                                            if(newValue === true){
                                                listener(); // Clears the $watch
                                                retrySubmitCounter++;
                                                $timeout(function() {
                                                    doSubmit(true);
                                                });
                                            }
                                            
                                        });
                                        
                                    }

                                });
                                
                                
                                // Did we find any unfinished uploaders?
                                // If so, cancel this submit as it'll get called again once the uploaders are done.
                                if(unfinishedUploaders > 0){
                                    return;    
                                }
                                
                                
                            }

                            // Run the submit function if the retry counter is down to 1 or it wasn't set
                            if(retrySubmitCounter < 2){
                                scope.submit();
                            }
                            
                            if(retrySubmitCounter > 0){
                                retrySubmitCounter--;
                            }
                            

                        }
                        
                    };

                    // Submit function
                    element.bind('submit', function (event)
                    {
                        scope.$apply(function(){
                            doSubmit(false, event);
                        });

                    });


                    // Create API 
                    scope.api = {
                        $resetForm: formCtrl.$resetForm,
                        submit: doSubmit
                    };

                }


            }



        }

    };

}])


.controller('six4FormServerErrorsCtrl', ["$scope", "$element", "$attrs", function($scope, $element, $attrs){

    var self = this;

}])

.directive('six4FormServerErrors', ["$log", "$animate", "six4FormDefaults", function ($log, $animate, six4FormDefaults) {

    return {

        restrict: 'EA',
        scope: {},
        require: '^form',
        controller: 'six4FormServerErrorsCtrl',
        template:   '<six4-form-server-error ng-if="showFormErrors" ng-repeat="error in errors">'+
                        '<span ng-bind="error.message"></span>'+
                    '</six4-form-server-error>',

        link: function ($scope, $element, $attrs, formController) {
            
            $scope.errors = formController.$serverErrors;
            
            // Defaults
            $scope.showAuto = angular.isDefined($attrs.showAuto) && $attrs.showAuto === 'false' ? false : true;
            $scope.showFormErrors = angular.isDefined($attrs.showFormErrors) && $attrs.showFormErrors === 'false' ? false : true;

            // Initially hide it if we're showing automatically
            if($scope.showAuto){
                $element.addClass('ng-hide');
            }

            // Watch the errors and show/hide the element if we need to
            $scope.$watch(function(){return formController.$serverErrors}, function( newValue, oldValue ) {
                
                if(newValue === undefined){
                    return;
                }
                
                $scope.errors = newValue;
                
                if($scope.showAuto && newValue.length > 0){
                    $animate.removeClass($element, 'ng-hide', {
                        tempClasses: 'ng-hide-animate'
                    });
                }
                else if($scope.showAuto && newValue.length === 0){
                    $animate.addClass($element, 'ng-hide', {
                        tempClasses: 'ng-hide-animate'
                    });
                }
                
            });

        }

    };

}]);

/////////////////////////////////////////////////////////
//              Six4 Show If Permission                //
/////////////////////////////////////////////////////////


angular.module('six4.directives')

.directive('six4ShowIfHasPermission', ["$animate", "six4Auth", function ($animate, six4Auth) {

    return {

        restrict: 'A',
        multiElement: true,
        link: function(scope, element, attr) {
            
            console.log("Checking permissions");
            
            var permitted = six4Auth.hasPermission(attr.six4ShowIfHasPermission);
            
            $animate[permitted ? 'removeClass' : 'addClass'](element, 'ng-hide', {
                tempClasses: 'ng-hide-animate'
            });
            
        }
        

    };


}]);

