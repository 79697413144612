angular.module('portal')

.controller('EditClientQuotesTabCtrl', function ($rootScope, $scope, $log, $http, $state, $stateParams, $q, $uibModal, s4pDialogs) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.editFormModel = {};
    ctrl.editFormMeta = {};
    ctrl.dataloading = false;


    // Get Details
    ctrl.loadQuotes = function () {

        ctrl.dataLoading = true;

        console.log($state);

        var params = {
            clientId: $stateParams.id,
            start: ctrl.pagingModel.start,
            limit: ctrl.pagingModel.itemsPerPage
        };

        return $http.get(portalSettings.apiUrl + '/quotes', { params: params }).then(function (response) {
            ctrl.pagingModel.totalItems = response.data.meta.totalCount;
            ctrl.pagingModel.totalPages = Math.ceil((ctrl.pagingModel.totalItems / ctrl.pagingModel.itemsPerPage));
            ctrl.tableData = response.data.records;
        }, function (response) {
            ctrl.tableData = [];
            ctrl.dataLoading = false;
            s4pDialogs.message({ message: 'Sorry, there was a problem loading the details.' });
        });

    };

    // Open add detail modal
    ctrl.openAddQuoteModal = function () {

        

        $uibModal.open({
            templateUrl: '/templates/addQuoteModal.tpl.html',
            controller: 'AddQuoteModalCtrl as addQuoteModal',
            windowClass: 'modal--sticky modal--has-header modal--has-footer',
            resolve: {
                modalSettings: function () {
                    return {
                        clientId: $stateParams.id
                    };
                }
            }
        }).result.then(function (result) {
            ctrl.loadQuotes();
        });

    };

    // Open edit detail modal
    ctrl.openEditQuoteModal = function (evt, row) {
        if (!$(evt.target).is('input[type=checkbox]')) {
            $uibModal.open({
                templateUrl: '/templates/editQuoteModal.tpl.html',
                controller: 'EditQuoteModalCtrl as editQuoteModal',
                windowClass: 'modal--sticky modal--has-header modal--has-footer',
                resolve: {
                    modalSettings: function () {
                        return {
                            clientId: row.id
                        };
                    }
                }
            }).result.then(function (result) {
                ctrl.loadQuotes();
            });
        }
    };


    // Remove items from api using single delete requests
    ctrl.removeItems = function () {

        if (ctrl.selectedRows && ctrl.selectedRows.length > 0) {

            ctrl.dataLoading = true;
            var counter = 0;

            ctrl.selectedRows.forEach(function (id) {

                counter++;

                $http.delete(portalSettings.apiUrl + '/quotes/' + id).then(function () {

                    counter--;
                    if (counter === 0) {
                        ctrl.loadDetails();
                    }

                });

            });

        }

    };


    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function () {


        // Set up pagingModel
        ctrl.pagingModel = {
            totalItems: 0,
            itemsPerPage: 50,
            pagesToShow: 5,
            currentPage: 1,
            offset: 0,
            start: 1
        };

        var promises = [
	       ctrl.loadQuotes()
        ];

        $q.all(promises).then(function (responses) {

            ctrl.dataloading = false;

        });

    };

    init();

});