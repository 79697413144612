angular.module('portal')

.controller('DashboardCtrl', function ($rootScope, $scope, $log, $uibModal, $http, $state) {

	'use strict';
    
	var master = $rootScope.master;

	var ctrl = this;
	
	// Open the demo modal
	ctrl.openDemoModal = function(){

		$uibModal.open({
            templateUrl: '/templates/editThingModal.tpl.html',
            controller: 'EditThingModalCtrl as editThingModal',
            windowClass: 'modal--sticky',
            resolve: {
                modalSettings: function(){
                    return {
                        id: 1
                    };
                }
            }
        }).result.then(function(result){
            
			// Deal with result
			console.log('Modal was closed', result);

        });

	}

	
	///////////////////////////////////////////////////////////////
    //  Initialize View
	///////////////////////////////////////////////////////////////

	var init = function () {
		
		

	};

	init();

});