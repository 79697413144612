angular.module('portal')

.controller('AddQuoteModalCtrl', function ($rootScope, $scope, $log, $http, $uibModalInstance, $state, $stateParams, s4pDialogs, six4Auth, modalSettings) {

    'use strict';

    var master = $rootScope.master;
    var ctrl = this;

    ctrl.addFormModel = {};


    ctrl.dismiss = function () {
        $uibModalInstance.dismiss();
    };


    // Submit Add Form
    ctrl.submitForm = function () {

        // Copy the model
        var item = angular.copy(ctrl.addFormModel);

        console.log(item);

        if (item.document) {
            item.documentId = item.document.id;
            delete item.document;
        }

        item.clientId = modalSettings.clientId;

        console.log(item);

        // Send the post request
        $http.post(portalSettings.apiUrl + '/quotes', item, {
            form: ctrl.addForm
        }).then(function (response) {

            $uibModalInstance.close(response.data.record);

        });

    };


    ///////////////////////////////////////////////////////////////
    //  Initialize View
    ///////////////////////////////////////////////////////////////

    var init = function () {
        // Date picker options
        ctrl.datePickerOptions = {
            showWeeks: false,
            startingDay: 1,
            maxMode: 'day',
        };

        console.log(modalSettings.clientId);
    };

    init();

});